<template>
  <scroll-center-dialog v-model="opened_form"
                        @close="closeDialog"
                        @apply="apply"
                        title="Изменить профиль"
                        width="500"
  >
    <v-autocomplete
      :error="hasError('sex')"
      :error-messages="getError('sex')"
      :items="selectors.sex"
      label="Пол<span>*</span>"
      placeholder="Выберите..."
      ref="sex"
      v-model="student_sex"
      required
    >
      <span slot="label">Пол<span class="red--text">*</span>:</span>
    </v-autocomplete>

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      min-width="auto"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="format_date"
          :label="label"
          :hint="hint"
          persistent-hint
          offset-y
          @input="inputValueByText"
        >

          <span slot="label">Дата рождения<span class="red--text">*</span>:</span>
          <template v-slot:prepend>
            <v-icon v-on="on" v-bind="attrs" color="primary">calendar_today</v-icon>
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        ref="picker"
        v-model="value_date"
        no-title
        @input="inputValueByPicker"
      ></v-date-picker>
    </v-menu>

    <v-autocomplete
      :error="hasError('country')"
      :error-messages="getError('country')"
      :items="selectors.countries"
      label="Гражданство<span>*</span>"
      placeholder="Выберите..."
      ref="country"
      required
      v-model="student_country"
    >
      <span slot="label">Гражданство<span class="red--text">*</span>:</span>
    </v-autocomplete>
  </scroll-center-dialog>
</template>

<script>
import UserDataFormMixin from "@/mixins/UserDataFormMixin"
import DebugJsonComponent from "../../../core/components/DebugJsonComponent"
import {loadData, makeGetRequest,sendPostRequest} from "@/helper"
import moment from "moment"
import account from "@/urls/accounts"
import dean from "@/urls/dean"
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

export default {
  name: "ProfileChange",
  components: {
    ScrollCenterDialog,
    DebugJsonComponent
  },
  mixins: [UserDataFormMixin],
  props: {
    required: {
      required: false,
      default: false,
      type: Boolean
    },
    label: {
      required: false,
      default: "Дата рождения"
    },
    hint: {
      required: false,
      default: "ДД.MM.ГГГГ"
    },
    student: {
      required: true,
      type: Object
    },
    opened_form: Boolean
  },
  data: function () {
    return {
      menu: false,
      URL_SELECT_SEX: account.STUDENT_SEX,
      URL_UPDATE_INFO: dean.DEAN.STUDENTS.UPDATE_INFO(this.$route.params.idFaculty, this.$route.params.idStudent),
      format_date: this.formatDate( this.student.user.birth),
      student_country: this.student.user.country,
      value_date: this.student.user.birth,
      student_sex: this.student.user.sex,
      selectors: {
        sex: [],
        countries: []
      }
    }
  },
  watch: {
    "format_date" (val, oval) {
      if(oval === null || oval.length <= val.length)
        this.$nextTick(() => {
          this.format_date = this.checkAndReturnDate(val)
        })
    }
  },
  methods: {
    closeDialog() {
      this.$emit("ended", "")
    },
    apply(){
      sendPostRequest(
        this.URL_UPDATE_INFO,
        {"sex":this.student_sex, "birth":this.format_date, "country":this.student_country},()=>{
          this.loading = false
          this.$emit("refreshItem")
        },()=>{
          this.loading = false
        }
      )
    },
    checkAndReturnDate(val) {
      let result = "";
      const dots = [3, 6];
      let i = 0;
      for(let char of val){
        i++;
        if(dots.indexOf(i) === -1){
          if(/^[0-9]$/g.test(char) && i <= 10)
            result += char
        }else{
          result += "."
          if(char !== "." && i <= 10)
            result += char
        }
      }
      if((result.length === 2 || result.length === 5) && result[result.length - 1] !== ".")
        result += "."
      return result
    },
    formatDate (date) {
      if (!date) return null
      return moment(date).format("DD.MM.YYYY")
    },
    parseDate (date) {
      if (!date) return null
      if (!/[0-9]{2}.[0-9]{2}.[0-9]{4}/g.test(date))
        return null
      return moment(date, "DD.MM.YYYY").format("YYYY-MM-YY")
    },
    inputValueByPicker(){
      this.format_date = this.formatDate(this.value_date)
      this.emitInputValueUpdate()
      this.menu = false
    },
    inputValueByText(){
      this.value_date = this.parseDate(this.format_date);
      if(this.value_date !== null)
        this.emitInputValueUpdate();
    },
    emitInputValueUpdate(){
      this.$emit("input", this.value_date);
    },
    getChoices: function (url) {
      return makeGetRequest(url).then(resp => {
        return resp.json()
      }).then(json => {
        return json
      });
    }
  },

  created() {
    this.getChoices(this.URL_SELECT_SEX)
      .then(choices => {
        this.selectors.sex = choices
      })
    this.getChoices(account.PROFILE.COUNTRY())
      .then(choices => {
        this.selectors.countries = choices
      })

    }
}
</script>

<style scoped>

</style>
