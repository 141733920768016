<template>
  <scroll-center-dialog v-model="opened_form"
                        @close="closeDialog"
                        @apply="apply"
                        width="500"
                        :title="!!id ? 'Изменить направление подготовки' : 'Добавить направление подготовки'"
                        :loading="loading"
  >
    <v-autocomplete
      :error="hasError('level')"
      :error-messages="getError('level')"
      label="Уровень образования"
      :items="selectors.level"
      item-text="text"
      item-value="value"
      v-model="value.level"
      no-data-text="Нет данных"
    />
    <v-text-field
      :error="hasError('name')"
      :error-messages="getError('name')"
      v-model="value.name"
      label="Наименование"
      required
    />
    <v-text-field
      :error="hasError('code')"
      :error-messages="getError('code')"
      v-model="value.code"
      label="Код"
      required
    />
  </scroll-center-dialog>
</template>

<script>
import urls from "@/urls/management";
import choices from "@/urls/choices";
import {loadData} from "@/helper";
import UserDataFormMixin from "@/mixins/UserDataFormMixin"
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

export default {
  components: {ScrollCenterDialog},
  mixins: [UserDataFormMixin],
  name: "TrainingDirectionForm",
  props: {
    id: Number,
    level: String,
    name: String,
    code: String,
    opened_form: Boolean
  },
  data: function (){
    return {
      URL_CREATE: urls.EDUCATION.TRAINING_DIRECTION.CREATE(),
      URL_UPDATE: urls.EDUCATION.TRAINING_DIRECTION.UPDATE(this.id),
      loading : false,
      value: {
        level: this.level || "",
        name: this.name || "",
        code: this.code || ""
      },
      selectors: {
        level: []
      }
    }
  },
  methods: {
    loadSelector(){
      loadData(
        choices.EDUCATION.CHOICES.LEVEL(),
        (data) => {
          this.selectors.level = data;
        }
      )
    },
    closeDialog() {
      this.$emit("ended", "")
    }
  },
  created() {
    this.loadSelector();
  }
}
</script>

<style scoped>

</style>
