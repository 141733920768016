<template>
  <v-container style="height: 100%" v-if="$route.name === names.DEPARTAMENTS.GROUP.LIST">
    <v-card flat>
<!--      <v-card-title class="title subprimary">-->
<!--        <span>Список групп:</span>-->
<!--        <v-spacer></v-spacer>-->
<!--        <v-btn @click="getItems()" color="primary" icon text><v-icon>update</v-icon></v-btn>-->
<!--      </v-card-title>-->
      <v-toolbar
        elevation="0"
        color="style-color-main-gray"
      >
        <v-text-field
          dense
          placeholder="Поиск"
          v-model="search"
          @change="getItems()"
          messages="Для поиска нажмите Enter или кнопку поиск"
        >
        </v-text-field>
        <v-btn icon><v-icon>search</v-icon></v-btn>
        <v-switch
          v-model="archive"
          inset
          hide-details
          label="Показать архивные"
          class="pr-5"
        ></v-switch>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="getItems()"
              class="mr-2"
              medium
            >
              update
            </v-icon>
          </template>
          <span>Обновить данные</span>
        </v-tooltip>
      </v-toolbar>
      <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              class="elevation-1"
              no-data-text="Нет данных"
              :options.sync="pagination"
              :server-items-length="total"
              :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
            >
              <template v-slot:item="props">
                <tr @click="getDetail(props.item)" style="cursor: pointer" class="text-left">
                  <td class="justify-center">{{props.item.litter}}-{{props.item.course}}{{props.item.number}}</td>
                  <td class="justify-center">{{props.item.profile}}</td>
                  <td class="justify-center">{{props.item.direction.code}} {{props.item.direction.name}}</td>
                  <!--                {  "direction": { "name": "Математика", "code": "01.03.01" }, "attachment": true }-->
                </tr>
              </template>
              <template slot="no-data">
                <v-card-text>Данные не загружены</v-card-text>
              </template>
            </v-data-table>
    </v-card>

  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>


<script>
// import Courses  from "../../forms/Courses"
import UserDataMixin from "../../../../../mixins/UserDataMixin"
import {getInstNameByRouterName, makeGetRequest} from "../../../../../helper";
import urls from "@/urls/dean"
import names from "@/modules/dean/routers/names";

export default {
  name: "ListLedGroups",
  // components: {Courses},
  mixins: [UserDataMixin],
  props: {
  },
  data () {
    return {
      URL_LIST: urls.DEAN.CATHEDRA.GROUP.LIST(this.$route.params.idFaculty),
      names:names,
      total: 0,
      search:"",
      items: [],
      loading: true,
      pagination: {},
      archive: false,
      headers: [
        {text: "Группа", sortable: false, align: "justify-center"},
        {text: "Профиль", sortable: false, align: "justify-center" },
        {text: "Направление", sortable: false, align: "justify-center" }
      ]
    }
  },
  watch: {
    pagination: {
      handler () {
        if(this.$route.name === names.DEPARTAMENTS.GROUP.LIST)
          this.getItems()
      },
      deep: true
    },
    archive() {
      this.getItems();
    }
  },


  created() {
    // this.$store.commit('leftmenu/activeRouterChange', this.$route.name);
    // this.$store.commit('leftmenu/selectInstance', getInstNameByRouterName(names.DETAIL));
    this.$store.commit("mainpage/changeTitle", "Список ведомых групп");
  },
  methods: {
    getDetail(item){
      this.$router.push({name: names.DEPARTAMENTS.GROUP.DETAIL, params: {idGroup: item.id }})
    },
    addItem(){
      this.closedForm()
      this.getItems();
    },

    getItems () {
      this.loading = true
      let url = new URL(this.URL_LIST)
      let params = {};
      if(this.search.length > 0)
        params.name = this.search;

      if(this.pagination.page !== undefined) {
        params.page = this.pagination.page;
        params.size = this.pagination.itemsPerPage;
      }
      else{
        params.page = "1";
        params.size = "10";
      }
      params.cathedra = this.$route.params.uidDepartament
      params.archive = this.archive;
      
      url.search = new URLSearchParams(params).toString();

      let sys = true
      makeGetRequest(url).then(resp => {
        if (!resp.ok) {
          sys = false
        }
        return resp.json()
      }) .then(json => {
        this.items = json.results
        this.total = json.count
        this.loading = false
      })
    }
  }
}


</script>
<style scoped>

</style>
