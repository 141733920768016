<template>
<!--  <v-container>-->
    <v-card flat>
<!--      <div>-->
      <v-toolbar flat dense class="body-1">
        <v-toolbar-title v-if="title">{{title}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon @click="openCreateForm">
            <v-icon>add</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="semesters"
        :loading="loading"
        :server-items-length="size"
        :options.sync="paginationD"
        :footer-props="{'items-per-page-options':[10,25,50, 100], 'items-per-page-text': 'Число элементов'}"
      >
        <template v-slot:item="props">
          <tr class="text-left">
            <td class="text-xs-left ">{{ props.index + 1 }}</td>
            <td class="text-xs-left">{{ props.item.course }}</td>
            <td class="text-xs-left">
                {{ props.item.semester }}
            </td>
            <td class="text-xs-left" v-if="props.item.hasOwnProperty('subject')">
              <router-link :to="getDetailLink(props.item)">
                {{ props.item.subject }}
              </router-link>
            </td>
            <td class="text-xs-left">{{ props.item.cathedra }}</td>
            <td class="text-xs-left">{{ props.item.control_type }}</td>
            <td>
<!--              <v-btn icon @click="openUpdateForm(props.item.id)">-->
<!--                <v-icon>edit</v-icon>-->
<!--              </v-btn>-->
              <v-btn icon @click="openDeleteApplier(props.item)">
                <v-icon>remove</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
<!--      <DebugJsonComponent :json="$props"></DebugJsonComponent>-->
      <Form :curriculum_id="curriculum_id" :semester_id="dialogs.form.id" v-if="dialogs.form.opened" :subject="subject" @close="closeCreateForm"></Form>
      <ApplyDialog
        v-if="dialogs.delete.applier"
        :opened="dialogs.delete.applier"
        @close="deleteSemester(dialogs.delete.selected, $event)">
        <template>
          Вы уверены что хотие удалить <strong>{{dialogs.delete.selected.semester}}</strong> семетр
          <strong>{{dialogs.delete.selected.course}}</strong> курса дисциплины
          <strong v-if='dialogs.delete.selected.subject !== undefined'>{{dialogs.delete.selected.subject}}</strong>
        </template>
      </ApplyDialog>
      <MsgDialog
        v-if="dialogs.delete.result.opened"
        :opened="dialogs.delete.result.opened"
        :title="dialogs.delete.result.text"
        :success="dialogs.delete.result.success"
        :warning="dialogs.delete.result.warning"
        @close="dialogs.delete.result.opened = false"
      >
        <span v-if="dialogs.delete.selected !== undefined">
          {{ dialogs.delete.selected.semester.slice(0,1).toUpperCase() }}{{ dialogs.delete.selected.semester.slice(1) }}
          семестр {{ dialogs.delete.selected.course }} курса
        </span> <span v-if="dialogs.delete.selected && dialogs.delete.selected.subject !== undefined">дисциплины {{ dialogs.delete.selected.subject }}</span>
        был удален из списка семестров дисциплины.
      </MsgDialog>
<!--      </div>-->
    </v-card>

  <!--  </v-container>-->
</template>

<script>
  import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
  import urls from "../../urls";
  import {loadData, setUrlParameters, makeDeleteRequest} from "../../../../helper";
  import Form from "./Form";
  import ApplyDialog from "../../../core/components/ApplyDialog";
  import MsgDialog from "../../../core/components/MsgDialog";
  import editor_name from "@/modules/curriculumEditor/routers/names";

  export default {
    name: "SemestersListBase",
    components: {
      DebugJsonComponent,
      Form,
      ApplyDialog,
      MsgDialog
    },
    props: {
     pagination:{
       type: [Object],
       default: undefined
     },
      title: {
        type: [String],
        default: undefined
      },
      loading: {
        type: [Boolean],
        default: false
      },
      curriculum_id: Number,
      semesters: Array,
      next: {
        type: String,
        default: ""
      },
      prev: {
        type: String,
        default: ""
      },
      size: {
        type: Number,
        default: 0
      },
      subject: {
        type: Number,
        default: undefined
      }
    },
    data(){
      let headers = [
        {text: "№", sortable: false, align: "left", width: 40},
        {text: "Курс", sortable: false, align: "left"},
        {text: "Семестр", sortable: false, align: "left"},
        {text: "Предмет", sortable: false, align: "left"},
        {text: "Кафедра", sortable: false, align: "left"},
        {text: "Тип контроля", sortable: false, align: "left"},
        {text: "Действия", sortable: false, align: "center", width: 160}
      ];
      return {
        paginationD: this.pagination,
        dialogs: {
          delete: {
            selected: null,
            applier: false,
            result: {
              opened: false,
              text: "",
              success: true,
              warning: false
            }
          },
          form: {
            id: undefined,
            opened: false
          }
        },
        selected: undefined,
        headers: headers
      }
    },
    watch: {
      paginationD: {
        handler () {
          this.$emit("setPag",this.paginationD);
        },
        deep: true
      }
    },
    methods: {
      getDetailLink(item){
        return {name: editor_name.CURRICULUM.SEMESTERS.DETAIL, params: {curriculum_id: this.curriculum_id, semester_id: item.id}}
        //{name: editor_name.CURRICULUM.DETAIL, params: {curriculum_id: this.curriculum_id, subject_id: item.id}}
        // {name: editor_name.CURRICULUM.DETAIL, params: {curriculum_id: item.id}}
      },
      update(){
        this.$emit("update");
      },
      openCreateForm(){
        this.dialogs.form.id = undefined;
        this.dialogs.form.opened = true;
      },
      openUpdateForm(selected){
        this.dialogs.form.id= selected;
        this.dialogs.form.opened = true;
      },
      closeCreateForm(){
        this.dialogs.form.id = undefined;
        this.dialogs.form.opened = false;
        this.update();
      },
      openDeleteApplier(selected){
        this.dialogs.delete.selected = selected;
        this.dialogs.delete.applier = true;
      },
      deleteSemester(item, apply) {
        this.dialogs.delete.applier = false;
        if (apply) {
          let subject_id = this.subject
          if(item.hasOwnProperty("subject_id"))
            subject_id = item.subject_id
          let url = urls.NAMES.EDITOR.SUBJECT.SEMESTER.DESTROY(subject_id, item.id);
          this.dialogs.delete.result.warning = true;
          this.dialogs.delete.result.success = false;
          this.dialogs.delete.result.opened = true;
          // this.selected = null;
          url = setUrlParameters(url, {semester_id: this.semester_id});
          makeDeleteRequest(url)
            .then(resp => {
              if (!resp.ok) {
                resp.json().then((json) => {
                  throw json;
                }).catch((error) => {
                  this.dialogs.result.text = error;
                  this.dialogs.result.warning = true;
                  this.dialogs.result.success = false;
                  this.dialogs.result.opened = true;
                  this.updatedSignal();
                });
              }else{
                this.update();
                // this.dialogs.result.text = 'Успешно удален';
                // this.dialogs.result.warning = false;
                // this.dialogs.result.success = true;
                // this.dialogs.result.opened = true;
                // this.updatedSignal();
              }
            })

        }
      }
    },
    created() {
    }
  }
</script>

<style scoped>

</style>
