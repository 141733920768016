<template>
  <v-app id="inspire">
    <v-content >
      <v-container fluid fill-height >
  <v-layout align-center column fill-heigh justify-center  >
    <v-card>
      <v-card-text>
        Уважаемый пользователь, Вы покидаете данный раздел сайта
      </v-card-text>
      <v-card-text>
        Вы будуте перенаправлены на <a href="https://portal.muctr.ru">portal.muctr.ru</a>
      </v-card-text>
    </v-card>
  </v-layout>
      </v-container>
    </v-content>

  </v-app>

</template>

<script>
  import {SemipolarSpinner} from "epic-spinners"

  export default {
    name: "LoadPortal",
    components: {
      SemipolarSpinner
    },
    created() {
      setTimeout(() => {
          document.location.href = process.env.VUE_APP_LOGIN_HOST;
      }, 10);
    }
  }
</script>

<style scoped>

</style>
