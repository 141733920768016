<template>
  <v-container style="height: 100%" v-if="$route.name === names.DEPARTAMENTS.STUDENTS.LIST">
    <v-card flat>
      <v-toolbar
        elevation="0"
        color="style-color-main-gray"
      >
        <v-text-field
          dense
          placeholder="Поиск"
          v-model="search"
          @change="getItems()"
          messages="Для поиска нажмите Enter или кнопку поиск"
        >
        </v-text-field>
        <v-btn icon>
          <v-icon>search</v-icon>
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="getItems()"
              class="mr-2"
              medium
            >
              update
            </v-icon>
          </template>
          <span>Обновить данные</span>
        </v-tooltip>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        class="elevation-1"
        no-data-text="Нет данных"
        :options.sync="pagination"
        :server-items-length="total"
        :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
      >
        <template v-slot:item.fio="{ item }">
          <router-link :to="{ name: names.STUDENT.DETAIL,params:{idStudent:item.id}}">
            <v-card-text v-if="item.fio !== '-'">{{ item.fio }}</v-card-text>
            <v-skeleton-loader v-else type="list-item" class="mx-auto"></v-skeleton-loader>
          </router-link>
        </template>
        <template v-slot:item.education_group.litter="{ item }">
          <span>{{ item.education_group.litter }}-{{ item.education_group.course }}{{ item.education_group.number }}</span>
        </template>
        <template v-slot:item.active="{ item }">
          <v-icon color="teal" v-if="item.active">done</v-icon>
          <v-icon v-else>clear</v-icon>
        </template>
        <template slot="no-data">
          <v-card-text>Данные не загружены</v-card-text>
        </template>
      </v-data-table>
    </v-card>

  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>


<script>

import UserDataMixin from "@/mixins/UserDataMixin"
import {makeGetRequest} from "@/helper";
import urls from "@/urls/dean"
import {generateListUidToName} from "@/helper/uidToName";
import names from "@/modules/dean/routers/names";

export default {
  name: "Groups",
  // components: {Courses},
  mixins: [UserDataMixin],
  props: {},
  data() {
    return {
      URL_LIST: urls.DEAN.CATHEDRA.STUDENT.LIST(this.$route.params.idFaculty),
      names: names,
      items: [],
      search: "",
      loading: true,
      total: 0,
      pagination: {},
      headers: [
        {text: "Обучающийся", sortable: false, align: "left", value: "fio"},
        {text: "Программа", sortable: false, align: "left", value: "level"},
        {text: "Номер зачетной книжки", value: "record_book_number", sortable: false, align: "start", width: "10%"},
        {text: "Форма", sortable: false, align: "left", value: "form"},
        {text: "Год поступления", sortable: false, align: "left", value: "entry_year"},
        {text: "Группа", sortable: false, align: "left", value: "education_group.litter"},
        {text: "Кафедра", sortable: false, align: "left", value: "cathedra"},
        {text: "Обучается", sortable: false, align: "left", value: "active"}
      ]
    }
  },
  created() {
  },
  watch: {
    pagination: {
      handler() {
        if (this.$route.name === names.DEPARTAMENTS.STUDENTS.LIST)
          this.getItems()
      },
      deep: true
    }
  },
  methods: {
    getDetail(item, push = false) {
      if (push)
        this.$router.push({
          name: names.STUDENT.DETAIL,
          params: {idStudent: item.id},
          query: {"cathedra": this.$route.params.uidDepartament}
        })
      else
        return {
          name: names.STUDENT.DETAIL,
          params: {idStudent: item.id},
          query: {"cathedra": this.$route.params.uidDepartament}
        }
    },
    addItem() {
      this.closedForm()
      this.getItems();
    },
    getItems() {

      this.loading = true
      let url = new URL(this.URL_LIST)
      let params = {};
      if (this.search.length > 0)
        params.fio = this.search;

      if (this.pagination.page !== undefined) {
        params.page = this.pagination.page;
        params.size = this.pagination.itemsPerPage;
      } else {
        params.page = "1";
        params.size = "10";
      }
      params.cathedra = this.$route.params.uidDepartament
      url.search = new URLSearchParams(params).toString();

      let sys = true
      makeGetRequest(url).then(resp => {
        if (!resp.ok) {
          sys = false
        }
        return resp.json()
      }).then(json => {

        this.items = json.results;
        this.total = json.count
        this.loading = false
        for (let i in this.items) {
          this.$set(this.items[i], "fio", "-")
        }
        generateListUidToName(this.items, "student", "fio")
      })
    }
  }
}


</script>
<style scoped>

</style>
