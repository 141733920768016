<template>
  <tr>
    <td>
      <difference-column :item="item" eios-name="kind" one-name="kind.value"></difference-column>
    </td>
    <td>
      <difference-column :item="item" eios-name="hours"></difference-column>
    </td>
    <td class="d-flex">
<!--      {{modals.link}}-->
      <v-spacer></v-spacer>
      <tooltip-btn tooltip="Полная информация" icon="info" small bottom :loading="loading"
                   @click="openDialog(modals.detail)" v-if="isAdmin"></tooltip-btn>
      <tooltip-btn tooltip="Указать связь" icon="link" small bottom :loading="loading"
                   @click="openDialog(modals.link)" v-if="!isLinked && isOnlyEIOS && isParentLinked"></tooltip-btn>
      <tooltip-btn tooltip="Очистить связь" icon="link_off" small bottom :loading="loading"
                   @click="openDialog(modals.clear)" v-if="isLinked"></tooltip-btn>
      <tooltip-btn tooltip="Обновить данные" icon="update" small bottom :loading="loading"
                   @click="openDialog(modals.update_related)" v-if="isLinked && isParentLinked"></tooltip-btn>
      <tooltip-btn tooltip="Создать объект" icon="add_circle" small bottom :loading="loading"
                   @click="openDialog(modals.create_related)" v-if="isOnlyOne && isParentLinked"></tooltip-btn>
      <tooltip-btn tooltip="Обновить информацию" icon="refresh" small bottom :loading="loading"
                   @click="refresh" v-if="isLinked && isParentLinked"></tooltip-btn>
      <link-action
        v-if="isOnlyEIOS && isParentLinked"
        v-model="modals.link.open"
        :item="item"
        :selector-url="modals.link.selector_url"
        :action-url="modals.link.action_url"
        @reload="refreshByParent"
        preloadable
      >
      </link-action>
      <action-for-tree
        v-if="isLinked"
        v-model="modals.clear.open"
        level="kind"
        :action-url="modals.clear.action_url"
        action-name="очистки информации о связи в ЭИОС и синхронизаторе с 1С Университет по дереву объектов"
        :item="item"
        @reload="refreshByParent"
        applyable
      >
        <template v-slot:title>Очистка информации о связях в дереве</template>
        <template v-slot:apply>Это действие не может быть отменено! Чтобы внести изменения, потребуется повторно связать данный объект.</template>
      </action-for-tree>
      <curriculum-action-for-object
        v-model="modals.create_related.open"
        :item="item"
        action-name="Создание объекта ЭИОС на основании объекта 1С"
        level="kind"
        :action-url="modals.create_related.action_url"
        one
        @reload="refreshByParent"
        v-if="isOnlyOne && isParentLinked"
      ></curriculum-action-for-object>
      <update-related
        v-if="isLinked && isParentLinked"
        v-model="modals.update_related.open"
        :action-url="modals.update_related.action_url"
        :item="item"
        @reload="refresh"
      ></update-related>
      <detail v-model="modals.detail.open" :item="item"></detail>
    </td>
  </tr>
</template>

<script>
import DifferenceColumn from "@/modules/curriculumEditor/pages/difference/components/DifferenceColumn.vue";
import urls from "@/modules/curriculumEditor/urls";
import selectors from "@/urls/selectors";
import DifferenceItemMixin from "@/modules/administrative/mixins/DifferenceItemMixin";
import LinkAction from "@/modules/administrative/dialogs/LinkAction.vue";
import UpdateRelated from "@/modules/administrative/dialogs/UpdateRelated.vue";
import Detail from "@/modules/administrative/dialogs/Detail.vue";
import ActionForTree from "@/modules/curriculumEditor/pages/difference/dialogs/ActionForTree.vue";
import TooltipBtn from "@/modules/administrative/components/TooltipBtn.vue";
import CurriculumActionForObject from "@/modules/curriculumEditor/pages/difference/dialogs/CurriculumActionForObject.vue";

export default {
  name: "Kind",
  components: {TooltipBtn, Detail, DifferenceColumn, UpdateRelated, LinkAction, ActionForTree, CurriculumActionForObject},
  mixins: [DifferenceItemMixin],
  props: {
    semester: undefined
  },
  data() {
    return {
      modals: {
        link: {
          open: false,
          action_url: urls.NAMES.EDITOR.UNIVERSITY.LINK.KIND,
          selector_url: this.callIfParentOne(selectors.SELECTORS.UNIVERSITY.CURRICULUM_KINDS, this.getParentOneGUID(), {"relation_is_empty": true})
        },
        update_related: {
          open: false,
          action_url: urls.NAMES.EDITOR.UNIVERSITY.UPDATE_RELATED.KIND
        },
        clear: {
          open: false,
          action_url: urls.NAMES.EDITOR.UNIVERSITY.CLEAR.KIND
        },
        create_related: {
          open: false,
          action_url: urls.NAMES.EDITOR.UNIVERSITY.CREATE_RELATED.KIND
        }
      }
    }
  },
  computed: {},
  methods: {
    getDifferenceUrl() {
      return urls.NAMES.EDITOR.SEMESTER.LESSON_KIND.DIFFERENCE(this.getEIOSId(), this.getParentEIOSId());
    }
  }
}
</script>

<style scoped>

</style>
