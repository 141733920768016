<template>
  <!-- TODO: Width need been check, now is 256px, old is 300px-->
  <v-navigation-drawer
    clipped
    app
    persistent
    v-if="leftmenu.enabled"
    v-model="leftmenu.show"
    id="main-left-menu"
    width="300"
  >
    <div class="d-flex flex-column justify-space-between fill-height">
      <CurrentLeftMenu :items="leftmenu.items"></CurrentLeftMenu>
      <InstanceLeftMenu :instances="leftmenu.instances" :current_instance="leftmenu.currentInstance"></InstanceLeftMenu>
    </div>
  </v-navigation-drawer>
</template>

<script>
  import {mapState} from "vuex"
  import CurrentLeftMenu from "@/modules/menus/left_menu/CurrentLeftMenu";
  import InstanceLeftMenu from "@/modules/menus/left_menu/InstanceLeftMenu";

  export default {
    name: "leftmenu",
    components: {CurrentLeftMenu, InstanceLeftMenu},
    data() {
      return {}
    },
    computed: mapState({
      leftmenu: state => state.leftmenu
    }),
    methods: {},
    created() {}
  }

</script>

<style scoped>

</style>
