<template>
  <v-container>
    <v-toolbar
      elevation="0"
      color="style-color-main-gray"
    >
    <v-text-field
      dense
      placeholder="Поиск"
      v-model="search.value"
      @change="searchData"
      messages="Для поиска нажмите Enter или кнопку поиск"
    >
    </v-text-field>
    </v-toolbar>
    <v-row v-if="cathedras.length > 0">

      <v-col cols="10" offset="1">
        <v-row justify="space-around">
          <v-data-iterator
            :items="cathedras"
            no-data-text="Кафедры не загружены"
            no-results-text="Кафедры не были найдены"
            :page.sync="pagination.page"
            :items-per-page.sync="pagination.size"
            :server-items-length="pagination.count"
            :loading="pagination.loading"
            class="d-flex flex-row flex-wrap"
            hide-default-footer
          >
            <template v-slot:item="{ item }">
<!--              v-for="(cathedra, index) in cathedras" :key="`cathedra_card_${index}`"-->
              <v-col cols="12" md="6" lg="4">
                <router-link
                  :to="{
                    name: names.CathedraDetail,
                    params: {
                      cathedra_uid: item.uid.toUpperCase()
                    }
                  }"
                  style="text-decoration: none"
                  @click="selectCathedra"
                >
                  <v-card
                    elevation="3"
                    class="blue lighten-4 elevation-10 ma-2 pa-2 d-flex fill-height"
                    min-height="200px"
                  >
<!--                    <div-->
<!--                      class="black&#45;&#45;text cathedra_item_in_list mt-auto fill-height"-->
<!--                      style="width: 100%;"-->
<!--                      v-if="item.abbr"-->
<!--                    >-->
<!--                      {{ item.abbr }}-->
<!--                    </div>-->
                    <v-row
                      class="black--text title my-auto"
                    >
                      <v-col
                        style="word-break: break-word; overflow-y: hidden; width: 100%;"
                      >
                        {{ item.name }}
                      </v-col>
                    </v-row>
                  </v-card>
                </router-link>
              </v-col>
            </template>
            <template v-slot:footer="{ pagination, options, updateOptions }">
              <v-col cols="12">
                <v-data-footer class="mt-auto"
                               items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                                :items-per-page-options="pagination_choices"
                               :options="options"
                               :pagination="pagination"
                               @update:options="updateOptions"  ></v-data-footer>
              </v-col>
            </template>
          </v-data-iterator>
<!--          -->
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else-if="!load">
      <v-col cols="10" offset="1" class="py-6">
        <v-row justify="space-around">
          <v-col cols="12" md="6" xl="4">
            <v-skeleton-loader type="card" height="300px"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6" xl="4">
            <v-skeleton-loader type="card" height="300px"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6" xl="4">
            <v-skeleton-loader type="card" height="300px"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6" xl="4">
            <v-skeleton-loader type="card" height="300px"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6" xl="4">
            <v-skeleton-loader type="card" height="300px"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6" xl="4">
            <v-skeleton-loader type="card" height="300px"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="10" offset="1" class="py-6">
        <v-row justify="space-around">
          <v-col cols="12" md="6" xl="4">
            <v-alert outlined type="warning">По вашему запросу ничего не найдено</v-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {loadData} from "@/helper";
  import urls from "@/urls/cathedras";
  import names from "../../routers/names";
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
  import TitledPageMixin from "@/mixins/TitledPageMixin";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import cathedras from "@/urls/cathedras";
  import OneFieldBaseSeachMixin from "@/mixins/OneFieldBaseSearchMixin";

  export default {
    name: "Index",
    mixins: [
      TitledPageMixin,
      PaginatedDataMapperMixin,
      OneFieldBaseSeachMixin
    ],
    components: {
      DebugJsonComponent
    },
    computed: {
      names: () => names
    },
    data: function () {
      return {
        load:false,
        pagination: {
          size: this.$vuetify.breakpoint.lgAndUp ? 9: 10
        },
        pagination_choices: [
          this.$vuetify.breakpoint.lgAndUp ? 9: 10,
          this.$vuetify.breakpoint.lgAndUp ? 24: 25,
          this.$vuetify.breakpoint.lgAndUp ? 48: 50
        ],
        page_title: "Кафедры",
        width: "300px",
        height: "300px",
        cathedras: [],
        search: {
          parameter: "name"
        }
      }
    },
    methods: {
      selectCathedra(item){
        this.$parent.name_cathedra = item.name;
        this.$parent.abbr_cathedra = item.abbr;
        this.$router.push({
          name: names.CathedraDetail,
          params: {
            cathedra_uid: item.uid.toUpperCase()
          }
        })
      },
      logData(data){
      },
      loadData(url=undefined) {
        this.loadCathedras(url);
      },
      loadCathedras: function (url=undefined) {
        this.load=false
        this.setLoading();
        if(url === undefined) {
          this.setCurrentLoadUrl(
            this.addSizeParameter(urls.CATHEDRA.LIST())
          )
          url = this.getCurrentLoadUrl()
        }
        loadData(
          url,
          (data) => {
            this.load=true
            if(data.results.length === 1){
              this.$parent.single_cathedra = true;
              this.$parent.name_cathedra = data.results[0].name;
              this.$parent.abbr_cathedra = data.results[0].abbr;
              this.$router.push({
                name: names.CathedraDetail,
                params: {cathedra_uid: data.results[0].uid.toUpperCase()}
              });
            }
            this.mapResults(data, "cathedras");
            this.setNotLoading();
          }
        )
      }
    },
    created() {
      this.loadCathedras();
    }
  }
</script>

<style scoped>
  .cathedra_item_in_list{
    /*height: 100%;*/
    text-align: center !important;
    /*line-height: 200px !important;*/
    font-size: 32px;
    user-select: none;
    overflow-x: hidden;
    text-decoration: underline !important;
  }
  @media (min-width: 600px) {
    .cathedra_item_in_list {
      font-size: 48px;
      height: auto;
    }
  }
</style>
