<template>
  <v-container class="px-5" >
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-toolbar
            elevation="0"
            color="style-color-main-gray"
          >
            <v-text-field
              dense
              hide-details
              label="Поиск"
              v-model="search"
            ></v-text-field>
            <v-btn icon><v-icon>search</v-icon></v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  @click=" loadData()"
                  class="mr-2"
                  medium
                >
                  update
                </v-icon>
              </template>
              <span>Обновить данные</span>
            </v-tooltip>
            <v-btn icon @click="openAddForm"><v-icon>add</v-icon></v-btn>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="admins"
            :items-per-page="10"
            :search="search"
            :loading="loading"
            no-data-text="Работники не загружены"
            no-results-text="Работники не были найдены"
          >
            <template v-slot:loading>
              <template v-for="i in 10">
                <v-skeleton-loader type="table-row" :key="`skeleton${i}`"></v-skeleton-loader>
              </template>
            </template>
            <template v-slot:item.delete="{item}">
              <v-btn icon @click="removeAdmin(item.id)">
                <v-icon>remove</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <scroll-center-dialog v-model="add.opened"
                          width="800px"
                          ref="add_admins_modal"
                          title="Выдать права административного работника кафедры"
                          @close="closeAddForm"
                          @apply="saveAddedAdmins"
                          :disabled="add.loading"
                          apply-color="success"
                          :applyable="add.selected.length > 0"
    >
      <template v-for="(selected, index) in add.selected">
        <v-autocomplete
          v-model="add.selected[index]"
          :items="add.filteredSelector(selected)"
          :key="`selected_admins_${selected}`"
          :loading="add.loading"
          :disabled="add.loading"
          hide-details
          label="Работник кафедры"
          class="my-3"
        >
          <template v-slot:prepend>
            <v-btn icon @click="add.removeFromSelected(selected)">
              <v-icon>remove</v-icon>
            </v-btn>
          </template>
        </v-autocomplete>
      </template>
      <v-autocomplete
        class="my-5"
        :key="`selector_admins`"
        @change="add.addToSelected($event)"
        :items="add.filteredSelector()"
        :value="add.last"
        :loading="add.loading"
        :disabled="add.loading"
        hide-details
        clearable
        label="Выберите работника кафедры"
      ></v-autocomplete>
    </scroll-center-dialog>
<!--    <DebugJsonComponent :json="admins"></DebugJsonComponent>-->
<!--    <DebugJsonComponent :json="add.selector"></DebugJsonComponent>-->
<!--    <DebugJsonComponent :json="add.selected"></DebugJsonComponent>-->
  </v-container>
</template>

<script>
import {loadData, sendDeleteRequest, sendPostRequest} from "@/helper";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import names from "@/modules/cathedra/routers/names";
import cathedras from "@/urls/cathedras";
import {generateListUidToName} from "@/helper/uidToName";
import selectors from "@/urls/selectors";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

export default {
  name: "AdminList",
  mixins: [TitledPageMixin],
  components: {
    ScrollCenterDialog,
    DebugJsonComponent
  },
  data() {
    return {
      page_title: "Список административных работников",
      cathedra_uid: this.$route.params.cathedra_uid,
      loading: false,
      admins: [],
      headers: [
        {text:"ФИО", value: "fio", sortable: false},
        {text: "Должность", value: "position_held", sortable: false},
        {text: "Отозвать права", value: "delete", sortable: false, width: "10%", align: "right"}
      ],
      search: "",
      add: {
        selector: [],
        selected: [],
        filteredSelector (value=null) {return this.selector.filter(el => this.selected.indexOf(el.value) === -1 || el.value === value)},
        addToSelected (value) {if (value !== "" && value !== undefined) this.selected.push(value); this.last = "";},
        removeFromSelected (value) {this.selected.splice(this.selected.indexOf(value), 1); this.last = "";},
        last: "",
        opened: false,
        loading: false
      }
    }
  },
  methods: {
    loadNames: function (data, uid, fio, finalizer=()=>{}) {
      data.forEach(el => {
        this.$set(el, fio, "")
      });
      generateListUidToName(data, uid, fio, finalizer)
    },
    loadData(){
      this.loading = true;
      loadData(
        cathedras.CATHEDRA.ADMINS.LIST(this.cathedra_uid),
        (data) => {
          this.admins = data
          this.loadNames(this.admins, "user", "fio", () => {this.loading = false;})
        }
      )
    },
    openAddForm(){
      this.add.opened = true;
      this.add.loading = true;
      loadData(
        selectors.SELECTORS.WORKERS.CATHEDRA(this.$route.params.cathedra_uid),
        (data) => {
          this.add.selector = data;
          this.add.selector.forEach(el => {
            this.$set(el, "fio", "");
            this.$set(el, "user", el.text.split(",")[0].trim());
            el.text = el.text.split(",")[1].trim();
          });
          this.add.selector = this.add.selector.filter(el => this.admins.findIndex(ael => ael.user === el.user) === -1)
          generateListUidToName(this.add.selector, "user", "fio", (data) => {
            data.forEach(el => {
              el.text = `${el.fio}, ${el.text}`
            });
            this.add.loading = false;
            this.add.last = "";
          });
        }
      )
    },
    closeAddForm(){
      this.add.opened = false;
      this.add.selected = [];
      this.add.selector = [];
      this.last = "";
    },
    saveAddedAdmins(){
      this.add.loading = true;
      let saver = () => {
        if (this.add.selected.length > 0)
          sendPostRequest(
            cathedras.CATHEDRA.ADMINS.ADD_TO(this.cathedra_uid),
            {
              "position_held_uid": this.add.selected.pop()
            },
            saver
          )
        else {
          this.add.loading = false;
          this.loadData();
          this.closeAddForm();
        }
      }
      saver();
    },
    removeAdmin(id){
      sendDeleteRequest(
        cathedras.CATHEDRA.ADMINS.REMOVE_FROM(
          this.cathedra_uid,
          id
        ),
        () => {
          this.loadData()
        }
      )
    }
  },
  created() {
    this.setPageTitleWithObject("Cathedra", {uid: this.$route.params.cathedra_uid}, " - ")
    this.loadData()
  }
}
</script>

<style scoped>

</style>
