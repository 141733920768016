<template>
  <v-card flat v-if="'direction' in education" class="text-left py-4">
    <v-row  >
      <v-col class="text-left d-flex flex-column px-5 body-1" height="100%" cols="12" >
      <v-row no-gutters align="center">
        <v-col cols="12" sm="4" class="font-weight-bold">Год поступления:</v-col>
        <v-col cols="12" sm="8" class="text-justify">{{ education.entry_year }}</v-col>
      </v-row>
      <v-divider class="my-1"></v-divider>
      <v-row no-gutters align="center">
        <v-col cols="12" sm="4" class="font-weight-bold">Форма обучения:</v-col>
        <v-col cols="12" sm="8" class="text-justify">{{ education.education_group.form || education.form }}</v-col>
      </v-row>
      <v-divider class="my-1"></v-divider>
      <v-row no-gutters align="center">
        <v-col cols="12" sm="4" class="font-weight-bold">Уровень образования:</v-col>
        <v-col cols="12" sm="8" class="text-justify">{{ education.education_group.level || education.level }}</v-col>
      </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row no-gutters align="center">
          <v-col cols="12" sm="4" class="font-weight-bold">Группа:</v-col>
          <v-col cols="12" sm="8" class="text-justify">
            {{education.education_group.litter}}-{{education.education_group.course}}{{education.education_group.number}}</v-col>
        </v-row>
      <v-divider class="my-1"></v-divider>
      <v-row no-gutters align="center">
        <v-col cols="12" sm="4" class="font-weight-bold">Финансирование:</v-col>
        <v-col cols="12" sm="8" class="text-justify">{{ education.financing }}</v-col>
      </v-row>
      <v-divider class="my-1"></v-divider>
      <v-row no-gutters align="center">
        <v-col cols="12" sm="4" class="font-weight-bold">Направление подготовки:</v-col>
        <v-col cols="12" sm="8" class="text-justify">{{ education.direction.code }} {{ education.direction.name }}
        </v-col>
      </v-row>
      <v-divider class="my-1"></v-divider>
      <v-row no-gutters align="center">
        <v-col cols="12" sm="4" class="font-weight-bold">Профиль подготовки:</v-col>
        <v-col cols="12" sm="8" class="text-justify">{{ education.profile }}</v-col>
      </v-row>
      <v-divider class="my-1"></v-divider>
      <v-row no-gutters align="center">
        <v-col cols="12" sm="4" class="font-weight-bold">Кафедра:</v-col>
        <v-col cols="12" sm="8" class="text-justify">{{
            education.education_group.cathedra || education.cathedra
          }}
        </v-col>
      </v-row>
      <v-divider v-if="endDivider"></v-divider>
      </v-col>
    </v-row>

  </v-card>
</template>

<script>
import {getFieldOrEmpty} from "@/helper";

export default {
  name: "EducationTemplate",
  props: {
    endDivider: Boolean,
    title: Boolean,
    education: Object
  },
  data: function () {
    return {}
  },
  methods: {
    get(...fields) {
      let result = this.education;

      for (let field in fields) {
        result = getFieldOrEmpty(result, fields[field]);
      }
      return result;
    }
  }
}
</script>

<style scoped>

</style>
