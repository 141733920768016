<template>
  <v-container style="height: 100%">
    <v-card>
      <v-toolbar
        elevation="0"
        color="style-color-main-gray"
      >
        <v-text-field
          dense
          placeholder="Поиск"
          v-model="search.value"
          @keypress.enter="searchData"
          messages="Для поиска нажмите Enter или кнопку поиск"
        >
        </v-text-field>
        <v-btn icon><v-icon>search</v-icon></v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="loadData() "
              class="mr-2"
              medium
            >
              update
            </v-icon>
          </template>
          <span>Обновить данные</span>
        </v-tooltip>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="isLoading"
        :page.sync="pagination.page"
        :items-per-page.sync="pagination.size"
        :server-items-length="count"
        :options.sync="options"
        :footer-props="{'items-per-page-options':[10,50,100], 'items-per-page-text': 'Число элементов'}"
        class="elevation-1 rounded-b-lg"
        :no-data-text="`${plural_model_name} не загружены`"
        :no-results-text="`${plural_model_name} не были найдены`"
      >
        <template v-slot:item.faculty="{ item }">
          {{ item.faculty.name }}
        </template>
        <template v-slot:item.statuses="{ item }">
          <related-link-indicator :item="item" related_parameter="education_related" tooltip="Связь ЭИОС"></related-link-indicator>
        </template>
        <template v-slot:item.actions="{ item }">

        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>

  import {loadData} from "@/helper";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import names from "@/modules/administrative/routers/names.js";
  import UserDataMixin from "@/mixins/UserDataMixin";
  import TitledPageMixin from "@/mixins/TitledPageMixin";
  import urls from "@/urls/admin";
  import RelatedLinkIndicator from "@/modules/administrative/pages/connector/components/RelatedLinkIndicator.vue";
  import {mapActions, mapGetters} from "vuex";

  export default {
    name: "CurriculumList",
    mixins:[
      PaginatedDataMapperMixin,
      TitledPageMixin
    ],
    components: {
      RelatedLinkIndicator
    },
    props: {},
    data(){
      return {
        page_title: "Группы студентов и аккаунты",
        plural_model_name: "Группы",
        search: {
          parameter: "search"
        },
        pagination: {
          filters: {}
        },
        selectors: {
          model: []
        },
        headers: [
          // {text: "№", sortable: false, align: 'left', value: 'index'},
          {text: "Название", sortable: false, align: "left",weight:"20%",value: "name"},
          {text: "Курс", sortable: false, align: "left",weight:"20%",value: "course"},
          {text: "Факультет", sortable: false, align: "left",weight:"40%",value: "faculty"},
          {text: "Статусы", value: "statuses", sortable: false, align: "center", width: "10%"},
          {text: "Действия", value: "actions", sortable: false, align: "center", width: "10%"}
        ]
      }
    },
    computed: {
      ...mapGetters({
        items: "admin/groups/getGroupItems",
        count: "admin/groups/getGroupCount",
        isLoading: "admin/groups/isGroupLoading",
        getCurrentUrl: "admin/groups/getGroupCurrentUrl"
      })
    },
    methods: {
      ...mapActions({
        loadFaceList: "admin/groups/loadGroupList"
      }),
      updateData(){
        if (this.search.value) {
          this.search.value = ""
        }
        this.loadData()
      },
      loadData(url = undefined) {
        this.loadFaceList({
          url: url,
          urlAppend: (url) => {
            return this.addPageParameter(this.addSizeParameter(url));
          },
          finalizer: (data, url) => {
            this.setCurrentLoadUrl(url);
          }
        })
      }
    },
    created() {
      // if (admin.ADMIN.ALLOWED()){
      //   if (this.getCurrentUrl) {
      //     this.loadFromSavedUrl(this.getCurrentUrl)
      //   } else {
      this.loadData()
      //   }
      //   this.loadSelectors()
      // }
      // else{
      //   this.$router.push({name: names_core.MAIN})
      // }
    }
  }
</script>

<style scoped>

</style>
