<template>
  <div class="page-content" style="margin: 0px; width: 100%">
    <PageHeader></PageHeader>
    <CurriculumList v-if="is_self_route"/>
    <router-view v-else> </router-view>
  </div>
</template>

<script>
  import names from "@/modules/curriculumEditor/routers/names";
  import CurriculumList from "./curriculum/List";
  import LeftMenuChangerMixin from "../../../mixins/LeftMenuChangerMixin";
  import {mapState} from "vuex"
  import PageHeader from "@/modules/menus/PageHeader";
  import {getInstMenu, getInstNameByRouterName} from "@/helper/instances";
  import helper from "@/urls/helper";
  import store from "@/store";
  import dean_names from "@/modules/dean/routers/names";

  export default {
    name: "CurriculumEditorBreadcrumbs",
    components: {
      CurriculumList, PageHeader
    },
    mixins: [
      LeftMenuChangerMixin
    ],
    props: {},
    computed: {
      ...mapState({
        mainpage: state => state.mainpage
      }),
      is_self_route: function (){
        return this.$route.name === names.CURRICULUM.LIST
      }
    },
    data(){
      return {}
    },
    methods: {
      getLeftMenu(){
        let left_menu = [
          {title: "Список учебных планов", icon: "view_list", router: names.CURRICULUM.LIST}
        ];
        if(this.$store.getters["urls/getAllowed"]("education", "editor|relations|workers-list", "list"))
          left_menu.push({title: "Список прав", icon: "admin_panel_settings", router: names.PERMISSION})
        return left_menu
      }
    },
    created() {
      this.$store.commit("leftmenu/enableLeftMenu");
      this.$store.commit("leftmenu/selectInstance", getInstNameByRouterName(names.CURRICULUM.LIST));//'CurriculumEditorCurriculumList'));
      this.$store.commit("leftmenu/replaceInstances", getInstMenu());
    }
  }
</script>

<style scoped>

</style>
