<template>
  <v-card>
    <v-toolbar
      color="style-color-main-gray"
      flat
    >Скачать допуски
      <v-spacer></v-spacer>
      <v-btn @click="closeDialog()" icon fab><v-icon>close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text>
      <v-checkbox
        v-model="current" label="Только допуски за текущую сессию и последнюю">
      </v-checkbox>
      <v-autocomplete
        multiple
        :items="selectors.sessions " v-model="sessions"
        label="Список сессий, с которых нужно выгрузить допуски"
        messages="Если ничего не выбрано выгружается все"
      ></v-autocomplete>
      <v-autocomplete
        multiple
        :items="selectors.academic_years  " v-model="academic_years "
        label="Список учебных годов"
        messages="Если ничего не выбрано выгружается все"
        class="pb-3"
      ></v-autocomplete>
      <v-btn  class="primary -3" @click="sendData()" :loading="loading">Скачать</v-btn>
    </v-card-text>

  </v-card>
</template>

<script>
import {loadData, makeGetRequest, makePostRequest} from "@/helper";
import selectors from "@/urls/selectors";
import UserDataFormMixin from "@/mixins/UserDataFormMixin";
import urls from "@/urls/dean";

export default {
  name: "LoadXlsx",
  // mixins: [UserDataFormMixin],
  data () {
    return {
      current:true,
      loading:false,
      sessions:[],
      academic_years:[],
      selectors:{
        sessions:[],
        academic_years:[]
      }
    }
  },
  methods: {
    sendData(){
      this.loading = true
      let data = {
        "current": this.current
      }
      let academic_years = []
      for (let i in this.academic_years){
        academic_years.push({year: this.academic_years[i]})
      }
      if (academic_years.length > 0)
        data.academic_years = academic_years

      let sessions = []
      for (let i in this.sessions){
        sessions.push({id: this.sessions[i]})
      }
      if (sessions.length > 0)
        data.sessions = sessions

      let status = true

      let url =  urls.DEAN.ADMISSION.FILE(this.$route.params.idFaculty)
      makePostRequest(
        url,data
      ) .then(resp => {
        this.loading = false
        if (!resp.ok) {
          status = false
          alert("Не удалось загрузить файл")
        }
        return resp.blob();
      }) .then(blob => {
        if(status) {
          var a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          a.download = "tolerance.xlsx";
          a.click();
          this.$emit("ended", "")
        }
      })

    },
    closeDialog() {
      this.$emit("ended", "")
    },
    loadSelector(){
      loadData(
        selectors.URLS.ACADEMIC_YEAR_SESSION(),
        (data) => {
          this.selectors.sessions=data
        }
      )
      loadData(
        selectors.URLS.YEAR(),
        (data) => {
          this.selectors.academic_years=data
        }
      )
    }
  },
  created() {
    this.loadSelector();
  }
}
</script>

<style scoped>

</style>
