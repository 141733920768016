import curriculum from "./curriculum";

// const accounts = String(process.env.VUE_APP_ACCOUNTS_PREFIX).replace('/', '');
// const education = String(process.env.VUE_APP_EDUCATION_PREFIX).replace('/', '');

export default ([
  ...curriculum
])


