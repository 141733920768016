import InfoUpdate from "@/modules/core/pages/InfoUpdate";
import LoadSys from "@/modules/core/pages/LoadSys"
import Roles from "@/modules/core/pages/Roles";
const education = String(process.env.VUE_APP_EDUCATION_PREFIX).replace("/", "");

export default ([
  {
    path: "/info",
    name: "Info",
    component: InfoUpdate,
    meta: {
      breadcrumb: "InfoUpdate"
    }
  },
  {
    path: "",
    name: "Main",
    component: LoadSys,
    meta: {
      breadcrumb: "Main"
    }
  },
  {
    path: "/roles",
    name: "Roles",
    component: Roles,
    meta: {
      breadcrumb: "Main",
      requiredAuth: true,
      instances: [
        education
      ]
    }
  }
])


