<template>
  <v-container v-if="is_self_route">
    <v-card>
      <v-toolbar :extended='$vuetify.breakpoint.smAndDown'
                 elevation="0"
                 class="white--text"
                 color="style-color-main-gray"
                 :style="{'height':`${$vuetify.breakpoint.mdAndUp ? 64: 140}px`} "
      >
        <template v-if="$vuetify.breakpoint.mdAndUp" >
          <v-text-field
            dense
            class="pa-6"
            placeholder="Поиск"
            v-model="search.value"
            @change="searchData"
            @click:clear="clearSearchData"
            clearable
            messages="Для поиска нажмите Enter"
          >
          </v-text-field>
          <v-btn icon><v-icon>search</v-icon></v-btn>

          <v-spacer></v-spacer>
          <v-toolbar-title>
            <v-select
              v-if="selectors.previous_semester"
              class="pa-4"
              :items="selectors.previous_semester"
              v-model="pagination.filters.year_semester"
              hide-details
              label="Семестр"
            ></v-select>
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </template>
        <template v-slot:extension v-if='$vuetify.breakpoint.smAndDown'>
          <v-col>
            <v-toolbar-title>
              <v-select
                v-if="selectors.previous_semester"
                :items="selectors.previous_semester"
                v-model="pagination.filters.year_semester"
                hide-details
                label="Семестр"
              ></v-select>
            </v-toolbar-title>

            <v-text-field
              dense
              placeholder="Поиск"
              v-model="search.value"
              @change="searchData"
              @click:clear="clearSearchData"
              clearable
              messages="Для поиска нажмите Enter"
            >
            </v-text-field>
          </v-col>
        </template>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="groups"
        :items-per-page.sync="pagination.size"
        no-data-text="Ведомые группы не загружены"
        no-results-text="Ведомые группы не были найдены"
        :page.sync="pagination.page"
        :loading="pagination.loading"
        :server-items-length="pagination.count"
        :footer-props="{'items-per-page-options':[10,20,50], 'show-current-page': true, 'show-first-last-page': true}"
      >
        <template v-slot:item.name="group">
          <router-link :to="{
            name: names.CathedraArchiveGroupsDetail,
            params: {
              archive: 'archive',
              cathedra_uid: cathedra_uid,
              group_id: group.item.id,
              semester: pagination.filters.year_semester,
            },
            query: {
              year_semester: pagination.filters.year_semester
            }
          }">
            {{group.item.name}}
          </router-link>
        </template>
        <template v-slot:item.subjects="group">
          <v-row class="py-6">
            <template v-for="(subject, index) in group.item.subjects" >
                  <span :key="`subjects_${index}`" class="mx-1"  >
                    {{subject.subject}}<template v-if="index + 1 !== group.item.subjects.length">,</template>
                  </span>
            </template>
          </v-row>
        </template>
      </v-data-table>

<!--      <DebugJsonComponent :json="selectors.previous_semester"></DebugJsonComponent>-->
<!--      <DebugJsonComponent :json="groups"></DebugJsonComponent>-->
    </v-card>
  </v-container>
</template>

<script>
import names from "../../routers/names";
import TitledPageMixin from "../../../../mixins/TitledPageMixin";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import {loadData, makeGetRequest} from "../../../../helper";
import urls from "@/urls/cathedras";
import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
import selectors from "@/urls/selectors"

export default {
  name: "ArchiveGroups",
  mixins: [PaginatedDataMapperMixin,TitledPageMixin],
  components: {DebugJsonComponent},
  data: function() {
    return {
      names: names,
      page_title: "Список архивных групп",
      cathedra_uid: this.$route.params.cathedra_uid,
      groups: [],
      headers: [
        {text:"Группа", sortable: false, align:"center", value: "name", width: "15%"},
        {text:"Дисциплины", sortable: false, align:"start", value: "subjects", width: "85%"}
      ],
      search: {
        parameter: "name"
      },
      selectors:{
        previous_semester: null
      },
      pagination: {
        filters: {
          year_semester: null
        }
      },
      semester: null,
      previous: null
    }
  },
  computed: {
    is_self_route: function () {return names.CathedraGroups === this.$route.name}
  },
  watch: {
  },
  methods:{
    loadData: function (url=undefined) {
      this.setLoading()
      if(url === undefined)
        url = urls.CATHEDRA.GROUPS.LIST.ARCHIVE(this.cathedra_uid,{year_semester: this.pagination.filters.year_semester});
      this.setCurrentLoadUrl(url);
      if(!url) {
        url = this.addFiltersParameter(url);
        this.setCurrentLoadUrl(url);
      }
      loadData(
        url,
        (data) => {
          this.mapResults(data, "groups");
          this.setNotLoading()
        }
      )
    },
    loadSelector(data, url) {
      let sys = true
      makeGetRequest(url).then(resp => {
        if (!resp.ok) {
          sys = false
        }
        return resp.json()
      }).then(json => {
        this.selectors[data] = json
        this.previous = this.selectors.previous_semester.length
        this.pagination.filters.year_semester = this.selectors.previous_semester[0].value
      })
    }
  },
  created() {
    this.setPageTitleWithObject("Cathedra", {uid: this.$route.params.cathedra_uid}, " - ")
    this.loadSelector(
      "previous_semester",
      selectors.SELECTORS.CATHEDRA.ARCHIVE.PREVIOUS_SEMESTER(
        this.$route.params.cathedra_uid.toLowerCase())
    )
    this.loadData()
  }
}
</script>

<style scoped>

</style>
