<template>
  <v-card>
    <v-toolbar class="mb-2" color="style-color-main-gray" flat>
      <v-toolbar-title>
        Контактная информация:
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <template v-slot:extension v-if="$vuetify.breakpoint.mobile && show_extra_actions">
        <v-toolbar-items @click.stop="" class="mx-auto">
          <slot name="extra_actions"></slot>
        </v-toolbar-items>
      </template>
      <v-toolbar-items @click.stop="" v-if="!$vuetify.breakpoint.mobile && show_extra_actions">
        <slot name="extra_actions"></slot>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text class="text-start py-0">
      <slot name="prepend_body"></slot>
      <v-row>
        <v-col class=" " v-if="phones">
          <v-row class="subtitle-1">
            <v-col>Телефоны:
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list v-if="phones.length > 0">
            <v-list-item v-for="(item, idx) in phones" :key="idx">
              <v-list-item-action>
                <v-icon small>phone</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                {{item.code}}{{item.number}}
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-row class="text-center" v-else>
            <v-col>
              К аккаунту не добавлена информация о контактных телефонах
            </v-col>
          </v-row>

        </v-col>
        <v-col  class=" "  v-if="emails">
          <v-row class="subtitle-1">
            <v-col>
              Университетские почты:
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list v-if="emails.length > 0">
            <v-list-item v-for="(item, idx) in emails" :key="idx">
              <v-list-item-action>
                <v-icon small>email</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                {{item.email}}
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-row class="text-center" v-else>
            <v-col>
              К аккаунту не добавлена информация о контактных почтах
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col  class=" " cols="12" v-if="addresses">
          <v-row class="subtitle-1">
            <v-col>Адреса:
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list v-if="addresses.length > 0">
            <v-list-item v-for="(item, idx) in addresses" :key="idx">
              <v-list-item-action>
                <v-icon small>address</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                {{item.free}}
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-row class="text-center" v-else>
            <v-col>
              К аккаунту не добавлена информация о адресах
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <slot name="append_body"></slot>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: "StudentContactsTemplate",
  components: {},
  computed: {},
  props: {
    show_extra_actions: {
      type: Boolean,
      default: true
    },
    phones: {
      default: undefined,
      type: Array
    },
    emails: {
      default: undefined,
      type: Array
    },
    addresses: {
      default: undefined,
      type: Array
    },
    show_last_login: {
      type: Boolean,
      default: true
    },
    show_avatar: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  methods: {
    getAddress(address) {
      let res = "";
      let combine = function (res, field) {
        if (res !== "")
          res += ", ";
        res += field;
        return res
      };
      if (address.region)
        res = combine(res, address.region);
      if (address.autonomy)
        res = combine(res, address.autonomy);
      if (address.area)
        res = combine(res, address.area);
      if (address.city)
        res = combine(res, address.city);
      if (address.district)
        res = combine(res, address.district);
      if (address.locality)
        res = combine(res, address.locality);
      if (address.street)
        res = combine(res, address.street);
      if (address.house)
        res = combine(res, address.house);
      if (address.pavilion)
        res = combine(res, address.pavilion);
      if (address.building)
        res = combine(res, address.building);
      if (address.apartment)
        res = combine(res, address.apartment);
      return res;
    }
  }
}
</script>

<style scoped>

</style>
