<template>
  <v-container class="page-content px-5" style="  width: 100%">
    <v-toolbar
      class="removeBtnPaddingRight rounded-t-lg"
      color="#DADADA"
      flat
    >
      <v-text-field
        single-line
        outlined
        dense
        clearable
        class="dark-primary"
        :disabled="isLoading"
        v-model="search.value" label="Поиск" hide-details @keydown.enter="searchData">
      </v-text-field>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="isLoading"
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.size"
      :server-items-length="count"
      :options.sync="options"
      :footer-props="{'items-per-page-options':[10,50,100]}"
      class="elevation-1 rounded-b-lg"
    >
      <template v-slot:item.fio="{ item }">
        {{ item.lastname }} {{ item.firstname }} {{ item.midname }}
      </template>
      <template v-slot:item.email="{ item }">
        <span v-if="item.email">{{ item.email }}</span>
        <span v-else>Не указана</span>
      </template>
      <template v-slot:item.phone="{ item }">
        <span v-if="item.phone">{{ item.phone }}</span>
        <span v-else>Не указана</span>
      </template>
      <template v-slot:item.statuses="{ item }" >
        <span v-if="item.account">
          <related-link-indicator :item="item.account" related_parameter="accounts_related" tooltip="Связь Portal"></related-link-indicator>
          <related-link-indicator :item="item.account" related_parameter="education_related" tooltip="Связь ЭИОС"></related-link-indicator>
          <related-link-indicator :item="item.account" related_parameter="adc_related" tooltip="Связь AD"></related-link-indicator>
          <related-link-indicator :item="item.account" related_parameter="booker_related" tooltip="Связь букер"></related-link-indicator>
        </span>
        <span v-else>
          Отсутсвует аккаунт
        </span>
      </template>

      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="updateData"
        >
          Обновить
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>


<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import RelatedLinkIndicator from "@/modules/administrative/pages/connector/components/RelatedLinkIndicator.vue";

export default {
  name: "AdminFaceList",
  components: {RelatedLinkIndicator},
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  data() {
    return {
      page_title: "Список физических лиц",
      search: {
        parameter: "search"
      },
      pagination: {
        filters: {}
      },
      selectors: {
        model: []
      },
      headers: [
        {text: "ФИО", value: "fio", align: "start", sortable: true, width: "25%"},
        {text: "Дата рождения", value: "birth", align: "start", sortable: true, width: "10%"},
        {text: "Почта", value: "email", align: "start", sortable: true, width: "15%"},
        {text: "Телефон", value: "phone", align: "start", sortable: true, width: "15%"},
        {text: "Идентификатор", value: "guid", align: "start", sortable: true, width: "20%"},
        {text: "Статусы", value: "statuses", sortable: false, align: "center", width: "15%"}
      ]
    }
  },
  computed: {
    ...mapGetters({
      items: "admin/faces/getFaceItems",
      count: "admin/faces/getFaceCount",
      isLoading: "admin/faces/isFaceLoading",
      getCurrentUrl: "admin/faces/getFaceCurrentUrl"
    })
  },
  watch: {},
  methods: {
    ...mapActions({
      loadFaceList: "admin/faces/loadFaceList"
    }),
    updateData(){
      if (this.search.value) {
        this.search.value = ""
      }
      this.pagination.filters = {
        "model": null
      }
      this.car_filter_model = null
      this.loadData()
    },
    loadData(url = undefined) {
      this.loadFaceList({
        url: url,
        urlAppend: (url) => {
          return this.addPageParameter(this.addSizeParameter(url));
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
        }
      })
    }
  },
  created() {
    // if (admin.ADMIN.ALLOWED()){
    //   if (this.getCurrentUrl) {
    //     this.loadFromSavedUrl(this.getCurrentUrl)
    //   } else {
    this.loadData()
    //   }
    //   this.loadSelectors()
    // }
    // else{
    //   this.$router.push({name: names_core.MAIN})
    // }
  }
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0px;
}
</style>
