import {generateListUidToName} from "@/helper/uidToName";
import {addGetParameters, loadData} from "@/helper";



export default {
  data() {
    return {
      search: {
        value: "",
        parameter: "q",
        in_process: false
      },
      options: {
        sortBy:[]
      },
      pagination: {
        size_name: "size",
        page_name: "page",
        need_reload: false,
        page: 1,
        count: 0,
        size: 10,
        next: false,
        previous: false,
        loading: false,
        current: null,
        filters: {},
        footer_page: {
          "items-per-page-options":[10,25,50],
          "show-current-page": true,
          "show-first-last-page": true
        },
        wait_loader: null
      }
    }
  },
  watch: {
    "options.sortBy": function (nVal, oVal) {
      let url = this.getCurrentLoadUrl();

        if(Object.keys( nVal) !== Object.keys( oVal)){
        if(Object.keys( this.options.sortBy).length !== 0 ){
          url = this.getCurrentLoadUrl();
          let sortParam = null
          let sortDesc = null
          let sendSort = ""

          sortParam = Object.assign({},this.options.sortBy)
          sortDesc = Object.assign({},this.options.sortDesc)

          let countKey = Object.keys(sortParam).length
          for (let a in sortDesc) {
            if(sortDesc[a]){
              sortParam[a] = "-"+sortParam[a]
            }
            countKey --;
            if(countKey === 0)
              sendSort = sendSort + sortParam[a]
            else
              sendSort = sendSort + sortParam[a] + ","
          }

          if(url && sendSort.length>0){
            url = this.addGetParameter(
              this.getCurrentLoadUrl(),
              "ordering",
              sendSort
            )

            this.setCurrentLoadUrl(
              this.addFiltersParameter(
                url
              ),
              true
            )
          }
        }
        else {
          if(url){
            const urlObj = new URL(url);
            urlObj.searchParams.delete("ordering")
            this.setCurrentLoadUrl(
              urlObj.toString()
            )
          }
        }
      }

    },
    // 'search.value': function (nVal, oVal) {
    //   if (this.wait_loader !== null)
    //     clearTimeout(this.wait_loader)
    //   this.wait_loader = setTimeout(() => {
    //     this.setCurrentLoadUrl(
    //       this.addSearchParameter(
    //         this.getCurrentLoadUrl()
    //       ),
    //       true
    //     )
    //   }, 1000);
    // },
    "search.value": function (nVal, oVal) {
      if (this.wait_loader !== null)
        clearTimeout(this.wait_loader)
      this.wait_loader = setTimeout(() => {
        if (this.search.value === "") {
          this.clearSearchData();
        }
      }, 1000);
    },
    "pagination.page": function (nVal, oVal) {
      this.setCurrentLoadUrl(
        this.addPageParameter(
          this.getCurrentLoadUrl()
        ),
        true
      )
    },
    "pagination.size": function (nVal, oVal) {
      this.setCurrentLoadUrl(
        this.addSizeParameter(
          this.getCurrentLoadUrl()
        ),
        true
      )
    },
    "pagination.filters": {
      deep: true,
      handler: function (nVal, oVal) {
        this.cleanPaginationPageData();
        this.setCurrentLoadUrl(
          this.addFiltersParameter(
            this.getCurrentLoadUrl()
          ),
          true
        )
      }
    },
    "pagination.current": function (nVal, oVal) {
      if(nVal !== oVal && this.pagination.need_reload) {
        this.pagination.need_reload = false;
        if (this.wait_loader !== null)
          clearTimeout(this.wait_loader)
        this.wait_loader = setTimeout(() => {
          this.loadData(this.getCurrentLoadUrl())
        }, 500);
      }
    }

  },
  methods: {
    cleanPaginationPageData(){
      this.pagination.page = 1;
    },
    cleanPaginationData(){
      this.pagination.size = 10;
      this.pagination.count = 0;
      this.pagination.page = 1;
    },
    mapResults: function (from, to) {
      this[to] = from.results;
      this.mapPagination(from);
    },
    mapPagination: function (from) {
      this.pagination.count = from.count;
      this.pagination.next = from.next !== null;
      this.pagination.previous = from.previous !== null;
    },
    setCurrentLoadUrl(url, need_reload=false){
      this.pagination.need_reload = need_reload;
      this.pagination.current = url;
    },
    getCurrentLoadUrl(){
      return this.pagination.current;
    },
    loadNames: function (data, uid, fio, finalizer=()=>{}) {
      data.forEach(el => {
        this.$set(el, fio, "")
      });
      generateListUidToName(data, uid, fio, finalizer)
    },
    setLoading(){
      this.pagination.loading = true;
    },
    setNotLoading(){
      this.pagination.loading = false;
    },
    addSizeParameter(url){
      return this.addGetParameter(
        url,
        this.pagination.size_name,
        this.pagination.size
      )
    },
    addPageParameter(url){
      return this.addGetParameter(
        url,
        this.pagination.page_name,
        this.pagination.page
      )
    },
    addSearchParameter(url) {
      return this.addGetParameter(
        url,
        this.search.parameter,
        this.search.value
      )
    },
    addFiltersParameter(url) {
      return this.addGetParameters(
        url,
        this.pagination.filters
      )
    },
    addGetParameters(url, parameters){
      return addGetParameters(url, parameters)
    },
    addGetParameter(url, key, value){
      return addGetParameters(url, {
        [key]: value
      })
    },
    searchData: function () {
      this.cleanPaginationPageData();
      this.setCurrentLoadUrl(
        this.addSearchParameter(
          this.getCurrentLoadUrl()
        ),
        true
      )
    },
    reloadData: function(){
      this.clearData();
      this.loadData(this.getCurrentLoadUrl());
    },
    clearSearchData: function () {
      this.cleanPaginationPageData();
      this.search.value = null;
      this.setCurrentLoadUrl(
        this.addSearchParameter(
          this.getCurrentLoadUrl()
        ),
        true
      );
    },
    clearData(){},
    loadData(url=undefined) {}
  }

}
