<template>
  <div v-if="is_self_route">
    <DetailTemplate v-if="group" :group="group" show_curriculum :loaded="loaded" show_students show_subjects></DetailTemplate>
    <v-container v-else>
      <v-row>
        <v-col>
          <v-skeleton-loader
            height="50"
            type="list-item"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-skeleton-loader
            height="200"
            type="card"
          >
          </v-skeleton-loader>
        </v-col>
        <v-col>
          <v-skeleton-loader
            height="200"
            type="card"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
<!--    <DebugJsonComponent :json="loaded"></DebugJsonComponent>-->
<!--    <DebugJsonComponent :json="group"></DebugJsonComponent>-->
  </div>
  <router-view v-else></router-view>
</template>

<script>
  // import LoadData from "@/modules/dean/mixins/LoadData";
  import TitledPageMixin from "../../../../mixins/TitledPageMixin";
  import LeftMenuChangerMixin from "../../../../mixins/LeftMenuChangerMixin";
  import DetailTemplate from "./detail/Base";
  import {loadData, makeGetRequest} from "@/helper";
  // import urls from "@/urls";
  // import names from "../../routers/names";
  import cathedras from "../../../../urls/cathedras";
  import {generateListUidToName} from "@/helper/uidToName";
  import names from "../../routers/names";
  // import {getE} from "../../../../urls/helper";
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";

  export default {
    name: "DetailedLedGroup",
    // mixins: [LoadData],
    mixins: [
      TitledPageMixin,
      LeftMenuChangerMixin
    ],
    components: {
      DetailTemplate,
      DebugJsonComponent
    },
    data: function () {
      return {
        page_title: "Детальное представление группы",
        opened_form: false,
        // items: undefined,
        attachment: false,
        cathedra_uid: this.$route.params.cathedra_uid.toUpperCase(),
        group_id: this.$route.params.group_id,
        group: null,
        loaded: {
          group: false,
          curriculum: false,
          semesters: false,
          students: false
        }
      }
    },
    computed: {
      is_self_route: function () {
        return names.CathedraGroupsDetail === this.$route.name
      },
      taught: function () {
        return this.$route.params.taught === "taught"
      },
      detailUrl: function () {
        return cathedras.CATHEDRA.GROUPS.DETAIL
      }
    },
    methods: {
      isReplaceMenu(menu) {
        return menu.length > 0;
      },
      getLeftMenu() {
        if(this.$route.query.teacher !== undefined)
          return [
            {
              title: "Вернуться к преподавателю",
              icon: "arrow_back_ios",
              included: false,
              router: {
                name: names.CathedraTeachersDetailGroups,
                params: {
                  cathedra_uid: this.$route.params.cathedra_uid,
                  teacher_uid: this.$route.query.teacher
                }
              }
            }
          ];
        else if(this.$route.query.student !== undefined)
          return [
            {
              title: "Вернуться к обучающемуся",
              icon: "arrow_back_ios",
              included: false,
              router: {
                name: names.CathedraStudentsDetail,
                params: {
                  cathedra_uid: this.$route.params.cathedra_uid,
                  student_id: this.$route.query.student,
                  taught: this.$route.params.taught,
                  reload_left_menu: true
                },
                query: {
                  group: this.$route.params.group_id
                }
              }
            }
          ];
        else
          return []
      },
      loadGroup() {
        loadData(
          this.detailUrl(this.cathedra_uid, this.group_id),
          (data) => {
            this.group = data;
            this.loaded.group = false;
            this.loaded.curriculum = true;
            loadData(
              cathedras.getEduUrlWithParameters(
                this.group.students.basename,
                this.group.students.action,
                this.group.students.params
              ),
              (data) => {
                this.group.students = data;
                this.group.students.forEach((el) => {
                  this.$set(el, "fio", "");
                });
                generateListUidToName(this.group.students, "user", "fio");
                this.loaded.students = true;
              }
            );
            loadData(
              cathedras.getEduUrlWithParameters(
                this.group.semesters.basename,
                this.group.semesters.action,
                this.group.semesters.params
              ),
              (data) => {
                this.group.semesters = data;// = data;
                let teachers = [];
                this.group.semesters.forEach(el => {
                  el.teachers.forEach(el => {
                    if(!teachers.find(fel => {return fel.uid === el.teacher}))
                      teachers.push({uid: el.teacher, fio: ""});
                  });
                });
                generateListUidToName(teachers, "uid", "fio", (data) => {
                  this.group.semesters.forEach(el => {
                    el.teachers.forEach(el => {
                      let teacher = data.find(fel => {return fel.uid === el.teacher});
                      this.$set(el, "fio", teacher.fio);
                    });
                  });
                });
                this.loaded.semesters = true;
              }
            )

          }
        )
      }
    },
    created() {
      this.loadGroup()
    }
  }
</script>

<style scoped>

</style>
