<template>
  <scroll-center-dialog v-model="opened_form"
                        @close="closeDialog"
                        @apply="apply"
                        :loading="loading"
                        :title="!!id ? 'Изменить' : 'Добавить'"
                        width="500"
  >
    <v-autocomplete
      outline
      :error="hasError('level')"
      :error-messages="getError('level')"
      :items="selectors.level"
      no-data-text="Нет данных"
      label="Уровень образования"
      v-model="value.level"
    ></v-autocomplete>
    <v-autocomplete
      outline
      :error="hasError('semester')"
      :error-messages="getError('semester')"
      :items="selectors.semester"
      no-data-text="Нет данных"
      label="Семестр"
      v-model="value.semester"
    ></v-autocomplete>
    <v-autocomplete
      outline
      :error="hasError('rating')"
      :error-messages="getError('rating')"
      :items="selectors.rating"
      no-data-text="Нет данных"
      label="Рейтинговая система"
      v-model="value.rating"
    ></v-autocomplete>
    <v-text-field
      :error="hasError('started')"
      :error-messages="getError('started')"
      label="Начало года"
      v-model="value.started"
      type="date"
    ></v-text-field>
    <v-text-field
      :error="hasError('ended')"
      :error-messages="getError('ended')"
      label="Окончание года"
      v-model="value.ended"
      type="date"
    ></v-text-field>
  </scroll-center-dialog>
</template>

<script>
  import urls from "@/urls/management";
  import UserDataFormMixin from "@/mixins/UserDataFormMixin"
  import {loadData, makeGetRequest} from "@/helper";
  import selectors from "@/urls/selectors"
  import choices from "@/urls/choices"
  import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

  export default {
    name: "YearSemester",
    components: {ScrollCenterDialog},
    mixins: [UserDataFormMixin],
    props: {
      id: Number,
      level: String,
      semester: String,
      started: String,
      end: String,
      opened_form: Boolean
    },
    data() {
      return {
        URL_CREATE: urls.EDUCATION.ACADEMIC_YEAR.YEAR_SEMESTER.CREATE(this.$route.params.idYear),
        URL_UPDATE: urls.EDUCATION.ACADEMIC_YEAR.YEAR_SEMESTER.UPDATE(this.$route.params.idYear, this.id),
        loading : false,
        selectors: {
          rating: [],
          semester: [],
          level: []
        },
        oldValue: {},
        value: {
          id: this.id || undefined,
          name: this.name || "",
          started: this.started || "",
          ended: this.ended || "",
          rating: "",
          year: this.$route.params.idYear
        }
        // errors: {}
      }
    },
    methods: {
      loadSelector(data, url) {
        loadData(
          url,
          (json) => {
            this.selectors[data] = json
          }
        )
      },
      closeDialog() {
        this.$emit("ended", "")
      }
    },
    created() {
      this.loadSelector("rating", selectors.SELECTORS.ACADEMIC_YEAR.RATING_SYSTEM())
      this.loadSelector("semester", choices.EDUCATION.CHOICES.AY_SEMESTER())
      this.loadSelector("level", choices.EDUCATION.CHOICES.LEVEL())
      if (this.id) {
        loadData(
          this.URL_UPDATE,
          (json) => {
            this.value = json
            this.value.rating = json.rating.id
            this.Oldvalue = Object.assign({}, this.value)
          }
        )
      }
    }
  }

</script>

<style scoped>

</style>
