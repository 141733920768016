

import helper from "./helper";
import {getUrlAllowed, getUrlWithParameters} from "@/helper/urls";

let getEduUrlWithParameters = helper.getEduUrlWithParameters;
let makeEduMediaUrl = helper.makeEduMediaUrl;
let getAllowedEduUrl = helper.allowedEduUrl


export default {
  getEduUrlWithParameters,
  makeEduMediaUrl,
  getByRecievedUrlData: (url, gets=null) => {
    return getEduUrlWithParameters(
    url.basename,
    url.action,
    url.params,
    gets
  )} ,
  STRING:{
    PRESENTATION:
      (gets = null) => getEduUrlWithParameters(
        "worker|relations|string|presentation",
        "post",
        {},
        gets
      )
  },
  ADMIN: {
    COURSE: {
      LIST: ( gets = null) =>
        getEduUrlWithParameters(
          "admin|core|course-list",
          "list",
          {},
          gets
        ),
      DELETE: (id, gets = null) =>
        getEduUrlWithParameters(
          "admin|core|course-detail",
          "destroy",
          {
            id: id
          },
          gets
        ),
      CREATE: ( gets = null) =>
        getEduUrlWithParameters(
          "admin|core|course-list",
          "create",
          {},
          gets
        ),
      IS_LIST_ALLOWED: () => getAllowedEduUrl(
        "admin|core|course-list", "list"
      )
    },
    REFERENCES: {
      DIRECTION: {
        LIST: ( gets = null) =>
          getEduUrlWithParameters(
            "admin|university|references|direction-list",
            "list",
            {},
            gets
          ),
        CREATE_RELATED: (university_identity, gets = null) =>
          getEduUrlWithParameters(
            "admin|university|direction_create_related",
            "post",
            {
              "university_identity": university_identity
            },
            gets
          ),
        CLEAR: (education_id, gets = null) =>
          getEduUrlWithParameters(
            "admin|university|direction_clear_relation",
            "post",
            {
              "id": education_id
            },
            gets
          ),
        UPDATE_RELATION_IDENTIFY: (education_id, gets = null) =>
          getEduUrlWithParameters(
            "admin|university|direction_relation_identify",
            "post",
            {
              "id": education_id
            },
            gets
          ),
        UPDATE_RELATED: (education_id, gets = null) =>
          getEduUrlWithParameters(
            "admin|university|direction_update_related",
            "post",
            {
              "id": education_id
            },
            gets
          ),
        LINK: (education_id, gets = null) =>
          getEduUrlWithParameters(
            "admin|university|direction_kind_link",
            "post",
            {
              "id": education_id
            },
            gets
          )
      },
      PROFILE: {
        LIST: ( gets = null) =>
          getEduUrlWithParameters(
            "admin|university|references|profile-list",
            "list",
            {
            },
            gets
          ),
        CREATE_RELATED: (university_identity, gets = null) =>
          getEduUrlWithParameters(
            "admin|university|profile_create_related",
            "post",
            {
              "university_identity": university_identity
            },
            gets
          ),
        CLEAR: (education_id, gets = null) =>
          getEduUrlWithParameters(
            "admin|university|profile_clear_relation",
            "post",
            {
              "id": education_id
            },
            gets
          ),
        UPDATE_RELATION_IDENTIFY: (education_id, gets = null) =>
          getEduUrlWithParameters(
            "admin|university|profile_relation_identify",
            "post",
            {
              "id": education_id
            },
            gets
          ),
        UPDATE_RELATED: (education_id, gets = null) =>
          getEduUrlWithParameters(
            "admin|university|profile_update_related",
            "post",
            {
              "id": education_id
            },
            gets
          ),
        LINK: (education_id, gets = null) =>
          getEduUrlWithParameters(
            "admin|university|profile_kind_link",
            "post",
            {
              "id": education_id
            },
            gets
          )
      },
      FACULTY: {
        LIST: ( gets = null) =>
          getEduUrlWithParameters(
            "admin|university|references|faculty-list",
            "list",
            {
            },
            gets
          )
      },
      CATHEDRA: {
        LIST: ( gets = null) =>
          getEduUrlWithParameters(
            "admin|university|references|cathedra-list",
            "list",
            {
            },
            gets
          )
      },
      SUBJECT: {
        LIST: ( gets = null) =>
          getEduUrlWithParameters(
            "admin|university|references|subject-list",
            "list",
            {
            },
            gets
          ),
        CREATE_RELATED: (university_identity, gets = null) =>
          getEduUrlWithParameters(
            "admin|university|level_subject_create_related",
            "post",
            {
              "university_identity": university_identity
            },
            gets
          ),
        CLEAR: (education_id, gets = null) =>
          getEduUrlWithParameters(
            "admin|university|level_subject_clear_relation",
            "post",
            {
              "id": education_id
            },
            gets
          ),
        UPDATE_RELATION_IDENTIFY: (education_id, gets = null) =>
          getEduUrlWithParameters(
            "admin|university|level_subject_relation_identify",
            "post",
            {
              "id": education_id
            },
            gets
          ),
        UPDATE_RELATED: (education_id, gets = null) =>
          getEduUrlWithParameters(
            "admin|university|level_subject_update_related",
            "post",
            {
              "id": education_id
            },
            gets
          ),
        LINK: (education_id, gets = null) =>
          getEduUrlWithParameters(
            "admin|university|level_subject_kind_link",
            "post",
            {
              "id": education_id
            },
            gets
          )
      }
    },
    EDUCATION: {
      LIST: ( gets = null) =>
        getEduUrlWithParameters(
          "admin|university|education-list",
          "list",
          {
          },
          gets
        )
    },
    FACE: {
      LIST: ( gets = null) =>
        getEduUrlWithParameters(
          "admin|university|face-list",
          "list",
          {
          },
          gets
        )
    },
    GROUP: {
      LIST: ( gets = null) =>
        getEduUrlWithParameters(
          "admin|university|group-list",
          "list",
          {
          },
          gets
        )
    },
    CURRICULUM: {
      LIST: ( gets = null) =>
        getEduUrlWithParameters(
          "admin|university|curriculum-list",
          "list",
          {
          },
          gets
        )
      // LINK: ( gets = null) =>
      //   getEduUrlWithParameters(
      //     'admin|university|curriculum-list',
      //     "list",
      //     {
      //     },
      //     gets
      //   ),
      // LINK_SUBJECT: ( gets = null) =>
      //   getEduUrlWithParameters(
      //     'admin|university|curriculum-list',
      //     "list",
      //     {
      //     },
      //     gets
      //   ),
      // LINK_SEMESTER: ( gets = null) =>
      //   getEduUrlWithParameters(
      //     'admin|university|curriculum-list',
      //     "list",
      //     {
      //     },
      //     gets
      //   ),
      // LINK_KIND: ( gets = null) =>
      //   getEduUrlWithParameters(
      //     'admin|university|curriculum-list',
      //     "list",
      //     {
      //     },
      //     gets
      //   ),
    }

  }

}
