import names from "@/modules/administrative/routers/names";

import CurriculumList from "@/modules/administrative/pages/connector/curriculums/List.vue"
import GroupList from "@/modules/administrative/pages/connector/groups/List.vue"
import FaceList from "@/modules/administrative/pages/connector/faces/List.vue"
import EducationList from "@/modules/administrative/pages/connector/educations/List.vue"

const accounts = String(process.env.VUE_APP_ACCOUNTS_PREFIX).replace("/", "");
const education = String(process.env.VUE_APP_EDUCATION_PREFIX).replace("/", "");


export default ([

  {
    path: "curriculums",
    name: names.CURRICULUM.LIST,
    component: CurriculumList,
    meta: {
      breadcrumb: "Список учебных планов 1С",
      requiredAuth: true,
      instances: [
        education
      ]
    }
  },
  {
    path: "groups",
    name: names.GROUP.LIST,
    component: GroupList,
    meta: {
      breadcrumb: "Список групп 1С",
      requiredAuth: true,
      instances: [
        education
      ]
    }
  },
  {
    path: "faces",
    name: names.FACE.LIST,
    component: FaceList,
    meta: {
      breadcrumb: "Список лиц 1С",
      requiredAuth: true,
      instances: [
        education
      ]
    }
  },
  {
    path: "educations",
    name: names.EDUCATION.LIST,
    component: EducationList,
    meta: {
      breadcrumb: "Список образований 1С",
      requiredAuth: true,
      instances: [
        education
      ]
    }
  }
])
