import {sendPostRequest} from "@/helper";
import helper from "@/urls/helper";

const state = {
  cash: {}
}

const getters = {
  getCash: state => (type, uid, id) => {
    return state.cash[`${type}-${id !== undefined ? id : uid}`]
  },
  checkCash: state => (type, uid, id) => {
    if (!state.cash.hasOwnProperty(`${type}-${id !== undefined ? id : uid}`))
      return false
    else
      return true
  }
}

const actions = {
  loadStringValue: ({commit, dispatch, getters}, {type, uid, id, short, finalizer }) => {
    let value = getters.getCash(type, uid, id)
    if (!value){
      let url = helper.getEduUrlWithParameters("worker|relations|string|presentation", "post")
      sendPostRequest(
        url,
        [{
          "type": type,
          "uid": uid,
          "id": Number(id),
          "short": short
        }],
        (final) => {
          commit("set_string_value", {value: final[0].str, type: type, id: id, uid: uid})
          finalizer(final[0].str)
        }
      );
    }
    else {
      finalizer(value)
    }
  }
}

const mutations = {
  set_string_value:  (state, {value, type, id, uid}) => {
    state.cash[`${type}-${id !== undefined ? id : uid}`] = value
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
