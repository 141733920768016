<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-toolbar
          elevation="0"
          color="grey lighten-1"
          class="white--text"
        >
          <v-text-field
            dense
            hide-details
            v-model="search"
            label="Поиск"
          ></v-text-field>
          <v-btn icon><v-icon>search</v-icon></v-btn>
        </v-toolbar>
        <v-dialog persistent v-model="opened_confirm" width="500">
          <v-card>
            <v-card-title>Подтвердите удаление</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-4">
              Вы уверены, что хотите удалить?<br>
            </v-card-text>
            <v-card-actions>
              <v-flex>
                <v-btn
                  @click="untieTeacherFromGroup(select)"
                  color="warning"
                  text
                >
                  Удалить
                </v-btn>
                <v-btn
                  @click="opened_confirm = false"
                  text
                >Отмена
                </v-btn>
              </v-flex>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-data-table
          :headers="headers"
          :items="subjects"
          :search="search"
          :expanded.sync="expanded"
          show-expand
        >
          <template v-slot:item.subject="props">
            <div>
              <v-tooltip bottom v-if="props.item.mottled">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="ma-0"
                    medium
                    :style="Rotate(props.item.half)"
                  >
                    hourglass_bottom
                  </v-icon>
                </template>
                <span>{{props.item.half}} половина семестра</span>
              </v-tooltip>
              {{props.item.subject}}
            </div>
          </template>

          <template v-slot:item.group="props">
            <td>
              {{props.item.group.name}}
            </td>
          </template>
          <template v-slot:item.actions="props">
            <td>
              <v-btn icon @click="setUntieTeacherFromGroup(props.item)"><v-icon >remove_circle</v-icon></v-btn>
            </td>
          </template>
          <template v-slot:expanded-item="subject">
            <td :colspan="subject.headers.length" class="mx-0 px-0">
              <v-card class="ma-1">
                <v-data-table
                  v-if="subject.item.students instanceof Array"
                  :headers="students_headers"
                  :items="subject.item.students"
                >
                  <template v-slot:item.fio="student">
                    <v-skeleton-loader type="text" v-if="student.item.fio === ''"></v-skeleton-loader>
                    <router-link :to="makeStudentLink(student.item)" :class="{'font-weight-bold': student.item.headman}" v-else>
                      {{student.item.fio}}
                    </router-link>

                  </template>
<!--                  <template v-slot:item.group="student">-->
<!--                    <span :class="{'font-weight-bold': student.item.headman}">-->
<!--                      <router-link :to="makeGroupLink(student.item)">-->
<!--                        {{student.item.education_group.name}}-->
<!--                      </router-link>-->
<!--                    </span>-->
<!--                  </template>-->
                  <template v-slot:item.headman="student">
                    <v-icon color="success" v-if="student.item.headman">
                      check
                    </v-icon>
                    <v-icon v-else>
                      remove
                    </v-icon>
                  </template>
                </v-data-table>
                <v-skeleton-loader v-else type="table"></v-skeleton-loader>
<!--                <DebugJsonComponent :json="subject.item" :status="subject.headers.length"></DebugJsonComponent>-->
              </v-card>

            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
<!--    <DebugJsonComponent :json="subjects"></DebugJsonComponent>-->
  </v-container>
</template>

<script>
  import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
  import TitledPageMixin from "../../../../mixins/TitledPageMixin";
  import cathedras from "../../../../urls/cathedras";
  import names from "../../routers/names";
  import {loadData, sendPostRequest} from "../../../../helper";
  import {generateListUidToName} from "../../../../helper/uidToName";

  export default {
    name: "Subjects",
    components: {
      DebugJsonComponent
    },
    mixins: [TitledPageMixin],
    props: {
      teacher_fio: {
        required: false,
        type: String
      }
    },
    data: function(){
      return {
        search:"",
        select: undefined,
        opened_confirm: false,
        subjects: undefined,
        expanded: [],
        headers: [
          {text: "", value: "data-table-expand"},
          {text: "Предмет", value: "subject", width: "55%", sortable: false},
          {text: "Группа", value: "group", width: "15%", sortable: false},
          {text: "Форма контроля", value: "control_type", width: "10%", sortable: false},
          {text: "Виды занятий", value: "type", width: "10%", sortable: false},
          {text: "", value: "actions", width: "5%", filterable: false, sortable: false}

        ],
        students_headers: [
          {text: "ФИО", value: "fio", width: "40%", filterable: true, sortable: true},
          {text: "Кафедра", value: "cathedra", width: "45%", filterable: true, sortable: false},
          // {text: 'Группа', value: 'group', width: '15%', align: 'center', filterable: true, sortable: false},
          {text: "Староста", value: "headman", width: "15%", align: "center", filterable: false, sortable: false}
        ]
      };
    },
    watch: {
      expanded: function(val){
        val.forEach(subject => {
          if(subject.students.basename !== undefined){
            subject.students.get_params.size = 100;
            loadData(
              cathedras.getByRecievedUrlData(subject.students, subject.students.get_params),
              (data) => {
                subject.students = data.results;
                subject.students.forEach(student => {
                  this.$set(student, "fio", "");
                });
                generateListUidToName(subject.students, "student", "fio");
              }
            )
          }
        });
      }
    },
    computed: {
      page_title(){
        return `${this.teacher_fio}: Список дисциплин`;
      }
    },
    methods: {
      Rotate(half) {
        let sign = ""
        if (half === "вторая")
          sign = "-"
        return "transform: rotate(" + sign + "90deg)"
      },
      setUntieTeacherFromGroup(item){
        this.select = item
        this.opened_confirm=true
      },
      makeStudentLink: function(student) {
        return {
          name: names.CathedraStudentsDetail,
          params: {
            cathedra_uid: this.$route.params.cathedra_uid,
            student_id: student.id,
            taught: student.attachment? undefined: "taught"
          },
          query: {
            teacher: this.$route.params.teacher_uid
          }
        }
      },
      makeGroupLink: function(student) {
        // console.log(id)
        return {
          name: names.CathedraGroupsDetail,
          params: {
            cathedra_uid: this.$route.params.cathedra_uid,
            group_id: student.education_group.id,
            taught: student.attachment?undefined:"taught"
          },
          query: {
            teacher: this.$route.params.teacher_uid
          }
        }
      },
      loadSubjects() {
        loadData(
          cathedras.CATHEDRA.TEACHERS.SUBJECTS(this.$route.params.cathedra_uid, this.$route.params.teacher_uid),
          (data) => {
            this.subjects = data;
            // this.attachment = data.url.attachment;
          }
        )
      },
      untieTeacherFromGroup(group_subject){
        sendPostRequest(
          cathedras.CATHEDRA.SUBJECT.TEACHERS_LINK_SAVE(
            this.$route.params.cathedra_uid,
            group_subject.subject_id
          ),
          {
            teachers: [
              {
                teacher: this.$route.params.teacher_uid,
                group_semester: group_subject.id
              }
            ]
          },
          () => {
            this.select = undefined
            this.opened_confirm=false
            this.loadSubjects();
          }
        )
      }
    },
    created() {
      this.loadSubjects()
    }
  }
</script>

<style scoped>

</style>
