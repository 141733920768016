<template>
  <v-container>
    <v-card>
      <v-toolbar elevation="0" color="style-color-main-gray">
        <v-toolbar-title>Рейтинговые системы:</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn @click="opened_form = true" color="secondary" text>
            <v-icon>add</v-icon>
            Добавить
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                text
                v-on="on"
                @click=" getItems()"
                class="mr-2"
                medium
              >
                <v-icon>
                  update
                </v-icon>
              </v-btn>
            </template>
            <span>Обновить данные</span>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>

      <v-data-table
        no-data-text="Нет данных"
        :headers="headers"
        :items="items"
        :loading="pagination.loading"
        :page.sync="pagination.page"
        :items-per-page.sync="pagination.size"
        :server-items-length="pagination.count"
        :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
        locale="ru"

        :expanded.sync="expanded"
        :single-expand="singleExpand"
        item-key="name"
        show-expand
      >
        <template v-slot:item.actions="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="updateItem(props.item)"
                medium>
                <v-icon>
                  edit
                </v-icon>
              </v-btn>
            </template>
            <span>Редактировать</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="openConfirmDelete(props.item)"
                medium>
                <v-icon>
                  delete
                </v-icon>
              </v-btn>
            </template>
            <span>Удалить</span>
          </v-tooltip>
        </template>
        <template v-slot:expanded-item="{ headers }">
          <td :colspan="headers.length" class="px-0">
            <v-card class="px-0">
              <v-card-text class="subtitles text-left">
                <v-row align-center fill-height justify-center row wrap>
                  <v-col
                    cols="12" md="4" lg="4">
                    Нижняя граница зачета без оценки: <b>{{ detail.credit }}</b>
                  </v-col>
                  <v-col
                    cols="12" md="8" lg="8">
                    Формула подсчета общего рейтинга: <b>{{ detail.formula }}</b>
                  </v-col>
                </v-row>

                <v-row align-center fill-height justify-center row wrap>
                  <v-col
                    cols="12" md="4" lg="4">
                    Нижняя граница удовлетворительной оценки: <b>{{ detail.satisfactory }}</b>
                  </v-col>
                  <v-col
                    cols="12" md="4" lg="4">
                    Нижняя граница автомата на 3: <b>{{ detail.satisfactory_automate }}</b>
                  </v-col>
                  <v-col
                    cols="12" md="4" lg="4">
                    Кол-во добавляемых баллов при автомате на 3: <b>{{ detail.satisfactory_additional }}</b>
                  </v-col>
                </v-row>

                <v-row align-center fill-height justify-center row wrap>
                  <v-col
                    cols="12" md="4" lg="4">
                    Нижняя граница хорошей оценки: <b>{{ detail.good }}</b>
                  </v-col>
                  <v-col
                    cols="12" md="4" lg="4">
                    Нижняя граница автомата на 4: <b>{{ detail.good_automate }}</b>
                  </v-col>
                  <v-col
                    cols="12" md="4" lg="4">
                    Кол-во добавляемых баллов при автомате на 4: <b>{{ detail.good_additional }}</b>
                  </v-col>
                </v-row>

                <v-row align-center fill-height justify-center row wrap>
                  <v-col
                    cols="12" md="4" lg="4">
                    Нижняя граница отличной оценки: <b>{{ detail.excellent }}</b>
                  </v-col>
                  <v-col
                    cols="12" md="4" lg="4">
                    Нижняя граница автомата на 5: <b>{{ detail.excellent_automate }}</b>
                  </v-col>
                  <v-col
                    cols="12" md="4" lg="4">
                    Кол-во добавляемых баллов при автомате на 5: <b>{{ detail.excellent_additional }}</b>
                  </v-col>
                </v-row>
              </v-card-text>

            </v-card>
          </td>
        </template>
        <template slot="no-data">
          <v-card-text>Данные не загружены</v-card-text>
        </template>
      </v-data-table>
      <RatingSystemForm
        @addItem="addItem"
        @ended="closedForm"
        @refreshItem="refreshItem"
        @input="closedForm"
        :opened_form="opened_form"
        v-bind="selected"
        v-if="opened_form"
      >
      </RatingSystemForm>
      <v-dialog persistent v-model="opened_confirm" width="500">
        <v-card v-if="deletable">
          <v-card-title>Подтвердите удаление</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            Вы уверены, что хотите удалить?<br>
          </v-card-text>
          <v-card-actions>
            <v-flex>
              <v-btn @click="deleteItem(deletable.id)" color="warning" text>
                Удалить
              </v-btn>
              <v-btn @click="closeConfirmDelete" text>
                Отмена
              </v-btn>
            </v-flex>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>

import urls from "@/urls/management";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import RatingSystemForm from "@/modules/educationalManagement/forms/RatingSystemForm";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import UserDataMixin from "@/mixins/UserDataMixin"
import {loadData, makeDeleteRequest, sendDeleteRequest} from "@/helper";

export default {
  name: "TrainingDirection",
  components: {RatingSystemForm, DebugJsonComponent},
  mixins: [TitledPageMixin, PaginatedDataMapperMixin, UserDataMixin],
  props: {},

  data() {
    return {
      singleExpand: true,
      URL_LIST: urls.EDUCATION.REFERENCE.RATING_SYSTEM.LIST(),
      page_title: "Список компетенций",
      headers: [
        {text: "Наименование", value: "name", align: "start", sortable: false},
        {text: "Действия", value: "actions", align: "center", sortable: false},
        {text: "Подробнее", value: "data-table-expand",  align: "center"}
      ],
      expanded: [],
      detail: {},
      items: []
    }
  },
  watch: {
    "expanded": {
      handler: function (newValue) {
        for (let i in this.expanded) {
          let url = urls.EDUCATION.REFERENCE.RATING_SYSTEM.DETAIL(this.expanded[i].id)
          loadData(url,(json)=>{
            this.detail = json
          },()=>{})
        }
      }
    }
  },
  methods: {
    addItem(){
      this.closedForm()
      this.getItems()
    },
    deleteItem(id) {
      let url = urls.EDUCATION.REFERENCE.RATING_SYSTEM.DELETE(id)
      makeDeleteRequest(url).then(resp => {
        if (resp.ok) {
          this.closeConfirmDelete()
          this.getItems()
        }
      })
    },
    loadData(url = undefined) {
      this.getItems(url)
    },
    getItems (url=undefined) {
      this.setLoading();
      if(url === undefined) {
        url = this.URL_LIST,
          this.setCurrentLoadUrl(url)
      }
      loadData(
        url,
        (data) => {
          this.mapResults(data,"items")
          this.setNotLoading()
        }
      )
    }
  },

  created(){
    this.getItems()
    this.$store.commit("mainpage/changeTitle", "Рейтинговые системы");
  }
}
</script>

<style scoped>

</style>
