// import Vue from 'vue'
import {
  // sendPostRequest,
  // sendDeleteRequest,
  sendGetRequest
  // sendPutRequest
} from "@/helper";
// import {executeRequest} from "../utils";
// import store from "@/store";
import urls from "@/urls/admin";

const state = {
  items: [],
  count: 0,
  next: null,
  prev: null,
  detail: null,
  id: null,
  loading: false,
  currentUrl: false,
  error: null
};

const getters = {
  getProfileItems: state => state.items,
  getProfileCount: state => state.count,
  getProfileCurrentUrl: state => state.currentUrl,
  getProfileDetail: state => state.detail,
  isProfileLoading: state => state.loading,
  getProfileError: state => state.error

};

const actions = {
  loadProfileList: ({commit, getters}, {url, urlAppend, finalizer=()=>{}, catcher=()=>{}}) => {
    if (!url) {
      url = urls.ADMIN.REFERENCES.PROFILE.LIST()
      if (urlAppend) url = urlAppend(url)
    }
    if (!getters.isProfileLoading) {
      commit("SET_PROFILE_LOADING");
      sendGetRequest(
        url,
        (data, url) => {
          commit("SET_PROFILE", data["results"]);
          commit("SET_PROFILE_COUNT", data["count"]);
          commit("SET_PROFILE_CURRENT_URL", url);
          commit("SET_PROFILE_LOADING_END");
          finalizer(data, url);
        },
        (data, url) => {
          commit("SET_PROFILE_LOADING_END");
          commit("SAVE_PROFILE_ERROR", data);
          catcher(data, url);
        }
      )
    }
  },
  loadCarsDetail: ({commit}, {url, id, finalizer=()=>{}, catcher=()=>{}}) => {
    if (!url) url = urls.ADMIN.PROFILE.DETAIL(id)
    sendGetRequest(
      url, (data, url) => {
        commit("SET_PROFILE_DETAIL", data);
        finalizer(data, url);
      }, (data, url) => {
        commit("SAVE_PROFILE_ERROR", data);
        catcher(data, url);
      }
    )
  }
  // createCars: ({dispatch, commit, getters}, {data, finalizer=()=>{}, catcher=()=>{}}) => {
  //   let url = urls.ADMIN.PROFILE.CREATE()
  //   executeRequest('Cars', sendPostRequest, {url, data, finalizer, catcher}, {dispatch, commit, getters});
  // },
  // changeCars: ({dispatch, commit, getters}, {data, finalizer,catcher}) => {
  //   let url = urls.ADMIN.PROFILE.UPDATE(data.id)
  //   executeRequest('Cars', sendPutRequest, {url, data, finalizer, catcher}, {dispatch, commit, getters});
  // },
  // deleteCars: ({dispatch, commit, getters}, {id, finalizer, catcher}) => {
  //   let url = urls.ADMIN.PROFILE.DELETE(id)
  //   executeRequest('Cars', sendDeleteRequest, {url, finalizer, catcher}, {dispatch, commit, getters});
  // },

};

const mutations = {
  SET_PROFILE: (state, cars) => {
    state.items = cars
  },
  SET_PROFILE_DETAIL: (state, value) => {
    state.detail = value
  },
  SET_PROFILE_COUNT: (state, value) => {
    state.count = value
  },
  SET_PROFILE_CURRENT_URL: (state, value) => {
    state.currentUrl = value
  },
  SET_PROFILE_LOADING: (state) => {
    state.loading = true
  },
  SET_PROFILE_LOADING_END: (state) => {
    state.loading = false
  },
  SAVE_PROFILE_ERROR: (state, error) => {
    state.error = error
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
