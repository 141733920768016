<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        class="mb-0"
        color="style-color-main-gray"
        flat
      >
        <v-toolbar-title>
          Группа: {{group.name}}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          Семестр: {{group.semester_name}}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="py-1">
          <v-btn text outlined color="light-blue darken-4" :to="journalLink">
            <v-icon class="mr-1">ballot</v-icon>Журнал группы
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-row wrap class="py-3">
        <v-col cols="12" :lg="show_curriculum ? 6: 12" class="pt-0 pr-0" style="border-right: 2px solid rgba(0,0,0,.12);">
          <GroupTemplate :elevation="0" v-bind="group" v-if="group !== undefined"></GroupTemplate>
        </v-col>
        <v-col cols="12" lg="6" v-if="show_curriculum" class="pt-0 pl-0">
          <GroupCurriculumTemplate :elevation="0" v-bind="group.curriculum" :loaded="loaded.curriculum">
          </GroupCurriculumTemplate>
        </v-col>
      </v-row>
      <v-row wrap>
        <v-col cols="12" v-if="show_subjects" class="my-0 py-0">
          <v-divider class="mx-1"></v-divider>
          <GroupSubjectsTemplate
            :elevation="0"
            :semesters="group.semesters"
            :loaded="loaded.semesters"
            :show_extra_actions="false"
            :expandable="true"
            v-if="group.semesters.basename === undefined"
            :headers="[
              {text: 'Предмет', value: 'subject', sortable: false, width: '50%'},
              {text: 'Кафедры', value: 'cathedrals', sortable: false, width: '45%'},
              {text: '', value: 'data-table-expand', width: '5%'},
            ]"
          ></GroupSubjectsTemplate>
        </v-col>
        <v-col cols="12" v-if="show_students" class="my-0 py-0">
          <v-divider class="mx-1"></v-divider>
          <GroupStudentsTemplate
            :elevation="0"
            :students="group.students"
            v-if="group.students.basename === undefined"
            :loaded="group.students.basename === undefined"
            :group_id="group.id"
            :expandable="true"
            :show_extra_actions="true"
            :group="true"
          >
          </GroupStudentsTemplate>
        </v-col>
      </v-row>

    </v-card>
  </v-container>
</template>

<script>
import LoadData from "@/modules/dean/mixins/LoadData"
import names from "../../../routers/names"
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent"
import GroupTemplate from "@/modules/cathedra/pages/groups/detail/ArchiveGroupTemplate"
import GroupCurriculumTemplate from "@/modules/templates/group/GroupCurriculumTemplate"
import GroupStudentsTemplate from "@/modules/templates/group/GroupStudentsTemplate"
import GroupSubjectsTemplate from "@/modules/templates/group/GroupSubjectsTemplate"
export default {
  name: "ArchiveBase",
  mixins: [LoadData],
  components: {
    DebugJsonComponent,
    GroupTemplate,
    GroupCurriculumTemplate,
    GroupStudentsTemplate,
    GroupSubjectsTemplate
  },
  props: {
    group: {
      required: true,
      type: Object
    },
    show_students: {
      type: Boolean,
      default: false
    },
    show_curriculum: {
      type: Boolean,
      default: false
    },
    show_subjects: {
      type: Boolean,
      default: false
    },
    loaded: {
      type: Object,
      default: () => {
        return {
          group: false,
          curriculum: false,
          students: false,
          semesters: false
        }
      }
    }
  },
  computed: {
    journalLink() {
      return {
        name: names.CathedraArchiveGroupsDetailJournal,
        params: this.$route.params,
        query: this.$route.query
      }
    }
  },
  data: function () {
    return {
      opened_form: false,
      students: {
        headers: [
          {text: "ФИО", value: "fio", sortable: false, align: "center"}
        ],
        editor: {
          open: false,
          selector: []
        }
      },
      expanded: {
        subjects: false,
        students: false
      }
    }
  },
  methods: {
  },
  created() {
  }
}
</script>

<style scoped>

</style>
