// import Vue from 'vue'
import store from "@/store";

const state = {
  route_history: [],
  request_history: []
};

const getters = {
  getRouteHistory: state => state.route_history,
  getRouteHistoryLength: state => state.route_history.length,
  getRequestHistory: state => state.request_history,
  getRequestHistoryLength: state => state.request_history.length
};

const actions = {
  appendToRouteHistory: ({commit, getters}, route) => {
    if(getters["getRouteHistoryLength"] >= 9){
      commit("REMOVE_LAST_IN_ROUTE_HISTORY")
    }
    let copy_route = Object.assign({}, route)
    delete copy_route["matched"]
    commit("APPEND_TO_ROUTE_HISTORY", copy_route)
  },
  appendToRequestHistory: ({commit, getters}, {response, data, url, sent}) => {
    if(getters["getRequestHistoryLength"] >= 9){
      commit("REMOVE_LAST_IN_REQUEST_HISTORY")
    }
    let copy_response = {
      url: response.url,
      status: response.status,
      statusText: response.statusText,
      type: response.type,
      headers: Object.assign({}, response.headers)
    }
    commit("APPEND_TO_REQUEST_HISTORY", {response: copy_response, data: data, url: url, sent: sent})
  }
};

const mutations = {
  APPEND_TO_ROUTE_HISTORY: (state, route) => {
    state.route_history.push(route)
  },
  REMOVE_LAST_IN_ROUTE_HISTORY: (state) => {
    state.route_history.pop()
  },
  APPEND_TO_REQUEST_HISTORY: (state, route) => {
    state.request_history.push(route)
  },
  REMOVE_LAST_IN_REQUEST_HISTORY: (state) => {
    state.request_history.pop()
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export function feedbackHook (to, from, next) {
  store.dispatch("feedback/appendToRouteHistory", to);
  next()
}
