<template>
  <v-container v-if="admission !== undefined" class="pa-3" flat>
    <v-row  class="py-3">
      <v-col cols="12" class="py-0   my-0" >
        <v-card  class="py-0" >
          <v-toolbar flat color="style-color-main-gray" elevation="0" class="pb-5">
          <v-toolbar-title>
            {{ admission.semester.subject_name}}
          </v-toolbar-title>
          </v-toolbar>
          <v-row>
            <v-col>
              <v-row no-gutters class="text-start subtitle-1 py-1 px-4">
                <v-col cols="12" md="4" class="font-weight-bold">Номер зачетной книжки:</v-col>
                <v-col cols="12" md="8">{{ admission.record_book_number}}</v-col>
              </v-row>
              <v-row no-gutters class="text-start subtitle-1 py-1 px-4">
                <v-col cols="12" md="4" class="font-weight-bold">Группа:</v-col>
                <v-col cols="12" md="8">{{ admission.group }}</v-col>
              </v-row>
              <v-row no-gutters class="text-start subtitle-1 py-1 px-4">
                <v-col cols="12" md="4" class="font-weight-bold">Кафедра:</v-col>
                <v-col cols="12" md="8">{{ admission.semester.cathedra}}</v-col>
              </v-row>
              <v-row no-gutters class="text-start subtitle-1 py-1 px-4">
                <v-col cols="12" md="4" class="font-weight-bold">Квалификация:</v-col>
                <v-col cols="12" md="8">{{ admission.semester.course.level}}</v-col>
              </v-row>
              <v-row no-gutters class="text-start subtitle-1 py-1 px-4">
                <v-col cols="12" md="4" class="font-weight-bold">Курс:</v-col>
                <v-col cols="12" md="8">{{ admission.semester.course.number}}</v-col>
              </v-row>
              <v-row no-gutters class="text-start subtitle-1 py-1 px-4">
                <v-col cols="12" md="4" class="font-weight-bold">Семестр:</v-col>
                <v-col cols="12" md="8">{{ admission.semester.semester}}</v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row no-gutters class="text-start subtitle-1 py-1 px-4">
                <v-col cols="12" md="4" class="font-weight-bold">Рейтинг в сместре:</v-col>
                <v-col cols="12" md="8">{{ admission.semester.rating_in_semester}}</v-col>
              </v-row>
              <v-row no-gutters class="text-start subtitle-1 py-1 px-4">
                <v-col cols="12" md="4" class="font-weight-bold">Рейтинг:</v-col>
                <v-col cols="12" md="8">{{ admission.semester.rating}}</v-col>
              </v-row>
              <v-row no-gutters class="text-start subtitle-1 py-1 px-4">
                <v-col cols="12" md="4" class="font-weight-bold">Контроль:</v-col>
                <v-col cols="12" md="8">{{ admission.semester.control_type}}</v-col>
              </v-row>
              <v-row no-gutters class="text-start subtitle-1 py-1 px-4">
                <v-col cols="12" md="4" class="font-weight-bold">Статус:</v-col>
                <v-col cols="12" md="8">
                  <span v-if="admission.accepted">Принят</span>
                  <span v-else-if="admission.accepted !== null">Отклонен</span>
                  <span v-else>Не рассмотрен</span>
                </v-col>
              </v-row>
              <v-row no-gutters class="text-start subtitle-1 py-1 px-4" v-if="admission.tolerance">
                <v-col cols="12" md="4" class="font-weight-bold">Номер допуска:</v-col>
                <v-col cols="12" md="8">
                  <router-link :to="{name: names.ADMISSIONS.DETAIL, params: {idAdmission: admission.tolerance.id}}">{{ admission.tolerance.number}}</router-link>
                </v-col>
              </v-row>
              <v-row no-gutters class="text-start subtitle-1 py-1 px-4" v-if="admission.reason">
                <v-col cols="12" md="4" class="font-weight-bold">Причина отклонения:</v-col>
                <v-col cols="12" md="8"> {{ admission.reason}}</v-col>
              </v-row>
            </v-col>
          </v-row>

          <scroll-center-dialog
            v-model="dialogDelete"
            width="500"
            title="Отклонить"
            @close="dialogDelete = false"
            @apply="deleteAdmission"
            apply-text="Отклонить"
            denny-text="Закрыть"
          >
           <v-textarea v-model="reason"
                       class="ma-4"
                       outlined
                       :readonly = admission.system_reason
           label="Причина отклонения">
           </v-textarea>
          </scroll-center-dialog>

          <scroll-center-dialog
            v-model="dialogAccept"
            width="500"
            title="Принять запрос на создание допуска"
            @apply="acceptAdmission"
            @close="dialogAccept = false"
            apply-text="Принять"
            denny-text="Закрыть"
          >
              <template v-if="admission.semester.subject_name">
                <v-text-field
                  label="Предмет"
                  class="mr-3 ml-3 mt-3"
                  v-model="admission.semester.subject_name"
                  :disabled="true"
                ></v-text-field>
              </template>
              <template v-if="admission.semester.control_type" >
                <v-row class="pb-3">
                  <v-col cols="4" class="text-left ml-3">
                    <div class= "text-left font-weight-black">
                      Тип контроля:
                    </div>
                  </v-col>
                  <v-col cols="6" class="text-left">
                    {{admission.semester.control_type}}
                  </v-col>
                </v-row>
              </template>
              <v-autocomplete
                class="mr-3 ml-3"
                outline
                :items="selectors.session"
                no-data-text="Нет данных"
                label="Сессия на которую выдается допуск"
                v-model="session"
                :error="hasError('session')"
                :error-messages="getError('session')"
              ></v-autocomplete>
              <v-text-field
                label="Номер допуска"
                v-model="number"
                class="mr-3 ml-3"
                :error="hasError('number')"
                :error-messages="getError('number')"
              ></v-text-field>
              <v-checkbox
                label="Академическая разница"
                v-model="academic_difference"
                :error="hasError('academic_difference')"
                :error-messages="getError('academic_difference')"
                rows="3"
                class="mr-3 ml-3"
              ></v-checkbox>
              <v-textarea
                label="Комментарий"
                v-model="dean_comment"
                rows="3"
                class="mr-3 ml-3"
                :error="hasError('dean_comment')"
                :error-messages="getError('dean_comment')"
              ></v-textarea>
              <v-file-input
                v-if="need_add_certificate"
                class="pa-1 ma-1"
                :error="hasError('certificate')"
                :error-messages="getError('certificate')"
                v-model="certificate"
                accept="application/pdf"
                label="Документ"
                hint="Файл основания выдачи допуска необходим для подтверждения права обучающигося на получение третьего и последующих допусков."
                persistent-hint
              ></v-file-input>

              <div v-if="certificate != null && typeof certificate === 'string'"
                   class="pa-2 ma-3 mb-3 document"
              >
                <div>
                  <v-icon size="28px">
                    picture_as_pdf
                  </v-icon>
                  <a :href="certificate"
                     class="documentContent"
                  >Документ обоснования
                  </a>
                </div>
                <div>
                  <v-btn text icon @click="reloadFile()">
                    <v-icon>
                      close
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            <v-alert v-if="hasError('semester')" type="error">{{getError('semester')[0]}}</v-alert>
          </scroll-center-dialog>
          <v-card-actions class="px-5"  v-if="admission.accepted === null">
              <v-btn v-if="!admission.system_reason" class="primary" @click="dialogAccept=true">Принять</v-btn>
              <v-btn class="primary" @click="dialogDelete=true">Отклонить</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

    </v-row>
  </v-container>
  <v-card v-else>
    <v-skeleton-loader
      type="card-avatar, article, actions"
    ></v-skeleton-loader>
  </v-card>
</template>

<script>
  import {loadData, makeGetRequest, sendDeleteRequest, sendPostRequest} from "@/helper";
  import urls from "@/urls/dean";
  import TitledPageMixin from "@/mixins/TitledPageMixin";
  import UserDataMixin from "@/mixins/UserDataMixin";
  import selectors from "@/urls/selectors";
  import names from "@/modules/dean/routers/names";
  import UserDataFormMixin from "../../../../mixins/UserDataFormMixin"
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent"
  import {finalizeRequest, makePostRequestFile} from "../../../../helper";
  import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

  export default {
    name: "DetailAdmission",
    components: {
      ScrollCenterDialog, DebugJsonComponent
    },
    mixins: [TitledPageMixin, UserDataMixin, UserDataFormMixin],
    data: function () {
      return {
        page_title: "Заказанный допуск",
        admission: undefined,
        reason:"",
        names:names,
        session:null,
        number:"",
        dean_comment:"",
        academic_difference:false,
        dialogAccept:false,
        dialogDelete:false,
        selectors:{
          session: [] 
        },
        tolerance: undefined,
        can_save: false,
        need_add_certificate: false,
        certificate: undefined
      }
    },
    methods: {
      loadData(){
        let url = urls.DEAN.ADMISSIONS_REQUEST.DETAIL(this.$route.params.idFaculty,this.$route.params.idAdmission)
        this.dialogAccept = false
        this.dialogDelete = false
        let sys = true
        makeGetRequest(url).then(resp => {
          if (!resp.ok) {
            sys = false
          }
          return resp.json()
        }).then(json => {
          this.admission = json
          this.loadSession()
          this.checkAdmission(this.admission)
          this.setPageTitleWithObject("Student", {uid: this.admission.student}, " - ", false)
          if(this.admission.system_reason)
            this.reason = this.admission.system_reason

        })
      },
      reloadFile() {
        this.certificate = null;
        this.need_add_certificate = true;
      },
      checkAdmission(admission){
        let url = urls.DEAN.STUDENTS.SEMESTER.CHECK_TOLERANCE(
          this.$route.params.idFaculty,
          admission.education,
          admission.semester.id
        )
        let saverCheck = (data) => {
          this.tolerance = data
          this.can_save = data.available_tolerance
          this.need_add_certificate = data.need_certificate
        };
        let catherCheck = (data) => {
          this.errors = data
        };
        loadData(url, saverCheck, catherCheck);
      },
      acceptAdmission(){
        let url = urls.DEAN.ADMISSIONS_REQUEST.ACCEPT(this.$route.params.idFaculty,this.$route.params.idAdmission)
        let metod = "POST"
        let formData = new FormData();
        if(!this.academic_difference)
          formData.append("session", this.session)
        formData.append("number", this.number)
        formData.append("dean_comment", this.dean_comment)
        formData.append("academic_difference", this.academic_difference)
        if (typeof this.certificate === "object" && this.certificate !== null) {
          formData.append("certificate", this.certificate)
        }
        finalizeRequest(makePostRequestFile(url, formData, metod),
          (json) => {
            this.loadData()
          },
          (json) => {
            this.receiveErrors(json);
          })
      },
      loadSession(){
        let url = selectors.URLS.ACADEMIC_YEAR_SESSION({current:true,individual_semester:this.admission.semester.id})
        let saverSession = (data) => {
          this.selectors.session = data
        };
        let catherSession = (data) => {
          // this.errors = data
        };
        loadData(url, saverSession, catherSession);
      },
      deleteAdmission(){
        let url = urls.DEAN.ADMISSIONS_REQUEST.DELETE(this.$route.params.idFaculty,this.$route.params.idAdmission)
        let data={
          reason:this.reason
        }
        sendPostRequest(url,data,
          ()=>{
            this.loadData();
          },
          ()=>{

          }
        )
      }
    },
    created() {
      this.loadData();
    }
  }
</script>

<style scoped>

</style>
