<template>
  <div
    class=" lighten-3"
    id="e3"
  >
    <v-container
      fluid
      grid-list-lg
    >
      <v-timeline dense>
        <v-timeline-item
          :key="i" large
          v-for="item,  i in items"
        >
          <v-layout row wrap>
            <v-flex xs12>
              <v-card>
                <v-card-title class="title subprimary">
                  <span class="title font-weight-bold">{{item.data}} {{item.title}} </span>
                </v-card-title>
                <v-card-text class=" text-xs-left title" v-html="item.text">
                </v-card-text>
                <v-card-actions>
                  <v-list-item class="grow">
                    <v-list-item-avatar color="white">
                      <v-img
                        aspect-ratio="1"  contain
                        :src="logo"
                        alt="logo" class="elevation-6"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        EIOS developers
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-timeline-item>
      </v-timeline>
    </v-container>
  </div>
</template>

<script>
  import logo from "@/assets/logo.png";

  export default {
    name: "InfoUpdate",
    data: function () {
      return {
        logo: logo,
        items: [
          {
            data: "06.11.2019",
            title: "Обновление инструкции",
            text: " <div> Уважаемые пользователи! Внесены дополнения в инструкцию по работе в системе \"Единый личный кабинет РХТУ им. Д.И. Менделеева\" \n </div> " +
              "<div class=\" pb-3 pt-3\">Для ознакомления перейдите по <a href=\"https://muctr.ru/portal-instruction/\">ссылке</a> \n</div>" +
              "<div class=\"pt-3\">С уважением, </div>" +
              "<div>Управление информационных технологий</div>"
          },
          {
            data: "21.10.2019",
            title: "Обновление системы",
            text: " <div> Уважаемые пользователи! Внесены правки портала единого личного кабинета РХТУ им. Д,И. Менделеева! \n </div> " +
              "<div class=\" pb-3 pt-3\">В рамках обновления было выполнено:\n</div>" +
              "<div>1. Добавлена панель управления администратора с возможностью:\n" +
              "поиска пользователей, просмотра его информации и входа в учетную запись;</div>" +
              "<div>2. Исправлены классификаторы в системе ученого звания и степени;</div>" +
              "<div>3. Проведены работы по адапции сайта для ОС Windows XP;</div>" +
              "<div>4. Исправлены незначительные ошибки: отображения аватара, подсказки пользователю и др.</div>" +
              "<div class=\"pt-3\">С уважением, </div>" +
              "<div>Управление информационных технологий</div>"
          },
          {
            data: "14.10.2019",
            title: "Обновление системы",
            text: " <div> Уважаемые пользователи! Внесены правки портала единого личного кабинета РХТУ им. Д,И. Менделеева! \n </div> " +
              "<div class=\" pb-3 pt-3\">В рамках обновления было выполнено:\n</div>" +
              "<div>1. Добавлена возможность повторной отправки письма подтверждения регистрации;\n</div>" +
              "<div>2. Внесены изменения и дополнения контента системы;\n</div>" +
              "<div>3. Проведены работы по адапции сайта под мобильные устройства;\n</div>" +
              "<div>4. Исправлены неактуальные ссылки на ресурсы РХТУ.\n</div>" +
              "<div class=\"pt-3\">С уважением, </div>" +
              "<div>Управление информационных технологий</div>"
          },
          {
            data: "07.10.2019",
            title: "Запуск системы",
            text: "<div>Уважаемые пользователи! 9 октября 2019 открылся Единый личный кабинет РХТУ им. Д,И. Менделеева.</div>" +
              "<div>Теперь Вы можете заполнить актуальную информацию о себе, а так же создавать и управлять корпоративными почтовыми ящиками. </div>" +
              "<div class=\"pt-3\">С уважением, Команда разработчиков</div>"
          }
        ]
      }
    }
  }
</script>

<style scoped>

</style>
