<template>
  <v-container fluid>
    <v-card >
      <v-toolbar
          class="mb-2"
          color="style-color-main-gray"
          flat
      >
        <v-text-field v-model="search" class="ma-2" prepend-inner-icon="search"></v-text-field>
      </v-toolbar>
      <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          class="elevation-1"
          no-data-text="Нет данных"
          :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
      >
        <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
        <template v-slot:item.title="props">
          <a :href="props.item.file" target="_blank">{{props.item.title}}</a>
        </template>
        <template slot="no-data">
          <v-card-text>Данные не загружены</v-card-text>
        </template>
      </v-data-table>
    </v-card>
  </v-container>

</template>


<script>
import UserDataMixin from "@/mixins/UserDataMixin"
import TitledPageMixin from "@/mixins/TitledPageMixin"
import teacher from "@/urls/teacher"
import {loadData} from "../../../../helper"


export default {
  name: "ListAdmissions",
  mixins: [UserDataMixin, TitledPageMixin],

  data() {
    return {
      items: [],
      search:"",
      headers: [
        {text: "Название", sortable: false, align: "left",value:"title", width: "22%"},
        {text: "Описание", sortable: false, align: "left",value:"description", width: "20%"},
        {text: "Тип пройденной программы", sortable: false, align: "left",value:"work_type", width: "8%"},
        {text: "Сфера", sortable: false, align: "left",value:"knowledge_field", width: "8%"},
        {text: "Тип документа", sortable: false, align: "left",value:"document_type", width: "8%"},
        {text: "Номер документа", sortable: false, align: "left",value:"number", width: "8%"},
        {text: "Объем пройденной программы", sortable: false, align: "left",value:"volume", width: "8%"},
        {text: "Дата начала изучения программы", sortable: false, align: "left",value:"start", width: "8%"},
        {text: "Дата конца изучения программы", sortable: false, align: "left",value:"end", width: "8%"}
      ],
      page_title: "Портфолио",
      loading: true,
      URL_LIST: teacher.URLS.STUDENT.STUDENT_PORTFOLIO(this.$route.params.student_id)

    }
  },

  created() {
    this.setPageTitleWithObject("Education", {id: this.$route.params.student_id}, " - ", true)
    this.getItems();
  },
  methods: {
    getItems() {
      this.loading = true
      let url = this.URL_LIST
      loadData(
          url,
          (data) =>{
            this.items = data
            this.loading = false
          }
      )
    }

  }
}


</script>

<style scoped>

</style>
