<template>

  <LoadPage v-bind:loading-page="!student.loadingPage" v-bind:load-page-error-msg="errorLoadPage" class="px-0">
    <slot>
      <v-layout wrap v-if="$route.name === names.StudentProfile">
        <v-col v-if="student.user ">
          <v-alert v-if="student.data.educations.length === 0" class="mt-2 text-left" type="info" outlined>
            Полный функционал системы будет доступен только после распределения пользователей по учебным группам.<br>
          </v-alert>
          <v-toolbar
            color="style-color-main-gray"
            flat
          >
            <v-toolbar-title class="text-wrap">
              Обучающийся:
              {{student.user.lastname}} {{student.user.firstname}} {{student.user.midname}}
            </v-toolbar-title>
          </v-toolbar>
          <v-row>
            <v-col cols="12" sm="4" class="d-flex flex-column justify-center" v-if="show_avatar">
              <v-img :src="(avatar !== null) ? avatar: NotAvatar "
                     class="ma-5 d-flex"
                     aspect-ratio="1"
                     contain>
              </v-img>
            </v-col>
            <v-col class="text-left d-flex flex-column px-5" height="100%" cols="12" :sm="show_avatar ? 8: 12">
              <v-row no-gutters align="center" v-if="student.user ">
                <v-col cols="12" sm="4" class="font-weight-bold">Последний вход:</v-col>
                <v-col cols="12" sm="8" class="text-justify">
                  <template v-if="student.user.last_login !== null">{{displayDate(student.user.last_login,true)}}</template>
                  <template v-else>Ни разу не заходил</template>
                </v-col>
              </v-row>
              <v-divider class="my-1" v-if="student.user"></v-divider>
              <v-row no-gutters align="center" v-if="student.user  ">
                <v-col cols="12" sm="4" class="font-weight-bold">Дата рождения:</v-col>
                <v-col cols="12" sm="8" class="text-justify">{{displayDate(student.user.birth)}}</v-col>
              </v-row>
              <v-divider class="my-1" v-if="student.user "></v-divider>
              <v-row no-gutters align="center" v-if="student.user ">
                <v-col cols="12" sm="4" class="font-weight-bold">Логин:</v-col>
                <v-col cols="12" sm="8" class="text-justify">{{student.user.username}}</v-col>
              </v-row>
              <v-divider class="my-1" v-if="student.user "></v-divider>
              <v-row no-gutters align="center" v-if="student.user ">
                <v-col cols="12" sm="4" class="font-weight-bold">Гражданство:</v-col>
                <v-col cols="12" sm="8" class="text-justify">{{student.user.country}}</v-col>
              </v-row>
              <v-divider class="my-1" v-if="student.user "></v-divider>
              <v-row no-gutters align="center" v-if="student.user">
                <v-col cols="12" sm="4" class="font-weight-bold">Внешняя электронная почта:</v-col>
                <v-col cols="12" sm="8" class="text-justify">{{student.user.email}}</v-col>
              </v-row>
              <v-divider class="my-1" v-if="student.user"></v-divider>
              <v-row no-gutters align="center" v-if="student.user  ">
                <v-col cols="12" sm="4" class="font-weight-bold">Пол:</v-col>
                <v-col cols="12" sm="8" class="text-justify">{{student.user.sex}}</v-col>
              </v-row>
              <v-divider class="my-1" v-if="student.sex  "></v-divider>

            </v-col>
          </v-row>
          <EducationListTemplate v-if="student.data" v-bind:educations = student.data.educations></EducationListTemplate>
        </v-col>
      </v-layout>
    </slot>
  </LoadPage>

<!--  </v-layout>-->

</template>

<script>
  import urls from "../../urls"
  import {
    loadData,
    makeGetRequest,
    getDisplay,
    makeProfileUrl,
    checkFieldTrue,
    makeGetFileRequest
  } from "../../../../helper";
  import {getInstMenu, getInstNameByRouterName} from "@/helper/instances";
  import {mapState} from "vuex"
  import moment from "moment"
  import EducationListTemplate from "./EducationListTemplate";
  import LoadPage from "../../../core/pages/LoadPage";
  import NotAvatar from "@/assets/NotAvatar.png";
  import store from "@/store";
  import names from "@/modules/student/routers/names"
  import teacher_names from "@/modules/teacher/routers/names";
  import dean_names from "@/modules/dean/routers/names";
  import cathedra_names from "@/modules/cathedra/routers/names";
  import educational_names from "@/modules/educationalManagement/routers/names";
  import LoadPortal from "@/modules/core/pages/LoadPortal";

  export default {
    name: "StudentProfileDetail",

    components: {
      LoadPage,
      EducationListTemplate
    },

    data: function () {
      return {
        avatar:null,
        show_avatar:true,
        pageTitle: "Обучающийся",
        errorLoadPage: "",
        alert: false,
        user: {loadingData: true},
        student: {
          data:undefined,
          user:undefined,
          loadingPage: true},
        choices: {
          document_type: {
            load: () => {
              let url = urls.EDUCATION.getUrl("student|choices|document_type", "get");
              this.getChoices(url).then((data) => {
                this.choices.document_type = data;
              });
            }
          }
        }
      }
    },
    computed: {
      NotAvatar: () => NotAvatar,
      names: () => names,
      loadingStudent: function(){
        return this.checkLoading("student")
      },
      loadingUser: function(){
        return this.checkLoading("student")
      },
      ...mapState({
        mainpage: state => state.mainpage
      })
    },
    methods: {
      loadAvatar(){
        makeGetFileRequest(this.student.user.avatar, {},{responseType: "arraybuffer"})
          .then(
            response => response.blob()
          ).then(
          data => {
            this.avatar = URL.createObjectURL(data)
          });
      },
      checkLoading: function(element) {
        return checkFieldTrue(this[element], "loadingData")
      },
      displayDate: function (date, minutes = false) {
        let format = "DD.MM.YYYY"
        if (minutes)
          format += " HH:mm"
        return moment(date).format(format)
      },
      getChoices: function (url) {
        return makeGetRequest(url).then(resp => {
          return resp.json()
        }).then(json => {
          return json
        });
      },

      getDisplay: function (value, selector) {
        return getDisplay(value, selector);
      },
      loadUser(){
        let profile_url = makeProfileUrl(urls.ACCOUNTS.getUrl("users|user|profile", "get"));
        loadData(profile_url,
          (json) => {
            this.student.user = json
            this.loadAvatar();
          },
          () => {
        });
      },
      loadStudent(){
        let url_education = urls.EDUCATION.getUrl("student|students|student", "get");
        loadData(url_education,
          (json) => {
          this.student.data = json
            this.loadLeftMenu();
            this.loadUser();
          },
          (json) => {
            this.student.loadingPage = false
            this.errorLoadPage = json.detail
          }
        );
      },
      getData() {
        this.loadStudent();
      },
      loadLeftMenu() {
        let menu = [
          {title: "Профиль", icon: "view_quilt", router: names.StudentProfile}
        ];
        this.student.data.educations.forEach((el) => {
          menu.push({
            title: el.direction.level +" "+ el.entry_year,
            icon: "view_quilt",
            router: {name: names.StudentEducation, params: {idStudent: el.id}}
          });
        });
        this.$store.commit("leftmenu/replaceItems", menu);
      }
    },

    created() {
      if (store.getters["urls/getAllowed"]( "education", "student|students|student", "get")){
        this.$store.commit("leftmenu/replaceInstances", getInstMenu());
        this.$store.commit("mainpage/changeTitle", this.pageTitle);
        this.getData();
      }
    },
    beforeRouteUpdate(to, from, next) {
      this.$store.commit("mainpage/changeTitle", this.pageTitle);
      next()
    }

  }
</script>

<style scoped>

  .action {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }

</style>
