<template>
  <scroll-center-dialog v-model="opened_form"
                        :title="!!id ? 'Изменить' : 'Добавить'"
                        @close="closeDialog"
                        @apply="apply"
                        width="800"
  >
    <v-text-field
      :error="hasError('name')"
      :error-messages="getErrorArray('name')"
      v-model="data.name"
      class="pa-1 ma-1"
    ><span slot="label">Название<span class="red--text">*</span>:</span>
    </v-text-field>
    <v-text-field
      class="pa-1 ma-1"
      :error="hasError('year')"
      :error-messages="getErrorArray('year')"
      label="Год"
      step="1"
      min="2000"
      max="2100"
      counter="4"
      v-model="data.year"
      type="number"
    >
      <span slot="label">Год<span class="red--text">*</span>:</span>
    </v-text-field>
    <v-autocomplete
      v-if="!id"
      class="pa-1 ma-1"
      :error="hasError('previous')"
      :error-messages="getErrorArray('previous')"
      :items="selectors.previous"
      label="Хранит информацию о предыдущем учебном плане"
      v-model="data.previous"
      no-data-text="Нет данных"
    ></v-autocomplete>
    <v-autocomplete
      v-if="!id"
      class="pa-1 ma-1"
      :error="hasError('extend')"
      :error-messages="getErrorArray('extend')"
      :items="selectors.extend"
      label="Расширяет текущий учебный план"
      v-model="data.extend"
      no-data-text="Нет данных"
    ></v-autocomplete>
    <v-autocomplete
      class="pa-1 ma-1"
      :error="hasError('form')"
      :error-messages="getErrorArray('form')"
      :items="selectors.form"
      label="Форма обучения"
      v-model="data.form"
      no-data-text="Нет данных"
    >
      <span slot="label">Форма обучения<span class="red--text">*</span>:</span>
    </v-autocomplete>
    <v-autocomplete
      v-if="!id"
      class="pa-1 ma-1"
      :error="hasError('faculty')"
      :error-messages="getErrorArray('faculty')"
      :items="selectors.faculty"
      label="Факультет"
      v-model="data.faculty"
      no-data-text="Нет данных"
    >
      <span slot="label">Факультет<span class="red--text">*</span>:</span>
    </v-autocomplete>
    <v-autocomplete
      class="pa-1 ma-1"
      :error="hasError('direction')"
      :error-messages="getErrorArray('direction')"
      :items="selectors.direction"
      v-model="data.direction"
      no-data-text="Нет данных"
    >
      <span slot="label">Направление<span class="red--text">*</span>:</span>
    </v-autocomplete>
    <v-autocomplete
      class="pa-1 ma-1"
      :error="hasError('profile')"
      :error-messages="getErrorArray('profile')"
      :items="selectors.profile"
      v-model="data.profile"
      no-data-text="Нет данных"
    >
      <span slot="label">Профиль<span class="red--text">*</span>:</span>
    </v-autocomplete>
    <v-autocomplete
      class="pa-1 ma-1"
      :error="hasError('standard')"
      :error-messages="getErrorArray('standard')"
      :items="selectors.standard"
      v-model="data.standard"
      no-data-text="Нет данных"
    >
      <span slot="label">Стандарт<span class="red--text">*</span>:</span>
    </v-autocomplete>
    <v-checkbox
      class="pa-1 ma-1"
      label="Иностранцы"
      v-model="data.foreign"
      :error="hasError('foreign')"
      :error-messages="getError('foreign')"
    ></v-checkbox>
  </scroll-center-dialog>
</template>

<script>
import urls from "@/urls/choices.js"
import UserDataFormMixin from "@/mixins/UserDataFormMixin"
import {loadData, makeGetRequest} from "@/helper";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

export default {
  name: "Courses",
  mixins: [UserDataFormMixin],
  components: {
    ScrollCenterDialog
  },
  props: [
    "id",
    "opened_form"
  ],

  data() {
    return {
      URL_CREATE: urls.getUrl("editor|curriculum|curriculum-list", "create"),
      URL_UPDATE: urls.getUrl("editor|curriculum|curriculum-detail", "update"),
      selectors:{
        direction: [],
        standard: [],
        form: [],
        faculty: [],
        profile: [],
        previous: [],
        extend: []
      },
      value:{},
      data: {
        id: this.id || undefined,
        previous: null,
        extend: null,
        direction: null,
        profile: null,
        name: undefined,
        foreign: false,
        faculty: null,
        form: null,
        standard: null,
        year: 2000
      }
      // errors: {}
    }
  },
  methods: {
    apply: function () {

      for (let obj in this.data){
        if(this.data[obj] !== null)
          this.value[obj] = this.data[obj]
      }

      if (this.id !== undefined) {
        this.update()
      } else {
        this.create()
      }
    },
    loadSelector(data, url) {
      let sys = true
      makeGetRequest(url).then(resp => {
        if (!resp.ok) {
          sys = false
        }
        return resp.json()
      }).then(json => {
        this.selectors[data] = json

        if (data === "extend"){
          this.selectors[data].push({text:"Не выбрано",value:null})
          this.selectors["previous"] = this.selectors[data]
        }
      })
    },
    closeDialog() {
      this.$emit("ended", "")
    }
  },
  created() {
    this.loadSelector("form", urls.getUrl("choices|ministry|form", "get"));
    this.loadSelector("direction", urls.getUrl("selectors|ministry|training_direction", "get"));
    this.loadSelector("faculty", urls.getUrl("selectors|relations|faculty", "get"));
    this.loadSelector("standard", urls.getUrl("selectors|ministry|state_standard", "get"));
    this.loadSelector("profile", urls.getUrl("selectors|ministry|training_profile", "get"));
    this.loadSelector("extend", urls.getUrl("selectors|curriculum|curriculum", "get"));
    if (this.id){
      let url =  this.URL_UPDATE.replace("<id>",this.id)
      let saver = (data) => {
        this.data.classroom_hours = data.classroom_hours
        this.data.year = data.year
        this.data.name = data.name
        this.data.zets = data.zets
        this.data.independent_hours = data.independent_hours
        this.data.direction = data.direction.id
        this.data.profile = data.profile.id
        this.data.standard = data.standard.id
        this.data.form  = data.form
        this.data.faculty = data.faculty_uid
        if(data.extend)
          this.data.extend = data.extend.id
        if(data.previous)
          this.data.previous = data.previous.id
      };
      let cather = (data) => {
        console.log(data)
      };
      loadData(url, saver, cather);
    }


  }
}

</script>

<style scoped>

</style>
