<template>
  <v-container>
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step
          :complete="e1 > 1"
          step="1"
        >
          Выбор группы
        </v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step
          :complete="e1 > 2"
          step="2"
        >
          Перезачет дисциплин
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3">
          Подтверждение
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card
            class="mb-12"
            flat
          >
            <v-alert
              dense
              outlined
              type="info"
            >
              Выберите группу в которую будет зачислен обучающийся и перенесены дисциплины учебного плана
            </v-alert>
            <v-autocomplete
              :items="selectors.group"
              label="Группа для перевода"
              v-model="step1.group"
              no-data-text="Нет данных"
              :error="hasError('education_group')"
              :error-messages="getError('education_group')"
              :loading="step1.loading"
              :readonly="step1.loading"
            ></v-autocomplete>

            <v-alert type="error" v-if="hasError('error')">{{ getError('error')[0] }}</v-alert>

            <v-btn
              v-if="step1.differ!==null"
              color="primary"
              @click="e1 = 2"
            >
              Дальше
            </v-btn>

          </v-card>

        </v-stepper-content>
        <v-stepper-content step="2">
          <v-card
            class="mb-12"
            v-if="step2.groupDiffer"
          >
            <v-toolbar
              class="mb-2 style-color-main-gray"
              flat
            >
              <v-toolbar-title>Выбор дисциплин для перезачета</v-toolbar-title>
            </v-toolbar>
            <v-expansion-panels accordion multiple>
              <v-expansion-panel
                v-for="(itm,i) in step2.groupDiffer"
                :key="i"
              >
                <v-expansion-panel-header style="background:#c9e0ea;color:#000000">
                  <v-row>
                    <v-col cols="9" class="py-0"> {{ step2.groupDiffer[i][0].individual_semester.subject_name }}</v-col>
                    <v-col cols="3" class="py-0">Перезачтено:
                      <v-icon>{{ setStatusDiff(itm) }}</v-icon>
                    </v-col>
                  </v-row>


                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-container>
                    <v-data-iterator
                      :items="itm"
                    >
                      <template v-slot:header>
                        <v-row class="hidden-sm-and-down">
                          <v-col cols="6" class="py-0 px-2" style="border-right: 1px dotted var(--main-gray);">
                            <v-card-title class="px-0">Дисциплины до перевода</v-card-title>
                          </v-col>
                          <v-col cols="6" class="py-0 px-2">
                            <v-card-title class="px-0">Дисциплины после перевода</v-card-title>
                          </v-col>
                        </v-row>

                      </template>

                      <template v-slot:default="props">
                        <v-row d-flex>
                          <v-col
                            v-for="item in props.items"
                            :key="item.individual_semester.id"
                            cols="12"
                          >
                            <v-row>
                              <v-col cols="12" sm="6" class="py-1 px-1">
                                <v-card min-height ="380">
                                  <v-card-title class="subheading font-weight-bold py-2" style="min-height: 100px !important; word-break: break-word!important;">
                                    <v-row>
                                      <v-col cols="12">
                                        {{ item.individual_semester.subject_name }}
                                      </v-col>
                                      <v-col>
                                        <v-checkbox v-model="item.recount"
                                                    label="Перезачесть"
                                                    class="py-0 my-auto text"
                                                    dense
                                                    :disabled="item.individual_semester.rating > 0 ? false : true"
                                                    :hide-details="item.individual_semester.rating > 0 ? true : false"
                                                    :messages="item.individual_semester.rating > 0 ? '' : 'Нельзя перезачесть предмет рейтинг по которому составляет 0 баллов'"
                                        ></v-checkbox>
                                      </v-col>
                                    </v-row>
                                  </v-card-title>
                                  <v-divider></v-divider>
                                  <v-list dense class="py-1">
                                    <v-list-item>
                                      <v-list-item-content>Семестр:</v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        Курс {{ item.individual_semester.course }} {{
                                          item.individual_semester.semester
                                        }}
                                      </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                      <v-list-item-content>Кафедра:</v-list-item-content>
                                      <v-list-item-content class="align-end text-left">
                                        {{ item.individual_semester.cathedra }}
                                      </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                      <v-list-item-content>Рейтинг:</v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item.individual_semester.rating}}
                                        ({{ item.individual_semester.text_rating }})
                                      </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                      <v-list-item-content>Часы:</v-list-item-content>
                                      <v-list-item-content class="align-end">
<!--                                        Кл. часы: {{ item.individual_semester.classroom_hours }} Самост часы:-->
                                        {{ item.individual_semester.hours }}
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-card>
                              </v-col>

                              <v-col cols="12" sm="6" class="py-1 px-1" >
                                <v-card v-if="item.curriculum_semester && item.individual_semester.rating > 0 " min-height="380">
                                  <v-card-title class="subheading font-weight-bold py-2" style="min-height: 100px !important;">
                                    {{ item.curriculum_semester.subject }}
                                    <v-spacer></v-spacer>
                                    <v-btn class="py-0" @click="deleteCurriculum(item)" text icon>
                                      <v-icon>clear</v-icon>
                                    </v-btn>
                                  </v-card-title>

                                  <v-divider></v-divider>

                                  <v-list dense>
                                    <v-list-item>
                                      <v-list-item-content>Семестр:</v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        Курс {{ item.curriculum_semester.course }} {{
                                          item.curriculum_semester.semester
                                        }}
                                      </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                      <v-list-item-content>Кафедры:</v-list-item-content>
                                      <v-list-item-content class="align-end text-left">
                                        <div v-for="(str,index) in item.curriculum_semester.cathedrals" class="align-start">
                                          {{str.name}}<span v-if="index!==item.curriculum_semester.cathedrals.length-1">;</span> <br>
                                        </div>
                                      </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                      <v-list-item-content>Тип контроля:</v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item.curriculum_semester.control_type }}
                                      </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                      <v-list-item-content>Часы:</v-list-item-content>
                                      <v-list-item-content class="align-end">
<!--                                        Кл. часы: {{ item.curriculum_semester.classroom_hours }} Самост часы:-->
<!--                                        {{ item.curriculum_semester.independent_hours }}-->
                                        {{ item.individual_semester.hours }}
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-card>
                                <v-card min-height="380" v-else-if="item.individual_semester.rating > 0">
                                  <v-card-title class="subheading font-weight-bold py-2" style="border-bottom: 1px solid var(--main-gray); min-height: 100px !important;">
                                    Выберите предмет для перезачета
                                    <v-text-field
                                      class="pl-5"
                                      append-icon="search"
                                      placeholder="Поиск"
                                      hide-details
                                      dense
                                      v-model="searchs[item.individual_semester.id]"
                                    ></v-text-field>
                                  </v-card-title>
                                  <v-simple-table height="280">
                                    <template v-slot:default>
                                      <tbody>
                                      <tr
                                        v-for="it in searchFromDynamic(searchs[item.individual_semester.id])"
                                        @click="setSubject(item,it)"
                                        style="cursor: pointer"
                                      >
                                        <td class="text-left">{{ it.subject }}, курс {{ it.course }}
                                          , семестр {{ it.semester }}
                                        </td>
                                      </tr>
                                      </tbody>
                                    </template>
                                  </v-simple-table>
                                </v-card>
                                <v-card height="380" v-else style="display: block;" class="pa-2">
                                  <v-alert
                                    outlined
                                    type="info"
                                    border="left"
                                    class="alert1"
                                  >
                                    <div class="text-justify pa-2">
                                      Предмет с оценкой 0 баллов полученной в семестре не требуется и не может быть перезачтён.
                                    </div>
                                  </v-alert>
                                </v-card>
                              </v-col>
                            </v-row>

                          </v-col>
                        </v-row>
                      </template>

                    </v-data-iterator>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

          </v-card>
          <v-btn
            color="primary"
            @click="e1 = 1"
            class="ma-2"
          >
            Назад
          </v-btn>
          <v-btn
            class="ma-2"
            color="primary"
            @click="e1 = 3"
          >
            Дальше
          </v-btn>

        </v-stepper-content>
        <v-stepper-content step="3">
          <v-card
            class="mb-12"
          >
            <v-card-title class="py-0 subheading font-weight-bold">Дисциплины для
              перезачета
            </v-card-title>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                <tr
                  v-for="it in sendDiffData"
                >
                  <td class="text-left">
                    <v-input error-count="1" :error="hasError('semesters',it)"
                             :error-messages="getError('semesters',it)">
                      {{ it.subject }}, курс {{ it.course }}
                      , семестр {{ it.semester }}
                    </v-input>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
          <v-alert type="error" v-if="hasError('hash')">{{ getError('hash')[0] }}</v-alert>
          <v-alert type="error" v-if="hasError('error')">{{ getError('error')[0] }}</v-alert>
          <v-alert type="error" v-if="hasError('individual_semester')">{{ getError('individual_semester')[0] }}
          </v-alert>
          <v-alert type="error" v-if="hasError('curriculum_semester')">{{ getError('curriculum_semester')[0] }}
          </v-alert>
          <v-alert type="error" v-if="hasError('semesters')">{{ getError('semesters')[0]}}</v-alert>
          <v-btn
            color="primary"
            class="ma-2"
            @click="e1 = 2"
            :disabled="loading"
          >
            Назад
          </v-btn>
          <v-btn
            class="ma-2"
            :loading="loading"
            @click="asseptDiff">
            Подтвердить перевод
          </v-btn>

        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
import urls from "@/modules/dean/urls";
import {loadData, makePostRequest} from "@/helper";
import names from "@/modules/dean/routers/names";
import dean from "@/urls/dean";
import selectors from "@/urls/selectors";
import {getInstMenuDean} from "@/modules/dean/helper";
export default {
  name: "ApproveTransfer",
  components: {
    DebugJsonComponent
  },

  data() {
    return {
      itemsPerPage: 10,
      detail: null,
      e1: 1,
      loading: false,
      searchs: {},
      step1: {
        group: null,
        differ: null,
        loading: false
      },
      step2: {
        journal: [],
        groupDiffer: null
      },
      errors: [],
      selectors: {
        group: []
      }
    }
  },
  computed: {
    sendDiffData: function () {
      let arr = []
      if (this.step2.groupDiffer) {
        for (let str of this.step2.groupDiffer) {
          for (let obj of str) {
            if (obj.curriculum_semester)
              if (obj.recount) {
                arr.push(
                  obj.curriculum_semester
                )
              }
          }
        }
        return arr
      } else return {}
    },

    dinamicSubject: function () {
      let arr = []
      let selectSubj = this.step1.differ.semesters
      let allSubj = this.step2.journal
      for (let str of allSubj) {
        let ss = selectSubj.find(item => {
          if (!item.curriculum_semester)
            return false
          if ((str.subject === item.curriculum_semester.subject)&&(str.semester === item.curriculum_semester.semester)) {
            return true
          } else
            return false
          // eslint-disable-next-line no-unreachable
          return false
        })
        if (!ss)
          arr.push(str)
      }
      return arr
    }
  },
  watch: {
    "step1.group": {
      handler: function (newValue) {
        this.errors = []
        this.step1.loading = true
        this.step1.differ = null
        this.findDiffrence()
        this.loadJournal();
      },
      deep: true
    }
  },
  methods: {
    searchFromDynamic(search){
      if(search)
        return this.dinamicSubject.filter(el => {
          return el.subject.toLowerCase().includes(search.toLowerCase())
        })
      return this.dinamicSubject
    },
    hasError: function (field, it = null) {
      if (
        field in this.errors
        && this.errors[field] !== undefined
        && this.errors[field] !== null
      )
        if (this.errors[field].length > 0) {
          if (it === null)
            return true;
          else {

            let b = this.errors[field][0].split("#")
            // console.log(it.id, Number(b[0]))
            // console.log(it.id === Number(b[0]))
            if (it.id === Number(b[0]))
              return true;
            else
              return false
          }
        }

      return false;
    },
    getError: function (field, it = null) {
      if (
        field in this.errors
        && this.errors[field] !== undefined
        && this.errors[field] !== null
      )
        if (this.errors[field].length > 0) {
          if (it === null)
            return this.errors[field];
          else {
            let b = this.errors[field][0].split("#")
            if (it.id === Number(b[0]))
              return b[1];
          }
        }
      return null;
    },
    asseptDiff() {
      this.loading =  true
      let arr = []
      for (let str of this.step2.groupDiffer) {
        for (let obj of str) {
          if (obj.curriculum_semester)
            if (obj.recount) {
              arr.push({
                individual_semester: obj.individual_semester.id,
                curriculum_semester: obj.curriculum_semester.id,
                recount: obj.recount
              })
            }
        }
      }
      let data = {
        hash: this.step1.differ.hash,
        semesters: arr,
        education_group: this.step1.group
      }

      let sys = true
      let url = dean.DEAN.TRANSFER.INCOMING.APPROVE(this.$route.params.idFaculty, this.$route.params.idDetail)
        // urls.getUrl('dean|students|education|transfer|to-approve-transfer', 'approve_transfer')
        // .replace('<faculty_uid>', this.$route.params.idFaculty)
        // .replace('<id>', this.$route.params.idDetail);

      if(this.$route.name === names.STUDENT.TRANSFER)
        url = dean.DEAN.TRANSFER.OUTGOING.CHANGE_GROUP(this.$route.params.idFaculty, this.$route.params.idDetail)
      makePostRequest(url, data).then(resp => {
        if (!resp.ok) {
          if (resp.status===500)
            alert("Критическая ошибка")
          sys = false
          this.loading = false
        }
        return resp.json()
      }).then(json => {
        if (!sys){
          this.errors = json
          this.loading = false
        }
        else {
          this.loading =  false
          this.$store.commit(
            "leftmenu/replaceItems",
            getInstMenuDean(this.$route.params.idFaculty , "Факультет")
          );
        // if(this.$route.name === names.STUDENT.TRANSFER)
          this.$router.push({name: names.STUDENT.LIST})
         // else
         //  this.$router.push({name:names.TRANSFER.DETAIL_TO })
        }
      })
    },
    setStatusDiff(itm) {
      let answ = "done"
      let bigData = false;
      for (let str of itm) {
        if ((str.curriculum_semester) && (!str.recount)) {
          answ = "clear"

        }else if (str.curriculum_semester === null){
          // if(bigData)
            answ = "help"
        }
        bigData = true;
      }
      return answ
    },
    setSubject(item, it) {
      item.curriculum_semester = it
    },

    uniqueSubjects(arr) {
      let result = [];

      for (let str of arr) {
        if (!result.find(item => {
          let answ = false
          for (let ob of item) {
            if (ob.individual_semester.subject_name === str.individual_semester.subject_name
              // && ob.individual_semester.course === str.individual_semester.course
              // && ob.individual_semester.semester === str.individual_semester.semester
            )
              answ = true
          }
          return answ
        })) {
          let obj = arr.filter(word => word.individual_semester.subject_name === str.individual_semester.subject_name);
          result.push(obj);
        }

      }
      return result;
    },
    deleteCurriculum(item) {
      item.curriculum_semester = null
    },
    loadJournal() {
      let saver = (data) => {
        this.step2.journal = data;
      };
      let catcher = (error) => {
        console.log(error);
      };
      let url = dean.DEAN.CURRICULUM.SEMESTER_LIST(this.$route.params.idFaculty, this.step1.group)
      loadData(url, saver, catcher);
    },
    findDiffrence() {
      let url = dean.DEAN.TRANSFER.INCOMING.DIFFERENCE(this.$route.params.idFaculty, this.$route.params.idDetail)
        // urls.getUrl('dean|students|education|transfer|to-find-difference', 'find_difference')
        // .replace('<faculty_uid>', this.$route.params.idFaculty)
        // .replace('<id>', this.$route.params.idDetail);

      if(this.$route.name === names.STUDENT.TRANSFER)
        url = dean.DEAN.TRANSFER.OUTGOING.DIFFERENCE(this.$route.params.idFaculty, this.$route.params.idDetail)

      let data = {education_group: this.step1.group}
      let sys = true
      makePostRequest(url, data).then(resp => {
        if (!resp.ok) {
          sys = false
        }
        return resp.json()
      }).then(json => {
        if (sys) {
          this.step1.differ = json;
          this.step1.loading = false
          this.step2.groupDiffer = this.uniqueSubjects(this.step1.differ.semesters)
          this.step2.groupDiffer.forEach(el => {
            el.forEach(el2 => {
              this.$set(this.searchs, el2.individual_semester.id, "");
            })
            // console.log(el)
          })
        } else {
          this.errors = json;
          this.step1.loading = false
        }

      })
    }


  },
  created() {
    let url = selectors.URLS.FACULTY.GROUPS(this.$route.params.idFaculty,{archive:false})
      // urls.getUrl('selectors|group|faculty|group', 'get')
      // .replace('<faculty_uid>', this.$route.params.idFaculty)

    let catcher = (error) => {
      console.log(error);
    };
    let saverGr = (data) => {
      this.selectors.group = data;
      if (this.$route.query?.group) {
        this.selectors.group.forEach(el => {
          if (el.text === this.$route.query.group) {
            this.step1.group = el.value
          }
        })
      }
    };
    loadData(url, saverGr, catcher);

  }
}
</script>

<style scoped>
  @media (min-width: 300px) and (max-width: 600px) {
  .alert1{
      top: 4.5em;
    }
  }
  @media (min-width: 600px) {
    .alert1 {
      top: 8em;
    }
  }
</style>
