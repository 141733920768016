<template>
  <v-list-item
    v-if="item.router"
    :class="{ 'v-leftmenu-active': checkActive(item) || checkIncluded(item)}"
    @click="changeRouter(item)"
    link
    class="px-2"
  >
    <v-list-item-avatar
      v-if="item.icon"
      size="34"
    >
      <v-icon v-text="item.icon"></v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="text-left subtitle-2">
        {{item.title}}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "CurrentLeftMenuAction",
  props: ["item"],
  data() {
    return {
    }
  },
  methods: {
    changeParam: function (router) {
      if (typeof (router) === "object") {
        return {name: router.name, params: router.params, query:router.query};
      } else {
        return {name: router};
      }
    },
    changeRouter: function (router) {
      if(!this.checkActive(router)) {
        this.$router.push(this.changeParam(router.router));
      }
    },
    checkRouteParams(keys, verifiable, route){
      if(verifiable === undefined)
        return false
      if(route === undefined)
        return false
      for(let key of keys)
        if(verifiable[key] !== route[key])
          return false;
      return true;
    },
    checkIncluded: function (item) {
      if (item.included) {
        let checkInParents = (verifiable, parent, params) => {
          if (verifiable.name === parent.name) {
            if(typeof item.included !== "object")
              return true;
            else
              return this.checkRouteParams(item.included, verifiable.params, params);
          } else if (parent.parent !== undefined)
            return checkInParents(verifiable, parent.parent, params)
          else
            return false
        }
        let verifiable;
        if (typeof item.router === "object")
          verifiable = item.router;
        else
          verifiable = {name: verifiable};
        return checkInParents(verifiable, this.$route.matched[this.$route.matched.length - 1], this.$route.params);
      } else {
        return false
      }
    },
    checkActive: function (item) {
      let current = this.$route;
      let verifiable = typeof item.router === "object" ? item.router: {name: item.router, params: {}};
      if(verifiable.name !== current.name)
        return false;
      if(Object.keys(current.params).length > Object.keys(verifiable.params).length)
        return false;
      return this.checkRouteParams(Object.keys(current.params), current.params, verifiable.params);
    }
  }
}
</script>

<style scoped>

</style>
