import { render, staticRenderFns } from "./EduDocForm.vue?vue&type=template&id=1850e370&scoped=true"
import script from "./EduDocForm.vue?vue&type=script&lang=js"
export * from "./EduDocForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1850e370",
  null
  
)

export default component.exports