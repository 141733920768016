<template>
  <v-container style="height: 100%">
    <v-card>
      <v-toolbar
        elevation="0"
        color="style-color-main-gray"
      >
        <v-text-field
          dense
          placeholder="Поиск"
          v-model="search.value"
          @keypress.enter="searchData"
          messages="Для поиска нажмите Enter или кнопку поиск"
        >
        </v-text-field>
        <v-btn icon><v-icon>search</v-icon></v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="loadData() "
              class="mr-2"
              medium
            >
              update
            </v-icon>
          </template>
          <span>Обновить данные</span>
        </v-tooltip>
      </v-toolbar>
      <v-data-table
        :items="items"
        :items-per-page.sync="pagination.size"
        :headers="headers"
        :no-data-text="`${plural_model_name} не загружены`"
        :no-results-text="`${plural_model_name} не были найдены`"
        :search.sync="search.value"
        :page.sync="pagination.page"
        :server-items-length="pagination.count"
        :footer-props="{'items-per-page-options':[10, 25, 50], 'items-per-page-text': 'Число элементов'}"
      >
        <template v-slot:item.levels="{ item }">
          <v-chip
            v-for="(level, i) in item.levels"
            :key="`level_${i}`"
            label
            style="min-width: 160px"
          >
            <span class="mr-1">
              <related-link-indicator :item="level"></related-link-indicator>
            </span>
            <span>
              {{ level.level }}
            </span>
          </v-chip>
        </template>
      </v-data-table>

    </v-card>
  </v-container>
</template>

<script>

  import {loadData} from "@/helper";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import UserDataMixin from "@/mixins/UserDataMixin";
  import TitledPageMixin from "@/mixins/TitledPageMixin";
  import urls from "@/urls/admin";
  import RelatedLinkIndicator from "@/modules/administrative/pages/connector/components/RelatedLinkIndicator.vue";

  export default {
    name: "CurriculumList",
    components: {RelatedLinkIndicator},
    mixins:[
      PaginatedDataMapperMixin,
      UserDataMixin,
      TitledPageMixin
    ],

    props: {},
    data(){
      return {
        plural_model_name: "Предметы",
        page_title: "Предметы 1С",
        items: [],
        URL_LIST: urls.ADMIN.REFERENCES.SUBJECT.LIST(),
        search: {
          parameter: "search"
        },
        // names:names,
        headers: [
          {text: "Название", sortable: false, align: "left",weight:"50%", value: "name"},
          {text: "Уровни", sortable: false, align: "left", weight:"40%", value: "levels"}
          // {text: "Год", sortable: false, align: 'left',weight:'5%',value: 'year'},
          // {text: "Форма", sortable: false, align: 'left',weight:'10%',value: 'form'},
          // {text: "Уровень",sortable: false, align: 'left',weight:'10%',value: 'level'},
          // {text: "Факультет", sortable: false,  align: 'left' ,value: 'faculty'},
          // {text: "Черновик", sortable: false,  align: 'left' ,value: 'is_draft'},
          // {text: "Зачисляемый", sortable: false,  align: 'left' ,value: 'is_enrollment'},
          // {text: "Иностранный", sortable: false,  align: 'left' ,value: 'foreign'},
          // {text: "Перенесен со старого EIOS", sortable: false,  align: 'left' ,value: 'migrated'},
        ]
      }
    },
    methods: {
      loadData(url=undefined) {
        this.loadCurriculum(url)
      },
      loadCurriculum: function (url=undefined) {
        this.setLoading()
        if(url === undefined) {
          this.setCurrentLoadUrl(
            this.URL_LIST
          )
          url = this.getCurrentLoadUrl()
        }
        loadData(
          url,
          (data) => {
            this.mapPagination(data)
            this.mapResults(data, "items")
          }
        )
      }
    },
    created() {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
