<template>
  <v-container style="height: 100%" v-if="is_self_router">
    <v-toolbar
      class="mb-2"
      color="style-color-main-gray"
      flat
    >
      <v-toolbar-title v-if="group.loaded">
        Группа: {{group.data.litter}}-{{ group.data.course }}{{group.data.number}}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="py-1">
        <v-btn text outlined color="light-blue darken-4" :to="journalLink">
          <v-icon class="mr-1">ballot</v-icon>Журнал группы
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-row wrap v-if="group.loaded">
      <v-col cols="12" md="6">
        <GroupTemplate
          v-if="group.loaded"
          v-bind="group.data">
        </GroupTemplate>
      </v-col>
      <v-col cols="12" md="6">
        <GroupCurriculumTemplate
          v-if="group.loaded"
          :direction="group.data.curriculum.direction"
          :profile=" group.data.curriculum.profile ? group.data.curriculum.profile.name : '' "
          :year="group.data.curriculum.year"
          :cathedra="group.data.cathedra"
          :loaded="true"
        >
        </GroupCurriculumTemplate>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <GroupSubjectsTemplate
          v-if="subjects.loaded"
          :load_half="true"
          :loaded="subjects.loaded"
          :semesters="subjects.data"
          :expandable="false"
          :headers="[
            {text: 'Предмет', value: 'subject', sortable: false},
            {text: 'Редактировать контрольные точки', value: 'controls', sortable: false, width: '20%'},
          ]"
        >
          <template v-slot:title>
            Ведомые дисциплины
          </template>
          <template v-slot:item.controls="props">
            <div class="justify-center d-flex">
              <v-dialog fullscreen v-model="dialog">
                <template v-slot:activator="{on, attrs}">
                  <v-btn icon v-on="on" v-bind="attrs">
                    <v-icon>
                      edit
                    </v-icon>
                  </v-btn>
                </template>
                <MiddlePointListForm
                  :group_subject_id="props.item.id"
                  :dialog.sync="dialog"
                  :gets_param="gets_param"
                ></MiddlePointListForm>
              </v-dialog>
            </div>
          </template>
        </GroupSubjectsTemplate>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <GroupStudentsTemplate
          v-if="students.loaded"
          :loaded="students.loaded"
          :students="students.data"
          :expandable="false"
        >
          <template v-slot:student_link="{student}">
            <router-link v-if="student.fio !== ''" :to="makeDetailLink(student)">
              {{student.fio}}
            </router-link>
          </template>
        </GroupStudentsTemplate>
      </v-col>
    </v-row>
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>

<script>
  import TitledPageMixin from "@/mixins/TitledPageMixin";
  import {loadData} from "@/helper";
  import {generateListUidToName} from "@/helper/uidToName";
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
  import GroupTemplate from "@/modules/templates/group/GroupTemplate";
  import GroupCurriculumTemplate from "@/modules/templates/group/GroupCurriculumTemplate";
  import GroupSubjectsTemplate from "@/modules/templates/group/GroupSubjectsTemplate";
  import GroupStudentsTemplate from "@/modules/templates/group/GroupStudentsTemplate";
  import MiddlePointListForm from "@/modules/teacher/pages/subjects/MiddlePointListForm";
  import urls from "@/urls/teacher"
  import names from "@/modules/teacher/routers/names";

  export default {
    name: "GroupDetail",
    mixins: [TitledPageMixin],
    components: {
      DebugJsonComponent,
      GroupTemplate,
      GroupCurriculumTemplate,
      GroupSubjectsTemplate,
      GroupStudentsTemplate,
      MiddlePointListForm
    },
    data() {
      return {
        page_title: "Профиль группы",
        dialog: false,
        group: {
          id: this.$route.params.group_id,
          data: {},
          loading: false,
          loaded: false
        },
        subjects: {
          data: [],
          loading: false,
          loaded: false
        },
        students: {
          data: [],
          loading: false,
          loaded: false
        },
        old_filter: this.$route.params.old_filter,
        gets_param: {}
      }
    },
    computed: {
      journalLink() {
        //console.log(this.$route.params)
        let params = this.$route.params
        params.old = this.gets_param.old
        return {
          name: names.GROUPS.JOURNAL,
          params: params
        }
      },
      is_self_router(){
        return this.$route.name === names.GROUPS.DETAIL;
      }
    },
    methods: {
      makeDetailLink(item){
        return {
          name: names.STUDENT.DETAIL, params: {student_id: item.id}
        }
      },
      loadSubjects: function (url_object, gets_params) {
        this.subjects.loading = true;
        loadData(
          urls.URLS.getByRecievedUrlData(url_object, gets_params),
          (data) => {
            this.subjects.data = data;
            this.subjects.loaded = true;
            this.subjects.loading = false;
          }
        );
      },
      loadStudents: function (url_object, gets_params) {
        this.students.loading = true;
        loadData(
          urls.URLS.getByRecievedUrlData(url_object, gets_params),
          (data) => {
            this.students.data = data;
            for (let el of this.students.data) this.$set(el, "fio","")
            generateListUidToName(
              this.students.data,
              "user",
              "fio",
              () => {
                this.students.loading = false;
                this.students.loaded = true;
                this.students.data.sort(function (a, b) {
                  if (a.fio > b.fio) {
                    return 1;
                  }
                  if (a.fio < b.fio) {
                    return -1;
                  }
                  return 0;
                });
              }
            );
          }
        );
      },
      loadGroup: function () {
        loadData(
          urls.URLS.GROUP.DETAIL(this.group.id, this.gets_param),
          (data) => {
            this.group.data = data;
            this.group.loaded = true;
            this.loadSubjects(this.group.data.subjects, this.gets_param);
            this.loadStudents(this.group.data.students, this.gets_param);
          }
        );
      }
    },
    created() {
      if (this.old_filter)
        this.gets_param = {old: this.old_filter}
      if (this.is_self_router)
        this.loadGroup();
    }

  }
</script>

<style scoped>

</style>
