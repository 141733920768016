<template>
  <v-container>
    <v-card>
      <v-toolbar :extended='$vuetify.breakpoint.xsOnly'
                 elevation="0"
                 color="style-color-main-gray"
      >
        <v-text-field v-model="search.value"
                      dense
                      class="pa-3"
                      placeholder="Поиск по наименованию"
                      @change="searchData"
                      messages="Для поиска нажмите Enter"
        ></v-text-field>
        <v-spacer></v-spacer>
        <template v-if="$vuetify.breakpoint.smAndUp" >
          <v-toolbar-items>
            <v-btn text @click="openCreateForm">
              Создать
              <v-icon>add</v-icon>
            </v-btn>
          </v-toolbar-items>
        </template>
        <template v-slot:extension v-if='$vuetify.breakpoint.xsOnly'>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn text @click="openCreateForm">
              Создать
              <v-icon>add</v-icon>
            </v-btn>
          </v-toolbar-items>
        </template>
      </v-toolbar>
      <v-data-table
        :headers="subject_list.headers"
        :items="subject_list.items"
        :loading="pagination.loading"
        :items-per-page.sync="pagination.size"
        :page.sync="pagination.page"
        :server-items-length="pagination.count"
        no-data-text="Дисциплины не загружены"
        no-results-text="Дисциплины не были найдены"
        :footer-props="{'items-per-page-options':[10, 25, 50, 100], 'items-per-page-text': 'Число элементов'}"
      >
        <template v-slot:item.number="props">
          {{ props.index + 1 + (pagination.page-1)*pagination.size }}
        </template>
        <template v-slot:item.subject="props">
          <router-link :to="getDetailLink(props.item)">
            {{ props.item.subject }}
          </router-link>
        </template>
        <template v-slot:item.appraisal_tools="props">
          <v-icon color="success" v-if="props.item.appraisal_tools">done</v-icon><v-icon color="red" v-else>close</v-icon>
        </template>
        <template v-slot:item.methodological_materials="props">
          <v-icon color="success" v-if="props.item.methodological_materials">done</v-icon><v-icon color="red" v-else>close</v-icon>
        </template>
        <template v-slot:item.work_program="props">
          <v-icon color="success" v-if="props.item.work_program">done</v-icon><v-icon color="red" v-else>close</v-icon>
        </template>
        <template v-slot:item.action="props">
          <v-btn icon @click="openUpdateForm(props.item.id)">
            <v-icon>edit</v-icon>
          </v-btn>
          <v-btn icon @click="openDeleteApplier(props.item)">
            <v-icon>remove</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <Form
        :curriculum_id="curriculum_id"
        :subject_id="dialogs.form.id"
        v-if="dialogs.form.opened"
        :opened="dialogs.form.opened"
        @close="closeCreateForm"
      >
      </Form>
      <ApplyDialog
        v-if="dialogs.delete.applier"
        :opened="dialogs.delete.applier"
        @close="deleteSubject(dialogs.delete.selected, $event)">
        <template>
          Вы уверены, что хотите удалить дисциплину учебного плана
          <strong v-if='dialogs.delete.selected.subject !== undefined'>{{dialogs.delete.selected.subject}}</strong>
          <v-alert outlined type="warning">Будут удалены все семестры предмета со всеми оценками у всех обучающихся учебных
            групп, которые обучаются по этому учебному плану</v-alert>
        </template>
      </ApplyDialog>
      <MsgDialog
        v-if="dialogs.delete.result.opened"
        :opened="dialogs.delete.result.opened"
        :title="dialogs.delete.result.text"
        :success="dialogs.delete.result.success"
        :warning="dialogs.delete.result.warning"
        @close="dialogs.delete.result.opened = false"
      >
        <span v-if="dialogs.delete.selected !== undefined">
          Предмет учебного плана
        </span> "<span v-if="dialogs.delete.selected && dialogs.delete.selected.subject !== undefined">{{ dialogs.delete.selected.subject }}</span>"
        был удален из списка семестров дисциплины.
      </MsgDialog>
    </v-card>
  </v-container>
</template>

<script>
  // import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
  import urls from "../../urls";
  import {loadData, setUrlParameters} from "../../../../helper";
  // import Detail from "./Detail";
  import Form from "./Form";
  import ApplyDialog from "../../../core/components/ApplyDialog";
  import MsgDialog from "../../../core/components/MsgDialog";
  import {makeDeleteRequest} from "@/helper";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import UserDataMixin from "@/mixins/UserDataMixin";
  import editor_name from "@/modules/curriculumEditor/routers/names";
  import names from "@/modules/curriculumEditor/routers/names";

  export default {
    name: "SubjectsList",
    mixins: [
      PaginatedDataMapperMixin,
      UserDataMixin
    ],
    components: {
      // DebugJsonComponent,
      Form,
      ApplyDialog,
      MsgDialog
    },
    props: {},
    data(){
      return {
        search: {
          parameter: "subject"
        },
        title: "Список дисциплин учебного плана",
        curriculum_id: parseInt(this.$route.params.curriculum_id),
        dialogs: {
          delete: {
            selected: null,
            applier: false,
            result: {
              opened: false,
              text: "",
              success: true,
              warning: false
            }
          },
          form: {
            id: undefined,
            opened: false
          }
        },
        subject_list: {
          items: [],
          headers: [
            {text: "№", sortable: false, align: "left", width: "5%", value:"number"},
            {text: "Предмет", sortable: false, align: "left", width: "40%", value:"subject"},
            {text: "Общее количество часов", sortable: false, align: "left", value:"hours"},
            {text: "З.Е.", sortable: false,  align: "left", value:"zets"},
            {text: "ФОС", sortable: false,  align: "left", value:"appraisal_tools"},
            {text: "МУ", sortable: false,  align: "left", value:"methodological_materials"},
            {text: "РПД", sortable: false,  align: "left", value:"work_program"},
            {text: "Действия", sortable: false, align: "left", value:"action"}
          ]
        }
      }
    },
    methods: {
      getDetailLink(item){
        return {name: editor_name.CURRICULUM.SUBJECTS.DETAIL, params: {curriculum_id: this.curriculum_id, subject_id: item.id}}
      },
      loadData: function (url=undefined) {
        this.setLoading();
        if(url === undefined)
          url = urls.NAMES.EDITOR.SUBJECT.LIST(this.curriculum_id);
        loadData(
          url,
          (data) => {
            this.mapPagination(data);
            this.subject_list.items = data.results;
            this.setCurrentLoadUrl(url);
            this.setNotLoading();
          }
        )
      },
      openCreateForm(){
        this.dialogs.form.id = undefined;
        this.dialogs.form.opened = true;
      },
      openUpdateForm(selected){
        this.dialogs.form.id= selected;
        this.dialogs.form.opened = true;
      },
      closeCreateForm(){
        this.dialogs.form.id = undefined;
        this.dialogs.form.opened = false;
        this.reloadData();
      },
      openDeleteApplier(selected){
        this.dialogs.delete.selected = selected;
        this.dialogs.delete.applier = true;
      },
      deleteSubject(item, apply) {
        this.dialogs.delete.applier = false;
        if (apply) {
          let url = urls.NAMES.EDITOR.SUBJECT.DESTROY(this.curriculum_id, item.id);
          makeDeleteRequest(url)
            .then(resp => {
              this.dialogs.delete.applier = false;
              if (!resp.ok) {
                resp.json().then((json) => {
                  throw json;
                }).catch((error) => {
                  // this.loadSubjects();
                  this.loadData(this.getCurrentLoadUrl());
                  this.dialogs.delete.result.text = error;
                  this.dialogs.delete.result.warning = true;
                  this.dialogs.delete.result.success = false;
                  this.dialogs.delete.result.opened = true;
                });
              }else{
                this.loadData(this.getCurrentLoadUrl());
                this.dialogs.delete.result.text = "Успешно удален";
                this.dialogs.delete.result.warning = false;
                this.dialogs.delete.result.success = true;
                this.dialogs.delete.result.opened = true;
                // this.updatedSignal();
              }
            })
        }
      }
    },
    created() {
      this.$store.commit("mainpage/changeTitle", this.title);
      this.loadData();
    }
  }
</script>

<style scoped>

</style>
