<template>
  <div v-if="is_self_router">
    <v-container>
      <v-row>
        <v-col cols="12" md="10" offset-md="1">
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
              v-for="(item, index) in list_menu" :key="`teacher_card_${index}`"
            >
              <v-card class="elevation-5 ma-3" >
                <router-link :to="getRouterParam(item)">
                  <div class="blue lighten-4 black--text teacher rounded">
                    <v-row no-gutters class="align-center vert-align-height">
                      <v-col md="11" lg="11" sm="11" class="text-left pl-3">
                          {{ item.title }}
                      </v-col>
                      <v-col md="1" lg="1" sm="1">
                        <v-icon>{{ item.icon }}</v-icon>
                      </v-col>
                    </v-row>
                  </div>
                </router-link>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>

<script>
    import UserDataMixin from "../../../mixins/UserDataMixin"
    import names from "@/modules/teacher/routers/names";
    import {loadData} from "@/helper";
    import teachers from "@/urls/teacher";


    export default {
      name: "ListUsers",
      mixins: [UserDataMixin],
      data: function () {
        return {
          page_title: "Преподаватель",
          teacher: {},
          teacher_uid: this.$route.params.teacher_uid
        }
      },
      computed: {
        is_self_router(){
          return this.$route.name === names.BASE;
        },
        left_menu: function () {
          return this.$store.getters["leftmenu/getLeftMenu"]
        },
        list_menu: function () {
          let arr = this.$store.getters["leftmenu/getLeftMenu"].slice();
          arr.shift()
          return arr
        }
      },
      methods: {
        loadDetail(){
          loadData(
            teachers.TEACHER.DETAIL(this.teacher_uid),
            (data) => {
              this.teacher = data
              this.page_title = this.teacher.names
            }
          )
        },
        getRouterParam(item){
          if(item.router.name !== undefined)
            return item.router;
          else
            return {name: item.router }
        }
      },
      created() {
      }
    }

</script>

<style scoped>
  .vert-align-height{
    height: inherit;
  }
  .teacher{
    height: 100px;
    text-align: center;
    font-size: 24px;
    user-select: none;
    text-decoration: underline !important;
  }
</style>
