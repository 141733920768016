<template>
  <scroll-center-dialog v-model="opened_form"
                        @close="closeDialog"
                        @apply="apply"
                        :disabled="loading"
                        :loading="loading"
                        width="500"
                        :title="!!id ? 'Изменить государственный стандарт' : 'Добавить государственный стандарт'"
  >
    <v-text-field
      :error="hasError('number')"
      :error-messages="getError('number')"
      label="Номер"
      required
      v-model="value.number"
      class="ml-1"
    ></v-text-field>

    <v-text-field
      :error="hasError('year')"
      :error-messages="getError('year')"
      label="Год"
      required
      v-model="value.year"
      class="ml-1"
    ></v-text-field>

    <v-text-field
      :error="hasError('name')"
      :error-messages="getError('name')"
      label="Название"
      required
      v-model="value.name"
      class="ml-1"
    ></v-text-field>
    <v-file-input
      v-if="typeof value.file !== 'string'"
      class="pa-1 ma-1"
      :error="hasError('file')"
      :error-messages="getError('file')"
      v-model="value.file"
      :messages="setInputFile(value.file)"
      accept="application/pdf"
      label="Файл (Формат .pdf, до 15МБ)"
    ></v-file-input>
    <v-text-field
      v-else-if="typeof value.file === 'string' && value.file !==null"
      class="pa-1 ma-1"
      v-model="value.file"
      clearable
      @click:clear="value.file = null"
      prepend-icon="attach_file"
      append-icon="get_app"
      readonly
      @click:append="loadFileWork"
      label="Файл (Формат .pdf, до 15МБ)"
    ></v-text-field>
  </scroll-center-dialog>
</template>

<script>
  import urls from "@/urls/management";
  import UserDataFormMixin from "@/mixins/UserDataFormMixin"
  import {makePostRequestFile,loadData} from "@/helper";
  import {loadFile} from "@/helper/fileLoader";
  import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";
  export default {
    components: {ScrollCenterDialog},
    mixins: [UserDataFormMixin],
    name: "FormStateStandards",
    props: {
      id: Number,
      number: String,
      year: String,
      name: String,
      opened_form: Boolean
    },
    data() {
      return {
        URL_CREATE: urls.EDUCATION.REFERENCE.STATE_STANDARDS.CREATE(),
        URL_UPDATE: urls.EDUCATION.REFERENCE.STATE_STANDARDS.UPDATE(this.id),
        URL_DETAIL: urls.EDUCATION.REFERENCE.STATE_STANDARDS.DETAIL(this.id),
        loading: false,
        standart: null,
        value: {
          number: "",
          name: "",
          id: this.id || undefined,
          file: null,
          year: ""
        }
        // errors: {}
      }
    },
    methods: {
      setInputFile(str){
        if (typeof str === "string"){
          return [str]
        }
        return []
      },
      loadFileWork(){
        loadFile(this.standart.document.file)
      },
      apply: function () {
        this.loading = true
        if (this.id === undefined) {
          let url = this.getCreateUrl();
          let formData = new FormData();
          formData.append("number", this.value.number)
          formData.append("year", this.value.year)
          formData.append("name", this.value.name)
          if (typeof this.value.file === "object" && this.value.file !== null){
            formData.append("file", this.value.file, this.value.file.name)
          }
          let status = true;
          makePostRequestFile(url, formData)
            .then(resp => {
              if (resp.status < 299) {
                status = false;
              }
              return resp.json();
            })
            .then(json => {
              if (!status) {
                this.loading = false
                this.$emit("addItem", json);
              } else {
                this.loading = false
                this.receiveErrors(json);
              }
            })
        } else {
          let url = this.getUpdateUrl();
          let formData = new FormData();

          formData.append("number", this.value.number)
          formData.append("year", this.value.year)
          formData.append("name", this.value.name)

          if (typeof this.value.file === "object" && this.value.file !== null){
            formData.append("file", this.value.file, this.value.file.name)
          }
          let status = true;
          makePostRequestFile(url, formData,"PATCH")
            .then(resp => {
              if (resp.status < 299) {
                status = false;
              }
              return resp.json();
            })
            .then(json => {
              if (!status) {
                this.loading = false
                this.$emit("refreshItem", json);
              } else {
                this.loading = false
                this.receiveErrors(json);
              }
            })

        }
      },
      closeDialog() {
        this.$emit("ended", "")
      },
      loadDetail(){
        loadData(this.URL_DETAIL,(data)=>{
          this.value.number = data.number
          this.value.year = data.year
          this.value.name = data.document.name
          this.value.file = data.document.file.file_name
          this.standart = data
        })
      }
    },
    created() {
      if(this.id){
        this.loadDetail()
      }
    }
  }
</script>

<style scoped>

</style>
