<template>
  <v-container v-if="$route.name === names.EDUCATIONAL.ADMISSION_REQUEST.LIST">
    <v-card >
      <v-toolbar
        class="mb-2"
        color="style-color-main-gray"
        flat
      >
        <div class="text-left">Внимание! Запросы на выгрузку списка допусков автоматически удаляются через 7 дней. Пожалуйста, учитывайте эти сроки и сохраняйте важную информацию вовремя.</div>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
              @click="loadData()"
              class="mr-2"
              medium
            >
              <v-icon>
                update
              </v-icon>
            </v-btn>
          </template>
          <span>Обновить данные</span>
        </v-tooltip>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="items"
        show-expand
        item-key="uid"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length + 1" >
              <v-card flat class="text-left my-3">
                <v-card-title>Фильтры использованные при выгрузке:</v-card-title>
                <v-row no-gutters class="body-1">
                  <v-col cols="4"> Допуск на экзамен: <v-icon color="green" v-if="item.query_params.success">done</v-icon></v-col>
                  <v-col cols="4">Предмет: {{getDisplay(Number(item.query_params.subject), selectors.subject)}}</v-col>
                  <v-col cols="4">ФИО: {{item.query_params.fio}}</v-col>
                  <v-col cols="4">Сессия: {{item.query_params.session}}</v-col>
                  <v-col cols="4">Курс: {{item.query_params.course}}</v-col>
                  <v-col cols="4">Кафедра: {{item.query_params.cathedra}}</v-col>
                  <v-col cols="4">Семестр: {{item.query_params.course_semester}}</v-col>
                  <v-col cols="4">Группа: {{getDisplay(Number(item.query_params.group),selectors.group)}}</v-col>
                  <v-col cols="4">Факультет: {{item.query_params.faculty}}</v-col>
                </v-row>
              </v-card>
          </td>
        </template>
        <template v-slot:item.success="props">
          <v-icon v-if="props.item.success">done</v-icon>
          <v-icon v-else>clear</v-icon>
        </template>
        <template v-slot:item.error="props">
          <v-icon v-if="props.item.error">done</v-icon>
          <v-icon v-else>clear</v-icon>
        </template>
        <template slot="no-data">
          <v-card-text>Данные не загружены</v-card-text>
        </template>
        <template v-slot:item.date="props">
          <span>{{displayDate(props.item.date,true)}}</span>
        </template>
        <template v-slot:item.expire="props">
          <span>{{displayDate(props.item.expire,true)}}</span>
        </template>
        <template v-slot:item.file="props">
          <a  v-if="props.item.file" :href="URL_XSL(props.item.uid)" target="_blank">
            Скачать
          </a>
          <div class="px-2" v-else>
            <v-icon >clear</v-icon>
          </div>

        </template>

      </v-data-table>
    </v-card>
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>

<script>
  import urls from "@/urls/management";
  import UserDataMixin from "@/mixins/UserDataMixin"
  import {getDisplay, loadData, makeGetRequest} from "@/helper";
  import TitledPageMixin from "@/mixins/TitledPageMixin";
  import {generateListUidToName, generateUidToName, uidToPositionHelds} from "@/helper/uidToName";
  import moment from "moment";
  import names from "@/modules/educationalManagement/routers/names";
  import selectors from "@/urls/selectors";

  export default {
    name: "ListAdmissions",
    computed: {
      urls() {
        return urls
      }
    },

    mixins: [
      TitledPageMixin,
      UserDataMixin],
    components: {},

    data () {
      return {
        page_title: "Запросы на выгрузку допусков",
        names:names,
        expanded: [],
        items: [],
        loading: true,
        URL_LIST: urls.EDUCATION.ADMISSION_REQUEST.LIST(),
        URL_XSL: urls.EDUCATION.ADMISSION_REQUEST.SAVE,
        headers: [
          {text: "Создан", sortable: false, align: "left", value: "date"},
          {text: "Действителен до", sortable: false, align: "left", value: "expire"},
          {text: "Пользователь", sortable: false, align: "left", value: "fio_user"},
          {text: "Выполнен", sortable: false, align: "left", value: "success"},
          {text: "Ошибка при формировании", sortable: false, align: "left", value: "error"},
          {text: "Файл", sortable: false, align: "left", value: "file"}
        ],
        selectors:{
          subject:[],
          group:[]
        }
      }
    },
    created() {
      this.loadData()
      this.loadSelectorSubject()
      this.loadSelectorGroup()
    },
    watch: {
    },
    methods: {
      getDisplay,
      loadSelectorSubject(){
        loadData(
          selectors.URLS.SEMESTERS(),
          (data) => {
            this.selectors.subject=data
            this.selectors.subject.unshift({text:"Все предметы",value:null})
          }
        )
      },
      loadSelectorGroup(){
        loadData(
          selectors.URLS.ACTIVE_GROUP(),
          (data) => {
            this.selectors.group=data
            this.selectors.group.unshift({text:"Все группы",value:null})
          }
        )
      },
      displayDate(date, minutes = false) {

        let format = "DD.MM.YYYY";
        if (minutes)
          format += " HH:mm";
        return moment(date).format(format)
      },
      loadData() {
        let url = this.URL_LIST;
        this.items=[]
        loadData(
          url,
          (data) => {
            this.items = data
            this.items.forEach(el => {
              this.$set(el, "fio_user", "");
            });
            generateListUidToName(this.items, "user", "fio_user");
          }
        )
      },
      getDetail(item){
        // this.$router.push({name: names.ADMISSIONS.DETAIL, params: {idAdmission: item.id}})
      }
    }
  }


</script>

<style scoped>

</style>

