import {getUrl, getUrlAllowed, makeMediaHostUrl, getUrlWithParameters} from "../../../helper/urls";

// const host_account = process.env.VUE_APP_API_HOST + process.env.VUE_APP_ACCOUNTS_PREFIX.replace('/', '');
const host_education = process.env.VUE_APP_API_HOST + process.env.VUE_APP_EDUCATION_PREFIX.replace("/", "");
// const account = String(process.env.VUE_APP_ACCOUNTS_PREFIX).replace('/', '');
const education = String(process.env.VUE_APP_EDUCATION_PREFIX).replace("/", "");



export default {
  CHOICES: {
    // COURSES: () => getUrlWithParameters(host_education, education, 'core|selector|course', 'get'),
    SEMESTERS: (gets=null) => getUrlWithParameters(host_education, education, "choices|academic|year|semester", "get", {}, gets),
    CONTROL_TYPE: (gets=null) => getUrlWithParameters(host_education, education, "choices|curriculum|control_type", "get", {}, gets),
    LESSON_KIND: (gets=null) => getUrlWithParameters(host_education, education, "choices|curriculum|lesson_kind", "get", {}, gets),
    GROUP_TYPE: (gets=null) => getUrlWithParameters(host_education, education, "choices|group|type", "get", {}, gets),
    LEVEL: (gets=null) => getUrlWithParameters(host_education, education, "choices|ministry|level", "get", {}, gets),
    FORM: (gets=null) => getUrlWithParameters(host_education, education, "choices|ministry|form", "get", {}, gets),
    DOCUMENT_TYPE: (gets=null) => getUrlWithParameters(host_education, education, "choices|student|document_type", "get", {}, gets)
    // : () => getUrlWithParameters(host_education, education, '', ''),

  },
  SELECTORS: {
    YEAR:
      (gets=null) => getUrlWithParameters(
        host_education,
        education,
        "selectors|academic|year|year",
        "get",
        {},
        gets
      ),
    RATING_SYSTEM:
      (gets=null) => getUrlWithParameters(
        host_education,
        education,
        "selectors|academic|year|rating_system",
        "get",
        {},
        gets
      ),
    YEAR_SEMESTER:
      (gets=null) => getUrlWithParameters(
        host_education,
        education,
        "selectors|academic|year|semester",
        "get",
        {},
        gets
      ),
    SESSION:
      (gets=null) => getUrlWithParameters(
        host_education,
        education,
        "selectors|academic|year|session",
        "get",
        {},
        gets
      ),
    SUBJECTS_CURRICULUM:
      (curriculum_id,gets=null) => {
        return getUrlWithParameters(
        host_education,
        education,
        "selectors|curriculum|subjects",
        "get",
        {curriculum_id: curriculum_id},
        gets
      )},
    COURSES:
      (gets=null) => {
        return getUrlWithParameters(
          host_education,
          education,
          "selectors|core|course",
          "get",
          {},
          gets
        )
      },
    SUBJECTS:
      (gets=null) => getUrlWithParameters(
        host_education,
        education,
        "selectors|core|subject",
        "get",
        {},
        gets
      ),
    CURRICULUM:
      (gets=null) => getUrlWithParameters(
        host_education,
        education,
        "selectors|curriculum|curriculum",
        "get",
        {},
        gets
      ),
    GROUP_SEMESTER:
      (gets=null) => getUrlWithParameters(
        host_education,
        education,
        "selectors|group|semester",
        "get",
        {},
        gets
      ),
    COMPETENCES:
      (gets=null) => getUrlWithParameters(
        host_education,
        education,
        "selectors|ministry|competence",
        "get",
        {},
        gets
      ),
    STATE_STANDARD:
      (gets=null) => getUrlWithParameters(
        host_education,
        education,
        "selectors|ministry|state_standard",
        "get",
        {},
        gets
      ),
    TRAINING_DIRECTION:
      (gets=null) => getUrlWithParameters(
        host_education,
        education,
        "selectors|ministry|training_direction",
        "get",
        {},
        gets
      ),
    TRAINING_PROFILE:
      (gets=null) => getUrlWithParameters(
        host_education,
        education,
        "selectors|ministry|training_profile",
        "get",
        {},
        gets
      ),
    CATHEDRAS:
      (gets=null) => getUrlWithParameters(
        host_education,
        education,
        "selectors|relations|cathedra",
        "get",
        {},
        gets
      )
  }
}
