<template>
  <v-card flat dense tile class="py-0">
    <v-subheader>Список преподавателей</v-subheader>
    <v-divider></v-divider>
    <v-data-table
      :headers="headers"
      hide-default-header
      hide-default-footer
      :items="teachers"
      :items-per-page="getTeacher"
      no-data-text="Нет ни одного назначенного преподавателя"
    >
    </v-data-table>
  </v-card>
</template>

<script>
import {generateListUidToName} from "@/helper/uidToName";

export default {
  name: "TeacherTableTemplate",
  props: {
    teachers: {
      requirement: true,
      type: Array
    }
  },
  computed:{
    getTeacher: function () {
      if (this.teachers)
        return this.teachers.length
      else {
        return 1
      }
    }
  },
  data: function () {
    return {
      headers: [
        {text: "", value: "fio"},
        {text: "", value: "type"}
      ]
    }
  },
  created() {
    generateListUidToName(this.teachers, "uid", "fio", (data) => {
    })
  }
}
</script>

<style scoped>

</style>
