
import ActualGroupList from "../pages/base/ActualGroupList";
import ActualStudentList from "../pages/base/ActualStudentList";
import CurriculumDetail from "../pages/base/curriculum/Detail";
import CurriculumList from "../pages/base/curriculum/List";
import EducationManagementBreadcrumbs from "../pages/Breadcrumbs";
import PermissionWorkerList from  "../pages/permission/PermissionWorkerList"
import SubjectsDocList from  "../pages/document_subjects/List"
import group from "./group";
import student from "./student";
import reference from "./reference";
import academic_yrear from "./academic_yrear";

import names from "@/modules/educationalManagement/routers/names";
import {ACCOUNTS_INSTANCE, EDUCATION_INSTANCE,ORGANIZATION_INSTANCE} from "@/helper/consts";
import AdmissionList from "@/modules/educationalManagement/pages/admission/List.vue";
import AdmissionDetail from "@/modules/educationalManagement/pages/admission/Detail.vue";

import AdmissionRequestList from "@/modules/educationalManagement/pages/admission_request/List.vue";

export default ([
  {
    path: "/management",
    name: names.EDUCATIONAL.BASE,
    component: EducationManagementBreadcrumbs,
    meta: {
      requiredAuth: true,
      breadcrumb: "Главная",
      instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE]
    },
    children: [
      {
        path: "group",
        name: names.EDUCATIONAL.GROUP.LIST,
        component: ActualGroupList,
        meta: {
          requiredAuth: true,
          breadcrumb: "Список групп",
          instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE]
        },
        children: [
          ...group
        ]
      },
      {
        path: "student",
        name: names.EDUCATIONAL.STUDENTS.LIST,
        component: ActualStudentList,
        meta: {
          requiredAuth: true,
          breadcrumb: "Список обучающихся",
          instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE]
        },
        children: [
          ...student
        ]
      },
      {
        path: "curriculums",
        name: names.EDUCATIONAL.CURRICULUM.LIST,
        component: CurriculumList,
        meta: {
          requiredAuth: true,
          breadcrumb: "Список учебных планов",
          instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE]
        },
        children: [
          {
            path: ":curriculum_id([0-9]+)",
            name: names.EDUCATIONAL.CURRICULUM.DETAIL,
            component: CurriculumDetail,
            meta: {
              requiredAuth: true,
              breadcrumb: "Учебный план",
              instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE]
            },
            children: []
          }
        ]
      },
      {
        path: "subjects",
        name: names.EDUCATIONAL.SUBJECTS_DOC.LIST  ,
        component: SubjectsDocList,
        meta: {
          requiredAuth: true,
          breadcrumb: "Список документов",
          instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE]
        }
      },
      {
        path: "admission",
        name: names.EDUCATIONAL.ADMISSION.LIST  ,
        component: AdmissionList,
        meta: {
          requiredAuth: true,
          breadcrumb: "Список допусков",
          instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE]
        },
        children: [
          {
            path: ":idAdmission",
            name: names.EDUCATIONAL.ADMISSION.DETAIL,//'EducationalManagementWorkDetail',
            component: AdmissionDetail,
            meta: {
              requiredAuth: true,
              breadcrumb: "Допуск",
              instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE]
            },
            children: []
          }
        ]
      },
      {
        path: "request",
        name: names.EDUCATIONAL.ADMISSION_REQUEST.LIST  ,
        component: AdmissionRequestList,
        meta: {
          requiredAuth: true,
          breadcrumb: "Запросы на выгрузку допусков",
          instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE]
        }
      },
      {
        path: "permission",
        name: names.EDUCATIONAL.PERMISSION.LIST ,
        component: PermissionWorkerList,
        meta: {
          requiredAuth: true,
          breadcrumb: "Список прав",
          instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE,ORGANIZATION_INSTANCE]
        }
      },
      ...academic_yrear,
      ...reference
    ]
  }

])


