<template>
  <scroll-center-dialog
    v-model="inValue"
    @close="inValue = false"
    @apply="inValue = false"
    applyText="Ок"
    :dennyable="false"
    title="Подробная техническая информация"
  >
    <debug-json-component :json="item" status="Полные загруженные данные" hidable></debug-json-component>
  </scroll-center-dialog>
</template>

<script>
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent.vue";

export default {
  name: "Detail",
  components: {DebugJsonComponent, ScrollCenterDialog},
  props: {
    item: {
      required: true
    },
    value: {
      required: true
    }
  },
  data() {
    return {
      inValue: undefined,
      loading: false
    }
  },
  watch: {
    inValue(nV, oV) {
      this.$emit("input", this.inValue)
    },
    value() {
      this.inValue = this.value
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
