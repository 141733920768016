<template>
  <v-card class="pa-0">
    <v-toolbar dark color="primary">
      <v-toolbar-title>
        Нормативная документация
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn text @click="$emit('update:open', false)">
          <v-icon>
            close
          </v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-container>
      <v-row no-gutters class="text-left subtitle-1 py-3">
        <v-col cols="12" md="3"> Файл рабочей программы:</v-col>
        <v-col cols="12" md="9" v-if="value.item.group_subject.work_program">
          {{value.item.group_subject.work_program.file_name}}
          <v-btn @click="loadFileWork(value.item.group_subject.work_program)" icon><v-icon >get_app</v-icon></v-btn>
        </v-col>
        <v-col cols="12" md="9" v-else>Файл не загружен</v-col>
      </v-row>
      <v-row no-gutters class="text-left subtitle-1 py-3">
        <v-col cols="12" md="3">Файл оценочных средств:</v-col>
        <v-col cols="12" md="9" v-if="value.item.group_subject.appraisal_tools">
          {{value.item.group_subject.appraisal_tools.file_name}}
          <v-btn @click="loadFileWork(value.item.group_subject.appraisal_tools)" icon><v-icon >get_app</v-icon></v-btn>
        </v-col>
        <v-col cols="12" md="9" v-else>Файл не загружен</v-col>
      </v-row>
      <v-row no-gutters class="text-left subtitle-1 py-3">
        <v-col cols="12" md="3">Файл методических материалов:</v-col>
        <v-col cols="12" md="9" v-if="value.item.group_subject.methodological_materials">
          {{value.item.group_subject.methodological_materials.file_name}}
          <v-btn @click="loadFileWork(value.item.group_subject.methodological_materials)" icon><v-icon >get_app</v-icon></v-btn>
        </v-col>
        <v-col cols="12" md="9" v-else>Файл не загружен</v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import {loadFile} from "@/helper/fileLoader"
export default {
  name: "FileListForm",
  props: {
    subject: {
      type: Object
    },
    open:{
      default: true,
      type: Boolean
    }
  },
  data: function () {
    return {
      value: null
    }
  },
    methods: {
      loadFileWork(url){
        loadFile(url)
      }
  },
  created() {
    this.value = this.subject
  }
}
</script>

<style scoped>

</style>
