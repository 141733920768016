
import BasePage from "../pages/Breadcrumbs";
import CathedraAdmins from "@/modules/cathedra/pages/core/AdminList";
import GroupsListBase from "../pages/core/GroupsListBase";
import GroupsDetail from "../pages/groups/Detail"
import Teachers from "../pages/core/Teachers";
import TaughtSubjects from "../pages/core/TaughtSubjects";
import StudentsListBase from "../pages/core/StudentsListBase";
import GroupJournalBase from "../pages/journal/Base";
import AdmissionDetail from "@/modules/cathedra/pages/admission/Detail";
import ListAdmissions from "@/modules/cathedra/pages/admission/List.vue";
import ListSubjectsDoc from "@/modules/cathedra/pages/document_subjects/List.vue";
import SubjectsDocDetail from "@/modules/cathedra/pages/document_subjects/Detail.vue";
import ListCurriculum from "../../cathedra/pages/curriculum/List"
import DetailedCurriculum from "../../cathedra/pages/curriculum/Detail"
import ArchiveDetail from "../pages/groups/ArchiveDetail";
import GroupArchiveJournalBase from "../pages/journal/ArchiveBase";
import ListIndividualStudents from "@/modules/cathedra/pages/individual_students/ListStudents"
import IndividualStudentJournal from "@/modules/cathedra/pages/individual_students/IndividualStudentJournal"

const accounts = String(process.env.VUE_APP_ACCOUNTS_PREFIX).replace("/", "");
const education = String(process.env.VUE_APP_EDUCATION_PREFIX).replace("/", "");
const organization = String(process.env.VUE_APP_ORGANIZATION_PREFIX).replace("/", "");

import names from "./names";
import cathedras from "../../../urls/cathedras";
import students from "./students";
import teachers from "./teachers";

export default ([
  {
    path: "/cathedras",
    name: names.CathedraList,//'Administrative|Cathedra',
    component: BasePage,
    meta: {
      requiredAuth: true,
      breadcrumb: "Кафедры",
      instances: [education, accounts]
    }
  },
  {
    path: "/cathedras/:cathedra_uid",
    name: names.CathedraDetail,//'Administrative|Cathedra|Detail',
    component: BasePage,
    meta: {
      requiredAuth: true,
      breadcrumb: function(d) {
        // console.log(d);
        return "Кафедра";
      },
      instances: [education, accounts]
    },
    children: [
      {
        path: "admission",
        name: names.CathedraAdmission,//'Administrative|Cathedra|Teachers',
        component: ListAdmissions,
        meta: {
          requiredAuth: true,
          breadcrumb: "Список допусков",
          instances: [education, accounts]
        },
        children: [
          {
            path: ":idAdmission",
            name: names.CathedraAdmissionDetail,//'Administrative|Cathedra|Teachers',
            component: AdmissionDetail,
            meta: {
              requiredAuth: true,
              breadcrumb: "Допуск",
              instances: [education, accounts,organization]
            }
          }
        ]
      },
      {
        path: "documents",
        name: names.CathedraListSubjects,//'Administrative|Cathedra|Teachers',
        component: ListSubjectsDoc,
        meta: {
          requiredAuth: true,
          breadcrumb: "Список предметной документации",
          instances: [education, accounts]
        },
        children: [
          {
            path: ":idDoc",
            name: names.CathedraSubjectsDetail,//'Administrative|Cathedra|Teachers',
            component: SubjectsDocDetail,
            meta: {
              requiredAuth: true,
              breadcrumb: "Подробнее",
              instances: [education, accounts]
            }
          }
        ]
      },
      {
        path: ":taught(taught)?/:archive(archive)?/groups",
        name: names.CathedraGroups,
        component: GroupsListBase,
        meta: {
          requiredAuth: true,
          divided: true,
          breadcrumb: function(props) {
            if(props.taught !== undefined)
              return "Ведомые группы";
            else if (props.archive !== undefined)
              return "Архивные группы";
            else
              return "Кафедральные группы";
          }
        },
        children: [
          {
            path: ":group_id",
            name: names.CathedraGroupsDetail,//'Administrative|Cathedra|Groups|Detail',
            component: GroupsDetail,
            meta: {
              requiredAuth: true,
              breadcrumb (params) {
                // console.log(this.$route)
                // if(this.$route.query.from_student)
                //   return {
                //     label: 'Группа',
                //     parent: names.CathedraStudentsDetail,
                //   };
                return "Группа"
              }
            },
            children: [
              {
                path: "journal",
                name: names.CathedraGroupsDetailJournal,//'Administrative|Cathedra|Groups|Detail',
                component: GroupJournalBase,
                meta: {
                  requiredAuth: true,
                  breadcrumb: "Журнал группы"
                },
                children: [

                ]
              }
            ]
          },
          {
            path: "old/:group_id",
            name: names.CathedraArchiveGroupsDetail,
            component: ArchiveDetail,
            meta: {
              requiredAuth: true,
              breadcrumb (params) {
                return "Архивная группа"
              }
            },
            children: [
              {
                path: "journal",
                name: names.CathedraArchiveGroupsDetailJournal,
                component: GroupArchiveJournalBase,
                meta: {
                  requiredAuth: true,
                  breadcrumb: "Журнал группы"
                }
              }
            ]
          }
        ]
      },
      {
        path: "teachers",
        name: names.CathedraTeachers,//'Administrative|Cathedra|Teachers',
        component: Teachers,
        meta: {
          requiredAuth: true,
          breadcrumb: "Список преподавателей",
          instances: [education]
        },
        children: [
          ...teachers
        ]
      },
      {
        path: ":taught(taught)?/students",
        name: "Administrative|Cathedra|Students",
        component: StudentsListBase,
        meta: {
          requiredAuth: true,
          breadcrumb: function(props) {
            // console.log(props)
            if(props.taught !== undefined)
              return "Ведомые обучающиеся";
            else
              return "Кафедральные обучающиеся";
          },
          instances: [education]
        },
        children: [
          ...students
        ]
      },
      {
        path: "individual_students",
        name: names.CathedraIndividualStudents,//'Administrative|Cathedra|Teachers',
        component: ListIndividualStudents,
        meta: {
          requiredAuth: true,
          breadcrumb: "Список обучающихся с индивидуальным учебным планом",
          instances: [education, accounts]
        },
        children: [
          {
            path: ":student_id(\\d+)/journal",
            name: names.CathedraIndividualStudentsJournal,
            component: IndividualStudentJournal,
            meta: {
              requiredAuth: true,
              breadcrumb: "Журнал оценок"
            }
          }
        ]
      },
      {
        path: "taught/subjects",
        name: "Administrative|Cathedra|Taught|Subjects",
        component: TaughtSubjects,
        meta: {
          requiredAuth: true,
          breadcrumb: "Ведомые дисциплины",
          instances: [education]
        },
        children: []
      },
      {
        path: "curriculum",
        name: names.CathedraCurriculumList,
        component: ListCurriculum,
        meta: {
          breadcrumb: "Учебный план",
          requiredAuth: true,
          instances: [
            accounts,
            education,
            organization
          ]
        },
        children: [
          {
            path: ":idCurriculum",
            name: names.CathedraCurriculumDetail,
            component: DetailedCurriculum,
            meta: {
              breadcrumb: "Подробнее",
              requiredAuth: true,
              instances: [
                accounts,
                education,
                organization
              ]
            }
          }
        ]
      },
      {
        path: "admins",
        name: names.CathedraAdmins,//'Administrative|Cathedra|Teachers',
        component: CathedraAdmins,
        meta: {
          requiredAuth: true,
          breadcrumb: "Список административных работников",
          instances: [education]
        },
        children: []
      }
    ]
  }
  // ...curriculum,
])


