<template>
  <v-container v-if="$route.name === names.StudentEducationAdmission">
    <v-card >
      <v-toolbar
        class="mb-2"
        color="style-color-main-gray"
        flat
      >
        <v-card-title class="title px-0" >
          Список допусков
        </v-card-title>
      </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="pagination.loading"
            class="elevation-1"
            no-data-text="Нет данных"
            :page.sync="pagination.page"
            :items-per-page.sync="pagination.size"
            :server-items-length="pagination.count"
            :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
          >
            <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
            <template v-slot:item="props">
              <tr @click="getDetail(props.item)"  class="text-left" style="cursor: pointer">
                <td>{{props.item.number}} </td>
                <td> {{props.item.subject}} </td>
                <td><span>{{getNumberSemestr(props.item.semester,props.item.course)}}  </span></td>
                <td> {{props.item.control_type}} </td>
                <td>
                  <v-tooltip bottom >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{getAbbreviation(props.item.academic_difference, props.item.is_exam)}}</span>
                    </template>
                    <span>{{getAdmissionType(props.item.academic_difference, props.item.is_exam)}}</span>
                  </v-tooltip>
                </td>
                <td> {{props.item.in_semester}}</td>
                <td> {{props.item.total}}</td>
                <td class="justify-center"> {{props.item.officially_closed}} </td>
                <td class="justify-center">
                  <span v-if="props.item.success == null">Допуск не закрыт</span>
                  <span v-else-if="props.item.success">
                  Успешно <v-icon color="success" v-if="props.item.success">done</v-icon>
                </span>
                  <span v-else>
                  Не успешно <v-icon>clear</v-icon>
                </span>
                </td>
              </tr>
            </template>
            <template slot="pageText" slot-scope="item">
              {{item.pageStart}} - {{item.pageStop}} из {{item.itemsLength}}
            </template>
            <template slot="no-data">
              <v-card-text>Данные не загружены</v-card-text>
            </template>
          </v-data-table>
    </v-card>
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>

<script>
  import urls from "@/urls/student"
  import UserDataMixin from "../../../../mixins/UserDataMixin"
  import {generateListUidToName} from "../../../../helper/uidToName";
  import {loadData} from "@/helper";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import names from "@/modules/student/routers/names"

  export default {
    name: "ListAdmissions",
    mixins: [
      PaginatedDataMapperMixin,
      UserDataMixin
    ],
    data () {
      return {
        total: 0,
        items: [],
        loading: true,
        pagination: {},
        URL_LIST: urls.STUDENT.ADMISSION.LIST(this.$route.params.idStudent),
        headers: [
          {text: "Номер", sortable: false, align: "left"},
          {text: "Предмет", sortable: false, align: "left"},
          {text: "Семестр", sortable: false, align: "left"},
          {text: "Тип контроля", sortable: false, align: "left"},
          {text: "Тип допуска", sortable: false, align: "left", value: "admission_type"},
          {text: "Рейтинг в семестре", sortable: false, align: "left"},
          {text: "Рейтинг", sortable: false, align: "left"},
          {text: "Дата сдачи", sortable: false, align: "left"},
          {text: "Статус", sortable: false, align: "left"}
        ]
      }
    },
    watch: {},
    computed: {
      names: () => names
    },
    created() {
      this.$store.commit("mainpage/changeTitle", "Список допусков");
      this.loadData();
    },
    methods: {
      getNumberSemestr(semester,course){
        let num =0
        if(semester === "осенний"){
          num = course * 2 - 1
        }else {
          num = course * 2
        }
        return num + " (" +semester+ ")"
      },
      getAbbreviation(academic_difference, is_exam){
        if (academic_difference)
          return "А/Р"
        else if (is_exam)
          return "Э"
        else
          return "Д"
      },
      getAdmissionType(academic_difference, is_exam){
        if (academic_difference)
          return "Академическая разница"
        else if (is_exam)
          return "Экзамен/Зачет с оценкой"
        else
          return "Добор/Зачет"
      },
      getDetail(item){
          this.$router.push({name: names.StudentEducationAdmissionDetail, params: {idAdmission: item.id}})
      },
      addItem(){
        this.closedForm()
        this.loadData();
      },
      loadData(url = undefined) {
        this.getItems(url)
      },
      getItems (url=undefined) {
        this.setLoading();
        if(url === undefined) {
          url = this.URL_LIST
          this.setCurrentLoadUrl(url);
        }
        loadData(
          url,
          (data) => {
            this.mapResults(data, "items")
            this.items.forEach(el => {
              this.$set(el, "fio", "");
              generateListUidToName(this.items, "student", "fio");
            });
            this.setNotLoading();
          }
        )
      }
    }
  }


</script>

<style scoped>

</style>
