<template>
  <v-list-item dense style="border-right: 1px solid rgba(0,0,0,.12); border-left: 1px solid rgba(0,0,0,.12);">
    <v-progress-linear :active="inLoading" absolute indeterminate top></v-progress-linear>
    <v-list-item-icon style="min-height: 40px" class="mr-2">
      <v-icon class="ml-auto" v-if="item.difference.subject" color="green">done</v-icon> <v-icon v-else>clear</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title class="font-weight-black subtitle-2 text-wrap">
        <span v-if="item.eios">{{ item.eios.subject }}</span>
        <span v-if="item.one && !item.eios">1C: {{ item.one.level_subject.name }}</span>
      </v-list-item-title>
      <v-list-item-subtitle>
        <span v-if="item.one && item.eios">1C: {{ item.one.level_subject.name }}</span>
        <span v-else>ЭИОС не найден</span>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action class="my-0 d-flex flex-row justify-end align-center">
      <tooltip-btn tooltip="Полная информация" icon="info" small bottom @click="openDialog(modals.detail)"
                   v-if="isAdmin" :loading="loading"></tooltip-btn>
      <tooltip-btn tooltip="Указать связь" icon="link" small bottom @click="openDialog(modals.link)"
                   v-if="!isLinked && isOnlyEIOS" :loading="loading"></tooltip-btn>
      <tooltip-btn tooltip="Очистить связь" icon="link_off" small bottom @click="openDialog(modals.clear)"
                   v-if="isLinked" :loading="loading"></tooltip-btn>
      <tooltip-btn tooltip="Обновить данные" icon="update" small bottom @click="openDialog(modals.update_related)"
                   v-if="isLinked && isParentLinked" :loading="loading"></tooltip-btn>
      <tooltip-btn tooltip="Провести поиск связей по дереву" small icon="find_replace" bottom :loading="loading"
                   @click="openDialog(modals.restore_by_tree)" v-if="isLinked && isParentLinked"></tooltip-btn>
      <tooltip-btn tooltip="Обновить идентификатор связи у связанных" small icon="dataset_linked" bottom :loading="loading"
                   @click="openDialog(modals.update_relation_identity)" v-if="isLinked && isParentLinked && isAdmin"></tooltip-btn>
      <tooltip-btn tooltip="Создать связанные" icon="playlist_add" small bottom :loading="loading"
                   @click="openDialog(modals.create_child_list)" v-if="isLinked && isParentLinked"></tooltip-btn>
      <tooltip-btn tooltip="Создать объект" icon="add_circle" small bottom :loading="loading"
                   @click="openDialog(modals.create_related)" v-if="isOnlyOne && isParentLinked"></tooltip-btn>
      <tooltip-btn tooltip="Обновить информацию" icon="refresh" small bottom :loading="loading"
                   @click="refresh" v-if="isLinked && isParentLinked"></tooltip-btn>
      <link-action
        v-if="isOnlyEIOS && isParentLinked"
        v-model="modals.link.open"
        :item="item"
        :selector-url="modals.link.selector_url"
        :action-url="modals.link.action_url"
        @reload="refreshCombine"
        restorable
        preloadable
      >
      </link-action>
      <action-for-tree
        v-if="isLinked"
        v-model="modals.restore_by_tree.open"
        level="subject"
        :action-url="modals.restore_by_tree.action_url"
        action-name="поиска и сопоставления по дереву объектов"
        :item="item"
        @reload="refresh"
      ></action-for-tree>
      <action-for-tree
        v-if="isLinked"
        v-model="modals.update_relation_identity.open"
        level="subject"
        :action-url="modals.update_relation_identity.action_url"
        action-name="обновления информации о связи в ЭИОС по дереву объектов"
        :item="item"
        @reload="refresh"
      ></action-for-tree>
      <update-related
        v-if="isLinked"
        v-model="modals.update_related.open"
        :action-url="modals.update_related.action_url"
        :item="item"
        @reload="refresh"
      ></update-related>
      <create-child-list
        v-if="isLinked && isParentLinked"
        v-model="modals.create_child_list.open"
        :action-url="modals.create_child_list.action_url"
        :item="item"
        child-type="Семестры"
        :base-object-name="`предмета учебного плана '${item.one.subject.name}'`"
        @reload="refresh"
      ></create-child-list>
      <action-for-tree
        v-if="isLinked"
        v-model="modals.clear.open"
        level="subject"
        :action-url="modals.clear.action_url"
        action-name="очистки информации о связи в ЭИОС и синхронизаторе с 1С Университет по дереву объектов"
        :item="item"
        @reload="refreshCombine"
        applyable
      >
        <template v-slot:title>Очистка информации о связях в дереве</template>
        <template v-slot:apply>Это действие не может быть отменено! Чтобы внести изменения, потребуется повторно связать данный объект.</template>
      </action-for-tree>
      <curriculum-action-for-object
        v-if="isOnlyOne && isParentLinked"
        v-model="modals.create_related.open"
        :item="item"
        action-name="Создание объекта ЭИОС на основании объекта 1С"
        level="subject"
        :action-url="modals.create_related.action_url"
        one
        @reload="refreshCombine"
      ></curriculum-action-for-object>
      <detail v-model="modals.detail.open" :item="item"></detail>
    </v-list-item-action>

  </v-list-item>
</template>

<script>
import urls from "@/modules/curriculumEditor/urls";
import selectors from "@/urls/selectors";
import UpdateRelated from "@/modules/administrative/dialogs/UpdateRelated.vue";
import ActionForTree from "@/modules/curriculumEditor/pages/difference/dialogs/ActionForTree.vue";
import Detail from "@/modules/administrative/dialogs/Detail.vue";
import LinkAction from "@/modules/administrative/dialogs/LinkAction.vue";
import CreateChildList from "@/modules/curriculumEditor/pages/difference/dialogs/CreateChildList.vue";
import {loadData} from "@/helper";
import admin_urls from "@/urls/admin";
import DifferenceItemMixin from "@/modules/administrative/mixins/DifferenceItemMixin";
import CurriculumActionForObject from "@/modules/curriculumEditor/pages/difference/dialogs/CurriculumActionForObject.vue";
import TooltipBtn from "@/modules/administrative/components/TooltipBtn.vue";
import {ref} from "vue";

export default {
  name: "Subject",
  components: {LinkAction, UpdateRelated, ActionForTree, Detail, CurriculumActionForObject, TooltipBtn, CreateChildList},
  mixins: [DifferenceItemMixin],
  props: {
    length: undefined,
    index: undefined
  },
  data() {
    return {
      inLoading: false,
      modals: {
        link: {
          open: false,
          item: undefined,
          action_url: urls.NAMES.EDITOR.UNIVERSITY.LINK.SUBJECT,
          // selector_url: selectors.SELECTORS.UNIVERSITY.CURRICULUM_SUBJECTS(this.curriculum.one.guid)
          selector_url: this.callIfParentOne(
            selectors.SELECTORS.UNIVERSITY.CURRICULUM_SUBJECTS, this.getParentOneGUID(), {"relation_is_empty": true}
          )
        },
        restore_by_tree: {
          open: false,
          action_url: urls.NAMES.EDITOR.UNIVERSITY.RESTORE_FOR_TREE.SUBJECT
        },
        update_related: {
          open: false,
          action_url: urls.NAMES.EDITOR.UNIVERSITY.UPDATE_RELATED.SUBJECT
        },
        update_relation_identity: {
          open: false,
          action_url: urls.NAMES.EDITOR.UNIVERSITY.UPDATE_RELATION_IDENTIFY.SUBJECT
        },
        clear: {
          open: false,
          action_url: urls.NAMES.EDITOR.UNIVERSITY.CLEAR.SUBJECT
        },
        create_child_list: {
          open: false,
          action_url: urls.NAMES.EDITOR.UNIVERSITY.CREATE_CHILD_LIST.BY_SUBJECT
        },
        create_related: {
          open: false,
          action_url: urls.NAMES.EDITOR.UNIVERSITY.CREATE_RELATED.SUBJECT
        }
        // detail: {
        //   open: false,
        // }
      }
    }
  },
  computed: {

  },
  methods: {
    ref,
    setLoadingValue(value) {
      this.inLoading = value
    },
    refreshCombine(){
      let identifiers = []
      if(this.item.eios)
        identifiers.push(this.item.eios.id)
      if(this.item.one)
        identifiers.push(this.item.one.guid)
      this.$emit("refreshIdentifiers", identifiers)
    },
    getDifferenceUrl() {
      return urls.NAMES.EDITOR.SUBJECT.DIFFERENCE(this.getEIOSId(), this.$route.params.curriculum_id);
    }
  }
}
</script>

<style scoped>

</style>
