
import Base from "../pages/student/Base";
import FinalJournal from "../pages/student/FinalJournal";
import WorkDetail from "../pages/student/work/WorkDetail";
import WorkList from "../pages/student/work/WorkList";
import PersonalCuriculum from "../pages/student/curriculum/PersonalCuriculum";
import SubjectList from "../pages/student/curriculum/SubjectList";
import SubjectDetail from "../pages/student/curriculum/SubjectDetail";
import Portfolio from "../pages/student/portfolio/Portfolio"
import EduDoc from "@/modules/educationalManagement/pages/student/educationDocument/EduDoc";

import names from "@/modules/educationalManagement/routers/names";
import {ACCOUNTS_INSTANCE, EDUCATION_INSTANCE} from "@/helper/consts";

export default ([
  {
    path: ":student_id([0-9]+)",
    name: names.EDUCATIONAL.STUDENTS.DETAIL,//'EducationalManagementStudentDetail',
    component: Base,
    meta: {
      requiredAuth: true,
      breadcrumb: "Обучающийся",
      instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE]
    },
    children: [
      {
        path: "curriculum",
        name: names.EDUCATIONAL.STUDENTS.CURRICULUM.DETAIL,//'EducationalManagementPersonalCuriculum',
        component: PersonalCuriculum,
        meta: {
          requiredAuth: true,
          breadcrumb: "Персональный учебный план",
          instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE]
        },
        children: [
          // {
          //   path: 'subject',
          //   name: names.EDUCATIONAL.STUDENTS.CURRICULUM.SUBJECT.LIST,//'EducationalManagementSubjectList',
          //   component: SubjectList,
          //   meta: {
          //     requiredAuth: true,
          //     breadcrumb: 'Список дисциплин',
          //     instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE,],
          //   },
          //   children: [
          //     {
          //       path: ':subject_id([0-9]+)',
          //       name: names.EDUCATIONAL.STUDENTS.CURRICULUM.SUBJECT.DETAIL,//'EducationalManagementSubjectDetail',
          //       component: SubjectDetail,
          //       meta: {
          //         requiredAuth: true,
          //         breadcrumb: 'Предмет',
          //         instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE,],
          //       },
          //       children: []
          //     },
          //   ]
          // },
        ]
      },
      {
        path: "work",
        name: names.EDUCATIONAL.STUDENTS.WORK.LIST,//'EducationalManagementWorkList',
        component: WorkList,
        meta: {
          requiredAuth: true,
          breadcrumb: "Список учебных работ",
          instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE]
        },
        children: [
          {
            path: ":work_id([0-9]+)",
            name: names.EDUCATIONAL.STUDENTS.WORK.DETAIL,//'EducationalManagementWorkDetail',
            component: WorkDetail,
            meta: {
              requiredAuth: true,
              breadcrumb: "Учебная работа",
              instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE]
            },
            children: []
          }
        ]
      },
      {
        path: "final/journal",
        name: names.EDUCATIONAL.STUDENTS.FULL_JOURNAL,//'EducationalManagementFinalJournal',
        component: FinalJournal,
        meta: {
          requiredAuth: true,
          breadcrumb: "Журнал полной успеваемости",
          instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE]
        },
        children: []
      },
      {
        path: "portfolio",
        name: names.EDUCATIONAL.STUDENTS.PORTFOLIO,//'EducationalManagementFinalJournal',
        component: Portfolio,
        meta: {
          requiredAuth: true,
          breadcrumb: "Портфолио",
          instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE]
        },
        children: []
      },
      {
        path: "edu/doc",
        name: names.EDUCATIONAL.STUDENTS.EDUDOC,
        component: EduDoc,
        meta: {
          breadcrumb: "Список документов об образовании",
          requiredAuth: true,
          instances: [ACCOUNTS_INSTANCE, EDUCATION_INSTANCE]
        }
      }
    ]
  }
])



