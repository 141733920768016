<template>
  <scroll-center-dialog v-model="opened_form"
                        @close="closeDialog"
                        @apply="apply"
                        :loading="loading"
                        :title="!!id ? 'Изменить' : 'Добавить'"
                        width="500"
  >
    <v-text-field
      :error="hasError('name')"
      :error-messages="getError('name')"
      label="Год"
      v-model="value.name"
    ></v-text-field>
    <v-text-field
      :error="hasError('start')"
      :error-messages="getError('start')"
      label="Начало года"
      v-model="value.start"
      type="date"
    ></v-text-field>
    <v-text-field
      :error="hasError('end')"
      :error-messages="getError('end')"
      label="Окончание года"
      v-model="value.end"
      type="date"
    ></v-text-field>
    <v-autocomplete
      outline
      :error="hasError('rating')"
      :error-messages="getError('rating')"
      :items="selectors.rating"
      no-data-text="Нет данных"
      label="Рейтинговая система"
      v-model="value.rating"
    ></v-autocomplete>
  </scroll-center-dialog>
</template>

<script>
  import urls from "@/urls/management";
  import UserDataFormMixin from "@/mixins/UserDataFormMixin"
  import {loadData} from "@/helper";
  import selectors from "@/urls/selectors";
  import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

  export default {
    name: "AcademicYear",
    components: {ScrollCenterDialog},
    mixins: [UserDataFormMixin],
    props: {
      id: Number,
      name: String,
      start: String,
      end: String,
      opened_form: Boolean
    },
    data() {
      return {
        URL_CREATE: urls.EDUCATION.ACADEMIC_YEAR.CREATE(),
        URL_UPDATE: urls.EDUCATION.ACADEMIC_YEAR.UPDATE(this.id),
        loading : false,
        selectors: {
          rating: []
        },
        oldValue: {},
        value: {
          id: this.id || undefined,
          name: this.name || "",
          start: this.start || "",
          end: this.end || "",
          rating: ""
        }
        // errors: {}
      }
    },
    methods: {
      loadSelector(data, url) {
        loadData(
          url,
          (json) => {
            this.selectors[data] = json
          }
        )
      },
      closeDialog() {
        this.$emit("ended", "")
      }
    },
    created() {
      this.loadSelector("rating", selectors.SELECTORS.ACADEMIC_YEAR.RATING_SYSTEM())
      if (this.id) {
        loadData(
          this.URL_UPDATE,
          (data) => {
            this.value.rating = data.rating.id
            this.Oldvalue = Object.assign({}, this.value)
          }
        )
      }
    }
  }

</script>

<style scoped>

</style>
