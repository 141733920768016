<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-card>
          <v-toolbar
            elevation="0"
            color="style-color-main-gray"
          >
            <v-text-field
              dense
              placeholder="Поиск"
              v-model="search"
            ></v-text-field>
            <!--          <v-btn icon><v-icon>search</v-icon></v-btn>-->
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="students"
            :search="search"
          >
            <template v-slot:item.fio="student">
              <span :class="{'font-weight-bold': student.item.headman}">
                <v-skeleton-loader type="text" v-if="student.item.fio === ''"></v-skeleton-loader>
                <router-link :to="makeStudentLink(student.item)" v-else>
                  {{student.item.fio}}
                </router-link>
              </span>
            </template>
            <template v-slot:item.group="student">
              <span >
                  {{student.item.group.name}}
              </span>
            </template>
            <template v-slot:item.headman="students">
              <v-icon color="success" v-if="students.item.headman">
                check
              </v-icon>
              <v-icon v-else>
                remove
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!--    <DebugJsonComponent :json="students"></DebugJsonComponent>-->
  </v-container>
</template>

<script>
import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
import TitledPageMixin from "../../../../mixins/TitledPageMixin";
import cathedras from "../../../../urls/cathedras";
import {loadData} from "../../../../helper";
import {generateListUidToName} from "../../../../helper/uidToName";
import names from "../../routers/names";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";

export default {
  name: "Students",
  components: {
    DebugJsonComponent
  },
  mixins: [TitledPageMixin,PaginatedDataMapperMixin],
  props: {
    teacher_fio: {
      required: false,
      type: String
    }
  },
  data: function(){
    return {
      // page_title: `Список обучающихся ${this.teacher_fio}`,
      students: undefined,
      headers: [
        {text: "ФИО", value: "fio", width: "35%", filterable: true, sortable: true},
        {text: "Тема работы", value: "title_work", width: "35%", align: "center", filterable: true, sortable: false},
        {text: "Группа", value: "group", width: "5%", align: "center", filterable: true, sortable: false},
        {text: "Курс", value: "course", width: "5%", align: "center", filterable: true, sortable: false},
        {text: "Форма обучения", value: "form", width: "15%", align: "center", filterable: true, sortable: false},
        {text: "Староста", value: "headman", width: "5%", align: "center", filterable: false, sortable: true}
      ],
      search: ""
    };
  },
  computed: {
    page_title() {
      return `${this.teacher_fio}: Список обучающихся дипломников преподавателя`
    }
  },
  methods: {

    makeStudentLink: function(student) {
      return {
        name: names.CathedraStudentsDetail,
        params: {
          cathedra_uid: this.$route.params.cathedra_uid,
          student_id: student.id,
          taught: student.attachment?undefined:"taught"
        }

      }
    },

    loadData(url=undefined) {
      if(url === undefined) {
        this.setCurrentLoadUrl(
          cathedras.CATHEDRA.TEACHERS.DIPLOMS(
            this.$route.params.cathedra_uid,
            this.$route.params.teacher_uid
          )
        )
        url = this.getCurrentLoadUrl()
      }
      loadData(
        url,
        (data) => {
          // this.students = data.results;
          this.students = data;
          // let fios = [];
          this.students.forEach(el => {
            this.$set(el, "fio", "");
          });
          generateListUidToName(this.students, "student", "fio");

        }
      )
    }
  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
