<template>
  <v-container style="height: 100%" v-if="$route.name === names.DEPARTAMENTS.TEACHER.LIST">
    <v-card flat>
      <v-toolbar
        elevation="0"
        color="style-color-main-gray"
      >
        <v-text-field
          dense
          placeholder="Поиск"
          v-model="search"
          @change="getItems()"
          messages="Для поиска нажмите Enter или кнопку поиск"
        >
        </v-text-field>
        <v-btn icon><v-icon>search</v-icon></v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="getItems()"
              class="mr-2"
              medium
            >
              update
            </v-icon>
          </template>
          <span>Обновить данные</span>
        </v-tooltip>
      </v-toolbar>
      <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              class="elevation-1"
              no-data-text="Нет данных"
              :options.sync="pagination"
              :server-items-length="total"
              :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов',

              }"
            >
              <template v-slot:item="props">
                <tr @click="getDetail(props.item)" style="cursor: pointer" class="text-left">
                  <td class="justify-center">{{props.item.fio}}</td>
                  <td class="justify-center">{{props.item.position_held}}</td>
                  <td class="justify-center">{{props.item.group_count}}</td>
                  <td class="justify-center">{{props.item.subject_count}}</td>
                  <td class="justify-center">{{props.item.hours}}</td>
                </tr>
              </template>
              <template slot="no-data">
                <v-card-text>Данные не загружены</v-card-text>
              </template>
            </v-data-table>
    </v-card>
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>


<script>
  // import Courses  from "../../forms/Courses"
  import {getInstNameByRouterName} from "@/helper/instances";
  import UserDataMixin from "../../../../../mixins/UserDataMixin"
  import {makeGetRequest} from "../../../../../helper";
  import urls from "@/urls/dean"
  import {generateListUidToName} from "../../../../../helper/uidToName";
  import names from "@/modules/dean/routers/names";

  export default {
    name: "ListLedSubjects",
    // components: {Courses},
    mixins: [UserDataMixin],
    props: {
    },
    data () {
      return {
        URL_LIST: urls.DEAN.CATHEDRA.TEACHER.LIST(this.$route.params.uidDepartament),
        names:names,
        total: 0,
        items: [],
        search:"",
        loading: true,
        pagination: {},
        headers: [
          {text: "Преподаватель", sortable: false, align: "justify-center"},
          {text: "Должность", sortable: false, align: "justify-center" },
          {text: "Число групп", sortable: false, align: "justify-center" },
          {text: "Число дисциплин", sortable: false, align: "justify-center" },
          {text: "Часы", sortable: false, align: "justify-center" }
        ]
      }
    },
    watch: {
      pagination: {
        handler () {
          if(this.$route.name === names.DEPARTAMENTS.TEACHER.LIST)
            this.getItems()
        },
        deep: true
      }
    },


    created() {

      // this.$store.commit('leftmenu/activeRouterChange', this.$route.name);
      // this.$store.commit('leftmenu/selectInstance', getInstNameByRouterName(names.DETAIL));

      this.$store.commit("mainpage/changeTitle", "Список преподавателей");
    },
    methods: {
      getDetail(item){
        this.$router.push({name: names.DEPARTAMENTS.TEACHER.DETAIL, params: {uidTeacher: item.user }})
      },
      addItem(){
        this.closedForm()
        this.getItems();
      },

      getItems () {
        this.loading = true
        let url = new URL(this.URL_LIST)
        let params = {};

        if(this.search.length > 0)
          params.fio = this.search;
        if(this.pagination.page !== undefined) {
          params.page = this.pagination.page;
          params.size = this.pagination.itemsPerPage;
        }
        else{
          params.page = "1";
          params.size = "10";
        }

        url.search = new URLSearchParams(params).toString();

        let sys = true
        makeGetRequest(url).then(resp => {
          if (!resp.ok) {
            sys = false
          }
          return resp.json()
        }) .then(json => {
          this.items = json.results
          this.total = json.count
          this.loading = false
          //console.log(this.pagination)
          for (let i in this.items) {
            this.$set(this.items[i], "fio","-")
          }
           generateListUidToName(this.items,"user","fio")
        })
      }
    }
  }


</script>
<style scoped>

</style>
