<template>
  <scroll-center-dialog v-model="opened_form"
                        width="500"
                        :title="!!id ? 'Изменить запись' : 'Добавить запись'"
                        @close="closeDialog"
                        @apply="apply"
  >
    <v-alert type="error" v-if="hasError('error')">
      {{ getErrorArray('error') }}
    </v-alert>
    <v-alert type="error" v-if="hasError('faculty')">
      {{ getErrorArray('faculty') }}
    </v-alert>
    <v-autocomplete
      ref="responsible"
      :loading="loaders.responsible.status"
      :error="hasError('worker')"
      :error-messages="getError('position_held_uid')"
      :items="selectors.responsible"
      :no-data-text="`${loaders.responsible.status ? 'Загрузка данных':'Нет данных'}`"
      label="Работник"
      v-model="value.position_held_uid"
    ></v-autocomplete>
  </scroll-center-dialog>
</template>

<script>
  import urls from "../../../../modules/teacher/urls"
  import UserDataFormMixin from "../../../../mixins/UserDataFormMixin"
  import {makeGetRequest, makePostRequest} from "../../../../helper"
  import dean from "@/urls/dean"
  import selectors from "@/urls/selectors"
  import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

  export default {
    name: "PermissionWorker",
    components: {ScrollCenterDialog},
    mixins: [UserDataFormMixin],
    props: ["id", "position_held_uid", "opened_form"],

    data() {
      return {
        URL_CREATE: dean.DEAN.PERMISSION.CREATE(this.$route.params.idFaculty),
        URL_SELECT_FACULTY_POSITION_HELD: selectors.SELECTORS.DEAN.FACULTY_POSITION_HELD(this.$route.params.idFaculty),
        loaders: {
          responsible: {
            status: true,
            handler: () => this.load(
              this,
              this.URL_SELECT_FACULTY_POSITION_HELD
            )
          }
        },
        value: {
          id: this.id || "",
          position_held_uid: this.position_held_uid || ""
        },
        selectors: {
          responsible: []
        }
      }
    },
    methods: {
      load: function (app, url) {
        makeGetRequest(url)
          .then(resp => resp.json())
          .then(json => {
            let users = [];
            let responsible = json
            for (let i in json) {
              var arrayOfStrings = json[i].text.split(",")
              users.push(arrayOfStrings[0]);
            }
            makePostRequest(
              urls.getUrlAcc("users|user|fio", "post"),
              {"uids": users}
            )
              .then(resp => resp.json())
              .then(json => {
                for (let i in json) {
                  let worker = "";
                  let replaced = responsible.find((item) => {
                    var arrayOfStrings = item.text.split(",");
                    worker = arrayOfStrings[1];
                    return arrayOfStrings[0] === json[i].value
                  });
                  replaced.text = json[i].text + " (" + worker + ")";
                  app.selectors.responsible = responsible
                }
                app.loaders.responsible.status = false
              });
          });
      },

      closeDialog() {
        this.$emit("ended", "")
      }
    },
    created() {
      this.loaders.responsible.handler();
    }
  }

</script>

<style scoped>

</style>
