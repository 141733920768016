<template>
  <v-container>
    <v-row>
      <v-col class="text-left" align-content="start" >
        <v-alert
          :color="color"
          icon="info"
          outlined
        >{{ status }}
          <template v-slot:append>
            <v-btn icon @click="hide = !hide" v-if="hidable" :class="{'hide': isShow}">
              <v-icon>arrow_drop_down</v-icon>
            </v-btn>
          </template>
        </v-alert>
        <v-expand-transition>
          <vue-json-pretty
            v-show="isShow"
            style="overflow-x: scroll;"
            class="text-xs-left"
            :data="json"
            :showLine="true"
            :showDoubleQuotes="true"
            :collapsedOnClickBrackets="true"
            :deep="5"
          ></vue-json-pretty>
        </v-expand-transition>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import VueJsonPretty from "vue-json-pretty"
  import "vue-json-pretty/lib/styles.css"
  // import VueJson from 'vue-json-pretty'

  export default {
    name: "DebugJsonComponent",
    components: {
      VueJsonPretty
    },
    props: {
      json: undefined,
      status: undefined,
      hidable: {
        default: false,
        type: Boolean
      },
      hideAtOpen: {
        default: false,
        type: Boolean
      },
      color: {
        default: "info",
        type: String
      }
    },
    data() {
      return {
        hide: !this.hideAtOpen
      }
    },
    computed: {
      isShow() {
        if (!this.hidable) return false
        return this.hide
      }
    }
  }
</script>

<style scoped>
  .v-btn.hide .v-icon {
    transform: rotate(-180deg);
    transition-duration: 500ms;
  }
  .v-btn .v-icon {
    transform: rotate(0deg);
    transition-duration: 500ms;
  }
</style>
