<template>
<!--  <div>-->
  <v-container fluid>
    <v-card>
      <v-toolbar
        class="mb-0"
        color="style-color-main-gray"
        flat
      >
        <v-toolbar-title>
          Группа: {{group.litter}}-{{ group.course }}{{group.number}}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="py-1">
          <v-btn text outlined color="light-blue darken-4" :to="journalLink">
            <v-icon class="mr-1">ballot</v-icon>Журнал группы
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-row wrap class="py-3">
        <v-col cols="12" :lg="show_curriculum ? 6: 12" class="pt-0 pr-0" style="border-right: 2px solid rgba(0,0,0,.12);">
          <GroupTemplate :elevation="0" v-bind="group" v-if="group !== undefined"></GroupTemplate>
        </v-col>
        <v-col cols="12" lg="6" v-if="show_curriculum" class="pt-0 pl-0">
          <GroupCurriculumTemplate :elevation="0" v-bind="group.curriculum" :loaded="loaded.curriculum">
            <template v-slot:profile>
              <router-link v-if="!$route.params.taught" :to="getDetailedCurriculum(group.curriculum.id)">
                {{ group.curriculum.profile}}
              </router-link>
            </template>
          </GroupCurriculumTemplate>
        </v-col>
      </v-row>
      <v-row wrap>
        <v-col cols="12" v-if="show_subjects" class="my-0 py-0">
          <v-divider class="mx-1"></v-divider>
          <GroupSubjectsTemplate
            :elevation="0"
            :load_half="true"
            :semesters="group.semesters"
            :loaded="loaded.semesters"
            :show_extra_actions="false"
            :expandable="true"
            v-if="group.semesters.basename === undefined"
            :headers="[
              {text: 'Предмет', value: 'subject', sortable: false, width: '50%'},
              // {text: 'Курс', value: 'course', sortable: false},
              // {text: 'Семестр', value: 'semester', sortable: false},
              {text: 'Кафедры', value: 'cathedrals', sortable: false, width: '45%'},
              // {text: 'Контроль', value: 'control_type', sortable: false},
              {text: '', value: 'data-table-expand', width: '5%'},
            ]"
          ></GroupSubjectsTemplate>
        </v-col>
        <v-col cols="12" v-if="show_students" class="my-0 py-0">
          <v-divider class="mx-1"></v-divider>
          <GroupStudentsTemplate
            :elevation="0"
            :students="group.students"
            v-if="group.students.basename === undefined"
            :loaded="group.students.basename === undefined"
            :group_id="group.id"
            :expandable="true"
            :show_extra_actions="true"
            :group="true"
          >
            <template v-slot:student_link="{ student }">
              <router-link :to="makeLink(student.id)">
                {{ student.fio }}
              </router-link>
            </template>
<!--            <template v-slot:extra_actions v-if="group.attachment">-->
<!--              <v-btn @click="opened_form = true" text small>-->
<!--                Назначить научных руководителей-->
<!--              </v-btn>-->
<!--            </template>-->
          </GroupStudentsTemplate>
        </v-col>
      </v-row>
<!--          <DebugJsonComponent :json="group.students"></DebugJsonComponent>-->
<!--      <v-dialog v-model="opened_form"  max-width="1000px" persistent>-->
<!--        <ScienceDirectorChanger :group_id="group.id" @close="opened_form = false"></ScienceDirectorChanger>-->
<!--      </v-dialog>-->
    </v-card>
  </v-container>
</template>

<script>
  import LoadData from "@/modules/dean/mixins/LoadData";
  import names from "../../../routers/names";
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
  import ScienceDirectorChanger from "../../scientific_directors/ScienceDirectorChanger";
  import GroupTemplate from "@/modules/templates/group/GroupTemplate";
  import GroupCurriculumTemplate from "@/modules/templates/group/GroupCurriculumTemplate";
  import GroupStudentsTemplate from "@/modules/templates/group/GroupStudentsTemplate";
  import GroupSubjectsTemplate from "@/modules/templates/group/GroupSubjectsTemplate";
  import cathedras from "../../../../../urls/cathedras";
  export default {
    name: "DetailedLedGroup",
    mixins: [LoadData],
    components: {
      ScienceDirectorChanger,
      DebugJsonComponent,
      GroupTemplate,
      GroupCurriculumTemplate,
      GroupStudentsTemplate,
      GroupSubjectsTemplate
    },
    props: {
      group: {
        required: true,
        type: Object
      },
      show_students: {
        type: Boolean,
        default: false
      },
      show_curriculum: {
        type: Boolean,
        default: false
      },
      show_subjects: {
        type: Boolean,
        default: false
      },
      loaded: {
        type: Object,
        default: () => {
          return {
            group: false,
            curriculum: false,
            students: false,
            semesters: false
          }
        }
      }
    },
    computed: {
      journalLink() {
        return {
          name: names.CathedraGroupsDetailJournal,
          params: this.$route.params,
          query: this.$route.query
        }
      }
    },
    data: function () {
      return {
        opened_form: false,
        semesters: {
          headers: [
            {text: "Предмет", value: "subject", sortable: false},
            {text: "Курс", value: "course", sortable: false},
            {text: "Семестр", value: "semester", sortable: false},
            {text: "Кафедра", value: "cathedra", sortable: false},
            {text: "Контроль", value: "control_type", sortable: false},
            {text: "", value: "data-table-expand"}
          ],
          expanded: []
        },
        students: {
          headers: [
            {text: "ФИО", value: "fio", sortable: false, align: "center"}
          ],
          editor: {
            open: false,
            selector: []
          }
        },
        expanded: {
          subjects: false,
          students: false
        }

      }
    },
    methods: {
      getDetailedCurriculum(curriculum_id) {
        return {
          name: names.CathedraCurriculumDetail,
          params: {
            cathedra_uid: this.$route.params.cathedra_uid,
            idCurriculum: curriculum_id
          }
        }
      },
      makeLink: function(id) {
        return {
          name: names.CathedraStudentsDetail,
          params: {
            cathedra_uid: this.$route.params.cathedra_uid.toLowerCase(),
            student_id: id,
            taught: this.$route.params.taught
          },
          query: {
            group: this.group.id,
            teacher: this.$route.query.teacher
          }
        }
      }
    },
    created() {
    }
  }
</script>

<style scoped>

</style>
