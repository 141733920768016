<template>
  <div class="page-content" style="margin: 0px; width: 100%">
    <PageHeader></PageHeader>
    <CathedraList v-if="$route.name === names.CathedraList"/>
    <CathedraDetail v-if="$route.name === names.CathedraDetail"/>
    <router-view v-else>
    </router-view>
  </div>
</template>

<script>
  import PageHeader from "@/modules/menus/PageHeader.vue"
  import CathedraList from "./core/CathedraList";
  import CathedraDetail from "./core/CathedraDetail";
  import LeftMenuChangerMixin from "../../../mixins/LeftMenuChangerMixin";
  import {mapState} from "vuex"
  import {getInstMenu, getInstNameByRouterName} from "@/helper/instances";
  import names from "../../cathedra/routers/names";
  import cathedras from "@/urls/cathedras";
  import helper from "@/urls/helper";
  import store from "@/store";
  import dean_names from "@/modules/dean/routers/names";

  // components: {Teacher, },

  export default {
    name: "CurriculumEditorBreadcrumbs",
    components: {
      CathedraList,
      CathedraDetail,
      PageHeader
    },
    mixins: [
      // LeftMenuChangerMixin,
    ],
    props: {},
    computed: mapState({
      mainpage: state => state.mainpage,
      names: () => names
    }),
    data(){
      return {
        is_set_left_menu: false,
        single_cathedra: false,
        name_cathedra: "",
        abbr_cathedra: ""
      }
    },
    watch: {
      "$route.params.cathedra_uid": function () {
          this.updateLeftMenu();
      }
    },
    methods: {
      isReplaceMenu(menu) {
        return true
      },
      updateLeftMenu: function () {
        let menu = this.getLeftMenu();
        if(this.isReplaceMenu(menu))
          this.$store.commit("leftmenu/replaceItems", menu);
      },
      getLeftMenu(){
        // console.log('getLeftMenu')
        let with_cathedra_uid = (name) => {
          return {name: name, params: {cathedra_uid: this.$route.params.cathedra_uid}}
        };
        let append_param = (router, params) => {
          router.params = {...router.params, ...params};
          return router
        };
        if(this.$route.params.hasOwnProperty("cathedra_uid")){
          let menu = [
            {title: "Доступные кафедры", icon: "list", router: names.CathedraList},
            {title: "Информация о кафедре", icon: "feedback", router: with_cathedra_uid(names.CathedraDetail)},
            {
              title: "Группы", icon: "supervisor_account", subs: [
                {title: "Кафедральные группы", icon: "supervisor_account", included: ["taught","archive"], router: append_param(with_cathedra_uid(names.CathedraGroups), {taught: undefined, archive: undefined})},
                {title: "Обучаемые группы", icon: "group", included: ["taught","archive"], router: append_param(with_cathedra_uid(names.CathedraGroups), {taught: "taught", archive: undefined})},
                {title: "Архивные группы", icon: "archive",  included: ["taught","archive"], router: append_param(with_cathedra_uid(names.CathedraGroups), {taught: undefined, archive: "archive"})}
              ]
            },
            {
              title: "Обучающиеся", icon: "supervisor_account", subs: [
                {title: "Обучающиеся кафедры", icon: "supervised_user_circle", included: ["taught"], router: append_param(with_cathedra_uid(names.CathedraStudents),{taught: undefined})},
                {title: "Ведомые обучающиеся", icon: "groups", included: ["taught"], router: append_param(with_cathedra_uid(names.CathedraStudents), {taught: "taught"})},
                {title: "Обучающиеся с ИУП", icon: "how_to_reg", router: with_cathedra_uid(names.CathedraIndividualStudents)}
              ]
            },
            {title: "Преподаватели кафедры", icon: "school", router: with_cathedra_uid(names.CathedraTeachers)},
            {title: "Допуски", icon: "menu_book", included: ["taught"], router: append_param(with_cathedra_uid(names.CathedraAdmission), {taught: "taught"})},
            {title: "Преподаваемые дисциплины", icon: "subject", router: with_cathedra_uid(names.CathedraTaughtSubjects)},
            {title: "Предметная документация", icon: "article", included: [], router: with_cathedra_uid(names.CathedraListSubjects)},
            {title: "Список учебных планов", icon: "list", included: [], router: with_cathedra_uid(names.CathedraCurriculumList)}
          ];
          helper.checkEduUrlAllowedWithParameters("administrative|relations|workers-list", "list", {"cathedra_uid": this.$route.params.cathedra_uid})
            .then(results => {
              let allowed = true;
              results.forEach(el => allowed = allowed && el.allowed)
              if(allowed){
                if(store.getters["leftmenu/getLeftMenu"].findIndex(el => el.router.name === names.CathedraDetail) !== -1)
                  store.commit(
                    "leftmenu/appendItem",
                    {
                      title: "Административные работники",
                      icon: "admin_panel_settings",
                      included: false,
                      router: with_cathedra_uid(names.CathedraAdmins)
                    }
                  )
              }
            })
          // menu.push({title: 'Административные работники', icon: 'admin_panel_settings', included: false, router: with_cathedra_uid(names.CathedraAdmins)},)
          return menu
        }else{
          return [
            {title: "Доступные кафедры", icon: "list", router: names.CathedraList}
          ]
        }
      }
    },
    beforeRouteUpdate(to, from, next) {
      if(to.params.cathedra_uid !== from.params.cathedra_uid){
        this.is_set_left_menu = false;
      }
      // console.log('Meta', to.meta)
      if(to.params.reload_left_menu || to.meta.reload_left_menu){
        this.is_set_left_menu = false;
      }
      if(!this.is_set_left_menu) {
        this.updateLeftMenu();
        this.is_set_left_menu = true;
      }
      next()
      // }else{
      //   next();
      // }
    },
    created() {
      this.$store.commit("leftmenu/enableLeftMenu");
      this.$store.commit("mainpage/changeTitle", "Список кафедр доступных вам для управления");
      this.$store.commit("leftmenu/replaceInstances", getInstMenu());
      this.$store.commit("leftmenu/selectInstance", getInstNameByRouterName("Administrative|Cathedra"));
      this.updateLeftMenu();
      this.is_set_left_menu = true;
    }
  }
</script>

<style scoped>

</style>
