import store from "../../store";


const education = String(process.env.VUE_APP_EDUCATION_PREFIX).replace("/", "");
const organization = String(process.env.VUE_APP_ORGANIZATION_PREFIX).replace("/", "");
const accounts = String(process.env.VUE_APP_ACCOUNTS_PREFIX).replace("/", "");
export default {


  getUrl(urlname, action) {
    let url = store.getters["urls/getPattern"](
      education,
      urlname,
      action
    );
    if (url !== "")
      return process.env.VUE_APP_API_HOST + process.env.VUE_APP_EDUCATION_PREFIX.replace("/", "") + url;
    else
      return ""
  },
  getUrlOrg(urlname, action) {
    let url = store.getters["urls/getPattern"](
      organization,
      urlname,
      action
    );
    if (url !== "")
      return process.env.VUE_APP_API_HOST + process.env.VUE_APP_ORGANIZATION_PREFIX.replace("/", "") + url;
    else
      return ""
  },
  getUrlAcc(urlname, action) {
    let url = store.getters["urls/getPattern"](
      accounts,
      urlname,
      action
    );
    if (url !== "")
      return process.env.VUE_APP_API_HOST + process.env.VUE_APP_ACCOUNTS_PREFIX.replace("/", "") + url;
    else
      return ""
  },
  getUrlAllowed(urlname, action) {
    return store.getters["urls/getAllowed"](
      education,
      urlname,
      action
    );
  }



}
