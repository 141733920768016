<template>
  <v-card  min-height="400">
    <v-toolbar color="primary" dark>
    </v-toolbar>
    <v-card class="py-3">
      <v-col>
        <v-row justify="center" >
          <v-icon size="250" color="grey">report_problem</v-icon>
        </v-row>

          <v-card-text class="font-weight-black py-1 px-0">
            У Вас нет доступа на данный ресурс
          </v-card-text>
          <v-card-text class=" title   py-1 px-0">При возникновении затруднений Вы можете обратиться в Единый центр поддержки пользователей следующими по электронному адресу
            <a href="mailto:support@muctr.ru">support@muctr.ru</a>.
          </v-card-text>
          <v-card-text class=" title text-xs-left py-1 px-0">Приносим извинения за доставленные неудобства</v-card-text>
      </v-col>
    </v-card>
  </v-card>
</template>

<script>
export default {
  name: 'DisableServices'
}
</script>

<style scoped>

</style>
