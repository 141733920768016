<template>
  <v-card class="pa-0">
    <v-toolbar dark color="primary">
      <v-toolbar-title>
        Настройка промежуточных контрольных точек
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn text @click="$emit('update:dialog', false)">
          <v-icon>
            close
          </v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-container>
      <v-row>
        <v-col  cols="12" class="py-2 px-0" v-for="(item) in control_points.items" :key="`${item.id}_controls`">
          <v-card height="100%">
            <v-toolbar flat class="px-0">
              <v-toolbar-items>
                <v-btn text @click="$set(control_points.expanded, item.id, !control_points.expanded[item.id])">
                  <v-icon v-if="control_points.expanded[item.id]">keyboard_arrow_up</v-icon>
                  <v-icon v-else>keyboard_arrow_down</v-icon>
                </v-btn>
              </v-toolbar-items>
              <v-toolbar-title>
                <span class="text-wrap">{{item.name}}</span>
                <span class="ml-1">
                  ({{formatDate(item.date)}})
                </span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items v-if="gets_param.old !== true">
                <v-btn  text @click="openCreate(item)">
                  <v-icon>add</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-divider></v-divider>
            <v-expand-transition>
              <div v-if="control_points.expanded[item.id]">
                <v-list v-if="item.sub_control_points.length">
                  <template v-for="(sub, index) in item.sub_control_points" >
                    <v-list-item :key="`${index}_list_item_sub_control_points`">
                      <v-row no-gutters>
                        <v-col cols="1">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-on="on" v-bind="attrs">
                                person
                              </v-icon>
                            </template>
                            {{sub.fio}}
                          </v-tooltip>
                        </v-col>
                        <v-col cols="6" class="text-start d-flex"><span class="my-auto">{{sub.name}}</span></v-col>
                        <v-col cols="3" class="text-start d-flex"><span class="my-auto">{{formatDate(sub.date)}}</span></v-col>
                        <v-col cols="2">
                          <v-btn icon small @click="openUpdate(sub, item.date)">
                            <v-icon>edit</v-icon>
                          </v-btn>
                          <v-btn icon small @click="deleteItem(sub)">
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-divider  :key="`${index}_divider_sub_control_points`"></v-divider>
                  </template>
                </v-list>
                <v-list v-else>
                  <v-list-item>
                    Нет ни одной дополнительной контрольной точки
                  </v-list-item>
                </v-list>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog width="500" v-model="edited.opened">
      <MiddlePointDetailForm
        v-if="edited.opened"
        :group_subject_id="group_subject_id"
        :id="edited.id"
        :edit="edited.edit"
        :name="edited.name"
        :date="edited.date"
        :dialog.sync="edited.opened"
        :parent_end_date="edited.parent_end_date"
        @save="loadData"
      ></MiddlePointDetailForm>
    </v-dialog>
    <v-snackbar color="red accent-1" v-model="errors.open" multi-line>
      <v-list light color="red accent-1">
        <v-list-item v-for="(error, index) in errors.messages" :key="`${index}_error`" class="black--text">
          {{ error }}
        </v-list-item>
      </v-list>
    </v-snackbar>
  </v-card>

</template>

<script>
import {loadData, sendDeleteRequest, sendPostRequest} from "@/helper";
import urls from "@/urls/teacher";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import MiddlePointDetailForm from "@/modules/teacher/pages/subjects/MiddlePointDetailForm";
import {generateListUidToName} from "@/helper/uidToName";

export default {
  name: "MiddlePointListForm",
  mixins: [],
  components: {MiddlePointDetailForm, DebugJsonComponent},
  props: {
    group_subject_id: {
      required: true,
      type: Number
    },
    gets_param: {
      required: true,
      type: Object
    },
    dialog: {
      default: true,
      type: Boolean
    }
  },
  data: function () {
    return {
      errors: {
        open: false,
        messages: []
      },
      edited: {
        opened: false,
        edit: false,
        id: undefined,
        parent_end_date: "",
        name: "",
        date: ""
      },
      control_points: {
        items: [],
        loaded: false,
        loading: false,
        expanded: {}
      }
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split("-")
      return `${day}.${month}.${year}`
    },
    openUpdate(item, date) {
      this.edited.id = item.id;
      this.edited.name = item.name;
      this.edited.date = item.date;
      this.edited.opened = true;
      this.edited.edit = true;
      this.edited.parent_end_date = date;
    },
    openCreate(item) {
      this.edited.id = item.id;
      this.edited.name = "";
      this.edited.date = "";
      this.edited.opened = true;
      this.edited.edit = false;
    },
    closeForm() {
      this.edited.opened = false;
      this.loadData();
    },
    deleteItem(item) {
      sendPostRequest(
        urls.URLS.SUBJECT.MIDDLE_POINTS.DELETE(this.group_subject_id),
        {origin: item.id},
        () => {
          this.loadData();
        },
        (data) => {
          this.errors.messages = [];
          for(let field of Object.keys(data)){
            this.errors.messages.push(...data[field]);
          }
          this.errors.open = true;
        }
      )
    },
    loadData() {
      this.control_points.loading = true;
      loadData(
        urls.URLS.SUBJECT.MIDDLE_POINTS.LIST(this.group_subject_id, this.gets_param),
        (data) => {
          this.control_points.items = data;
          this.control_points.loaded = true;
          this.control_points.loading = false;
          let users = []
          this.control_points.items.forEach(item => {
            this.$set(this.control_points.expanded, item.id, true);
            item.sub_control_points.forEach(el => {
              if(users.findIndex(fel => el.creator === fel.uid) === -1)
                users.push({uid: el.creator, fio: ""});
            })
          })
          generateListUidToName(users, "uid", "fio",
            () => {
              this.control_points.items.forEach(item => {
                item.sub_control_points.forEach(el => {
                  this.$set(
                    el,
                    "fio",
                    users.find(fel => el.creator === fel.uid).fio
                  )
                })
              })
            })
        }
      )
    },
    getFio(){
      //console.log('hover')
    }
  },
  created() {
    this.loadData();
  }
}
</script>

<style scoped>

</style>
