<template>
  <v-container v-if="$route.name === names.EDUCATIONAL.ADMISSION.LIST">
    <v-card >
      <v-toolbar
        class="mb-2"
        color="style-color-main-gray"
        flat
      >
        <v-text-field class="pr-2"
                      dense
                      placeholder="Поиск по номеру допуска"
                      v-model="search.value"
                      @keypress.enter="searchData"
                      @click:clear="clearSearchData"
                      clearable
                      messages="Для поиска нажмите Enter"
        ></v-text-field><v-btn outlined @click="opened_confirm = true">Сформировать выгрузку</v-btn>
      </v-toolbar>
      <v-expansion-panels accordion>
        <v-expansion-panel
          :key="'_op'"
        >
          <v-expansion-panel-header><span>Расширенный поиск <v-icon>search</v-icon></span></v-expansion-panel-header>
          <v-expansion-panel-content>
        <v-row  no-gutters>
          <v-col cols="12">
            <v-select class="px-1" :items="selectors.success" v-model="pagination.filters.success"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select class="px-1" :items="selectors.session" v-model="pagination.filters.session"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select class="px-1" :items="selectors.admission_type" v-model="admission_type"
            ></v-select>
          </v-col>
          <v-col>
            <v-autocomplete class="px-1" :items="selectors.subject" v-model="pagination.filters.subject"></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-select class="px-1" :items="selectors.course_semester" v-model="pagination.filters.course_semester"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select  class="px-1" :items="selectors.course" v-model="pagination.filters.course"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-autocomplete hide-details class="px-1" :items="selectors.group" v-model="pagination.filters.group"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-text-field clearable placeholder="Поиск по ФИО обучающегося"  class="px-1"  v-model.lazy.trim="pagination.filters.fio" ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field clearable placeholder="Поиск по названию преподающей предмет кафедры"  class="px-1"  v-model.lazy.trim="pagination.filters.cathedra" ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field clearable placeholder="Поиск по названию факультета обучающегося"  class="px-1"  v-model.lazy.number="pagination.filters.faculty" ></v-text-field>
          </v-col>
          <v-col cols="12" >
            <v-row no-gutters>
              <v-alert outlined type="info">Допуски с порядковым номером</v-alert>
              <v-select style="max-width: 100px" class="px-3"  v-model="tolerance_number_type"  :items="selectors.tolerance_number_type"></v-select>
              <v-text-field type="number" placeholder="Порядковый номер"  class="px-1"  v-model="tolerance_number" ></v-text-field>
            </v-row>
          </v-col>
        </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-data-table
        :headers="headers"
        :loading="pagination.loading"
        :page.sync="pagination.page"
        :items-per-page.sync="pagination.size"
        :server-items-length="pagination.count"
        :options.sync="options"
        multi-sort
        :footer-props="{'items-per-page-options':[10,25,50]}"
        :items="items"
      >
        <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
        <template v-slot:item.subject="props">
          <span style="text-decoration: underline; cursor: pointer" @click="getDetail(props.item)">{{props.item.subject}}</span>
        </template>
        <template v-slot:item.rating="props">
          <span>{{props.item.semester.rating}}</span>
        </template>
        <template v-slot:item.semester="props">
          <span>{{getNumberSemestr(props.item.semester,props.item.course)}}  </span>
        </template>
        <template v-slot:item.control_type="props">
          <span>{{props.item.control_type}}</span>
        </template>
        <template v-slot:item.group="props">
          <span>{{props.item.group.name}}</span>
        </template>
        <template v-slot:item.created="props">
          <span>{{displayDate(props.item.created,true)}}</span>
        </template>
        <template v-slot:item.admission_type="props">
          <v-tooltip bottom v-if="props.item.tolerance_count > 1">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on"> info </v-icon>
            </template>
            <span>Это {{props.item.tolerance_count}} допуск данного вида по предмету</span>
          </v-tooltip>
          <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{getAbbreviation(props.item.academic_difference, props.item.is_exam)}}</span>
            </template>
            <span>{{getAdmissionType(props.item.academic_difference, props.item.is_exam)}}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.success="props">
          <v-icon v-if="props.item.success" color="green">done</v-icon>
          <v-icon v-else-if="props.item.success !== null" color="red">clear</v-icon>
          <span v-else>
            <span v-if="props.item.closed && !props.item.success">Архив</span>
            <span v-else>Не рассмотрен</span>
          </span>
        </template>
        <template slot="no-data">
          <v-card-text>Данные не загружены</v-card-text>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog persistent v-model="opened_confirm" width="500">
      <v-card >
        <v-card-title>Сформировать выгрузку</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          Если вы не укажете дополнительные параметры расширенного поиска, то результаты будут включать только те допуски, которые не были взяты в работу<br>
        </v-card-text>
        <v-card-actions>
          <v-flex>
            <v-btn
              @click="loadXls"
              color="warning"
              text
            >
              Сформировать
            </v-btn>
            <v-btn
              @click="opened_confirm=false"
              text
            >Отмена
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>

<script>
  import selectors from "@/urls/selectors";
  import urls from "@/urls/management";
  import UserDataMixin from "@/mixins/UserDataMixin"
  import {loadData} from "@/helper";

  import TitledPageMixin from "@/mixins/TitledPageMixin";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import {generateListUidToName, generateUidToName, uidToPositionHelds} from "@/helper/uidToName";
  import moment from "moment";
  import names from "@/modules/educationalManagement/routers/names";


  export default {
    name: "ListAdmissions",

    mixins: [
      TitledPageMixin,
      PaginatedDataMapperMixin,
      UserDataMixin],
    components: {},

    data () {
      return {
        search: {
          parameter: "search"
        },
        admission:[],
        admissionPrint:[],
        tolerance_number:null,
        tolerance_number_type: "exact",
        loadingPrint:false,
        success: "null",
        opened_load:false,
        subject:null,
        session:null,
        admission_type:null,
        selectors:{
          tolerance_number_type:[
            {text:"=",value: "exact"},
            {text:">",value: "gt"},
            {text:">=",value: "gte"},
            {text:"<",value: "lt"},
            {text:"<=",value: "lte"}
          ],
          course:[
            {text:"Все курсы",value: null},
            {text:"1",value: "1"},
            {text:"2",value: "2"},
            {text:"3",value: "3"},
            {text:"4",value: "4"},
            {text:"5",value: "5"},
            {text:"6",value: "6"}
          ],
          course_semester:[
            {text:"Все семестры",value: null},
            {text:"Осенний",value: "осенний"},
            {text:"Весенний",value: "весенний"}
          ],
          admission_type:[
            {text:"Все типы допусков",value: null},
            {text:"Добор/Зачет",value: "dobor"},
            {text:"Экзамен/Зачет с оценкой",value: "is_exam"},
            {text:"Академическая разница",value: "academic_difference"}
          ],
          subject:[],
          session:[],
          group:[],
          success:[
            {text:"Все допуски",value: null},
            {text:"Успешно закрытые допуски",value: "true"},
            {text:"Неуспешно закрытые допуски",value: "false"},
            {text:"Допуски, не взятые в работу",value: "null"}
          ]
        },
        page_title: "Список допусков",
        total: 0,
        names:names,
        items: [],
        loading: true,
        pagination: {
          filters: {
            success: "null",
            academic_difference: null,
            is_exam: null,
            subject: null,
            fio: null,
            session: null,
            course: null,
            group: null,
            course_semester: null,
            faculty: null,
            cathedra: null,
            tolerance_number: null,
            tolerance_number__lt: null,
            tolerance_number__gt: null,
            tolerance_number__lte: null,
            tolerance_number__gte: null
          }
        },
        URL_LIST: urls.EDUCATION.ADMISSION.LIST(),
        headers: [
          {text: "Номер", sortable: true, align: "left", value: "number",width: "7%"},
          {text: "Дата выдачи", sortable: true, align: "left", value: "created",width: "8%"},
          {text: "Предмет", sortable: false, align: "left", value: "subject"},
          {text: "Семестр", sortable: false, align: "left", value: "semester", width: "8%"},
          {text: "Контроль", sortable: false, align: "left", value: "control_type"},
          {text: "Рейтинг", sortable: false, align: "left", value: "total"},
          {text: "Обучающийся", sortable: false, align: "left", value: "fio_student"},
          {text: "Группа", sortable: false, align: "left", value: "group"},
          {text: "Кафедра", sortable: false, align: "left", value: "cathedra.name"},
          {text: "Факультет", sortable: false, align: "left", value: "faculty.name"},
          {text: "Тип допуска", sortable: false, align: "left", value: "admission_type"},
          {text: "Рейтинг в семестре", sortable: false, align: "left", value: "in_semester"},
          {text: "Статус", sortable: false, align: "left", value: "success"}
        ]
      }
    },
    created() {
      this.loadSelectorSubject()
      this.loadSelectorGroup()
    },
    watch: {
      tolerance_number(){
        this.pagination.filters.tolerance_number= null,
        this.pagination.filters.tolerance_number__lt= null,
        this.pagination.filters.tolerance_number__gt= null,
        this.pagination.filters.tolerance_number__lte= null,
        this.pagination.filters.tolerance_number__gte= null
        if(this.tolerance_number_type === "exact"){
            this.pagination.filters["tolerance_number"] = this.tolerance_number
          }else
        this.pagination.filters["tolerance_number__"+this.tolerance_number_type] = this.tolerance_number
      },
      tolerance_number_type(){
        this.pagination.filters.tolerance_number= null,
        this.pagination.filters.tolerance_number__lt= null,
        this.pagination.filters.tolerance_number__gt= null,
        this.pagination.filters.tolerance_number__lte= null,
        this.pagination.filters.tolerance_number__gte= null
        if(this.tolerance_number_type === "exact"){
          this.pagination.filters["tolerance_number"] = this.tolerance_number
        }else
          this.pagination.filters["tolerance_number__"+this.tolerance_number_type] = this.tolerance_number
      },
      admission_type(){
        if(this.admission_type === "academic_difference"){
          this.pagination.filters.is_exam = null
          this.pagination.filters.academic_difference = true
        }
        else if(this.admission_type === "is_exam"){
          this.pagination.filters.is_exam = true
          this.pagination.filters.academic_difference = null
        }
        else if(this.admission_type === "dobor"){
          this.pagination.filters.is_exam = false
          this.pagination.filters.academic_difference = null
        }
        else{
          this.pagination.filters.is_exam = null
          this.pagination.filters.academic_difference = null
        }
      }
    },
    methods: {
      loadXls(){
        this.opened_confirm = false
        loadData(
          urls.EDUCATION.ADMISSION.LOAD(this.pagination.filters),
          (data) => {
            this.$router.push({name: names.EDUCATIONAL.ADMISSION_REQUEST.LIST})
          })
      },
      displayDate(date, minutes = false) {

        let format = "DD.MM.YYYY";
        if (minutes)
          format += " HH:mm";
        return moment(date).format(format)
      },
      getNumberSemestr(semester,course){
        let num =0
        if(semester === "осенний"){
          num = course * 2 - 1
        }else {
          num = course * 2
        }
        return num + " (" +semester+ ")"
      },
      getAbbreviation(academic_difference, is_exam){
        if (academic_difference)
          return "А/Р"
        else if (is_exam)
          return "Э"
        else
          return "Д"
      },
      getAdmissionType(academic_difference, is_exam){
        if (academic_difference)
          return "Академическая разница"
        else if (is_exam)
          return "Экзамен/Зачет с оценкой"
        else
          return "Добор/Зачет"
      },
      loadSelectorSubject(){
        loadData(
          selectors.URLS.SEMESTERS(),
          (data) => {
            this.selectors.subject=data
            this.selectors.subject.unshift({text:"Все предметы",value:null})
            this.loadSelectorSession()
          }
        )
      },
      loadSelectorGroup(){
        loadData(
          selectors.URLS.ACTIVE_GROUP(),
          (data) => {
            this.selectors.group=data
            this.selectors.group.unshift({text:"Все группы",value:null})
            this.loadSelectorSession()
          }
        )
      },
      loadSelectorSession(){
        loadData(
          selectors.URLS.ACADEMIC_YEAR_ALL_SESSION(),
          (data) => {
            this.selectors.session=data
            this.selectors.session.unshift({text:"Все сессии",value:null})
            this.loadData();
          }
        )
      },
      loadData(url = undefined) {
        this.admission=[]
        this.setLoading();
        if(!url) {
          url = this.addFiltersParameter(this.URL_LIST);
          this.setCurrentLoadUrl(url);
        }
        loadData(
          url,
          (data) => {
            this.mapResults(data, "items")
            this.items.forEach(el => {
              this.$set(el, "fio_student", "");
            });
            generateListUidToName(this.items, "student", "fio_student");
            this.setNotLoading();
          }
        )
      },
      getItems (url = undefined) {
        this.loadData(this.getCurrentLoadUrl());
        this.setCurrentLoadUrl(url, true);
      },
      getDetail(item){
        this.$router.push({name: names.EDUCATIONAL.ADMISSION.DETAIL, params: {idAdmission: item.id}})
      }
    }
  }


</script>

<style scoped>

</style>

