<template>
  <v-container v-if="names.CathedraIndividualStudents === this.$route.name">
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-toolbar
            elevation="0"
            color="style-color-main-gray"
          >
            <v-text-field
              dense
              placeholder="Поиск"
              v-model="search.value"
              @keypress.enter="searchData"
              @click:clear="clearSearchData"
              clearable
              messages="Для поиска нажмите Enter или кнопку поиск"
              append-icon="search"
              @click:append="searchData"
            >
            </v-text-field>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="items"
            :expanded.sync="expanded"
            show-expand
            :items-per-page.sync="pagination.size"
            no-data-text="Обучающийся с индивидуальными учебными планами не загружены"
            no-results-text="Обучающийся с индивидуальными учебными планами не были найдены"
            :page.sync="pagination.page"
            :loading="pagination.loading"
            :server-items-length="pagination.count"
            :footer-props="{'items-per-page-options':[10,20,50], 'show-current-page': true, 'show-first-last-page': true}"
          >
            <template v-slot:item.journal="props">
              <v-btn outlined :to="journalLink(props.item.id)">
                <v-icon>format_list_bulleted</v-icon>Журнал оценок
              </v-btn>
            </template>
            <template v-slot:loading>
              <template v-for="i in 10">
                <v-skeleton-loader type="table-row" :key="`skeleton${i}`"></v-skeleton-loader>
              </template>
            </template>
            <template v-slot:expanded-item="props">
              <td :colspan="props.headers.length" class="py-2">
                <v-row wrap v-if="props.item.semesters !== undefined">
                  <v-col class="text-left" :cols="props.item.semesters.length > 0 && $vuetify.breakpoint.mdAndUp? 6: 12" v-for="(semester, index) in props.item.semesters" :key="`semester_${index}`">
                    <v-card elevation="2" class="pa-0 ma-0" height="100%">
                      <v-card-title>
                        <v-row>
                          <v-col cols="8" class="py-0" style="word-break: break-word;">
                            {{semester.name}}
                          </v-col>
                          <v-col cols="4" class="text-right py-0">
                            {{semester.control_type}}
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-text>
                        <v-data-table
                          :headers="[{text: 'Преподаватель', value: 'fio', width: '100%', sortable: false},]"
                          :items="semester.teachers"
                          mobile-breakpoint="0"
                          :hide-default-footer="semester.teachers.length < 5"
                          :items-per-page="semester.teachers.length"
                          no-data-text="Прикрепленные преподаватели не загружены"
                          no-results-text="Прикрепленные преподаватели не были найдены"
                          :footer-props="{'items-per-page-options':[5], 'items-per-page-text': 'Число элементов:', 'show-current-page': true}"
                        >
                          <template v-slot:header.fio="props2">
                            <v-row class="text-start">
                              <v-col>{{props2.header.text}}</v-col>
                              <v-btn icon  @click="openEditable(props.item.id, semester.id, semester.teachers)">
                                <v-icon>edit</v-icon>
                              </v-btn>
                            </v-row>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="editable.opened"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <LinkedTeachers
        v-if="editable.opened"
        :student_id="editable.student_id"
        :subject_id="editable.subject_id"
        :selected="editable.teachers"
        @saved="closeEditable()"
        @close="editable.opened = false"
      >
      </LinkedTeachers>
    </v-dialog>
  </v-container>
  <router-view v-else></router-view>
</template>

<script>

import names from "../../routers/names";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import urls from "@/urls/cathedras";
import {loadData} from "@/helper";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import LinkedTeachers from "@/modules/cathedra/pages/individual_students/LinkedTeachers.vue"


export default {
  name: "ListStudents",
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  components: {LinkedTeachers},
  data: function() {
    return {
      names: names,
      page_title: "Список обучающихся с индивидуальным учебным планом",
      items: [],
      search: {
        parameter: "fio"
      },
      expanded: [],
      editable: {
        student_id: undefined,
        subject_id: undefined,
        teachers: [],
        opened: false
      },
      headers: [
        {text:"ФИО", sortable: false, align:"left", value: "student", width: "70%"},
        {text: "", sortable: false, align:"left", value: "journal", width: "25%"},
        {text: "", value: "data-table-expand", width: "5%"}
      ]
    }
  },
  computed: {
  },
  methods:{
    journalLink(student_id) {
      return {
        name: names.CathedraIndividualStudentsJournal,
        params: {
          cathedra_uid: this.$route.params.cathedra_uid.toLowerCase(),
          student_id: student_id
        },
        query: this.$route.query
      }
    },
    loadData(url=undefined){
      this.setLoading()
      if (url === undefined) {
        url = urls.CATHEDRA.INDIVIDUAL_STUDENT.LIST(this.$route.params.cathedra_uid)
        this.setCurrentLoadUrl(url)
      }
      loadData(
        url,
        (data) => {
          this.mapResults(data, "items")
          this.setNotLoading()
        }
      )
    },
    openEditable(student, subject, teachers){
      this.editable.student_id = student
      this.editable.subject_id = subject
      this.editable.teachers = teachers
      this.editable.opened = true
    },
    closeEditable() {
      this.loadData()
      this.editable.opened = false
    }
  },
  created() {
    this.setPageTitleWithObject("Cathedra", {uid: this.$route.params.cathedra_uid}, " - ")
    this.loadData()
  }
}
</script>

<style scoped>

</style>
