import store from "../../store";

const host_account = process.env.VUE_APP_API_HOST + process.env.VUE_APP_ACCOUNTS_PREFIX.replace("/", "");
const host_education = process.env.VUE_APP_API_HOST + process.env.VUE_APP_EDUCATION_PREFIX.replace("/", "");
const account = String(process.env.VUE_APP_ACCOUNTS_PREFIX).replace("/", "");
const education = String(process.env.VUE_APP_EDUCATION_PREFIX).replace("/", "");

function getUrl(host, base, urlname, action) {
  let url = store.getters["urls/getPattern"](
    base,
    urlname,
    action
  );
  if (url !== "")
    return host + url;
  else
    return ""

}
function getUrlAllowed(base, urlname, action) {
  return store.getters["urls/getAllowed"](
    base,
    urlname,
    action
  );
}

export default {


  ACCOUNTS: {
    // TOKEN_ALIVE: host_account + 'authenticate/token/alive/',
    getUrl(urlname, action) {
      return getUrl(host_account, account, urlname, action)
    },
    getUrlAllowed(urlname, action) {
      return getUrlAllowed(account, urlname, action)
    }
  },
  EDUCATION: {
    getUrl(urlname, action) {
      return getUrl(host_education, education, urlname, action)
    },
    getUrlAllowed(urlname, action) {
      return getUrlAllowed(education, urlname, action)
    }
  }
  // CHOICES_PROFILE: {
  //   getUrl: function (urlname) {
  //     return this.BASE + this[urlname];
  //   }
  // },

  //
  //
  //
  // PROFILE_URLS: {
  //   BASE: host_account + 'user/<user-uid>/',
  //   SELF: 'profile/',
  //   getNamedUrl: function (namespace, urlname) {
  //     return this.BASE + this[namespace][urlname]
  //   },
  //   getUrl: function (urlname) {
  //     return this.BASE + this[urlname];
  //   }
  // },
}
