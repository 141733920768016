<template>
  <v-card elevation="0">
    <v-toolbar class="mb-2"
               flat
               :style="{'height':`${$vuetify.breakpoint.smAndUp ? 64: 150}px`} "
    >
      <template v-if="$vuetify.breakpoint.mdAndUp" >
        <v-row>
          <v-col cols="8">
            <v-text-field class="pt-4"
                          dense
                          placeholder="Поиск по названию предмета"
                          v-model="search.value"
                          @keypress.enter="searchData"
                          @click:clear="clearSearchData"
                          clearable
                          messages="Для поиска нажмите Enter"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-autocomplete class="py-3"  hide-details label="Индивидуальность" :items="selectors.is_individual" v-model="pagination.filters.is_individual"
            ></v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-autocomplete  class="py-3 "  hide-details label="Семестр" :items="selectors.semesters" v-model="pagination.filters.year_semester"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </template>
      <template v-slot:extension v-if='$vuetify.breakpoint.smAndDown' >
        <v-col>
          <v-text-field
            dense
            placeholder="Поиск"
            v-model="search.value"
            @keypress.enter="searchData"
            @click:clear="clearSearchData"
            clearable
            messages="Для поиска нажмите Enter или кнопку поиск"
          ></v-text-field>
          <v-autocomplete class="py-3" hide-details label="Индивидуальность" :items="selectors.is_individual" v-model="pagination.filters.is_individual"
          ></v-autocomplete>
          <v-autocomplete  class="py-3" hide-details label="Семестр" :items="selectors.semesters" v-model="semester"
          ></v-autocomplete>
        </v-col>
      </template>
    </v-toolbar>
    <v-data-table
      v-if="subjects.loaded"
      :headers="subjects.headers"
      :items="subjects.data"
      :loading="subjects.loading"
      :expanded.sync="subjects.expanded"
      :server-items-length="pagination.count"
      :items-per-page.sync="pagination.size"
      :page.sync="pagination.page"
      show-expand
      :footer-props="{'items-per-page-options':[10,25,50]}"
    >
      <template v-slot:item.rating="props">
        <v-row class=" fill-height" style="min-width: 120px;">
          <v-col  cols="4" class="ma-0 pa-0 my-auto">
            <span>{{props.item.rating}}</span>
          </v-col>
          <v-col  cols="8" class="ma-0 pa-0 d-flex justify-space-between my-auto" >
            <v-chip v-if="props.item.reload" small>
              Перезачет
            </v-chip>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.is_individual="props">
        <v-chip v-if="props.item.is_individual"
                small>
          Индивидуальный
        </v-chip>
      </template>
      <template v-slot:expanded-item="props">
        <td :colspan="props.headers.length">
          <SubjectDetail :subject_id="props.item.id" @back="subjects.expanded.splice(subjects.expanded.indexOf(props.item), 1)"></SubjectDetail>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import urls from "@/urls/management";
  import {loadData, checkFieldTrue} from "@/helper";
  import SubjectDetail from "./SubjectDetail";
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import selectors from "@/urls/selectors";

  export default {
    name: "SubjectList",
    mixins: [
      PaginatedDataMapperMixin
    ],
    components: {
      DebugJsonComponent,
      SubjectDetail
    },
    props: {
      subject_url: Object,
      curriculum_id: Number
    },
    computed: {
      loadingPage: function(){
        return checkFieldTrue(this.student, "loading");
      }
    },
    data: function () {
      return {
        URL_LIST: urls.EDUCATION.STUDENT.CURRICULUM.LIST(this.$route.params.student_id),
        search: {
          parameter: "subject_name"
        },
        loadErrorMsg: "",
        actions: {
          nextCourseApplier: false,
          prevCourseApplier: false
        },
        subjects: {
          student_id: this.$route.params.student_id,
          data: {},
          expanded: [],
          loading: false,
          loaded: false,
          headers: [
            {text: "Предмет", value: "subject", sortable: false, align: "left", width: "25%"},
            {text: "Кафедра", value: "cathedra", sortable: false, align: "left", width: "25%"},
            {text: "Индивидуальный", value: "is_individual", sortable: false, align: "left"},
            {text: "Курс", value: "course", sortable: false, align: "left"},
            {text: "Семестр", value: "semester", sortable: false, align: "left"},
            {text: "Форма контроля", value: "control_type", sortable: false, align: "left"},
            {text: "Количество часов", value: "hours",sortable: false, align: "left"},
            {text: "Зеты", value: "zets", sortable: false,  align: "left"},
            {text: "Итоговые баллы", value: "rating", sortable: false,  align: "left", width: "15%"},
            {text: "", value: "data-table-expand", width: "5%"}
          ]
        },
        selectors: {
          semesters: [],
          is_individual:[
            {text:"Все предметы",value: null},
            {text:"Индивидуальные предметы",value: "true"},
            {text:"Не индивидуальные",value: "false"}
          ]
        },
        pagination: {
          filters: {
            year_semester: null,
            is_individual: null
          }
        },
        semester: null
      }
    },
    methods: {
      loadData: function (url=undefined) {
        this.setLoading();
        if(!url) {
          url = this.addFiltersParameter(this.URL_LIST);
          this.setCurrentLoadUrl(url);
        }
        loadData(
          url,
          (data) => {
            this.subjects.data = data.results;
            this.mapPagination(data)
            this.setCurrentLoadUrl(url)
            this.subjects.loaded = true
            this.subjects.loading = false
            this.setNotLoading()
          }
        );
      },
      loadSelector(){
        loadData(
          selectors.SELECTORS.DEAN.YEAR_SEMESTER_STUDENT(this.$route.params.student_id),
          (data) => {
            this.selectors.semesters= data
            this.selectors.semesters.unshift({text:"Все семестры",value:null})
          }
        )
      }
    },
    created() {
      this.loadData();
      this.loadSelector()
    }
  }
</script>

<style scoped>

</style>
