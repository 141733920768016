<template>
  <v-container >
    <v-card  >
      <v-toolbar class="title" flat dense>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn @click="opened_form = true" color="secondary" text>
            <v-icon>add</v-icon>
            Добавить
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                text
                v-on="on"
                @click="getItems()"
                class="mr-2"
                medium
              >
                <v-icon>
                  update
                </v-icon>
              </v-btn>
            </template>
            <span>Обновить данные</span>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pb-3 px-0 pt-0">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          no-data-text="Нет данных"
          :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
        >
          <template v-slot:item.head="{ item }">
            <v-icon v-if="item.head">done</v-icon> <v-icon v-else>clear</v-icon>
          </template>
          <template v-slot:item.option="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  @click="openConfirmDelete(item)"
                  medium
                >
                  <v-icon>
                    delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Удалить</span>
            </v-tooltip>
          </template>
          <template slot="no-data">
            <v-card-text>Данные не загружены</v-card-text>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <Form @addItem="addItem"
          @ended="closedForm"
          @refreshItem="refreshItem"
          @input="closedForm"
          :opened_form="opened_form"
          v-bind="selected"
          v-if="opened_form"
    >
    </Form>

    <v-dialog persistent v-model="opened_confirm" width="500">
      <v-card v-if="deletable">
        <v-card-title>Подтвердите удаление</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          Вы уверены, что хотите удалить?<br>
        </v-card-text>
        <v-card-actions>
          <v-flex>
            <v-btn
              @click="deleteItem(deletable.id)"
              color="warning"
              text
            >
              Удалить
            </v-btn>
            <v-btn
              @click="closeConfirmDelete"
              text
            >Отмена
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>


<script>
  import Form  from "@/modules/educationalManagement/pages/permission/Form"
  import UserDataMixin from "@/mixins/UserDataMixin"
  import {loadData, makeDeleteRequest, makeGetRequest} from "@/helper";
  import urls from "@/urls/management";
  import {generateListUidToName} from "@/helper/uidToName";
  import names from "@/modules/dean/routers/names";

  export default {
    name: "ListLedGroups",
    components: {Form},
    mixins: [UserDataMixin],
    props: {
    },
    data () {
      return {
        URL_LIST:  urls.EDUCATION.PERMISSION.LIST(),
        URL_DELETE: urls.EDUCATION.PERMISSION.DELETE(),
        names:names,
        items: [],
        loading: true,
        headers: [
          {text: "Работник", sortable: false, value:"fio"},
          {text: "Глава учебного управления", sortable: false, value:"head"},
          {text: "Действия", sortable: false, value:"option", align: "center"}
        ]
      }
    },
    watch: {
    },
    created() {
      this.getItems();
      this.$store.commit("mainpage/changeTitle", "Выдача прав на редактирование");
    },
    methods: {
      addItem(){
        this.closedForm()
        this.getItems()
      },
      getItems(){
        this.loading = true
        loadData(
          this.URL_LIST,
          (data) => {
            this.items = data
            for (let i in this.items) {
              this.$set(this.items[i], "fio","-")
            }
            generateListUidToName(this.items,"worker","fio")
            this.loading = false
          }
        )
      },
      deleteItem(id) {
        let url = urls.EDUCATION.PERMISSION.DELETE(id)
        makeDeleteRequest(url).then(resp => {
          if (resp.ok) {
            this.closeConfirmDelete()
            this.getItems()
          }
        })
      }
    }
  }


</script>
<style scoped>

</style>
