<template>
  <scroll-center-dialog v-model="opened_form" @close="closeDialog" @apply="save" title="Изменить семестр" width="600">
    <div class="px-2 pt-2 pb-1">
      <v-alert outlined type="info" border="left">
        <div class="text-justify">
          Внимание! </br>
          Внесение изменений сделает  предмет  индивидуальным, а так же  отключит его синхронизацию с учебным планом.
        </div>
      </v-alert>
    </div>
    <v-autocomplete
      outline
      :error="hasError('course')"
      :error-messages="getError('course')"
      :items="selectors.course"
      no-data-text="Нет данных"
      label="Курс"
      v-model="value.course"
    ></v-autocomplete>
    <v-autocomplete
      outline
      :error="hasError('semester')"
      :error-messages="getError('semester')"
      :items="selectors.semester"
      no-data-text="Нет данных"
      label="Семестр"
      v-model="value.semester"
    ></v-autocomplete>
    <v-alert type="error" v-if="hasError('detail')">{{ getError('detail')[0] }}</v-alert>
  </scroll-center-dialog>
</template>
<script>

import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";
import UserDataFormMixin from "@/mixins/UserDataFormMixin";
import {loadData, sendPutRequest} from "@/helper";
import urls from "@/urls/dean"
import choices from "@/urls/choices"
import selectors from "@/urls/selectors"

export default {
  name: "IndividualSemesterChangeForm",
  mixins: [UserDataFormMixin],
  components: {
    ScrollCenterDialog
  },
  props: [ "curriculum_semester","course", "semester","id", "opened_form", "level"],
  data() {
    return {
      value: {
        semester: this.semester.slice(2,-1) || "",
        course: null
      },
      selectors:{
        semester: [],
        course: []
      },
      errors:{}
    }
  },
  methods: {
    closeDialog() {
      this.$emit("ended", "")
    },
    loadSelector(data, url) {
      loadData(
        url,
        (json) => {
          this.selectors[data] = json
        }
      )
    },
    loadSelectorCourse() {
      loadData(
        selectors.URLS.COURSES({"level":this.level}),
        (json) => {
          this.selectors.course = json
          let course = this.selectors.course.find(fel => fel.text === String(this.course))
          if(course)
            this.value.course = course.value
        }
      )
    },
    save(){
      sendPutRequest(
        urls.DEAN.STUDENTS.CURRICULUM.SEMESTER.INDIVIDUAL.UPDATE(this.$route.params.idFaculty, this.$route.params.idStudent, this.id),
        this.value,
        ()=>{
          this.closeDialog()
        },
        (error)=>{
          this.receiveErrors(error)
        }
      )
    }
  },
  created (){
    this.loadSelector("semester", choices.EDUCATION.CHOICES.AY_SEMESTER())
    this.loadSelectorCourse()
  }
}
</script>

<style scoped>

</style>
