<template>
  <v-container style="height: 100%" v-if="is_self_router">
    <WorkForm v-if="change"
              @close="form_close()"
              :data_work = "data_work">
    </WorkForm>
    <v-card>
<!--      <DebugJsonComponent :json="works"></DebugJsonComponent>-->
      <v-toolbar color="style-color-main-gray" elevation="0">
        <v-text-field
          dense
          placeholder="Поиск"
          v-model="search.value"
          @keypress.enter="searchData"
          messages="Для поиска по названию работы нажмите Enter или кнопку поиск"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="AddItem()"
              class="mr-2"
              medium
            >
              add
            </v-icon>
          </template>
          <span>Добавить работу</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="loadData()"
              class="mr-2"
              medium
            >
              update
            </v-icon>
          </template>
          <span>Обновить данные</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text class="pb-3 px-0 pt-0">
        <v-row justify-center no-gutters>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="works"
              :loading="pagination.loading"
              :page.sync="pagination.page"
              :items-per-page.sync="pagination.size"
              :server-items-length="pagination.count"
              :footer-props="{'items-per-page-options':[10,25,50]}"
              no-data-text="Нет данных"
            >
              <template v-slot:item.name="props">
                <router-link v-if="props.item.name !== ''" :to="makeDetailLink(props.item)">
                  {{props.item.name}}
                </router-link>
                <v-skeleton-loader v-else type="text"></v-skeleton-loader>
              </template>
              <template v-slot:item.semester="props">
                {{props.item.semester.subject}} ({{props.item.semester.semester}} семестр {{props.item.semester.course}} курс)
              </template>
              <template v-slot:item.action="props">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click=changeItem(props.item)
                      class="mr-2"
                      medium
                    >
                      edit
                    </v-icon>
                  </template>
                  <span>Редактировать</span>
                </v-tooltip>
                <v-tooltip bottom >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="openConfirmDelete(props.item)"
                      medium
                    >
                      delete
                    </v-icon>
                  </template>
                  <span>Удалить</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-dialog persistent v-model="opened_confirm" width="500">
        <v-card v-if="deletable">
          <v-card-title>Подтвердите удаление</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            Вы уверены, что хотите удалить?<br>
          </v-card-text>
          <v-card-actions>
            <v-flex>
              <v-btn
                @click="deleteItem(deletable.id)"
                color="warning"
                text
              >
                Удалить
              </v-btn>
              <v-btn
                @click="closeConfirmDelete"
                text
              >Отмена
              </v-btn>
            </v-flex>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>

<script>
import TitledPageMixin from "@/mixins/TitledPageMixin"
import UserDataMixin from "../../../../../mixins/UserDataMixin"
import {loadData, makeDeleteRequest} from "@/helper"
import {generateListUidToName} from "@/helper/uidToName"
import names from "@/modules/cathedra/routers/names"
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin"
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent"
import cathedra from "@/urls/cathedras"
import WorkForm from "@/modules/cathedra/pages/teachers/studentWorks/WorkForm"
export default {
  name: "ListWorks",
  mixins: [
    TitledPageMixin,
    PaginatedDataMapperMixin,
    UserDataMixin
  ],
  components: {
    WorkForm,
    DebugJsonComponent
  },
  props: {
    teacher_fio: {
      required: false,
      type: String
    }
  },
  data () {
    return {
      change: false,
      URL_WORK_LIST: cathedra.CATHEDRA.TEACHERS.INDIVIDUAL_WORK.WORK_LIST(this.$route.params.cathedra_uid.toLowerCase(), this.$route.params.teacher_uid),
      search: {
        parameter: "document"
      },
      headers: [
        {text: "Обучающийся", value: "fio", sortable: false, align: "start", width: "20%"},
        {text: "Группа", value: "group", sortable: false, align: "center"},
        {text: "Название работы", value: "name", sortable: false, align: "center"},
        {text: "Тип работы", value: "type", sortable: false, align: "center", width: "15%"},
        {text: "Предмет", value: "semester", sortable: false, align: "center", width: "35%"},
        {text: "Действия", value: "action", sortable: false, align: "center"}
      ],
      works: [],
      data_work: ""
    }
  },
  computed: {
    page_title(){
      return `${this.teacher_fio}: Список работ`;
    },
    is_self_router(){
      return this.$route.name === names.CathedraTeachersDetailWorks;
    }
  },
  created() {
    if(this.is_self_router)
      this.loadData();
  },
  methods: {
    makeDetailLink(item){
      return {name: names.CathedraTeachersDetailWorksDetail, params: { idWork: item.id}}
    },
    deleteItem(id){
      let url  = cathedra.CATHEDRA.TEACHERS.INDIVIDUAL_WORK.WORK_DELETE(id, this.$route.params.cathedra_uid.toLowerCase(), this.$route.params.teacher_uid)
      makeDeleteRequest(url).then(resp=>{
        this.opened_confirm = false
        this.getItems()
      })
    },
    loadData(url = undefined) {
      this.getItems(url)
    },
    getItems (url=undefined) {
      this.setLoading();
      if(url === undefined) {
        url = this.URL_WORK_LIST
        this.setCurrentLoadUrl(url)
      }
      loadData(
        url,
        (data) => {
          this.works = data.results
          this.mapPagination(data) ;
          for (let el of this.works) this.$set(el, "fio","")
          generateListUidToName(this.works,"student","fio", () => {this.setNotLoading();})
        }
      )
    },
    AddItem(){
      this.data_work = ""
      this.change = true
    },
    changeItem(data){
      this.data_work = data
      this.change = true
    },
    form_close() {
      this.change = false
      this.getItems()
      this.data_work = ""
    }
  }

}
</script>

<style scoped>

</style>
