<template>
  <v-dialog v-model="open" persistent :fullscreen="$vuetify.breakpoint.smAndDown" :max-width="800" min-width="400" >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }"></slot>
    </template>
    <v-card v-if="open">
      <v-toolbar color="primary" dark>
        <v-toolbar-title>
          <slot name="title">Задать вопрос</slot>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-toolbar-items>
            <v-btn @click="close" class="pa-0 ma-0" dark text icon>
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar-items>
      </v-toolbar>
      <v-container :style="`overflow-y: scroll; max-height: calc(${$vuetify.breakpoint.smAndDown? 100: 90}vh - 112px); ${$vuetify.breakpoint.smAndDown? '': 'padding-left: 28px;'}`">
<!--        <v-scroll-y-transition>-->
        <v-form ref="form">
          <v-row dense>
            <v-col>
              <v-alert color="warning" outlined icon="warning" dense class="mb-0 text-center">
                Форма предназначена для вопросов по некорректной работе функционала. По вопросам наполнения профилей обращайтесь в Ваш деканат или кафедру.
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field class="py-3" readonly label="Страница" outlined hide-details dense :value="path" :loading="loading" :disabled="loading" error-messages="">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea ref="text" label="Опишите проблему в свободной форме" v-model="info.text" outlined auto-grow rows="5" placeholder="Опишите проблему с которой вы столкнулись в свободной форме."  :loading="loading" :disabled="loading || loaded" :rules="[rules.required]"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-list v-if="info.files.length">
                <v-list-item v-for="(file, i) in info.files" :key="`file_${i}`">
                  <v-list-item-icon><v-icon>description</v-icon></v-list-item-icon>
                  <v-list-item-content class="py-0 my-0"><v-text-field v-model="file.name"></v-text-field></v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click="info.files.splice(i, 1)"><v-icon>close</v-icon></v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-file-input outlined v-if="info.files.length <= 4" placeholder="Можете приложить скриншоты или файлы pdf"   @change="append" :clearable="false" ref="filefield" accept="image/*,file/pdf" :error-messages="error" :error="error !== null"></v-file-input>
            </v-col>
          </v-row>
          <v-row dense v-if="!loaded">
            <v-col>
              <v-alert color="info" outlined icon="info" dense class="mb-0 text-center">
                Для корректного выявления проблемы к обращению автоматически будет прикреплена информация о Ваших действиях в системе ЭИОС РХТУ.
                Информация о действиях на других сайтах не собирается
              </v-alert>
            </v-col>
          </v-row>
          <v-row dense v-else>
            <v-col>
              <v-alert color="info" outlined icon="info" dense class="mb-0 text-center">
                Ответ данное обращение будет направлен на электронную почту, к которой привязан Ваш аккаунт
              </v-alert>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-toolbar width="100%" dense :absolute="$vuetify.breakpoint.smAndDown" bottom>
        <v-toolbar-items v-if="!loaded" style="width: 100%">
          <v-btn color="success" width="100%" @click="sendFeedback" :loading="loading" :disabled="loading">
            Отправить обращение
          </v-btn>
        </v-toolbar-items>
        <v-alert color="success" outlined icon="check" dense class="mb-0" v-else width="100%">
          Спасибо за Ваше обращение! Ожидайте ответ специалиста по электронной почте.
        </v-alert>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import {makeGetRequest} from "@/helper";
import {getUrlWithParameters} from "@/helper/urls";
import {makePostRequestFile} from "@/helper";

export default {
  name: "Feedback",
  props: {
    width: {
      default: 500,
      type: Number
    }
  },
  data() {
    return {
      open: false,
      info: {
        text: "",
        files: []
      },
      error: null,
      text_error: false,
      loading: false,
      loaded: false,
      rules: {
        required: (val) => val !== "" || "Необходимо заполнить поле"
      }
    }
  },
  watch: {
    open(){
      if(this.open && !this.$store.getters["urls/checkAlreadyLoaded"]("feedback")){
        makeGetRequest(process.env.VUE_APP_INSTANCE_ALLOWED_URLS + "?instances=feedback")
          .then(resp => resp.json())
          .then(data => {
            this.$store.commit("urls/appendUrls", data);
          });
      }
      if(!this.open){
        window.removeEventListener("paste", this.appendFromClipboard);
      }else{
        window.addEventListener("paste", this.appendFromClipboard);
      }
    }
  },
  computed: {
    ...mapGetters({
      "route_history": "feedback/getRouteHistory",
      "request_history": "feedback/getRequestHistory"
    }),
    path() {
      return document.location
    },
    send_url(){
      return getUrlWithParameters(
        process.env.VUE_APP_API_HOST + "feedback",
        "feedback",
        "feedback|user-feedback-list",
        "create",
        {},
        {}
        )
    }
  },
  methods: {
    appendFromClipboard(event){
      for(let item of event.clipboardData.files){
        this.append(item, false)
      }
    },
    append(event, filefield=true){
      if(event != null){
        if(RegExp("image/.+").test(event.type) || event.type === "file/pdf") {
          if(this.info.files.findIndex(el => el.file.name === event.name && el.file.size === event.size)) {
            this.info.files.push({name: event.name, file: event});
            if(filefield)
              this.$refs.filefield.reset();
            this.error = null;
          }
        }else if(filefield){
          this.$refs.filefield.reset();
          this.error = "Неподходящий формат"
        }
      }
    },
    close(){
      this.open = false
      this.info.text = ""
      this.info.files = []
      this.error = null
      this.loaded = false
      this.loading = false
    },
    sendFeedback(){
      if(this.$refs.form.validate()){
        this.loading = true
        let form_data = new FormData();
        form_data.append("context", JSON.stringify({
            request_history: this.request_history,
            route_history: this.route_history,
            location: this.path
          })
        )
        form_data.append("text", this.info.text)
        form_data.append("from_frontend_url", this.path.href)
        for(let el of this.info.files){
          form_data.append("files", el.file, el.name)
        }
        makePostRequestFile(this.send_url, form_data)
          .then(resp => resp.json())
          .then(
            (json)=>{
              this.loading = false
              this.loaded = true
            }
          )
      }
    }

  }
}
</script>

<style scoped>

</style>
