<template>
  <v-container style="height: 100%">
    <v-card>
      <v-toolbar
        elevation="0"
        color="style-color-main-gray"
      >
        <v-text-field
          dense
          placeholder="Поиск"
          v-model="search"
          @change="getItems()"
          messages="Для поиска нажмите Enter или кнопку поиск"
        >
        </v-text-field>
        <v-btn icon><v-icon>search</v-icon></v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="getItems()"
              class="mr-2"
              medium
            >
              update
            </v-icon>
          </template>
          <span>Обновить данные</span>
        </v-tooltip>
      </v-toolbar>
      <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              :expanded.sync="expanded"
              show-expand
              no-data-text="Нет данных"
              :options.sync="pagination"
              :server-items-length="total"
              :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
            >
              <template v-slot:expanded-item="props">
                <td :colspan="props.headers.length" class="mx-0 px-0">
                  <v-card class="ma-1">
                    <v-data-table
                      v-if="props.item.groups instanceof Array"
                      :headers="[
                        {text: 'Группа', value: 'group', width: '10%', sortable: false},
                        {text: 'Контроль', value: 'control_type', width: '10%', sortable: false},
                        {text: 'Преподаватели', value: 'teachers', width: '40%', sortable: false},
                        ]"
                      :items="props.item.groups"
                    >
                      <template v-slot:item.group="props">
                        <v-card-text>{{props.item.group.name}}</v-card-text>
                      </template>
                      <template v-slot:item.teachers ="props">
                        <v-col>
                          <div v-for="teacher in props.item.teachers">{{teacher.fio}}, преподает {{teacher.type}}</div>
                        </v-col>

                      </template>
                    </v-data-table>
                  </v-card>

                </td>
              </template>

              <template slot="no-data">
                <v-card-text>Данные не загружены</v-card-text>
              </template>
            </v-data-table>
    </v-card>

    <!--</v-flex>-->
    <!--</v-layout>-->
  </v-container>
</template>


<script>
  // import Courses  from "../../forms/Courses"
  import UserDataMixin from "../../../../../mixins/UserDataMixin"
  import {loadData, makeGetRequest} from "../../../../../helper";
  import {getInstNameByRouterName} from "@/helper/instances";
  import urls from "@/urls/dean"
  import cathedras from "../../../../../urls/cathedras";
  import {generateListUidToName} from "../../../../../helper/uidToName";
  import names from "@/modules/dean/routers/names";

  export default {
    name: "ListLedSubjects",
    // components: {Courses},
    mixins: [UserDataMixin],
    props: {
    },
    data () {
      return {
        URL_LIST: urls.DEAN.CATHEDRA.LED.SUBJECT.LIST(this.$route.params.uidDepartament),
        expanded: [],
        names:names,
        total: 0,
        search:"",
        items: [],
        loading: true,
        pagination: {},
        headers: [
          {text: "Предмет",value:"name", sortable: false, align: "left"},
          {text: "Тип",value:"level", sortable: false, align: "left" },
          {text: "", value: "data-table-expand"}

        ]
      }
    },
    watch: {
      expanded: function(val){
        val.forEach(subject => {
          if(subject.groups.basename !== undefined){
            loadData(
              urls.getEduUrlWithParameters(
                subject.groups.basename,
                subject.groups.action,
                subject.groups.params
              ),
              (data) => {
                subject.groups = data;
                // console.log(subject.groups)
                subject.groups.forEach(group => {
                  group.teachers.forEach(teacher => {
                    this.$set(teacher, "fio", "");
                  })
                  generateListUidToName(group.teachers, "teacher", "fio");
                });
                //
              }
            )
          }
        });
      },

      pagination: {
        handler () {
          this.getItems()
        },
        deep: true
      }
    },


    created() {
      // this.$store.commit('leftmenu/activeRouterChange', this.$route.name);
      // this.$store.commit('leftmenu/selectInstance', getInstNameByRouterName(names.DETAIL));
      this.$store.commit("mainpage/changeTitle", "Список ведомых дисциплин");
    },
    methods: {

      addItem(){
        this.closedForm()
        this.getItems();
      },

      getItems () {
        this.loading = true
        let url = new URL(this.URL_LIST)
        let params = {};

        if(this.search.length > 0)
          params.name = this.search;

        if(this.pagination.page !== undefined) {
          params.page = this.pagination.page;
          params.size = this.pagination.itemsPerPage;
        }
        else{
          params.page = "1";
          params.size = "10";
        }

        url.search = new URLSearchParams(params).toString();

        let sys = true
        makeGetRequest(url).then(resp => {
          if (!resp.ok) {
            sys = false
          }
          return resp.json()
        }) .then(json => {
          this.items = json.results
          this.total = json.count
          this.loading = false
        })
      }
    }
  }


</script>
<style scoped>

</style>
