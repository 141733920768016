<template>
  <scroll-center-dialog
    v-model="inValue" @close="inValue = false" @apply="execute({education_id: item.eios.id})" apply-text="Создать" :disabled="!apply"
    :loading="loading">
    <template v-slot:title>
      <slot name="title">Принудительное создание объектов</slot>
    </template>
    <slot></slot>
    <v-card-text class="text-left subtitle-1">
      Вы уверены, что хотите создать объекты, которые одновременно существуют в системах 1С и ЭИОС?
<!--      Вы уверены что хотите создать все объекты типа "{{childType}}" в системе ЭИОС существующие у объекта {{baseObjectName}} в системе 1С.-->
      <v-alert type="info" outlined>
        Обратите внимание, что создание объекта невозможно, если имеются хотя бы один несвязанный объект в одной из систем. <br/>
        Поэтому перед выполнением:
        <ul>
          <li>Либо, удалите все не связанные объекты</li>
          <li>Либо, свяжите все не связанные объекты</li>
        </ul>
      </v-alert>
      <v-alert type="warning" outlined>
        Отменить это действие не представляется возможным.
        <v-switch
          v-model="apply" label="Я уверен и понимаю"
          :error="hasError('apply')" :error-messages="getError('apply')"
          @change="deleteError('apply')"
        ></v-switch>
      </v-alert>
      <debug-json-component :json="errors" v-if="hasErrors()" color="error" :status="'Ошибка'" hidable hide-at-open></debug-json-component>
    </v-card-text>
  </scroll-center-dialog>
</template>

<script>
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ActionExecutroMixin from "@/modules/administrative/mixins/ActionExecutroMixin";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent.vue";

export default {
  name: "UpdateRelated",
  components: {ScrollCenterDialog, DebugJsonComponent},
  mixins: [FormErrorsMixin, ActionExecutroMixin],
  props: {
    childType: undefined,
    baseObjectName: undefined
  },
  data() {
    return {
      apply: false
    }
  },
  methods: {
    getActionArgs(kwargs){
      return {
        apply: this.apply
      }
    },
    checkHasError(data){
      return !!data && Object.hasOwn(data, "has_error") && data.has_error
    },
    mapRawError(data){
      this.errors.raw = data.log;
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>
