<template>
      <Detail v-if="is_self_route"></Detail>
      <router-view v-else></router-view>
</template>

<script>
  import Detail from "./Detail";
  import LeftMenuChangerMixin from "@/mixins/LeftMenuChangerMixin";
  import names from "@/modules/educationalManagement/routers/names";

  export default {
    name: "EducationManagementBaseStudent",
    mixins: [
      LeftMenuChangerMixin
    ],
    components: {
      Detail
    },
    data: function () {
      return {}
    },
    computed: {
      is_self_route: function() {
        return this.$route.name === names.EDUCATIONAL.STUDENTS.DETAIL
      }
    },
    methods: {
      getLeftMenu(){
        return [
          {title: "Назад", icon: "arrow_back_ios", router: {name: names.EDUCATIONAL.STUDENTS.LIST, params: this.$route.params}},
          {title: "Обучающийся", icon: "person", router: {name: names.EDUCATIONAL.STUDENTS.DETAIL, params: this.$route.params}},
          {title: "Персональный УП", icon: "list_alt", router: {name: names.EDUCATIONAL.STUDENTS.CURRICULUM.DETAIL, params: this.$route.params}},
          {title: "Список УР", icon: "view_list", router: {name: names.EDUCATIONAL.STUDENTS.WORK.LIST, params: this.$route.params}},
          {title: "Итоговый журнал", icon: "format_list_bulleted", router: {name: names.EDUCATIONAL.STUDENTS.FULL_JOURNAL, params: this.$route.params}},
          {title: "Портфолио", icon: "file_present", router: {name: names.EDUCATIONAL.STUDENTS.PORTFOLIO, params: this.$route.params}},
          {title: "Документы об образовании", icon: "cases", router: {name: names.EDUCATIONAL.STUDENTS.EDUDOC, params: this.$route.params}
          }
        ];
      }
    },
    created() {}
  }
</script>

<style scoped>

</style>
