<template>
  <v-container>
    <v-card>
      <v-toolbar
        elevation="0"
        color="style-color-main-gray"
      >
        <v-text-field v-model="search.value"
                      dense
                      class="pa-3"
                      placeholder="Поиск по направлению подготовки, номеру направления и профилю подготовки"
                      @change="searchData"
                      messages="Для поиска нажмите Enter или кнопку поиск"
        ></v-text-field>
        <v-toolbar-items>
          <v-select :items="selectors.linked" hide-details class="pt-1" v-model="pagination.filters.linked" v-if="!isProductionMode"></v-select>
          <v-btn text @click="opened_form = true">
            Создать
            <v-icon>add</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-data-table
        :items="curriculum"
        :items-per-page.sync="pagination.size"
        :loading="pagination.loading"
        :headers="headers"
        no-data-text="Учебные планы не загружены"
        no-results-text="Учебные планы не были найдены"
        :page.sync="pagination.page"
        :server-items-length="pagination.count"
        :footer-props="{'items-per-page-options':[10, 25, 50], 'items-per-page-text': 'Число элементов'}"
      >
        <template v-slot:item="props">
          <tr class="text-left">
            <td>{{ props.index + 1 + (pagination.page-1)*pagination.size }}</td>
            <td>
              <router-link :to="getDetailLink(props.item)">
                {{ props.item.name }}
                <span v-if="checkIsLinked(props.item) && !isProductionMode">[{{props.item.number}}]</span>
              </router-link>
            </td>
            <td class="text-xs-left">
                {{ props.item.year }}
            </td>
            <td>{{ props.item.form }}</td>
            <td>{{ props.item.level }}</td>
            <td>{{ props.item.faculty }}</td>
            <td>
              <v-icon v-if="props.item.is_draft">done</v-icon>
              <v-icon v-else>clear</v-icon>
            </td>
            <td>
              <v-icon v-if="props.item.university_identifier">done</v-icon>
              <v-icon v-else>clear</v-icon>
            </td>
            <td class="text-center" v-bind="selected">
              <tooltip-btn v-if="!props.item.is_system && !checkIsLinked(props.item) && !isProductionMode" tooltip="Связать" icon="link"
                           bottom @click="openDialog(dialogs.link, props.item)"></tooltip-btn>
<!--              <v-tooltip v-if="!props.item.is_system && checkIsOnlyEIOS(props.item)" bottom>-->
<!--                <template v-slot:activator="{ on }">-->
<!--                  <v-btn icon medium v-on="on" @click="openLink(props.item)">-->
<!--                    <v-icon>link</v-icon>-->
<!--                  </v-btn>-->
<!--                </template>-->
<!--                <span>Связать</span>-->
<!--              </v-tooltip>-->
              <tooltip-btn v-if="!props.item.is_system && checkIsLinked(props.item) && !isProductionMode" tooltip="Отвязать"
                           icon="link_off" bottom @click="openDialog(dialogs.clear, props.item)"></tooltip-btn>
<!--              <v-tooltip v-if="!props.item.is_system && checkIsOnlyEIOS(props.item)" bottom>-->
<!--                <template v-slot:activator="{ on }">-->
<!--                  <v-btn icon medium v-on="on" @click="openLink(props.item)">-->
<!--                    <v-icon>link_off</v-icon>-->
<!--                  </v-btn>-->
<!--                </template>-->
<!--                <span>Отвязать</span>-->
<!--              </v-tooltip>-->
              <v-tooltip v-if="!props.item.is_system" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    @click="updateItem(props.item)"
                    medium
                  >
                    <v-icon>
                      edit
                    </v-icon>
                  </v-btn>
                </template>
                <span>Редактировать</span>
              </v-tooltip>
              <v-tooltip bottom v-if="!props.item.is_system && is_dean">
                <template v-slot:activator="{ on }" >
                  <v-btn
                    icon
                    v-if="props.item.delete_available.available"
                    v-on="on"
                    @click="openConfirmDelete(props.item)"
                    medium
                  >
                    <v-icon>
                      delete
                    </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    v-else
                    v-on="on"
                    color="red"
                    medium
                  >
                    <v-icon>
                      delete
                    </v-icon>
                  </v-btn>
                </template>
                <span v-if="props.item.delete_available.available">Удалить</span>
                <span v-else>{{props.item.delete_available.reason}}</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
      <Form @addItem="addItem"
            @ended="closedForm"
            @refreshItem="refreshItem"
            v-bind:opened_form="opened_form"
            v-bind="selected"
            v-bind:info="$route.params.id"
            v-if="opened_form"
      >
      </Form>
      <v-dialog persistent v-model="opened_confirm" width="500">
        <v-card v-if="deletable">
          <v-card-title>Подтвердите удаление</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            Вы уверены, что хотите удалить?<br>
          </v-card-text>
          <v-card-actions>
            <v-flex>
              <v-btn
                @click="deleteItem(deletable.id)"
                color="warning"
                text
              >
                Удалить
              </v-btn>
              <v-btn
                @click="closeConfirmDelete"
                text
              >Отмена
              </v-btn>
            </v-flex>
          </v-card-actions>
        </v-card>
      </v-dialog>
<!--      <link-dialog-->
<!--        v-model="dialogs.link.open"-->
<!--        :item="dialogs.link.item"-->
<!--        :selector_url="curriculum_selector_url"-->
<!--        :loading="dialogs.link.loading"-->
<!--        @apply="linkCurriculum"-->
<!--      >-->
<!--        <template v-slot:title>Связывание учебного плана</template>-->
<!--      </link-dialog>-->
      <link-action
        v-if="!isProductionMode"
        v-model="dialogs.link.open"
        :item="dialogs.link.item"
        :selector-url="dialogs.link.selector_url"
        :action-url="dialogs.link.action_url"
        @reload="refreshItem"
        restore-default
      >
      </link-action>
      <action-for-tree
        v-if="!isProductionMode"
        v-model="dialogs.clear.open"
        level="curriculum"
        :action-url="dialogs.clear.action_url"
        action-name="очистка информации о связи в ЭИОС и синхронизаторе с 1С Университет по дереву объектов"
        :item="dialogs.clear.item"
        @reload="refreshItem"
        applyable
      >
        <template v-slot:title>Очистка информации о связях в дереве</template>
      </action-for-tree>
    </v-card>
<!--    <DebugJsonComponent :json="curriculum"></DebugJsonComponent>-->
  </v-container>
</template>

<script>

  import urls from "../../urls";
  import {loadData, sendPostRequest, displayDate, isProductionMode} from "@/helper";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import names from "@/modules/cathedra/routers/names";
  import editor_name from "@/modules/curriculumEditor/routers/names";
  import UserDataMixin from "@/mixins/UserDataMixin";
  import Form from "@/modules/curriculumEditor/pages/curriculum/Form.vue"
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent"
  import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";
  import LinkAction from "@/modules/administrative/dialogs/LinkAction.vue";
  import selectors from "@/urls/selectors";
  import TooltipBtn from "@/modules/administrative/components/TooltipBtn.vue";
  import ActionForTree from "@/modules/curriculumEditor/pages/difference/dialogs/ActionForTree.vue";

  export default {
    name: "CurriculumList",
    mixins:[
      PaginatedDataMapperMixin,
      UserDataMixin
    ],
    components:{
      TooltipBtn,
      LinkAction,
      // DebugJsonComponent,
      Form,
      ActionForTree
      // ScrollCenterDialog
    },
    props: {},
    computed: {
      is_dean(){
        return  this.$store.getters["urls/getAllowed"]("education", "editor|curriculum|curriculum-detail", "destroy")
        // this.$store.getters['urls/getAllowed']('education','dean|relations|faculty-list', 'list')
        // &&

      },
      curriculum_selector_url(){
        return selectors.SELECTORS.UNIVERSITY.CURRICULUM()
      },
      isProductionMode(){
        return isProductionMode()
      }
    },
    data(){
      return {
        search: {
          parameter: "info"
        },
        title: "Список учебных планов",
        curriculum: [],
        dialogs: {
          link: {
            open: false,
            item: undefined,
            selector_url: selectors.SELECTORS.UNIVERSITY.CURRICULUM(),
            action_url: urls.NAMES.EDITOR.UNIVERSITY.LINK.CURRICULUM
          },
          clear: {
            open: false,
            item: undefined,
            action_url: urls.NAMES.EDITOR.UNIVERSITY.CLEAR.CURRICULUM
          }
        },
        selectors: {
          linked: [
            {value: null, text: "Все"},
            {value: false, text: "Не связанные"},
            {value: true, text: "Связанные"}
          ]
        },
        pagination: {
          filters: {
            linked: null
          }
        },
        URL_LIST: urls.NAMES.EDITOR.CURRICULUM.LIST(),
        URL_DELETE: urls.NAMES.EDITOR.CURRICULUM.DESTROY(),
          headers: [
            {text: "№", sortable: false, align: "left"},
            {text: "Название", sortable: false, align: "left",weight:"30%"},
            {text: "Год", sortable: false, align: "left",weight:"5%"},
            {text: "Форма", sortable: false, align: "left",weight:"10%"},
            {text: "Уровень",sortable: false, align: "left",weight:"10%"},
            {text: "Факультет", sortable: false,  align: "left" },
            {text: "Черновик", sortable: false,  align: "left" },
            {text: "Связан", sortable: false,  align: "left" },
            // {text: "Синхронизация", sortable: false,  align: 'left' },
            {text: "Действия", sortable: false,  align: "center", width: "10%"}
          ]
      }
    },
    methods: {
      displayDate: displayDate,
      checkIsLinked(item){
        return item.university_identifier !== undefined && item.university_identifier !== null
      },
      getDetailLink(item){
        return {
          name: editor_name.CURRICULUM.DETAIL,
          params: {
            curriculum_id: item.id,
            size: this.pagination.size,
            page: this.pagination.page
          }}
      },
      getItems(){
        this.closedForm()
        this.loadCurriculum();
      },
      closedForm() {
        this.selected = {};
        this.opened_form = false
      },
      addItem(){
        this.closedForm()
        this.loadCurriculum();
      },
      refreshItem(){
        this.closedForm()
        this.loadCurriculum(this.getCurrentLoadUrl());
      },
      openDialog(dialog, item){
        dialog.item = item
        dialog.open = true
      },
      logData(data){
        console.log(data);
      },
      loadData(url=undefined) {
        this.loadCurriculum(url);
      },
      groupedMethods(data, url){
        this.mapPagination(data);
        this.mapResults(data, "curriculum");
        this.setCurrentLoadUrl(url)
        this.setNotLoading()
      },
      loadCurriculum: function (url=undefined) {
        this.setLoading()
        if(url === undefined) {
          url = urls.NAMES.EDITOR.CURRICULUM.LIST();
          if(this.$route.params.size || this.$route.params.page)
          {
            url = new URL(url)
            url.searchParams.set("page", this.$route.params.page)
            url.searchParams.set("size", this.$route.params.size)
            url.searchParams.set("linked", this.$route.params.linked)
            this.pagination.size = this.$route.params.size
            this.pagination.page = this.$route.params.page
            this.pagination.filters.linked = this.$route.params.linked
          }
        }
        loadData(url, (data) => {this.groupedMethods(data, url)},
          (err) => {
            if(err.detail==="Неправильная страница"){
              url = urls.NAMES.EDITOR.CURRICULUM.LIST();
              this.pagination.size = 10
              this.pagination.page = 1
              loadData(url, (data) => {this.groupedMethods(data, url)})
            }
          }
        )
      }
    },
    created() {
      this.$store.commit("mainpage/changeTitle", this.title);
      this.loadCurriculum();
    }
  }
</script>

<style scoped>

</style>
