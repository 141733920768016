<template>
  <v-list single-line>
    <template v-for="(item, i) in instances.slice().reverse()">
      <span :key="i">
        <v-divider class=""></v-divider>
        <InstanceLeftMenuAction
          :currentInstance="current_instance"
          :item="item"
          v-if="item.router"
        />
      </span>
    </template>
  </v-list>
</template>

<script>
import InstanceLeftMenuAction from "@/modules/menus/left_menu/InstanceLeftMenuAction";

export default {
  name: "InstanceLeftMenu",
  components: {
    InstanceLeftMenuAction
  },
  props: {
    instances: {
      required: true,
      type: Array
    },
    current_instance: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
