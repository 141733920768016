<template>
  <div class="d-flex flex-row">
    <v-icon v-show="item.difference[diff_name]" color="green" class="mr-3">done</v-icon>
    <v-icon v-show="!item.difference[diff_name]"  class="mr-3">clear</v-icon>
    <span v-if="item.eios">{{ getFrom(item.eios, eios_name) }}</span><span v-else>Не найден</span>
    <span class="px-2">-</span>
    <span v-if="item.one">{{ getFrom(item.one, one_name) }}</span><span v-else>Не найден</span>
<!--    <v-spacer></v-spacer>-->

  </div>
</template>

<script>
export default {
  name: "DifferenceColumn",
  props: {
    item: {
      required: true
    },
    eiosName: {
      type: String,
      required: true
    },
    oneName: {
      type: String,
      required: false
    },
    diffName: {
      type: String,
      required: false
    }
  },
  data() {
    return {}
  },
  computed: {
    eios_name() {
      return this.eiosName
    },
    one_name() {
      return this.oneName ? this.oneName : this.eiosName
    },
    diff_name() {
      return this.diffName ? this.diffName : this.eiosName
    }
  },
  methods: {
    getFrom(instance, field){
      if(instance === null || instance === undefined)
        return ""
      let value = instance
      for (field of field.split(".")) {
        value = value[field]
      }
      return value
    }
  }
}
</script>

<style scoped>

</style>
