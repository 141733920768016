<template>
  <action-for-object
    :item="item"
    :action-name="actionName"
    :action-url="actionUrl"
    :object-name="inObjectName"
    :type-name="typeName"
    :applyable="applyable"
    :object-id="objectId"
    :one="one"
    v-model="inValue"
    @reload="$emit('reload')"
  ></action-for-object>
</template>

<script>
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ActionExecutroMixin from "@/modules/administrative/mixins/ActionExecutroMixin";
import ActionForObject from "@/modules/administrative/dialogs/ActionForObject.vue";

const LEVEL = {
  CURRICULUM: "curriculum",
  SUBJECT: "subject",
  SEMESTER: "semester",
  KIND: "kind"
}

export default {
  name: "CurriculumActionForObject",
  components: {ActionForObject},
  mixins: [],
  props: {
    value: {
      required: true
    },
    level: {
      required: true
    },
    actionName: {
      required: true
    },
    item: {
      required: true
    },
    objectName: {
      required: false,
      type: String,
      default: undefined
    },
    actionUrl: {
      required: true
    },
    applyable: {
      default: false,
      type: Boolean
    },
    one: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      inValue: undefined,
      apply: false,
      LEVEL: LEVEL
    }
  },
  watch: {
    inValue(nV, oV) {
      this.$emit("input", this.inValue)
    },
    value() {
      this.inValue = this.value
    }
  },
  computed: {
    inEIOSObjectName(){
      if(this.objectName !== undefined)
        return this.title
      if (this.level === LEVEL.CURRICULUM) {
        return this.getEIOSValue(this.item).name
      } else if (this.level === LEVEL.SUBJECT) {
        return this.getEIOSValue(this.item).subject
      } else if (this.level === LEVEL.SEMESTER) {
        return `${(this.getEIOSValue(this.item).course - 1) * 2 + (this.getEIOSValue(this.item).semester === "осенний" ? 1: 2)} семестр (${this.getEIOSValue(this.item).course}:${this.getEIOSValue(this.item).semester})`
      } else {
        return this.getEIOSValue(this.item).name
      }
    },
    inONEObjectName(){
      if(this.objectName !== undefined)
        return this.title
      if (this.level === LEVEL.CURRICULUM) {
        return this.getONEValue(this.item).name
      } else if (this.level === LEVEL.SUBJECT) {
        return this.getONEValue(this.item).subject.name
      } else if (this.level === LEVEL.SEMESTER) {
        return `${this.getONEValue(this.item).semester}`
      } else {
        return this.getEIOSValue(this.item).name
      }
    },
    inObjectName(){
      return this.one ? this.inONEObjectName: this.inEIOSObjectName
    },
    objectId(){
      return this.one ? this.getONEValue(this.item).guid: this.getEIOSValue(this.item).id
    },
    typeName(){
      if (this.level === LEVEL.CURRICULUM) {
        return "Учебный план"
      } else if (this.level === LEVEL.SUBJECT) {
        return "Предмет учебного плана"
      } else if (this.level === LEVEL.SEMESTER) {
        return "Семестр учебного плана"
      } else {
        return "Вид занятий учебного плана"
      }
    }
  },
  methods: {
    getActionArgs(kwargs){
      if (this.applyable)
        return {
          apply: this.apply
        }
      else
        return {}
    },
    getEIOSValue(item){
      if(item !== undefined && item !== null)
        return Object.hasOwn(item, "eios") && item.eios !== undefined && item.eios !== null ? item.eios : item
      return {}
    },
    getONEValue(item){
      if(item !== undefined && item !== null)
        return Object.hasOwn(item, "one") && item.one !== undefined && item.one !== null ? item.one : item
      return {}
    },
    getEducationId(){
      return this.objectId
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>
