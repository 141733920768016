<template>
  <v-container>
    <v-card flat>
      <v-toolbar elevation="0" color="style-color-main-gray">
        <v-toolbar-title>Государственные стандарты:</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn @click="opened_form = true" color="secondary" text>
            <v-icon>add</v-icon>
            Добавить
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                text
                v-on="on"
                @click="getItems()"
                class="mr-2"
                medium
              >
                <v-icon>
                  update
                </v-icon>
              </v-btn>
            </template>
            <span>Обновить данные</span>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        no-data-text="Нет данных"
        :loading="pagination.loading"
        :page.sync="pagination.page"
        :items-per-page.sync="pagination.size"
        :server-items-length="pagination.count"
        :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
      >
        <template v-slot:item="props">
          <tr class="justify-center text-left"  >
            <td class="justify-center">
                {{props.item.document.name}}
            </td>
            <td class="justify-center">
              {{props.item.year}}
            </td>
            <td class="justify-center">
              <v-btn @click="loadFileWork(props.item.document.file)" icon><v-icon >get_app</v-icon></v-btn>
            </td>
            <td class="text-right justify-center " v-bind="selected">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    @click="updateItem(props.item)"
                    medium
                  >
                    <v-icon>
                      edit
                    </v-icon>
                  </v-btn>
                </template>
                <span>Редактировать</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    @click="openConfirmDelete(props.item)"
                    medium
                  >
                    <v-icon>
                      delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Удалить</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
        <template slot="no-data">
          <v-card-text>Данные не загружены</v-card-text>
        </template>
      </v-data-table>

      <FormStateStandards
        @addItem="addItem"
        @ended="closedForm"
        @refreshItem="refreshItem"
        @input="closedForm"
        :opened_form="opened_form"
        v-bind="selected"
        v-if="opened_form"
      >
      </FormStateStandards>

      <v-dialog persistent v-model="opened_confirm" width="500">
        <v-card v-if="deletable">
          <v-card-title>Подтвердите удаление</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            Вы уверены, что хотите удалить?<br>
          </v-card-text>
          <v-card-actions>
            <v-flex>
              <v-btn @click="deleteItem(deletable.id)" color="warning" text>
                Удалить
              </v-btn>
              <v-btn @click="closeConfirmDelete" text>
                Отмена
              </v-btn>
            </v-flex>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>


<script>
  import urls from "@/urls/management";
  import UserDataMixin from "@/mixins/UserDataMixin"
  import {loadData, makeDeleteRequest} from "@/helper";
  import {loadFile} from "@/helper/fileLoader";
  import moment from "moment"
  import FormStateStandards from "@/modules/educationalManagement/forms/FormStateStandards.vue"
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import FormTrainingProfile from "@/modules/educationalManagement/forms/FormTrainingProfile";

  export default {
    name: "StateStandards",
    mixins: [ UserDataMixin, PaginatedDataMapperMixin],
    components:{FormTrainingProfile, FormStateStandards},

    data () {
      return {
        URL_LIST: urls.EDUCATION.REFERENCE.STATE_STANDARDS.LIST(),
        selectors:{
          status:[]
        },
        lastName: "",
        firstName: "",
        patronymic: "",
        filters: {},
        items: [],
        headers: [
          {text: "Название", sortable: false, align: "justify-center", value: "lastName",width: "70%"},
          {text: "Год", sortable: false, align: "justify-center", value: "firstName",width: "10%"},
          {text: "Файл", sortable: false, align: "justify-center", value: "document",width: "10%"},
          {text: "Действия", sortable: false, align: "right", value: "thesisTopic",width: "10%"}
        ]
      }
    },
    created(){
      this.$store.commit("mainpage/changeTitle", "Заявки в архиве");
      this.getItems()
    },
    methods: {
      loadData(url = undefined) {
        this.getItems(url)
      },
      getItems (url=undefined) {
        this.setLoading();
        if(url === undefined) {
          url = this.URL_LIST,
            this.setCurrentLoadUrl(url)
        }
        loadData(
          url,
          (data) => {
            this.mapResults(data,"items")
            this.setNotLoading()
          }
        )
      },
      loadFileWork(url){
        loadFile(url)
      },
      displayDate: function (date, minutes = false) {
        let format = "DD.MM.YYYY"
        if (minutes)
          format += " HH:mm"
        return moment(date).format(format)
      },
      addItem(){
        this.closedForm()
        this.loadData();
      },
      refreshItem(){
        this.closedForm()
        this.loadData();
      },
      closedForm: function () {
        this.selected = {};
        this.opened_form = false
      },
      deleteItem(id) {
        let url = urls.EDUCATION.REFERENCE.STATE_STANDARDS.DELETE(id)
        makeDeleteRequest(url).then(resp => {
          if (resp.ok) {
            this.closeConfirmDelete()
            this.getItems()
          }
        })
      }
    }
  }

</script>
<style scoped>

</style>
