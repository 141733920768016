export default {
  watch: {
    page_title: function (val) {
      this.setMainTitle()
    }
  },
  computed: {

  },
  methods: {
    setPageTitleWithObject(type, {uid, id}, separator=" ", short=null, finalizer=()=>{}){
      this.$store.dispatch(
        "stringvalue/loadStringValue",
        {
          type: type,
          uid: uid,
          id: id,
          short: short,
          finalizer: (value) => {
            this.page_title += `${separator}${value}`
            finalizer(value)
          }
        })
    },
    setMainTitle(){
      this.$store.commit("mainpage/changeTitle", this.page_title);
    }
  },
  created() {
    this.setMainTitle();
  }

}
