<template>
  <v-container>
    <v-card >
      <v-toolbar
        class="mb-2"
        color="style-color-main-gray"
        flat
      >
        <template v-if="$vuetify.breakpoint.mdAndUp" >
          <v-btn @click="opened_form=true" text ><v-icon>add</v-icon> Создать</v-btn>
          <v-text-field class="pa-6"
                        dense
                        placeholder="Поиск"
                        v-model="search.value"
                        @keypress.enter="searchData"
                        messages="Для поиска нажмите Enter или кнопку поиск"
          ></v-text-field>
          <v-spacer></v-spacer>

        </template>

        <template v-slot:extension v-if='$vuetify.breakpoint.smAndDown'>
          <v-col>
            <v-btn @click="opened_form=true" text ><v-icon>add</v-icon> Создать</v-btn>
            <v-text-field
              class="pt-6"
              dense
              placeholder="Поиск"
              v-model="search.value"
              @keypress.enter="searchData"
              messages="Для поиска нажмите Enter или кнопку поиск"
            ></v-text-field>

          </v-col>
        </template>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :loading="pagination.loading"
        :page.sync="pagination.page"
        :items-per-page.sync="pagination.size"
        :server-items-length="pagination.count"
        :footer-props="{'items-per-page-options':[10,25,50]}"
        :items="items"
      >
        <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
        <template v-slot:item.option="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="updateItem(item)"
                class="mr-2"
                medium
              >
                <v-icon>
                  edit
                </v-icon>
              </v-btn>
            </template>
            <span>Редактировать</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="saveFile(item)"
                medium
                class="mr-2"
              >
                <v-icon>
                  get_app
                </v-icon>
              </v-btn>
            </template>
            <span>Загрузить</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="openConfirmDelete(item)"
                medium
              >
                <v-icon>
                  delete
                </v-icon>
              </v-btn>
            </template>
            <span>Удалить</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog persistent v-model="opened_confirm" width="500">
      <v-card v-if="deletable">
        <v-card-title>Подтвердите удаление</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          Вы уверены, что хотите удалить?<br>
        </v-card-text>
        <v-card-actions>
          <v-flex>
            <v-btn
              @click="deleteItem(deletable.id)"
              color="warning"
              text
            >
              Удалить
            </v-btn>
            <v-btn
              @click="closeConfirmDelete"
              text
            >Отмена
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Form @ended="closedForm"
          v-bind="selected"
          v-if="opened_form"
          :opened_form="opened_form"
    >
    </Form>

  </v-container>
</template>

<script>
import selectors from "@/urls/selectors";
import urls from "@/urls/dean";
import UserDataMixin from "@/mixins/UserDataMixin"
import {
  addGetParameters,
  finalizeRequest,
  loadData,
  makeDeleteRequest,
  makeGetRequest,
  makePostRequestFile
} from "@/helper";
import names from "@/modules/dean/routers/names";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import {generateListUidToName} from "@/helper/uidToName";
import Form from "@/modules/dean/pages/certificate/Form"

export default {
  name: "List",

  mixins: [
    TitledPageMixin,
    PaginatedDataMapperMixin,
    UserDataMixin],
  components: {Form },

  data () {
    return {
      search: {
        parameter: "fio"
      },
      status:"Все статусы",
      page_title: "Выданные справки",
      total: 0,
      names:names,
      items: [],
      loading: true,
      pagination: {},
      URL_LIST: urls.DEAN.CERTIFICATE.LIST(this.$route.params.idFaculty),
      headers: [
        {text: "Обучающийся", sortable: false, align: "left", value: "student.fio"},
        {text: "Группа", sortable: false, align: "left", value: "student.group"},
        {text: "Справка", sortable: false, align: "left", value: "type"},
        {text: "Дата выдачи", sortable: false, align: "left", value: "date_issue"},
        {text: "Номер", sortable: false, align: "left", value: "number"},
        {text: "Действия", sortable: false, align: "right", value: "option"}
      ]
    }
  },
  created() {
    this.getItems();
  },
  methods: {
    saveFile(item){
      let url = urls.DEAN.CERTIFICATE.SAVE(this.$route.params.idFaculty, item.id)
      let status = true
      makeGetRequest(
        url
      ) .then(resp => {
        if (!resp.ok) {
           status = false
          alert("Не удалось загрузить файл")
        }
        return resp.blob();
      }) .then(blob => {
          if(status) {
            var a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = "certificate.pdf";
            a.click();
          }
      })
    },
    deleteItem(id) {
      let url = urls.DEAN.CERTIFICATE.DELETE(this.$route.params.idFaculty, id)
      makeDeleteRequest(url).then(resp => {
        if (resp.ok) {
          this.closeConfirmDelete()
          this.getItems();
        }
      })
    },
    loadData(url = undefined) {
      this.getItems(url)
    },
    getItems (url=undefined) {
      this.setLoading();
      if(url === undefined) {
        url = this.URL_LIST
        this.setCurrentLoadUrl(url);
      }
      loadData(
        url,
        (data) => {
          this.mapResults(data, "items")
          this.setNotLoading();
        }
      )
    },
    addItem(){
      this.closedForm()
      this.getItems();
    }

  }
}


</script>

<style scoped>

</style>
