<!--  Компонент не используется-->
<template>
  <div>
    <v-layout justify-center>
      <v-flex>
        <v-card ref="form">
          <v-toolbar color="primary" dark>
            <v-toolbar-title>
              <span > <div>Оформить допуск</div></span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-toolbar-items>
                <v-btn @click="closeDialog" class="pa-0 ma-0" dark text icon>
                  <v-icon>close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar-items>
          </v-toolbar>

            <v-text-field v-model="value.total"
                          class="mr-3 ml-3"
                          :error="hasError('total')"
                          :error-messages="getError('total')"
                          label="Балл"
                          type="number">
            </v-text-field>
          <v-text-field v-model="value.comment"
                        class="mr-3 ml-3"
                        :error="hasError('comment')"
                        :error-messages="getError('comment')"
                        label="Комментарий">
          </v-text-field>
            <v-alert type="error" v-if="hasError('error')">
              {{getErrorArray('error')}}
            </v-alert>
            <v-btn @click="saveAdmission" color="primary">Сохранить</v-btn>

          <v-divider class="mt-5"></v-divider>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>

  import UserDataFormMixin from "../../../mixins/UserDataFormMixin"
  import { loadData, makeGetRequest, makePostRequest} from "../../../helper";
  import urls from "../../teacher/urls";

  export default {
    name: "Admission",
    mixins: [UserDataFormMixin],
    props: ["id"],
    data() {
      return {
        URL_CREATE:new URL(urls.getUrl("teacher|individual|tolerance-close", "close").replace("<id>", this.$route.params.idAdmission)) ,

        value: {
          success: true,
          comment: "",
          total: 0
        }
      }
    },
    methods: {
      saveAdmission(){
        let status = true
        makePostRequest(this.URL_CREATE, this.value)
          .then(resp => {
            if (!resp.ok) {
              status = false;
            }

            return resp.json();
          })
          .then(json => {
            if (status) {
              this.closeDialog()
            } else {
              this.receiveErrors(json);
            }
          })
      },
      closeDialog() {
        this.$emit("ended", "")
      }
    },
    created() {

    }
  }

</script>

<style scoped>

</style>
