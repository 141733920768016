// TODO: Учесть блокировку полей кт в журнале преподавателя

const TABLE_BASE = 1000;
const MIDDLE_BASE = 1000;
const SUBMIDDLE_BASE = 100;
const ADDITIONAL_BASE = 10000;
const EXAM_BASE = 20000;
/**
 * Миксин добавляющий функции навигации по журналу с контрольными точками для журнала преподавателя и административного
 * работника кафедры. Для его использования нужно добавить в соответсвующие поля следующие вызовы:
 * :ref="getMiddleRefName(props.index, index)"
 * :tabindex="getMiddleTabIndex(props.index, index)"
 * @keydown.down.prevent="downMiddle(props.index, index)"
 * @keydown.up.prevent="upMiddle(props.index, index)"
 * @keydown.right.prevent="rightMiddle(props.index, index, props.item.medium_control.length)"
 * @keydown.left.prevent="leftMiddle(props.index, index, props.item.can_change_exam)"
 * В зависимости от типа поля, меняется наименование функций и список их аргументов, но не сильно.
 */
export default {
  data() {
    return {}
  },
  computed: {
    rows_length() {
      return 0
    }
  },
  methods: {
    /**
     * Функция формирования индекса табуляции поля кт
     * @param row текущая строка
     * @param col текущий столбец
     */
    getMiddleTabIndex(row, col){
      return (col + 1) * MIDDLE_BASE + row
    },
    /**
     * Функция формирования индекса табуляции доп кт
     * @param row текущая строка
     * @param col текущий столбец
     * @param sub текущая доп кт
     */
    getSubMiddleTabIndex(row, col, sub){
      return (col + 1) * MIDDLE_BASE + (sub + 1) * SUBMIDDLE_BASE + row
    },
    /**
     * Функция формирования индекса табуляции доп опроса
     * @param row текущая строка
     */
    getAdditionalTabIndex(row){
      return ADDITIONAL_BASE + row
    },
    /**
     * Функция формирования индекса табуляции экзамена
     * @param row текущая строка
     */
    getExamTabIndex(row){
      return EXAM_BASE + row
    },
    /**
     * Функция формирования имени поля кт
     * @param row текущая строка
     * @param col текущий столбец
     */
    getMiddleRefName(row, col){
      return `${this.getMiddleTabIndex(row, col)}_middle`
    },
    /**
     * Функция формирования имени поля доп кт
     * @param row текущая строка
     * @param col текущий столбец
     * @param sub текущая доп кт
     */
    getSubMiddleRefName(row, col, sub){
      return `${this.getSubMiddleTabIndex(row, col, sub)}_submiddle`
    },
    /**
     * Функция формирования имени поля доп опроса
     * @param row текущая строка
     */
    getAdditionalRefName(row){
      return `${this.getAdditionalTabIndex(row)}_additional`
    },
    /**
     * Функция формирования имени поля экзамена
     * @param row текущая строка
     */
    getExamRefName(row){
      return `${this.getExamTabIndex(row)}_exam`
    },
    /**
     * Функция получения следующего индекса
     * @param current текущий индекс
     * @param max максимальный
     */
    getNextIndex(current, max){
      return current + 1 === max ? 0: current + 1;
    },
    /**
     * Функция получения предыдущего индекса
     * @param current текущий индекс
     * @param max максимальный
     */
    getPrevIndex(current, max){
      return current - 1 === -1 ? max - 1: current - 1;
    },
    /**
     * Функция получения индекса нижнего элемента
     * @param row текущая строка
     */
    getDownRowIndex(row){
      return this.getNextIndex(row, this.rows_length);
    },
    /**
     * Функция получения индекса верзнего элемента
     * @param row текущая строка
     */
    getUpRowIndex(row){
      return this.getPrevIndex(row, this.rows_length);
    },
    /**
     * Функция получения поля кт из $refs
     * @param row текущая строка
     * @param col текущий столбец
     */
    getMiddleRef(row, col){
      if (this.$refs[this.getMiddleRefName(row, col)]){
        return this.$refs[this.getMiddleRefName(row, col)][0];
      } else {
        return undefined;
      }
    },
    /**
     * Функция получения поля дополнительной кт из $refs
     * @param row текущая строка
     * @param col текущий столбец
     * @param sub текущая доп кт
     */
    getSubMiddleRef(row, col, sub){
      if (this.$refs[this.getSubMiddleRefName(row, col, sub)][0]){
        return this.$refs[this.getSubMiddleRefName(row, col, sub)][0];
      } else {
        return undefined;
      }
    },
    /**
     * Функция получения поля доп опроса из $refs
     * @param row текущая строка
     */
    getAdditionalRef(row){
      return this.$refs[this.getAdditionalRefName(row)]
    },
    /**
     * Функция получения поля экзамена из $refs
     * @param row текущая строка
     */
    getExamRef(row){
      return this.$refs[this.getExamRefName(row)]
    },
    /**
     * Функция проверки возможности проставить фокус для поля кт по номеру строки и номеру столбца
     * @param row текущая строка
     * @param col текущий столбец
     */
    focusMiddleInput(row, col){
      this.getMiddleRef(row, col).focus();
    },
    /**
     * Функция проверки возможности проставить фокус для поля доп кт по номеру строки, номеру столбца, и номеру доп кт
     * @param row текущая строка
     * @param col текущий столбец
     * @param sub текущая доп кт
     */
    focusSubMiddleInput(row, col, sub){
      this.getSubMiddleRef(row, col, sub).focus();
    },
    /**
     * Функция проверки возможности проставить фокус для поля доп опроса по номеру строки
     * @param row текущая строка
     */
    focusAdditionalInput(row){
      this.getAdditionalRef(row).focus();
    },
    /**
     * Функция проверки возможности проставить фокус для поля экзамена по номеру строки
     * @param row текущая строка
     */
    focusExamInput(row){
      this.getExamRef(row).focus();
    },
    /// CAN FOCUS
    /**
     * Функция проставления фокуса поля кт по номеру строки и номеру столбца
     * @param row текущая строка
     * @param col текущий столбец
     */
    canFocusMiddleInput(row, col){
      if(this.$refs[this.getMiddleRefName(row, col)] !== undefined){
        return !this.getMiddleRef(row, col).isDisabled
      } else {
        return false
      }
    },
    /**
     * Функция проставления фокуса поля доп кт по номеру строки, номеру столбца, и номеру доп кт
     * @param row текущая строка
     * @param col текущий столбец
     * @param sub текущая доп кт
     */
    canFocusSubMiddleInput(row, col, sub){
      if(this.$refs[this.getSubMiddleRefName(row, col, sub)] !== undefined){
        return !this.getSubMiddleRef(row, col, sub).isDisabled
      } else {
        return false
      }
    },
    /**
     * Функция проставления фокуса поля доп опроса по номеру строки
     * @param row текущая строка
     */
    canFocusAdditionalInput(row){
      if(this.getAdditionalRef(row) !== undefined){
        return !this.getAdditionalRef(row).isDisabled
      } else {
        return false
      }
    },
    /**
     * Функция проставления фокуса поля экзамена по номеру строки
     * @param row текущая строка
     */
    canFocusExamInput(row){
      if(this.getExamRef(row) !== undefined){
        return !this.getExamRef(row).isDisabled
      } else {
        return false
      }
    },
    //// MIDDLE WITH SUB
    /**
     * Функция перемещения на нижнюю строку для ситуации с подконтрольными точками
     * (если есть, то перемещаемся в них, а не сразу в кт)
     * @param row текущая строчка
     * @param col текущий столбец
     * @param sub_middle_length длинаа подконтрольных точек
     */
    downMiddleWithSub(row, col, sub_middle_length){
      if(sub_middle_length > 0){
        this.focusSubMiddleInput(row, col, 0);
      }else{
        this.downMiddle(row, col);
      }
    },
    /**
     * Функция перемещения на верхнюю строку для ситуации с подконтрольными точками
     * (если есть, то перемещаемся в них, а не сразу в кт)
     * @param row текущая строчка
     * @param col текущий столбец
     * @param sub_middle_length длинаа подконтрольных точек
     */
    upMiddleWithSub(row, col, sub_middle_length){
      if(sub_middle_length > 0){
        this.focusSubMiddleInput(this.getUpRowIndex(row), col, 0);
      }else{
        this.upMiddle(row, col);
      }
    },
    //// MIDDLE
    /**
     * Функция перемещения фокуса на нижнее поле в таблице контрольных точек
     * @param row текущая строка
     * @param col текущий столбец
     */
    downMiddle(row, col){
      row = this.getDownRowIndex(row)
      if(this.canFocusMiddleInput(row, col))
        this.focusMiddleInput(row, col);
      else
        this.downMiddle(row, col);
    },
    /**
     * Функция перемещения фокуса на верхнее поле в таблице кт
     * @param row текущая строка
     * @param col текущий столбец
     */
    upMiddle(row, col){
      row = this.getUpRowIndex(row)
      if(this.canFocusMiddleInput(row, col))
        this.focusMiddleInput(row, col);
      else
        this.upMiddle(row, col);
    },
    /**
     * Функция перемещения фокуса на правое поле в таблице кт, если текущее поле крайнее
     * фокус перемещается на поле доп опроса
     * @param row текущая строка
     * @param col текущий столбец
     * @param middle_length длинна списка кт
     */
    rightMiddle(row, col, middle_length){
      col += 1
      if(col < middle_length){
        if(this.canFocusMiddleInput(row, col))
          this.focusMiddleInput(row, col);
        else
          this.rightMiddle(row, col, middle_length);
      }else{
        if(this.canFocusAdditionalInput(row))
          this.focusAdditionalInput(row);
        else
          this.rightMiddle(row, -1, middle_length);
      }
    },
    /**
     * Функция перемещения фокуса на левое поле в таблице кт, если текущее поле крайнее
     * и открыто поле экзамена, то фокус перемещается на поле экзамена
     * и закрыто поле экхамена, то фокус перемещается на поле доп.опроса
     * @param row текущая строка
     * @param col текущий столбец
     * @param middle_length
     */
    leftMiddle(row, col, middle_length){
      if(col > 0) {
        col = this.getPrevIndex(col, middle_length)
        if(this.canFocusMiddleInput(row, col))
          this.focusMiddleInput(row, col);
        else
          this.leftMiddle(row, col);
      }else {
        if (this.canFocusExamInput(row)) {
          this.focusExamInput(row);
        } else if(this.canFocusAdditionalInput(row)) {
          this.focusAdditionalInput(row);
        } else {
          this.leftMiddle(row, middle_length, middle_length)
        }
      }
    },
    //// SUBMIDDLE
    /**
     * Функция пермещения фокуса на нижнее поле кт, при переходе из дополнительной кт.
     * @param row текущая строка
     * @param col текущий столбец
     */
    downSubMiddle(row, col){
      this.downMiddle(this.getDownRowIndex(row), col);
    },
    /**
     * Функция пермещения фокуса на верхнее поле кт, при переходе из дополнительной кт.
     * @param row текущая строка
     * @param col текущий столбец
     */
    upSubMiddle(row, col){
      this.upMiddle(row, col);
    },
    /**
     * Функция перемещения фокуса на правое поле дополнительной кт, перемещение зациклено по кругу.
     * @param row текущая строка
     * @param col текущий столбец
     * @param sub текущая доп кт
     * @param sub_middle_length длинна массива доп кт
     */
    rightSubMiddle(row, col, sub, sub_middle_length){
      sub = this.getNextIndex(sub, sub_middle_length);
      this.focusSubMiddleInput(row, col, sub);
    },
    /**
     * Функция перемещения фокуса на левое поле дополнительной кт, перемещение зациклено по кругу.
     * @param row текущая строка
     * @param col текущий столбец
     * @param sub текущая доп кт
     * @param sub_middle_length длинна массива доп кт
     */
    leftSubMiddle(row, col, sub, sub_middle_length){
      sub = this.getPrevIndex(sub, sub_middle_length);
      this.focusSubMiddleInput(row, col, sub);
    },
    //// ADDITIONAL
    /**
     * Функция перемещения фокуса на нижнее поле доп. опроса
     * @param row текущая строка
     */
    downAdditional(row){
      row = this.getDownRowIndex(row)
      if(this.canFocusAdditionalInput(row))
        this.focusAdditionalInput(row);
      else
        this.downAdditional(row);
    },
    /**
     * Функция перемещения фокуса на верхнее поле доп. опроса
     * @param row текущая строка
     */
    upAdditional(row){
      row = this.getUpRowIndex(row)
      if(this.canFocusAdditionalInput(row))
        this.focusAdditionalInput(row);
      else
        this.upAdditional(row);
    },
    /**
     * Функция перемещения фокуса на поле срава,
     * если открыто проставление экзамена, то перемещаемся на него
     * если закрыто проставление экзамена, то перемещаемся на начальную кт
     * @param row текущая строка
     * @param middles_length длинаа масива кт
     */
    rightAdditional(row, middles_length){
      if(this.canFocusExamInput(row)){
        this.focusExamInput(row);
      } else {
        this.rightMiddle(row, -1, middles_length)
      }
    },
    /**
     * Функция перемещения фокуса на поле слева, всегда последняя кт.
     * @param row текущая строка
     * @param middles_length длинаа масива кт
     */
    leftAdditional(row, middles_length){
      this.leftMiddle(row, middles_length, middles_length);
    },
    //// EXAM
    /**
     * Функция перемещения фокуса на нижнее поле экзамена
     * @param row текущая строка
     */
    downExam(row){
      row = this.getDownRowIndex(row)
      if(this.canFocusExamInput(row))
        this.focusExamInput(row);
      else
        this.downExam(row);
    },
    /**
     * Функция перемещения фокуса на верхнее поле экзамена
     * @param row текущая строка
     */
    upExam(row){
      row = this.getUpRowIndex(row)
      if(this.canFocusExamInput(row))
        this.focusExamInput(row);
      else
        this.upExam(row);
    },
    /**
     * Функция перемещения фокуса на поле справа от экзамена, всегда первая доступная для записи кт.
     * @param row текущая строка
     * @param middles_length длинаа масива кт
     */
    rightExam(row, middles_length){
      this.rightMiddle(row, -1, middles_length)
    },
    /**
     * Функция перемещения фокуса на поле слева от экзамена, всегда доп опрос.
     * @param row текущая строка
     * @param middles_length длинаа масива кт
     */
    leftExam(row, middles_length){
      if(this.canFocusAdditionalInput(row))
        this.focusAdditionalInput(row);
      else
        this.leftAdditional(row, middles_length)
    },

    //// Full journal

    /**
     * Функция формирования индекса табуляции поля кт
     * @param row текущая строка
     * @param col текущий столбец
     */
    getTableTabIndex(row, col){
      return (col + 1) * TABLE_BASE + row
    },
    /**
     * Функция формирования имени поля кт
     * @param row текущая строка
     * @param col текущий столбец
     */
    getTableRefName(row, col){
      return `${this.getTableTabIndex(row, col)}_table`
    },
    /**
     * Функция получения объекта поля из списка $refs по номеру строки и номеру столбца
     * @param row
     * @param col
     */
    getTableRef(row, col){
      return this.$refs[this.getTableRefName(row, col)][0]
    },
    /**
     * Функция проставления фокуса поля кт по номеру строки и номеру столбца
     * @param row текущая строка
     * @param col текущий столбец
     */
    canFocusTableInput(row, col){
      if(this.$refs[this.getTableRefName(row, col)] !== undefined){
        return !this.getTableRef(row, col).isDisabled;
      } else {
        return false;
      }
    },
    /**
     * Функция проставления фокуса поля кт по номеру строки и номеру столбца
     * @param row текущая строка
     * @param col текущий столбец
     */
    focusTableInput(row, col){
      this.$refs[this.getTableRefName(row, col)][0].focus()
    },
    /**
     * Функция перемещения фокуса на нижнее поле экзамена
     * @param row текущая строка
     * @param col текущая столбец
     * @param row_length количество строк
     */
    downTable(row, col, row_length){
      row = this.getNextIndex(row, row_length);
      if(this.canFocusTableInput(row, col))
        this.focusTableInput(row, col);
      else
        this.downTable(row, col, row_length);
    },
    /**
     * Функция перемещения фокуса на верхнее поле экзамена
     * @param row текущая строка
     * @param col текущая столбец
     * @param row_length количество строк
     */
    upTable(row, col, row_length){
      row = this.getPrevIndex(row, row_length);
      if(this.canFocusTableInput(row, col))
        this.focusTableInput(row, col);
      else
        this.upTable(row, col, row_length);
    },
    /**
     * Функция перемещения фокуса на поле справа от экзамена, всегда первая доступная для записи кт.
     * @param row текущая строка
     * @param col текущая столбец
     * @param col_length максимальный столбец
     */
    rightTable(row, col, col_length){
      col = this.getNextIndex(col, col_length);
      if(this.canFocusTableInput(row, col))
        this.focusTableInput(row, col);
      else
        this.rightTable(row, col, col_length);
    },
    /**
     * Функция перемещения фокуса на поле слева от экзамена, всегда доп опрос.
     * @param row текущая строка
     * @param col текущая столбец
     * @param col_length максимальный столбец
     */
    leftTable(row, col, col_length){
      col = this.getPrevIndex(col, col_length);
      if(this.canFocusTableInput(row, col))
        this.focusTableInput(row, col);
      else
        this.leftTable(row, col, col_length);
    }
  }
}
