<template>
  <v-container >
<!--    <DebugJsonComponent :json="students"></DebugJsonComponent>-->
    <v-toolbar
      elevation="0"
      class="white--text"
      color="style-color-main-gray"
    >
      <v-text-field
        dense
        hide-details
        label="Поиск"
        v-model="search"
      ></v-text-field>
      <v-btn icon><v-icon>search</v-icon></v-btn>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            @click=" loadStudents()"
            class="mr-2"
            medium
          >
            update
          </v-icon>
        </template>
        <span>Обновить данные</span>
      </v-tooltip>

    </v-toolbar>
    <v-card flat>
      <v-data-table
        :headers="students.headers"
        :items="students.data"
        :loading="students.loading"
        :items-per-page="students.data.length"
        :search="search"
        :custom-filter="(val, search, item) => search != null && item.fio.indexOf(search) !== -1"
        sort-by="name"
        group-by="active"
        group-desc
        hide-default-footer
      >
        <template v-slot:group.header="{group, headers, isOpen, toggle}">
          <td :colspan="headers.length" class="text-start">
            <v-row justify="space-between">
              <v-col class="ma-0 py-0 pl-10 d-flex" cols="auto">
                <span v-if="group" class="my-auto subtitle-1">Обучаются</span>
                <span v-else  class="my-auto subtitle-1">Отчисленные</span>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="ma-0 py-0 pr-10" cols="auto">
                <v-btn @click="toggle" icon>
                  <v-icon v-if="isOpen">
                    keyboard_arrow_up
                  </v-icon>
                  <v-icon v-else>
                    keyboard_arrow_down
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </td>
        </template>
        <template v-slot:item="props">
          <tr class="text-left">
            <td>{{ props.index + 1 }}</td>
            <td>
              <StudentLinkToDetail :student_id="props.item.id" v-if="props.item.fio">
                {{ props.item.fio }}
              </StudentLinkToDetail>
              <v-skeleton-loader width="100%" type="text" v-else></v-skeleton-loader>
            </td>
            <td class="text-center">
              <v-icon color="success" v-if="props.item.headman">
                check_circle_outline
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import urls from "@/urls/management";
  import {loadData, checkFieldTrue} from "@/helper";
  import StudentLinkToDetail from "../student/LinkToDetail";
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
  import {generateListUidToName} from "@/helper/uidToName";

  export default {
    name: "StudentList",
    components: {
      StudentLinkToDetail,
      DebugJsonComponent
    },
    computed: {
      loadingPage: function(){
        return checkFieldTrue(this.students, "loading");
      }
    },
    data: function () {
      return {
        title: "Список обучающихся группы",
        loadErrorMsg: "",
        search: "",
        students: {
          data: [],
          loading: false,
          headers: [
            {text: "№", sortable: false, align: "start", width: "5%"},
            {text: "ФИО", sortable: false, align: "start", width: "80%"},
            {text: "Староста", sortable: false, align: "center", width: "15%"}
            // {text: "Отчислен", sortable: false, align: 'center', width: '15%',},
          ]
        }
      }
    },
    methods: {
      loadStudents: function () {
        this.students.loading = true;
        let saver = (data) => {this.students.data = data;
          for (let i in this.students.data) {
            this.$set(this.students.data[i], "fio","")
          }
          generateListUidToName(this.students.data, "user", "fio", () => {
            this.students.data.sort((a,b) => a.fio.toUpperCase() > b.fio.toUpperCase() ? 1: -1)
            this.students.loading = false
          });
        };
        let catcher = (error) => {console.log(error)};
        let url = urls.EDUCATION.GROUP.STUDENTS(this.$route.params.group_id);
        loadData(url, saver, catcher);
      }
    },
    created() {
      this.loadStudents();
      this.$store.commit("mainpage/changeTitle", this.title);
    }
  }
</script>

<style scoped>

</style>
