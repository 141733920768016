<template>
  <scroll-center-dialog v-model="opened_form" @close="closeDialog" @apply="save" title="Добавление предметов для индивидуального изучения" width="500">
    <v-autocomplete
      outline
      :error="hasError('semester')"
      :error-messages="getError('semester')"
      :items="selectors.semester"
      no-data-text="Нет данных"
      label="Дисциплина"
      v-model="value.semester"
    ></v-autocomplete>
  </scroll-center-dialog>
</template>

<script>

import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";
import UserDataFormMixin from "@/mixins/UserDataFormMixin";
import {loadData, sendPostRequest} from "@/helper";
import urls from "@/urls/dean"
import selectors from "@/urls/selectors"

export default {
  name: "OptionallySubjectAddForm",
  mixins: [UserDataFormMixin],
  components: {
    ScrollCenterDialog
  },
  props: [ "opened_form"],
  data() {
    return {
      value: {
        semester: ""
      },
      selectors:{
        semester: []
      },
      errors:{}
    }
  },
  methods: {
    closeDialog() {
      this.$emit("ended", "")
    },
    save(){
      sendPostRequest(
        urls.DEAN.STUDENTS.CURRICULUM.SEMESTER.INDIVIDUAL.ADD_OPTIONALLY_SEMESTER(this.$route.params.idFaculty, this.$route.params.idStudent),
        this.value,
        ()=>{
          this.closeDialog()
        },
        (res)=>{
          this.receiveErrors(res)
        }
      )
    },
    loadMissingOptionally(){
      loadData(
        selectors.SELECTORS.DEAN.EDUCATION_MISSING_OPTIONALLY(this.$route.params.idStudent),
        (json) => {
          this.selectors.semester = json
        }
      )
    }
  },
  created() {
    this.loadMissingOptionally()
  }
}
</script>

<style scoped>

</style>
