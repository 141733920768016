<template>
  <v-container style="height: 100%" v-if="is_self_route">
    <v-card flat>
<!--      <DebugJsonComponent :json="items"></DebugJsonComponent>-->
      <v-toolbar flat class="title" color="style-color-main-gray">
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn @click="opened_form = true" color="secondary" text>
            <v-icon>add</v-icon>
            Добавить
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                text
                v-on="on"
                @click="getItems()"
                class="mr-2"
                medium
              >
                <v-icon>
                  update
                </v-icon>
              </v-btn>
            </template>
            <span>Обновить данные</span>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pb-3 px-0 pt-0">
        <v-layout justify-center>
          <v-flex>
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="pagination.loading"
              :page.sync="pagination.page"
              :items-per-page.sync="pagination.size"
              :server-items-length="pagination.count"
              class="elevation-1"
              no-data-text="Нет данных"
              :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
              style="cursor: pointer"
            >
              <template v-slot:item.semesters="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn small text outlined @click="getDetail(item)" v-on="on" class="mr-2" width="100%">
                      <v-icon
                        small
                        class="mr-2"
                      >
                        list
                      </v-icon>
                      Семестры
                    </v-btn>
                  </template>
                  <span>Список семестров учебного года</span>
                </v-tooltip>
              </template>
              <template v-slot:item.option="{ item }" >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      @click="updateItem(item)"
                      medium
                    >
                      <v-icon>
                        edit
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Редактировать</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      @click="openConfirmDelete(item)"
                      medium
                    >
                      <v-icon>
                        delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Удалить</span>
                </v-tooltip>
              </template>
              <template v-slot:no-data>
                <v-card-text>Данные не загружены</v-card-text>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <AcademicYear @addItem="addItem"
                  @input="closedForm"
                  @ended="closedForm"
                  @refreshItem="refreshItem"
                  v-bind="selected"
                  v-bind:info="$route.params.id"
                  v-if="opened_form"
                  :opened_form="opened_form"
    >
    </AcademicYear>
    <v-dialog persistent v-model="opened_confirm" width="500">
      <v-card v-if="deletable">
        <v-card-title>Подтвердите удаление</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          Вы уверены, что хотите удалить?<br>
        </v-card-text>
        <v-card-actions>
          <v-flex>
            <v-btn
              @click="deleteItem(deletable.id)"
              color="warning"
              text
            >
              Удалить
            </v-btn>
            <v-btn
              @click="closeConfirmDelete"
              text
            >Отмена
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>


<script>
  import AcademicYear  from "../../forms/academic_year/AcademicYear"
  import UserDataMixin from "@/mixins/UserDataMixin"
  import {loadData, makeDeleteRequest} from "@/helper";
  import urls from "@/urls/management";
  import names from "@/modules/educationalManagement/routers/names";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";

  export default {
    name: "ListCourses",
    components: {DebugJsonComponent, AcademicYear},
    mixins: [UserDataMixin, PaginatedDataMapperMixin],
    props: {
      isDetail: Boolean
    },
    data () {
      return {
        URL_LIST: urls.EDUCATION.ACADEMIC_YEAR.LIST(),
        items: [],
        pagination: {},
        headers: [
          {text: "Семестры", sortable: false, align: "left",value:"semesters", width: "10%"},
          {text: "Год", sortable: false, align: "center",value:"name"},
          {text: "Рейтинговая система", sortable: false, align: "center",value:"rating_system"},
          {text: "Начало года", sortable: false, align: "center", value:"start"},
          {text: "Конец года", sortable: false, align: "center", value:"end"},
          {text: "Действия", sortable: false, align: "center", value:"option", width: "10%"}
        ]
      }
    },
    watch: {
    },
    computed: {
      is_self_route: function() {
        this.$store.commit("mainpage/changeTitle", "Список учебных годов");
        return this.$route.name === names.EDUCATIONAL.ACADEMIC_YEAR.LIST
      }
    },
    created() {
      this.getItems()
      this.$store.commit("mainpage/changeTitle", "Список учебных годов");
    },
    methods: {
      getDetail(item){
        this.$router.push({
          name: names.EDUCATIONAL.ACADEMIC_YEAR.SEMESTER.DETAIL,
          params: {idYear: item.id }
        })
      },
      deleteItem(id) {
        let url = urls.EDUCATION.ACADEMIC_YEAR.DELETE(id)
        makeDeleteRequest(url).then(resp => {
          if (resp.ok) {
            this.closeConfirmDelete()
            this.getItems();
          }
        })
      },
      addItem(){
        this.closedForm()
        this.getItems ();
      },
      loadData(url = undefined) {
        this.getItems(url)
      },
      getItems (url=undefined) {
        this.setLoading();
        if(url === undefined) {
          url = this.URL_LIST,
          this.setCurrentLoadUrl(url);
        }
        loadData(
          url,
          (data) => {
            this.mapResults(data,"items")
            this.setNotLoading();
          }
        )
      }
    }
  }

</script>
<style scoped>

</style>
