<template>
 <AdmissionTemplate  :admission="admission">
 </AdmissionTemplate>
</template>

<script>
  import AdmissionTemplate from "@/modules/templates/admission/AdmissionTemplate"
  import {loadData} from "@/helper";
  import urls from "@/urls/student"
  import {generateUidToName} from "@/helper/uidToName";
  import TitledPageMixin from "@/mixins/TitledPageMixin";
  import UserDataMixin from "@/mixins/UserDataMixin";
  export default {
    name: "DetailAdmission",
    components: {
      AdmissionTemplate
    },
    mixins: [TitledPageMixin, UserDataMixin],
    data: function () {
      return {
        page_title: "Допуск",
        admission: undefined
      }
    },
    methods: {
      loadData(){
        let url = urls.STUDENT.ADMISSION.DETAIL(this.$route.params.idStudent, this.$route.params.idAdmission)
        loadData(
          url,
          (data) => {
            this.admission = data
            this.$set(this.admission, "fio_teacher", "")
            generateUidToName(this.admission, "teacher", "fio")
              this.$set(this.admission, "fio_student", "")
              generateUidToName(this.admission, "student", "fio_student")
          }
        )
      }
    },
    created() {
      this.loadData();
    }
  }
</script>

<style scoped>

</style>
