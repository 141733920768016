<template>
  <div>
    <v-sheet   v-show="isActive">
      <v-card class="ma-4">
        <v-toolbar dense elevation="0" color="style-color-main-gray">
          <v-toolbar-title>
            Информация
          </v-toolbar-title>
        </v-toolbar>
        <v-container fluid class="text-start px-4">
          <single-dean-top-template></single-dean-top-template>
          <v-row class="mb-5">
            <v-col cols="12">
              Для получения справки следуйте инструкции привденной ниже:
            </v-col>
            <v-col cols="12">
              <v-timeline dense>
                <v-timeline-item icon="post_add" color="style-color-blue-light" icon-color="black" right>
                  <v-card style="vertical-align: center" elevation="2">
                    <v-container>
                      <v-row align-content="center">
                        <v-col>Заполнить заявку в электронном виде(форма на данной странице)</v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-timeline-item>
                <v-timeline-item icon="schedule" color="style-color-blue-light" icon-color="black" right>
                  <v-card style="vertical-align: center" elevation="2">
                    <v-container>
                      <v-row align-content="center">
                        <v-col>
                          Дождаться уведомления от Единого деканата о сроках готовности и получения документов.
                          При возникновении вопросов при составлении справок, работник Единого деканата с Вами свяжется.
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-timeline-item>
                <v-timeline-item icon="get_app" color="style-color-blue-light" icon-color="black" right>
                  <v-card style="vertical-align: center" elevation="2">
                    <v-container>
                      <v-row align-content="center">
                        <v-col>Получить запрашиваемые документы в Едином деканате лично или на указанную Вами почту
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-card class="ma-4">
        <v-toolbar dense elevation="0" color="style-color-main-gray">
          <v-toolbar-title>
            Форма заказа справки
          </v-toolbar-title>
        </v-toolbar>
        <v-container class="text-start">
          <v-form ref="form">
            <v-col offset="1">
              <v-row dense>
                <v-col cols="10">
                  <v-text-field
                    v-model="user.fio"
                    label="ФИО*"
                    readonly
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" md="5">
                  <v-text-field
                    label="Справка предоставляется в"
                    placeholder="Полное наименование организации"
                    v-model="user.goal_org"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="10" md="5">
                  <v-text-field
                    v-model="user.group"
                    readonly
                    label="Группа*"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" md="5">
                  <v-select
                    v-model="user.path"
                    :items="selector.path"
                    label="Способ получения*"
                    required
                    :rules="[rules.required]"

                  >
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <div class="text-left" style="-moz-hyphens: auto; -webkit-hyphens: auto; -ms-hyphens: auto;">
                          {{ data.item.text }}
                        </div>
                      </template>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="10" md="5">
                  <v-text-field
                    v-model="user.faculty"
                    readonly
                    label="Направление подготовки*"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" md="5" v-if="user.path === 3">
                  <v-text-field
                    label="Адрес получателя*"
                    required
                    v-model="user.address"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" md="5" v-else>
                  <v-text-field
                    v-model="user.phone"
                    label="Телефон для связи*"
                    :rules="[rules.phone, rules.required]"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="10" md="5">
                  <v-text-field
                    v-model="user.email"
                    label="Адрес эл-почты*"
                    :error="hasError('email')"
                    :error-messages="getError('email')"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" md="5" v-if="user.path === 3">
                  <v-text-field
                    v-model="user.phone"
                    label="Телефон для связи*"
                    :rules="[rules.phone, rules.required]"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="10" md="10" v-if="user.path === 3">
                  <v-text-field
                    v-model="user.letter_recipient"
                    label="Получатель письма*"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row  >
                <v-col cols="8">
                  <v-slider
                    class=" "
                    v-model="user.copy_num"
                    step="1"
                    max="10" min="1"
                    ticks
                    label="Количество экземпляров"
                  ></v-slider>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    v-model="user.copy_num"
                    max="10" min="1"
                    type="number"
                    hide-details
                    single-line
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10">
                  <v-alert outlined color="info">
                    <v-checkbox v-model="user.apply" :rules="[rules.apply_required]">
                      <template v-slot:label>
                      <span>
                        Согласен на обработку моих персональных данных,
                        в соответствии с Федеральным законом
                        от 27.07.2006 года №152-ФЗ «О персональных данных»,
                        на условиях и для целей, определенных в
                        <a href="" target="_blank">Согласии на обработку персональных данных</a>
                      </span>
                      </template>
                    </v-checkbox>
                  </v-alert>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10" v-if="!loaded">
                  <v-btn width="100%" color="success" @click="sendRequest" :loading="loading" :disabled="loading">
                    Отправить
                  </v-btn>
                </v-col>
                <v-col cols="6" offset="3" v-else>
                  <v-alert outlined color="success">
                    Ваша заявка отправлена.
                  </v-alert>
                </v-col>
              </v-row>
            </v-col>
          </v-form>
        </v-container>
      </v-card>
    </v-sheet>
    <v-container  v-show="!isActive">
      <DisableServices ></DisableServices>
    </v-container>
  </div>
</template>

<script>
import TitledPageMixin from "@/mixins/TitledPageMixin";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import SingleDeanTopTemplate from "@/modules/student/pages/SingleDean/SingleDeanTopTemplate";
import {makeGetRequest, makePostRequestFile, sendPostRequest} from "@/helper";
import {mapGetters} from "vuex";
import {getUrlWithParameters} from "@/helper/urls";
import urls from "../../urls";
import {loadData, setUrlParameters} from "../../../../helper";
import urlsM from "@/urls/management";
import DisableServices from "@/modules/templates/DisableServices.vue";

export default {
  name: "ReferencePXTY",
  components: {
    DisableServices,
    SingleDeanTopTemplate,
  },
  mixins: [
    TitledPageMixin,
    FormErrorsMixin
  ],
  data: function () {
    return {
      page_title: 'Заказ справок об обучении в РХТУ им. Д.И. Менделеева',
      user: {
        fio: '',
        group: '',
        faculty: '',
        phone: '',
        email: '',
        apply: false,
        address: '',
        letter_recipient: '',
        copy_num: 1,
        goal_org: '',
        path: 1,
        type: 1,
        education: this.$route.params.idStudent,
      },
      loaded: false,
      loading: false,
      education: {
        data: {},
        loaded: false,
      },
      selector: {
        path: [
          {value: 1, text: 'В электронном виде на адрес электронной почты, указанной в личном кабинете ЭИОС'},
          {value: 2, text: 'Лично в бумажном виде'},
          {value: 3, text: 'Почтой России по адресу'},
        ],
      },
      rules: {
        required: value => !!value || 'Поле обязательно для заполнения.',
        apply_required: value => value || 'Подать запрос электронно без согласия на обработку персональных данных невозможно.',
        phone: value => RegExp(/((\+7)|(8))[0-9]{10}$/g).test(value) || value === '' ||
          'Телефоный номер должен начинаться с 8 или +7 и содержать 10 цифр',
      },
    }
  },
  created() {
    if(!this.$store.getters['urls/checkAlreadyLoaded']('feedback')){
      makeGetRequest(process.env.VUE_APP_INSTANCE_ALLOWED_URLS + '?instances=feedback')
        .then(resp => resp.json())
        .then(data => {
          this.$store.commit('urls/appendUrls', data);
        });
    }

    this.loadEducation();
  },
  methods: {
    loadEducation: function () {
      let url = urls.EDUCATION.getUrl('student|students|education-detail', 'retrieve');
      url = setUrlParameters(url, {id: this.$route.params.idStudent});
      loadData(url, (data) => {
        this.education.data = data;
        loadData(
          urlsM.ACCOUNTS.PROFILE(this.education.data.user),
          (data) => {
            this.education.data.user = data;
            this.education.loaded = true;

            this.user.fio = this.education.data.user.lastname + ' ' + this.education.data.user.firstname + ' ' + this.education.data.user.midname
            this.user.email = this.education.data.user.email
            if (this.education.data.user.phones.length > 0){
              let code = String(this.education.data.user.phones[0].code)
              this.user.phone =  code + String(this.education.data.user.phones[0].number)
            }
            else
              this.user.phone = ''
            this.user.group = this.education.data.education_group.litter + '-' + this.education.data.education_group.course +
              this.education.data.education_group.number
            this.user.faculty = this.education.data.direction.code + ' ' + this.education.data.direction.name
          });
      })
    },
    sendRequest(){
      if(this.$refs.form.validate()){
        this.loading = true
         this.clearErrors()
        sendPostRequest(
          this.send_url,
          this.user,
          () => {
            this.loading = false;
            this.loaded = true;
          },
          (errors) => {
            this.mapErrors(errors);
            this.loading = false;
          }
        )
      }
    }
  },
  computed: {
    isActive () {
      return this.$store.getters['urls/checkAlreadyLoaded']('feedback')
    },
    send_url() {
      return getUrlWithParameters(
        process.env.VUE_APP_API_HOST + 'feedback',
        'feedback',
        'singledean|send-request-reference',
        'post',
        {},
        {}
      )
    }
  },
}
</script>

<style scoped>

</style>
