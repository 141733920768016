<template>
  <scroll-center-dialog
    v-model="inValue"
    @close="inValue = false"
    @apply="execute"
    :loading="loading"
    :disabled="link === '' || link === undefined"
    applyText="Связать"
  >
    <template v-slot:title>
      <slot name="title">Связывание составляющей учебного плана "{{link}}"</slot>
    </template>
    <slot></slot>
    <loading-autocomplete
      v-if="!preloadable"
      v-model="link"
      search-parameter="search"
      :url="selectorUrl"
      :load-fios="false"
      :error="hasError('education_related')"
      :error-messages="getError('education_related')"
      no-filter
    >
    </loading-autocomplete>
    <v-autocomplete
      v-else
      v-model="link"
      :items="selector.items"
      :loading="selector.loading"
      :error="hasError('education_related')"
      :error-messages="getError('education_related')"
    >

    </v-autocomplete>
    <v-switch v-model="restore" label="Провести попытку сопоставления вложенных сущностей" v-if="restorable">
    </v-switch>
  </scroll-center-dialog>
</template>

<script>
import {sendGetRequest} from "@/helper";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import LoadingAutocomplete from "@/modules/core/components/LoadingAutocomplete.vue";
import ActionExecutroMixin from "@/modules/administrative/mixins/ActionExecutroMixin";

export default {
  name: "LinkAction",
  components: {ScrollCenterDialog, LoadingAutocomplete},
  mixins: [ActionExecutroMixin],
  props: {
    preloadable: {
      default: false,
      type: Boolean
    },
    restorable: {
      default: false,
      type: Boolean
    },
    restoreDefault: {
      default: false,
      type: Boolean
    },
    selectorUrl: {
      required: true
    }
  },
  data() {
    return {
      link: undefined,
      restore: this.restoreDefault,
      selector: {
        items: [],
        loading: false
      }
    }
  },
  methods: {
    clearState(){
      this.clearErrors()
      this.selector.items = undefined
    },
    onOpenPreAction() {
      if (this.preloadable) {
        this.loadSelector()
      }
    },
    loadSelector(){
      this.selector.loading = true
      sendGetRequest(
        this.selectorUrl,
        (data) => {
          this.selector.items = data
          this.selector.loading = false
        }
      )
    },
    getActionArgs(kwargs){
      // console.log(this.link, 'JOPA')
      return {
        university: this.link,
        restore: this.restore
      }
    },
    getEducationId(){
      return this.getEIOSValue(this.item).id
    },
    getEIOSValue(item){
      if(item !== undefined)
        return Object.hasOwn(item, "eios") ? item.eios : item
      return {}
    }
  }
}
</script>

<style scoped>

</style>
