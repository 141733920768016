<template>
  <scroll-center-dialog v-model="opened_form"
                        :title="!this.id ? 'Оформить допуск' : 'Редактировать допуск'"
                        width="500"
                        @close="closeDialog"
                        @apply="saveAdmission"
                        :applyable="applySave"
                        :disabled="loading"
                        :loading="loading"
  >
    <v-autocomplete
      v-if="!this.id"
      class="mr-3 ml-3"
      outline
      :error="hasError('semester')"
      :error-messages="getError('semester')"
      :items="selectors.semester"
      no-data-text="Нет данных"
      label="Предмет"
      v-model="semester"
    ></v-autocomplete>
    <template v-else>
      <v-text-field
        label="Предмет"
        class="mr-3 ml-3"
        v-model="value.subject"
        :disabled="true"
      ></v-text-field>
    </template>
    <template v-if="value.control_type">
      <v-row class="pb-3">
        <v-col cols="4" class="text-left ml-3">
          <div class="text-left font-weight-black">
            Тип контроля:
          </div>
        </v-col>
        <v-col cols="6" class="text-left">
          {{ value.control_type }}
        </v-col>
      </v-row>
    </template>
    <v-alert type="error" v-if="hasError('error')">
      {{ getErrorArray('error') }}
    </v-alert>
    <v-alert
      outlined
      type="error"
      border="left"
      v-if="tolerance!== undefined && !tolerance.available_tolerance"
    >
      Допуски выдать нельзя
    </v-alert>
    <v-alert outlined type="info" border="left"
             v-else-if="tolerance!== undefined && tolerance.available_tolerance">
      <span v-if="!tolerance.exam_tolerance">Допуск для добора баллов</span>
      <span v-else>Допуск для сдачи зачета/экзамена</span>
    </v-alert>
    <v-checkbox
      :error="hasError('academic_difference')"
      :error-messages="getError('academic_difference')"
      label="Академическая разница"
      v-model="value.academic_difference "
      rows="3"
      class="mr-3 ml-3"
    ></v-checkbox>
    <div v-if="!this.value.academic_difference">
      <v-autocomplete
        v-if="semester || this.id"
        class="mr-3 ml-3"
        outline
        :error="hasError('session')"
        :error-messages="getError('session')"
        :items="selectors.session"
        no-data-text="Нет данных"
        label="Сессия на которую выдается допуск"
        v-model="value.session"
      ></v-autocomplete>
    </div>
    <v-text-field
      :error="hasError('number')"
      :error-messages="getError('number')"
      label="Номер допуска"
      v-model="value.number"
      class="pa-1 ma-1"
    ></v-text-field>
    <v-textarea
      :error="hasError('dean_comment')"
      :error-messages="getError('dean_comment')"
      label="Комментарий"
      v-model="value.dean_comment"
      rows="3"
      class="mr-3 ml-3"
    ></v-textarea>
    <v-file-input
      v-if="need_add_certificate "
      class="pa-1 ma-1"
      :error="hasError('certificate')"
      :error-messages="getError('certificate')"
      v-model="value.certificate"
      accept="application/pdf"
      label="Документ"
      hint="Файл основания выдачи допуска необходим для подтверждения права обучающигося на получение третьего и последующих допусков."
      persistent-hint
    ></v-file-input>

    <div v-if="value.certificate != null && typeof value.certificate.url === 'string'"
         class="pa-2 ma-3 mb-3 document"
    >
      <div>
        <v-icon size="28px">
          picture_as_pdf
        </v-icon>
        <a :href="value.certificate.url"
           class="documentContent"
        >Документ обоснования
        </a>
      </div>
      <div>
        <v-btn text icon @click="reloadFile()">
          <v-icon>
            close
          </v-icon>
        </v-btn>
      </div>
    </div>
  </scroll-center-dialog>
</template>

<script>

  import UserDataFormMixin from "../../../../../mixins/UserDataFormMixin"
  import {loadData, sendPatchRequest, sendPostRequest} from "@/helper";
  import urls from "@/urls/dean"
  import selectors from "@/urls/selectors"
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent"
  import {finalizeRequest, makePostRequestFile} from "../../../../../helper";
  import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

  export default {
    name: "Admission",
    mixins: [UserDataFormMixin],
    components: {
      ScrollCenterDialog,
      DebugJsonComponent
    },
    props: ["id", "opened_form"],
    data() {
      return {
        URL_SEMESTER: selectors.SELECTORS.DEAN.STUDENT_SEMESTER(
          this.$route.params.idFaculty,
          this.$route.params.idStudent,
          {tolerance:true, previous_semesters:true}
        ),
        selectors:{
          session: [] ,
          semester: [] 
        },
        value: {
          session: null ,
          semester: null,
          number: null,
          dean_comment: "",
          academic_difference:false,
          certificate: null,
          subject: null,
          control_type: null
        },
        semester: null,
        tolerance: undefined,
        can_save: false,
        need_add_certificate : false,
        loading: false
      }
    },
    watch: {
      "semester": function (val) {
        this.selectors.session = []
        this.loadSession(val)
        this.checkAdmission(val)
      }
    },
    computed: {
      applySave () {
        return Boolean(this.can_save || this.id)
      }
    },
    methods: {
      reloadFile() {
        this.value.certificate = null;
        this.need_add_certificate = true;
      },
      checkAdmission(semester){
        let url = urls.DEAN.STUDENTS.SEMESTER.CHECK_TOLERANCE(
          this.$route.params.idFaculty,
          this.$route.params.idStudent,
          semester
        )
        let saverCheck = (data) => {
          this.tolerance = data
          this.can_save = data.available_tolerance
          this.need_add_certificate = data.need_certificate
          this.value.control_type = data.control_type
        };
        let catherCheck = (data) => {
          this.errors = data
        };
        loadData(url, saverCheck, catherCheck);
      },
      saveAdmission(){
        this.loading = true
        this.value.semester = this.semester
        let url = urls.DEAN.STUDENTS.SEMESTER.ADD_TOLERANCE(
          this.$route.params.idFaculty,
          this.$route.params.idStudent,
          this.semester
        )
        let metod = "POST"
        if (this.id) {
          url = urls.DEAN.ADMISSION.UPDATE(this.$route.params.idFaculty, this.id)
          metod = "PATCH"
        }
        let formData = new FormData();
        if(!this.value.academic_difference)
          formData.append("session", this.value.session)
        formData.append("number", this.value.number)
        formData.append("dean_comment", this.value.dean_comment)
        formData.append("academic_difference", this.value.academic_difference)
        if (typeof this.value.certificate === "object" && this.value.certificate !== null) {
          formData.append("certificate", this.value.certificate)
        }
        finalizeRequest(makePostRequestFile(url, formData, metod),
          (json) => {
            this.loading = false
            this.closeDialog()
          },
          (json) => {
            this.loading = false
            this.receiveErrors(json);
          })
      },
      closeDialog() {
        this.$emit("ended", "")
      },
      loadSemester(page=1){
        let url = this.URL_SEMESTER
        let catherSemester = (data) => {
          this.errors = data
        };
        let saverSemester= (data) => {
          data.forEach(el => {
            this.selectors.semester.push(el);
          });
        };
        url = url+"?page="+page+"&size=200"
        loadData(url, saverSemester, catherSemester);
      },
      loadSession(val){
        let url = selectors.URLS.ACADEMIC_YEAR_SESSION({current:true, individual_semester: val})
        let saverSession = (data) => {
          this.selectors.session = data
        };
        let catherSession = (data) => {
          this.errors = data
        };
        loadData(url, saverSession, catherSession);
      }
    },
    created() {
      this.loadSemester(1)
      if (this.id){
        let url = urls.DEAN.STUDENTS.ADMISSION.DETAIL(this.$route.params.idFaculty, this.$route.params.idStudent, this.id)
        let getDetail = (data) => {
          this.loadSession(data.semester.id)
          this.value.number = data.number
          this.value.dean_comment = data.dean_comment
          if(data.academic_difference)
            this.value.academic_difference = data.academic_difference
          else
            this.value.session = data.session.id
          this.value.certificate = data.certificate
          this.value.subject = data.semester.subject
          this.value.control_type = data.semester.control_type
        };
        let catherDetail = (data) => {
          this.errors = data
        };
        loadData(url, getDetail, catherDetail);
      }
    }
  }

</script>

<style scoped>
.document {
  border-bottom: 1px rgba(0,0,0,.42);
  display: flex;
  justify-content: space-between;
  align-items: center
}
.documentContent {
  font-size: 16px;
  margin-left: 15px
}
</style>
