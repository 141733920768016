<template>
    <Students v-if="taught && is_self_route" :detail_url="cathedras.CATHEDRA.STUDENT.TAUGHT.LIST"></Students>
    <Students v-else-if="!taught && is_self_route" :detail_url="cathedras.CATHEDRA.STUDENT.OWNED.LIST"></Students>
    <router-view v-else></router-view>
</template>

<script>
  import names from "../../routers/names";
  import Students from "./Students";
  // import TaughtStudents from "./TaughtStudents";
  import cathedras from "../../../../urls/cathedras";

  export default {
    name: "StudentsList",
    components: {
      Students
      // , TaughtStudents
    },
    computed: {
      cathedras: () => cathedras,
      taught: function () {
        return this.$route.params.taught === "taught"
      },
      is_self_route: function () {
        return names.CathedraStudents === this.$route.name
      }
    },
    created() {
      // console.log('StudentList')
      // console.log(this.taught)
      // console.log(this.$route.params)
    }
  }
</script>

<style scoped>

</style>
