<template>
  <v-container>
    <CurriculumTemplate v-if="curriculum.loaded" :curriculum="curriculum.data.curriculum"></CurriculumTemplate>
    <template v-if="curriculum.data.curriculum!==undefined && !curriculum.data.curriculum.is_system">
      <v-toolbar class="mb-2" color="style-color-main-gray" flat>
        <v-toolbar-title>
          Список дисциплин учебного плана
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <SubjectList v-if="curriculum.loaded" :subject_url="curriculum.data.semesters"></SubjectList>
    </template>
  </v-container>
</template>

<script>
  import urls from "@/urls/management";
  import {loadData, checkFieldTrue} from "@/helper";
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
  import CurriculumTemplate from "@/modules/templates/curriculum/CurriculumTemplate";
  import SubjectList from "./SubjectList";
  import TitledPageMixin from "../../../../../mixins/TitledPageMixin";

  export default {
    name: "PersonalCuriculum",
    components: {
      DebugJsonComponent,
      SubjectList,
      CurriculumTemplate
    },
    mixins: [
      TitledPageMixin
    ],
    computed: {
      loadingPage: function(){
        return checkFieldTrue(this.student, "loading");
      }
    },
    data: function () {
      return {
        page_title: "Учебный план",
        loadErrorMsg: "",
        actions: {
          nextCourseApplier: false,
          prevCourseApplier: false
        },
        curriculum: {
          student_id: this.$route.params.student_id,
          data: {},
          loading: false,
          loaded: false,
          status: ""
        }
      }
    },
    methods: {
      loadCurriculum: function () {
        let url = urls.EDUCATION.STUDENT.CURRICULUM.RETRIEVE(this.curriculum.student_id);
        this.curriculum.loading = true;
        loadData(
          url,
          (data) => {
            this.curriculum.data = data;
            this.curriculum.loaded = true;
            this.curriculum.loading = false;
          },
          (error) => {
            this.curriculum.status = error;
            this.curriculum.loading = false;
          });
      }
    },
    created() {
      this.setPageTitleWithObject("Education", {id: this.$route.params.student_id}, " - ", true)
      this.loadCurriculum();
    }
  }
</script>

<style scoped>

</style>
