<template>
  <v-container v-if="$route.name === router_name">
    <v-card elevation="3" class="ma-2">
      <v-toolbar flat color="style-color-main-gray" :extended="$vuetify.breakpoint.smAndDown">
        <v-toolbar-title class=" title text-sm-left">{{ curriculum.data.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <portal to="extension" :disabled="$vuetify.breakpoint.mdAndUp">
          <v-toolbar-title class="subtitle-2"
                           v-if="curriculum.data.hasOwnProperty('is_lock') && !curriculum.data.is_lock">
            <v-tooltip bottom v-if="curriculum.data.hasOwnProperty('need_sync') && curriculum.data.need_sync">
              <template v-slot:activator="{atts, on}">
                <v-icon v-bind="atts" v-on="on" class="mr-2">schedule</v-icon>
              </template>
              Находится в очереди на сихронизацию, которая будет выполнена в 2 часа ночи.
            </v-tooltip>
            <!--            <span v-if="!curriculum.data.need_sync">Cинхронизирован {{ displayDate(curriculum.data.last_sync) }}</span>-->
            <!--            <span v-else>Запланирован</span>-->
            <span v-if="curriculum.data.is_draft">
              <v-tooltip bottom>
                <template v-slot:activator="{atts, on}">
                  <v-icon v-bind="atts" v-on="on" class="mr-2">info</v-icon>
                </template>
                Черновик не может быть синхронизирован, так как к черновому учебному плану <br>
                  не может быть привязано ни одной группы и обучающигося.
              </v-tooltip>
              Черновик
            </span>
            <span v-else-if="!curriculum.data.need_sync && curriculum.data.last_sync===null">Не синхронизировано</span>
            <span v-else-if="!curriculum.data.need_sync">Cинхронизирован {{
                displayDate(curriculum.data.last_sync)
              }}</span>
            <span v-else>Запланирован</span>
          </v-toolbar-title>
          <v-toolbar-title class="subtitle-2"
                           v-else-if="curriculum.data.hasOwnProperty('is_lock') && curriculum.data.is_lock">
            <v-tooltip bottom>
              <template v-slot:activator="{atts, on}">
                <v-icon v-bind="atts" v-on="on" class="mr-2">info</v-icon>
              </template>
              Учебный план и все данные связанные с ним заблокированы в связи с процессом синхронизации данных с
              группами и обучающимися.
            </v-tooltip>
            <span>Заблокирован {{ displayDate(curriculum.data.locked) }}</span>
          </v-toolbar-title>
        </portal>
        <template v-slot:extension v-if="$vuetify.breakpoint.smAndDown">
          <v-spacer></v-spacer>
          <portal-target name="extension">
          </portal-target>
        </template>
        <v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp && !curriculum.data.is_system">
          <v-btn text @click="openForm()">
            <v-icon>edit</v-icon>
          </v-btn>
<!--          <v-tooltip v-if="!curriculum.data.is_system" bottom>-->
<!--            <template v-slot:activator="{ on }">-->
<!--              <v-btn icon medium v-on="on" @click="openLink()">-->
<!--                <v-icon>link</v-icon>-->
<!--              </v-btn>-->
<!--            </template>-->
<!--            <span>Связать</span>-->
<!--          </v-tooltip>-->
          <tooltip-btn tooltip="Связать" icon="link" bottom @click="openDialog(dialogs.link)" v-if="!isLinked && $vuetify.breakpoint.mdAndUp && !isProductionMode"></tooltip-btn>
          <tooltip-btn tooltip="Отвязать" icon="link_off" bottom @click="openDialog(dialogs.clear)" v-if="isLinked && $vuetify.breakpoint.mdAndUp && !isProductionMode"></tooltip-btn>
          <v-menu bottom left nudge-bottom="64">
            <template v-slot:activator="{attrs, on}">
              <v-btn text v-bind="attrs" v-on="on">
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="!curriculum.data.is_draft">
                <v-btn width="100%" text
                       v-if="curriculum.data.hasOwnProperty('need_sync') && !curriculum.data.need_sync"
                       @click="updateCurriculum.need_sync.applier = true">Требуется синхронизация
                </v-btn>
                <v-btn width="100%" text
                       v-else-if="curriculum.data.hasOwnProperty('need_sync') && curriculum.data.need_sync"
                       @click="updateCurriculum.need_sync.applier = true">Не требуется синхронизация
                </v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn width="100%" text @click="updateCurriculum.opened_form_draft=true">Изменить статус черновика
                </v-btn>
              </v-list-item>
              <v-list-item v-if="is_dean">
                <v-btn width="100%" text @click="updateCurriculum.opened_form_faculty=true">Сменить факультет</v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn width="100%" text @click="updateCurriculum.opened_form_extend=true">Изменить расширяемый УП
                </v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn width="100%" text @click="updateCurriculum.opened_form_copy=true">Сделать копию</v-btn>
              </v-list-item>
              <v-list-item v-if="$vuetify.breakpoint.smAndDown && !isProductionMode">
                <v-btn width="100%" text @click="openDialog(dialogs.link)" v-if="isLinked">Связать с 1С</v-btn>
                <v-btn width="100%" text @click="openDialog(dialogs.clear)" v-else>Отвязать от 1С</v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar-items>
        <!--        <v-toolbar-items v-else>-->
        <!--          <v-menu>-->
        <!--            <template v-slot:activator="{attrs, on}">-->
        <!--              <v-btn text v-bind="attrs" v-on="on">-->
        <!--                <v-icon>more_vert</v-icon>-->
        <!--              </v-btn>-->
        <!--            </template>-->
        <!--            <v-list>-->
        <!--              <v-btn text width="100%" @click="updateCurriculum.opened_form_draft=true">Изменить черновик</v-btn>-->
        <!--              <v-btn text width="100%" @click="updateCurriculum.opened_form_faculty=true" v-if="is_dean">Изменить факультет</v-btn>-->
        <!--              <v-btn text width="100%" @click="updateCurriculum.opened_form_extend=true">Изменить расширяемый УП</v-btn>-->
        <!--              <v-btn text width="100%" @click="openForm()">Редактировать</v-btn>-->
        <!--            </v-list>-->
        <!--          </v-menu>-->
        <!--        </v-toolbar-items>-->
      </v-toolbar>
      <v-alert
        v-if="check_cathedrals"
       outlined
        type="warning"
        class="text-justify mx-3  my-2" >
        Обратите внимание! <br>
        В учебном плане присутствуют семестры, у которых не выбраны ведущие кафедры для дисциплин.
        До выбора ведущих кафедр предмет не отобразится у административных работников кафедры и синхронизация учебного плана доступна не будет!
      </v-alert>
      <v-card-text class="pb-1">
        <v-row class="text-left">
          <v-col cols="6" xs="6" md="3">
            <v-card-text class="py-0 font-weight-bold">Список учебных групп:</v-card-text>
          </v-col>
          <v-col cols="6" xs="6" md="9">
            <v-card-text class="py-0">
              <span v-for="(str,index) in curriculum.data.groups">
                {{ str.name }}<span v-if="index!==curriculum.data.groups.length-1">,</span>
              </span>
            </v-card-text>
          </v-col>
        </v-row>
        <v-row class="text-left">
          <v-col cols="6" xs="6" md="3">
            <v-card-text class="py-0 font-weight-bold">Номер учебного плана 1С:</v-card-text>
          </v-col>
          <v-col cols="6" xs="6" md="9">
            <v-card-text class="py-0">
              <span v-if="isLinked">{{curriculum.data.number}}</span>
              <span v-else>Учебный план не связан с 1С</span>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card-text>
      <CurriculumDetailTemplate :loaded="curriculum.loaded" :curriculum="curriculum.data"></CurriculumDetailTemplate>
      <Form @addItem="loadCurriculum()"
            @ended="closedForm()"
            @refreshItem="loadCurriculum()"
            v-bind:opened_form="opened_form"
            v-bind="curriculum.data"
            v-if="opened_form"
      >
      </Form>
      <scroll-center-dialog v-model="updateCurriculum.opened_form_extend"
                            width="800"
                            title="Изменить"
                            @close="closedForm"
                            @apply="saveExtand"
                            :dennyable="false"
                            apply-text="Изменить"
                            :right="true"
      >
        <v-autocomplete
          class="pa-3 ma-1"
          :items="selectors.extend"
          label="Расширяет текущий учебный план"
          v-model="updateCurriculum.opened_form_extend"
          no-data-text="Нет данных"
        ></v-autocomplete>
      </scroll-center-dialog>

      <scroll-center-dialog v-model="updateCurriculum.opened_form_draft"
                            width="500"
                            title="Изменить черновик"
                            apply-text="Изменить"
                            @close="closedForm"
                            @apply="saveDraft"
                            :dennyable="false"
                            :right="true"
      >
        <v-checkbox
          class="pa-3 ma-1"
          label="Черновик"
          v-model="updateCurriculum.draft"
          :error="hasError('is_draft')"
          :error-messages="getError('is_draft')"
        ></v-checkbox>
      </scroll-center-dialog>

      <scroll-center-dialog v-model="updateCurriculum.opened_form_faculty"
                            width="800"
                            title="Изменить факультет"
                            @close="closedForm"
                            @apply="saveFaculty"
                            apply-text="Изменить"
                            :dennyable="false"
                            :right="true"
      >
        <v-autocomplete
          class="pa-3 ma-1"
          :items="selectors.faculty"
          label="Факультет"
          v-model="updateCurriculum.faculty"
          :error="hasError('faculty')"
          :error-messages="getError('faculty')"
          no-data-text="Нет данных"
        >
          <span slot="label">Факультет<span class="red--text">*</span>:</span>
        </v-autocomplete>
      </scroll-center-dialog>
      <ApplyDialog
        v-if="updateCurriculum.need_sync.applier"
        :opened="updateCurriculum.need_sync.applier"
        :disable-apply="check_cathedrals"
        @close="changeNeedSync($event)">
        <template>
          <div class="text-justify pb-2">
            <span v-if="!curriculum.data.need_sync">
              Вы уверены, что хотите запросить синхронизацию для этого учебного плана?<br/>
              Синхронизация пройдет  <b><i>в 2 часа ночи</i></b>.<br/>
              <b>!</b>На время синхронизации учебный план и все объекты, связанные с ним, будут заблокированы.
            </span>
            <span v-else>
              Вы уверены, что хотите отказаться от запрошенной синхронизации для этого учебного плана?<br/>
              Если не провести синхронизацию, то ваши изменения, если они имеются, не будут распространены на обучающихся и группы.
            </span>
          </div>
          <v-alert
            border="bottom"
            colored-border
            type="warning"
            class="text-justify pt-1"
            elevation="2"
          >
            Синхронизация проводит изменение учебного плана для групп и обучающихся, связанных с ним. При создании новой группы все изменения учебного плана применятся автоматически.
            Для учебного плана без обучающихся синхронизация не требуется.
          </v-alert>
          <v-alert
            v-if="check_cathedrals"
            border="bottom"
            colored-border
            type="error"
            class="text-justify pt-1"
            elevation="2">
            Обратите внимание!
            В учебном плане присутствуют семестры, у которых не выбраны ведущие кафедры для дисциплин.
            До выбора ведущих кафедр предмет не отобразится у административных работников кафедры и синхронизация учебного плана доступна не будет!
          </v-alert>
        </template>
      </ApplyDialog>
      <MsgDialog
        v-if="updateCurriculum.need_sync.result.opened"
        :opened="updateCurriculum.need_sync.result.opened"
        :title="updateCurriculum.need_sync.result.text"
        :success="updateCurriculum.need_sync.result.success"
        :warning="updateCurriculum.need_sync.result.warning"
        @close="updateCurriculum.need_sync.result.opened = false"
      >
        <span v-if="curriculum.data.need_sync">
          Объект будет синхронизирован <b><i>в 2 часа ночи следующего дня</i></b>.<br/>
          Вы можете запросить отменить необходимость синхронизации.
          <b>!</b>На время синхронизации учебный план будет заблокирован для изменений, так же как и все объекты связаны с ним.<br/>
        </span>
        <span v-else>
          Объект не будет синхронизирован.<br/>
          Вы можете заново запросить синхронизацию.<br/>
          Если не провести синхронизацию, то ваши изменения, если они имеются, не будут распространены на обучающихся и группы.
        </span>
      </MsgDialog>

      <ApplyDialog
        v-if="updateCurriculum.opened_form_copy"
        :opened="updateCurriculum.opened_form_copy"
        @close="changeCopy($event)"
      >
        <span>Создать ли полную копию данного учебного плана?</span>
      </ApplyDialog>

    </v-card>
    <link-action
      v-if="!isLinked && !isProductionMode"
      v-model="dialogs.link.open"
      :item="curriculum.data"
      :selector-url="dialogs.link.selector_url"
      :action-url="dialogs.link.action_url"
      @reload="loadCurriculum"
      restore-default
    >
    </link-action>
    <action-for-tree
      v-if="isLinked && !isProductionMode"
      v-model="dialogs.clear.open"
      level="curriculum"
      :action-url="dialogs.clear.action_url"
      action-name="очистка информации о связи в ЭИОС и синхронизаторе с 1С Университет по дереву объектов"
      :item="curriculum.data"
      @reload="loadCurriculum"
      applyable
    >
      <template v-slot:title>Очистка информации о связях в дереве</template>
    </action-for-tree>
    <!--    <DebugJsonComponent :json="curriculum.data.is_system"></DebugJsonComponent>-->
  </v-container>
  <router-view v-else></router-view>
</template>

<script>
import names from "@/modules/curriculumEditor/routers/names";
import moment from "moment"
import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
import CurriculumDetailTemplate from "@/modules/curriculumEditor/pages/templates/CurriculumDetailTemplate";
import urls from "../../urls";
import {
  loadData,
  makeGetRequest,
  sendPostRequest,
  isProductionMode
} from "@/helper";

import Form from "@/modules/curriculumEditor/pages/curriculum/Form.vue"
import {makePostRequest} from "@/helper";
import UserDataFormMixin from "@/mixins/UserDataFormMixin";
import {loadFile} from "@/helper/fileLoader";
import MsgDialog from "@/modules/core/components/MsgDialog";
import ApplyDialog from "@/modules/core/components/ApplyDialog";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";
import LinkAction from "@/modules/administrative/dialogs/LinkAction.vue";
import selectors from "@/urls/selectors";
import TooltipBtn from "@/modules/administrative/components/TooltipBtn.vue";
import ActionForTree from "@/modules/curriculumEditor/pages/difference/dialogs/ActionForTree.vue";

export default {
  name: "CurriculumDetail",
  components: {
    TooltipBtn,
    ScrollCenterDialog,
    MsgDialog,
    ApplyDialog,
    DebugJsonComponent,
    Form,
    CurriculumDetailTemplate,
    LinkAction,
    ActionForTree
  },
  mixins: [UserDataFormMixin],
  props: {},
  data() {
    return {
      check_cathedrals: false,
      from_route: null,
      router_name: names.CURRICULUM.DETAIL,//'CurriculumEditorCurriculumDetail',
      title: "Учебный план",
      opened_form: false,
      selectors: {
        faculty: [],
        extend: []
      },
      updateCurriculum: {
        opened_form_extend: false,
        opened_form_draft: false,
        opened_form_faculty: false,
        opened_form_copy: false,
        extend: null,
        draft: null,
        faculty: null,
        need_sync: {
          applier: false,
          result: {
            opened: false,
            text: "",
            success: true,
            warning: false
          }
        }
      },
      curriculum: {
        id: this.$route.params.curriculum_id,
        data: {},
        loading: false,
        loaded: false
      },
      dialogs: {
        link: {
          open: false,
          loading: false,
          selector_url: selectors.SELECTORS.UNIVERSITY.CURRICULUM(),
          action_url: urls.NAMES.EDITOR.UNIVERSITY.LINK.CURRICULUM
        },
        clear: {
          open: false,
          item: undefined,
          action_url: urls.NAMES.EDITOR.UNIVERSITY.CLEAR.CURRICULUM
        }
      }
    }
  },
  computed: {
    is_dean() {
      return this.$store.getters["urls/getAllowed"]("education", "dean|relations|faculty-list", "list")
        &&
        this.$store.getters["urls/getAllowed"]("education", "dean|relations|workers-list", "list")
    },
    curriculum_selector_url(){
      return selectors.SELECTORS.UNIVERSITY.CURRICULUM()
    },
    isLinked(){
      return this.curriculum.data.university_identifier !== undefined && this.curriculum.data.university_identifier !== null
    },
    isProductionMode(){
      return isProductionMode()
    }
  },
  methods: {
    displayDate: function (date, minutes = false) {
      let format = "DD.MM.YYYY"
      if (minutes)
        format += " HH:mm"
      return moment(date).format(format)
    },
    makeMediaUrl(url) {
      return urls.makeMediaHostUrl(url);
    },
    loadFileWork(url) {
      loadFile(url)
    },
    sendData(url, data) {
      let sys = true
      makePostRequest(url, data, "PATCH")
        .then(resp => {
          if (!resp.ok) {
            sys = false
          }
          return resp.json();
        })
        .then(json => {
          if (sys) {
            this.loadCurriculum()
            this.closedForm()
          } else {
            this.receiveErrors(json);
          }
        });
    },
    checkCathedrals(){
      let url = urls.NAMES.EDITOR.CURRICULUM.CHANGE.CHECK(this.curriculum.id)
      loadData(url, (json)=>{
        this.check_cathedrals = json.error
      }, ()=>{});
    },
    saveExtand() {
      let url = urls.getUrlWithParameters("editor|curriculum|curriculum-change-extend", "change_extend")
        .replace("<id>", this.curriculum.id)
      this.sendData(url, {extend: this.updateCurriculum.extend})
    },
    saveDraft() {
      let url = urls.getUrlWithParameters("editor|curriculum|curriculum-change-draft", "change_draft")
        .replace("<id>", this.curriculum.id)
      this.sendData(url, {is_draft: this.updateCurriculum.draft})
    },
    saveFaculty() {
      let url = urls.getUrlWithParameters("editor|curriculum|curriculum-change-faculty", "change_faculty")
        .replace("<id>", this.curriculum.id)
      this.sendData(url, {faculty: this.updateCurriculum.faculty})
    },
    saveNeedSync() {
      let url = urls.getUrlWithParameters("editor|curriculum|curriculum-change-need-sync", "change_need_sync")
        .replace("<id>", this.curriculum.id)
      this.sendData(url, {need_sync: !this.curriculum.data.need_sync})
    },
    changeNeedSync(apply) {
      if (apply) {
        this.saveNeedSync();
      }
      this.updateCurriculum.need_sync.applier = false;
    },
    makeCopy() {
      let url = urls.getUrlWithParameters("editor|curriculum|curriculum-duplication", "duplication")
        .replace("<id>", this.curriculum.id)
      let sys = true
      makePostRequest(url, {})
        .then(resp => {
          if (!resp.ok) {
            sys = false
          }
          return resp.json();
        })
        .then(json => {
          if (sys) {
            this.loadCurriculum()
            this.closedForm()
            alert("Успешно скопирован")
          } else {
            this.receiveErrors(json);
          }
        });
    },
    changeCopy(apply) {
      if (apply) {
        this.makeCopy();
      }
      this.updateCurriculum.opened_form_copy = false
    },

    loadCurriculum: function () {
      this.opened_form = false
      let saver = (data) => {
        this.curriculum.data = data;
        this.updateCurriculum.draft = data.is_draft
        this.updateCurriculum.faculty = data.faculty_uid
        this.curriculum.loaded = true;
        this.checkCathedrals()
        this.updateLeftMenu(this.curriculum.data.is_system)
      };
      let catcher = (error) => {
        console.log(error);
      };
      let url = urls.NAMES.EDITOR.CURRICULUM.RETRIEVE(this.curriculum.id);
      loadData(url, saver, catcher);
    },
    openForm() {
      this.opened_form = true
    },
    closedForm() {
      this.opened_form = false
      this.updateCurriculum.opened_form_extend = false
      this.updateCurriculum.opened_form_draft = false
      this.updateCurriculum.opened_form_faculty = false
      this.updateCurriculum.opened_form_copy = false
    },
    getLeftMenu(is_system) {
      // alert(is_system)
      if (!is_system) {
        return [
          {
            title: "Назад", icon: "undo", router: this.from_route || {
              name: names.CURRICULUM.LIST,
              params: {size: this.$route.params.size, page: this.$route.params.page}
            }
          },//'CurriculumEditorCurriculumList'}},
          {
            title: "Учебный план",
            icon: "view_sidebar",
            router: {name: names.CURRICULUM.DETAIL/*'CurriculumEditorCurriculumDetail'*/, params: this.$route.params}
          },
          {
            title: "Сводная таблица",
            icon: "grading",
            router: {name: names.CURRICULUM.SUMMARY, params: this.$route.params}
          },
          !isProductionMode() ? {
            title: "Отличия от 1С",
            icon: "difference",
            router: {name: names.CURRICULUM.DIFFERENCE, params: this.$route.params}
          } : null,
          {
            title: "Дисциплины",
            icon: "subject",
            router: {
              name: names.CURRICULUM.SUBJECTS.LIST/*'CurriculumEditorSubjectsList'*/,
              params: this.$route.params
            },
            included: []
          },
          // {title: 'Сводная таблица', icon: 'grading', router: {name: names.CURRICULUM.SUMMARY, params: this.$route.params}},
          {
            title: "Семестры",
            icon: "view_agenda",
            router: {
              name: names.CURRICULUM.SEMESTERS.LIST/*'CurriculumEditorSemestersList'*/,
              params: this.$route.params
            },
            included: true
          }
        ]
      } else {
        return [
          {
            title: "Назад", icon: "undo", router: this.from_route || {
              name: names.CURRICULUM.LIST,
              params: {size: this.$route.params.size, page: this.$route.params.page}
            }
          }
        ]
      }
    },
    updateLeftMenu: function (is_system) {
      let menu = this.getLeftMenu(is_system);
      this.$store.commit("mainpage/changeTitle", this.title);
      this.$store.commit("leftmenu/replaceItems", menu);
    },
    loadSelector(data, url) {
      let sys = true
      makeGetRequest(url).then(resp => {
        if (!resp.ok) {
          sys = false
        }
        return resp.json()
      }).then(json => {
        this.selectors[data] = json
      })
    },
    openDialog(dialog){
      dialog.open = true
    }
    // openLink(){
    //   this.dialogs.link.item = this.curriculum;
    //   this.dialogs.link.open = true;
    // },
    // linkCurriculum({education_id, university_guid, restore}){
    //   this.dialogs.link.loading = true
    //   this.dialogs.link.errors = {}
    //   sendPostRequest(
    //     urls.NAMES.EDITOR.UNIVERSITY.LINK.CURRICULUM(education_id),
    //     {
    //       university: university_guid,
    //       restore: restore
    //     },
    //     () => {
    //       this.loadCurriculum()
    //       this.dialogs.link.loading = false
    //     },
    //     (errors) => {
    //       this.dialogs.link.loading = false
    //       if (errors)
    //         this.dialogs.link.errors = errors
    //     }
    //   )
    // }
  },
  beforeRouteUpdate(to, from, next) {
    this.updateLeftMenu();
    next();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.from_route !== undefined && from.name && !from.name.includes(names.CURRICULUM._BASE)) {
        vm.from_route = from;
        vm.updateLeftMenu();
      }
    });
  },
  created() {
    this.$store.commit("mainpage/changeTitle", this.title);
    this.loadSelector("faculty", urls.getUrlWithParameters("selectors|relations|faculty", "get"));
    this.loadSelector("extend", urls.getUrlWithParameters("selectors|curriculum|curriculum", "get"));
    this.loadCurriculum();
    // this.updateLeftMenu()
  }
}
</script>

<style scoped>

</style>
