<template>
  <v-row>
    <v-col cols="12">
      <v-alert outlined color="info">
        <v-row>
          <v-col cols="12" md="4">
            <b>Контакты Единого деканата:</b>
            <ul>
              <li>Почта: <a href="mailto:student@muctr.ru">student@muctr.ru</a></li>
              <li>Телефон: 8(499) 978 95 15</li>
            </ul>
          </v-col>
          <v-col cols="12" md="4">
            <b>Адрес:</b><br/>
            Москва, Миусская площадь, д.9, каб. 131<br/>
          </v-col>
          <v-col cols="12" md="4">
            <b>Время работы(график):</b><br/>
            <ul>
              <li>пн-чт 09:00 - 18:00</li>
              <li>пт 09:00 - 16:00</li>
            </ul>
          </v-col>
        </v-row>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>


export default {
  name: "SingleDeanTopTemplate",
  components: {}
}
</script>

<style scoped>

</style>
