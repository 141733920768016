<template>
  <v-layout align-center column fill-heigh justify-center v-if="loadingPage">
    <div class="action">
      <semipolar-spinner
        :animation-duration="2000"
        :size="115"
        color="#1935ff"
      />
    </div>
    {{loadPageErrorMsg}}
  </v-layout>
  <v-flex   v-else>
    <slot></slot>
  </v-flex>
</template>

<script>
  import {SemipolarSpinner} from "epic-spinners"

  export default {
    name: "LoadPage",
    components: {
      SemipolarSpinner
    },
    props: {
      loadingPage: Boolean,
      loadPageErrorMsg: String
    },
    data: function () {
      return {}
    }
  }
</script>

<style scoped>

</style>
