<template>
  <v-container style="min-width: 100%;">
    <v-data-table
      v-if="rows.length !== 0"
      :items="rows"
      :headers="header_computed"
      dense
      :items-per-page="rows.length"
      hide-default-footer
      :search="search"
      :loading="loading"
      no-data-text="Нет данных"
    >
      <template v-slot:item.subject_name="props" v-if="load_half">
        <div>
          <v-tooltip bottom v-if="props.item.mottled">
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="ma-0"
                medium
                :style="Rotate(props.item.half)"
              >
                hourglass_bottom
              </v-icon>
            </template>
            <span>{{props.item.half}} половина семестра</span>
          </v-tooltip>
          {{props.item.subject_name}}
        </div>
      </template>
      <template v-slot:item.is_individual="props">
        <v-tooltip bottom v-if="props.item.is_individual">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on">info_outline
            </v-icon>
          </template>
          <span>Индивидуальный</span>
        </v-tooltip>
      </template>
      <template v-slot:item.first_col="props">
        <span v-if="props.item[first_row_value] !== ''">{{ props.item[first_row_value] }}</span>
        <v-skeleton-loader v-else type="text"></v-skeleton-loader>
      </template>
      <template v-slot:header.medium_control>
        <div class="d-flex flex-column">
          <div class="px-1 text-center">Текущий контроль</div>
          <v-row>
            <v-col cols="4">1 контрольная точка</v-col>
            <v-col cols="4">2 контрольная точка</v-col>
            <v-col cols="4">3 контрольная точка</v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:item.medium_control="props">
        <v-row v-if="props.item.medium_control.length">
          <v-col v-for="(item, index) in props.item.medium_control" :order="index" class="py-0 px-2 my-auto mx-0" :key="`medium_control_${item.id}_${index}`">
            <v-text-field
              type="number"
              step="1"
              min="0"
              max="100"
              v-model="item.value"
              v-if="can_update"
              hide-details
              dense
              class="body-2 my-auto"
              :disabled="save_execute"
              :error="haveError(item)"
              :error-messages="getError(item)"
            ></v-text-field>
            <span v-else>
            <v-tooltip top v-if="item.date">
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on">{{item.value}}</span>
              </template>
              <span>{{displayDate(item.date)}}</span>
            </v-tooltip>
            <span v-else>
              {{item.value}}
            </span>
            </span>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <span>не созданы контрольные точки</span>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.exam="props">
        <v-text-field
          type="number"
          step="1"
          min="0"
          max="40"
          v-model="props.item.exam"
          v-if="can_update"
          hide-details
          dense
          class="body-2 my-auto"
          :disabled="save_execute"
        ></v-text-field>
        <span v-else>{{props.item.exam}}</span>
      </template>
      <template v-slot:item.rating="props" v-if="show_marks">
        <v-row class=" fill-height" style="min-width: 120px;">
          <v-col  cols="2" class="ma-0 pa-0 d-flex justify-space-between my-auto">
            <span class="d-flex">{{props.item.rating}}</span>
          </v-col>
          <v-col  cols="8" class="ma-0 pa-0 d-flex justify-space-between my-auto">
            <v-chip v-if="props.item.reload" small :close="can_close_reload"  @click:close="$emit('open', {student_id: props.item.student_education_id, id: props.item.id})">
              Перезачет
            </v-chip>
          </v-col>
          <v-col cols="2" class="ma-0 pa-0 d-flex justify-space-between my-auto">
            <v-tooltip top>
              <template v-slot:activator="{on, attrs}">
                <v-col cols="auto" class="ma-0 pa-0">
                  <v-icon
                    class="ml-auto"
                    small
                    v-on="on"
                    v-bind="attrs"
                  >
                    info
                  </v-icon>
                </v-col>
              </template>
              <span>{{props.item.text_rating}}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
      <template v-slot:loading>
        <v-skeleton-loader
          type="table-row"
        >
        </v-skeleton-loader>
      </template>
    </v-data-table>
    <span v-else>
    В учебной группе отсутствуют обучающиеся и журнал недоступен. Пожалуйста, обратитесь в деканат учебной группы.
    </span>
    <slot name="extra_actions"></slot>
  </v-container>
</template>

<script>
import {clearUserAuth, displayDate, makeDeleteRequest} from "@/helper";

export default {
  name: "JournalTableTemplate",
  props: {
    can_update: {
      type: Boolean,
      required: true,
      default: false
    },
    load_half: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Array,
      required: true
    },
    search: {
      type: String,
      required: true
    },
    save_execute: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      required: true
    },
    first_row_label: {
      required: false,
      type: String,
      default: "Обучающийся"
    },
    first_row_value: {
      required: false,
      type: String,
      default: "fio"
    },
    grouped: {
      required: false,
      default: false,
      type: Boolean
    },
    show_marks: {
      type: Boolean,
      default: false
    },
    can_close_reload: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      headers: [
        {text: this.first_row_label || "Обучающийся", value: this.first_row_value, sortable: true, filterable: true, width: "25%"},
        {text: "", value: "is_individual", sortable: false, filterable: false, width: "3%"},
        {text: "Текущий контроль", value: "medium_control", sortable: false, filterable: false, width: "30%"},
        {text: "Доп. опрос", value: "additional", sortable: false, filterable: false, width: "10%"},
        {text: "Рейтинг в семестре", value: "rating_in_semester", sortable: true, filterable: false, width: "10%"},
        {text: "Экзамен", value: "exam", sortable: false, filterable: false, width: "10%"},
        {text: "Сумма итоговых баллов", value: "rating", sortable: true, filterable: false, width: "10%"},
        {text: "Оценка", value: "text_rating", sortable: false, filterable: false, width: "10%"}
      ]
    }
  },
  computed:{
    header_computed() {
      let header_comp = []
      if (this.show_marks){
        header_comp = [
          {text: this.first_row_label || "Обучающийся", value: this.first_row_value, sortable: true, filterable: true, width: "25%"},
          {text: "", value: "is_individual", sortable: false, filterable: false, width: "3%"},
          {text: "Текущий контроль", value: "medium_control", sortable: false, filterable: false, width: "30%"},
          {text: "Доп. опрос", value: "additional", sortable: false, filterable: false, width: "11%"},
          {text: "Рейтинг в семестре", value: "rating_in_semester", sortable: true, filterable: false, width: "8%"},
          {text: "Экзамен", value: "exam", sortable: false, filterable: false, width: "11%"},
          {text: "Итоговая оценка", value: "rating", sortable: false, filterable: false, width: "25%"}
        ]
      }
      else{
        header_comp = this.headers
      }
      return header_comp
    }
  },
  methods: {
    displayDate,
    findInErrors(item){
      return this.errors.findIndex((fel) => {return fel.student === item.student_education_id})
    },
    haveError(item){
      return this.findInErrors(item) !== -1
    },
    getError(item){
      let index = this.findInErrors(item);
      if(index !== -1){
        return this.errors[index].message.value
      }
    },
    Rotate(half) {
      let sign = ""
      if (half === "вторая")
        sign = "-"
      return "transform: rotate(" + sign + "90deg)"
    }
  },
  created() {
    // console.log(this.rows)
  }
}
</script>

<style scoped>

</style>
