<template>
  <scroll-center-dialog v-model="opened_form"
                        @close="closeDialog"
                        @apply="apply"
                        width="500"
                        :title="!!id ? 'Изменить' : 'Добавить'"
  >
    <v-text-field
      :error="hasError('number')"
      :error-messages="getErrorArray('number')"
      label="Курс"
      v-model="value.number"
      type="number"
    ></v-text-field>
    <v-autocomplete
      :error="hasError('level')"
      :error-messages="getErrorArray('level')"
      :items="selectors.level"
      label="Уровень"
      v-model="value.level"
      no-data-text="Нет данных"
    ></v-autocomplete>
  </scroll-center-dialog>
</template>

<script>
  import urls from "../urls"
  import UserDataFormMixin from "../../../mixins/UserDataFormMixin"
  import {makeGetRequest} from "../../../helper";
  import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

  export default {
    name: "Courses",
    components: {ScrollCenterDialog},
    mixins: [UserDataFormMixin],
    props: {
      id: Number,
      number: String,
      level: String,
      opened_form: Boolean
    },
    data() {
      return {
        URL_CREATE: urls.getUrl("admin|core|course-list", "list"),
        URL_UPDATE: urls.getUrl("admin|core|course-detail", "destroy"),
        selectors:{
          level: []
        },
        value: {
          id: this.id || "",
          number: this.number || "",
          level: this.level || false
        }
      }
    },
    methods: {
      loadSelector(data, url) {
        let sys = true
        makeGetRequest(url).then(resp => {
          if (!resp.ok) {
            sys = false
          }
          return resp.json()
        }).then(json => {
          this.selectors[data] = json
        })
      },
      closeDialog() {
        this.$emit("ended", "")
      }
    },
    created() {
      let url = urls.getUrl("choices|ministry|level",  "get")
      this.loadSelector("level",url)
    }
  }

</script>

<style scoped>

</style>
