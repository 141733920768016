<template>
  <v-card-text v-if="loaded" class="text-left">
    <v-card-title>
      "{{ curriculum.eios.name }}" ===> "{{ curriculum.one.number }}"
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="curriculum_difference"
      disable-pagination
      disable-filtering
      disable-sort
      hide-default-footer
    >
      <template v-slot:item.difference="{ item }">
        <v-icon v-if="item.difference" color="green">done</v-icon> <v-icon v-else>clear</v-icon>
      </template>
    </v-data-table>
    <subjects
      :subjects="curriculum.subjects"
      :curriculum="curriculum"
      @refresh="$emit('refresh')"
      @update:subjects="updateSubjects"
      :loading="loading"
    ></subjects>

  </v-card-text>
  <v-skeleton-loader v-else type="card" width="100%" height="300px"></v-skeleton-loader>
</template>

<script>
import Subject from "@/modules/curriculumEditor/pages/difference/Subject.vue";
import Semester from "@/modules/curriculumEditor/pages/difference/Semester.vue";
import DifferenceColumn from "@/modules/curriculumEditor/pages/difference/components/DifferenceColumn.vue";
import Kinds from "@/modules/curriculumEditor/pages/difference/Kinds.vue";
import Subjects from "@/modules/curriculumEditor/pages/difference/Subjects.vue";

export default {
  name: "Curriculum",
  components: {Subjects, Kinds, Subject, Semester, DifferenceColumn},
  props: {
    loaded: undefined,
    curriculum: undefined,
    loading: {
      default: false
    }
  },
  data() {
    return {
      headers: [
        {value: "field", text: "Поле", sortable: false, align: "left",weight:"30%"},
        {value: "difference", text: "Отличается", sortable: false, align: "left",weight:"10%"},
        {value: "eios", text: "ЭИОС", sortable: false, align: "left",weight:"30%"},
        {value: "one", text: "1С", sortable: false, align: "left",weight:"30%"}
      ],
      headers_semesters: [
        {value: "semester", text: "Семестр", sortable: false, align: "left", weight:"25%"},
        {value: "course", text: "Курс", sortable: false, align: "left", weight:"25%"},
        {value: "hours", text: "Часы", sortable: false, align: "left", weight:"25%"},
        {value: "zets", text: "Зеты", sortable: false, align: "left", weight:"25%"},
        {value: "actions", text: "Действия", sortable: false, align: "left", weight:"25%"}
      ],
      groups: undefined,
      expanded: []
      // semesters: [],
    }
  },
  watch: {
    curriculum: function(nV, oV) {
      // this.semesters = this.getSemesters()
      // this.expanded = this.semesters
    }
  },
  computed: {
    curriculum_difference(){
      let difference = []
      difference.push(
        {
          field: "Направление подготовки",
          difference: this.curriculum.difference.direction,
          eios: this.curriculum.eios.direction.name,
          one: this.curriculum.one.direction.name
        },
        {
          field: "Профиль подготовки",
          difference: this.curriculum.difference.profile,
          eios: this.curriculum.eios.profile.name,
          one: this.curriculum.one.profile.name
        },
        {
          field: "Факультет",
          difference: this.curriculum.difference.faculty,
          eios: this.curriculum.eios.faculty,
          one: this.curriculum.one.faculty.name
        },
        {
          field: "Уровень",
          difference: this.curriculum.difference.level,
          eios: this.curriculum.eios.direction.level,
          one: this.curriculum.one.level.university_value
        },
        {
          field: "Форма",
          difference: this.curriculum.difference.form,
          eios: this.curriculum.eios.form,
          one: this.curriculum.one.form.university_value
        },
        {
          field: "Год поступления",
          difference: this.curriculum.difference.year,
          eios: this.curriculum.eios.year,
          one: this.curriculum.one.years
        },
        {
          field: "Номер",
          difference: this.curriculum.difference.number,
          eios: this.curriculum.eios.number,
          one: this.curriculum.one.number
        }
      )
      return difference
    }
  },
  methods: {
    updateSubjects(subjects){
      let new_curriculum = this.curriculum
      new_curriculum.subjects = subjects
      this.$emit("update:curriculum", new_curriculum)
    }
  },
  created() {

  }
}
</script>

<style scoped>

</style>
