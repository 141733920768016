import store from "../store";
import moment from "moment";

export function getUserToken() {
  return localStorage.getItem("user-token")
}

export function isWorker() {
  return store.state.user.isWorker && localStorage.getItem("isWorker")
}
export function isStudent() {
  return store.state.user.isStudent && localStorage.getItem("isStudent")
}

export function isProductionMode() {
  return process.env.NODE_ENV === "production"//'development'
}

export function isAuthenticated() {
  return store.getters["user/isAuthenticate"];// && localStorage.getItem('user-token') && window.$cookies.isKey('userMuctr')
}
export function clearUserAuth(){
  localStorage.removeItem("user-token"); // clear your user's token from localstorage
  localStorage.removeItem("isWorker");
  localStorage.removeItem("username");
  localStorage.removeItem("user-uid");
  window.$cookies.remove("userMuctr");
  store.commit("user/AUTH_LOGOUT")
}

export function checkIsWorker() {
  if(store.state.user.isWorker === null){
    store.dispatch("user/CHECK_USER_IS_WORKER", () => {

    })
  }
}

export function isAliveToken(success = () => {}, error = () => {}) {
  if(store.getters["user/isCheckExpired"]()){
    store.dispatch("user/CHECK_TOKEN_ALIVE").then(
      () => {
        // checkIsWorker();
        success()
      }
    ).catch(error)
  } else {
    success()
    // checkIsWorker()
  }
}

export function goToLoginPage(){
  document.location.href = process.env.VUE_APP_LOGIN_HOST + `?from=${document.location.href}`
}

// export function isAliveToken(success=()=>{}, error=()=>{}) {
//   const host_account = process.env.VUE_APP_API_HOST + process.env.VUE_APP_ACCOUNTS_PREFIX;
//   let url = host_account + 'authenticate/token/alive/';
//   console.log(1)
//   if (store.getters['user/hasCookie']) {
//     store.dispatch('user/LOAD_FROM_COOKIE').then(() => {
//       makePostRequest(
//         url,
//         {token: store.getters['user/get_token']}
//       ).then(resp => {
//           if (!resp.ok) {
//             throw Error(resp.statusText);
//           }
//         console.log(2)
//           return resp.json()
//         }
//       ).then(json => {
//         console.log(json.alive)
//         if (json.alive === false) {
//           store.dispatch('user/AUTH_LOGOUT');
//           store.dispatch('urls/clearUrls');
//         }else{
//           success(json);
//         }
//       }).catch(error => {
//         store.dispatch('user/AUTH_LOGOUT');
//         store.dispatch('urls/clearUrls');
//         error();
//       });
//     })
//   }
//   else{
//     error();
//   }
// }

export function getUserUid() {
  return localStorage.getItem("user-uid");
}

export function getHttpHeaders() {
  /**
   * Возвращает словарь заголовков запроса для отправки запрос содержащего JSON пакет данных
   *
   * @return {Map} промис запроса
   *
   */
  let headers = {
    "Content-Type": "application/json"
  }
  if (getUserToken())
    headers["Authorization"] = "Token " + getUserToken()
  // console.log(headers)
  return headers
}

export function getHttpHeadersFile() {
  /**
   * Возвращает словарь заголовков запроса для отправки запроса содержащего файлы
   *
   * @return {Map} промис запроса
   *
   */
  let headers = {}// 'Content-Type': 'multipart/form-data',}
  if (getUserToken())
    headers["Authorization"] = "Token " + getUserToken()
  // console.log(headers)
  return headers
}

export function makeGetRequest(url, addheaders) {
  /**
   * Выполняет запрос типа GET для запроса данных
   *
   * @param {string} url - адресс на который посылается запрос
   * @param {Object} addheaders - дополнительные заголовки запроса упакованные в словарь.
   *
   * @return {Promise} промис запроса
   *
   */
  let headers = getHttpHeaders();
  return fetch(url, {
    headers: headers,
    method: "GET"
  });
}

export function makePostRequest(url, data, method, addheadres) {
  /**
   * Выполняет запрос типа POST с отправкой данных упакованных в JSON пакет
   *
   * @param {string} url - адресс на который посылается запрос
   * @param {Object} data - данные упакованные в объект отправляемые на адресс
   * @param {Object} addheaders - дополнительные заголовки запроса упакованные в словарь.
   *
   * @return {Promise} промис запроса
   *
   */
  let headers = getHttpHeaders()
  if (!method)
    method = "POST"
  return fetch(url, {
    headers: headers,
    method: method,
    body: JSON.stringify(data)
  });
}

export function makePostRequestFile(url, data, method, addheadres) {
  /**
   * Выполняет запрос настраиваемого типа с отправкой данных типа FormData
   *
   * @param {string} url - адресс на который посылается запрос
   * @param {Object} data - данные упакованные в объект отправляемые на адресс
   * @param {Object} addheaders - дополнительные заголовки запроса упакованные в словарь.
   *
   * @return {Promise} промис запроса
   *
   */
  let headers = getHttpHeadersFile()
  if (!method)
    method = "POST"
  return fetch(url, {
    headers: headers,
    method: method,
    body: data
  });
}

export function makeGetFileRequest(url, addheaders, addinits) {
  let headers = getHttpHeadersFile();
  return fetch(url, {
    headers: {
      ...headers,
      ...addheaders
    },
    method: "GET",
    ...addinits
  });
}

export function makeUpdateRequest(url, data, addheaders) {
  /**
   * Выполняет запрос типа PUT с отправкой данных упакованных в JSON пакет
   *
   * @param {string} url - адресс на который посылается запрос
   * @param {Object} data - данные упакованные в объект отправляемые на адресс
   * @param {Object} addheaders - дополнительные заголовки запроса упакованные в словарь.
   *
   * @return {Promise} промис запроса
   *
   */
  let headers = getHttpHeaders()
  return fetch(url, {
    headers: headers,
    method: "PUT",
    body: JSON.stringify(data)
  });
}

export function makeParticalUpdateRequest(url, data, addheaders) {
  /**
   * Выполняет запрос типа PATCH для частичного изменения какого то объекта
   *
   * @param {string} url - адресс на который посылается запрос
   * @param {Object} data - данные упакованные в объект отправляемые на адресс
   * @param {Object} addheaders - дополнительные заголовки запроса упакованные в словарь.
   *
   * @return {Promise} промис запроса
   *
   */
  return makePostRequest(url, data, "PATCH")
}

export function makeDeleteRequest(url) {
  /**
   * Выполняет запрос типа DELETE с возможностью отправки данных
   *
   * @param {string} url - адресс на который посылается запрос
   * @param {Object} addheaders - дополнительные заголовки запроса упакованные в словарь.
   *
   * @return {Promise} промис запроса
   *
   */
  let headers = getHttpHeaders()
  return fetch(url, {
    headers: headers,
    method: "DELETE"
  });
}

export function makeProfileUrl(url) {
  return url.replace("<user-uid>", getUserUid()).replace("<uuid:user>", getUserUid()).replace("<user>", getUserUid()).replace("<user_uid>", getUserUid());
}


export function getInstNameByRouterName(router) {
  /**
   * Функция которая возвращает нижнее боковое меню инстанса
   * @return {Array} - списко всех подмодулей инстанса, отображаемых в левом нижнем меню
   */
  return getInstMenu().find(el => {return router === el.router}).name
}

export function loadCookies() {

}

export function makeManagerUrl(url, manager) {
  return url.replace("<manager-uid>", manager);
}

export function getDisplay(value, selector) {
/**
  * Функции выбиращая текстовое отображения для значения из списка выбора
  *
  * @param {string} url - урл с которого загружаются данные
  * @param {function} finalizer - функция которая выполняется после загрузки данных, и сохраняет их
  * @param {function} catcher - функция которая выполняется если загрузка произошла не с кодом ОК
  *
  */
  if (selector) {
    let res = selector.find((el) => {
      if (el.value === value)
        return true
    });
    if (res)
      return res.text;
    else
      return null;
  } else
    return null;
}



export function finalizeRequest(request, finalizer=()=>{}, catcher=()=>{}, log=(resp, data)=>{}, sent={}, url=null) {
  /**
   * Функция завершающая запрос и прокидывающая колбеки внутрь промисов
   *
   * @param {Promise} request - промис произовольного запроса, вокруг которого нужно сделать обертку
   * @param {function} finalizer - функция которая выполняется после загрузки данных, и сохраняет их
   * @param {function} catcher - функция которая выполняется если загрузка произошла не с кодом ОК
   *
   */
  request
    .then(resp => {
      // console.log(resp)
      if (!resp.ok) {
        return resp.json()
          .then((json) => {
            log(resp, json)
            catcher(json)
          })
          .catch((err) => {
            log(resp, err)
            catcher(err)
          });
      }else {
        return resp.json()
          .then(json => {
            log(resp, json)
            finalizer(json)
          })
          .catch((err) => {
            log(resp, err)
            finalizer(undefined)
          });
      }
    }).catch(error => {
      console.error(error)
      log({
        url: request.url,
        status: "caught",
        statusText: error.toString(),
        type: request.method
      }, error)
      catcher(error)
    })

}

export function sendGetRequest(url, finalizer=()=>{}, catcher=()=>{}){
/**
  * Функции выполняющей гет запрос при помощи промисов
  *
  * @param {string} url - урл с которого загружаются данные
  * @param {function} finalizer - функция которая выполняется после загрузки данных, и сохраняет их
  * @param {function} catcher - функция которая выполняется если загрузка произошла не с кодом ОК
  *
  * */
  finalizeRequest(
    makeGetRequest(url),
    (data) => finalizer(data, url),
    (data, status) => catcher(data, url, status),
    (resp, data)=>{
      store.dispatch("feedback/appendToRequestHistory", {response: resp, data: data, sent: {}, url: url})
    }
  );
}

export function sendPatchRequest(url, data,  finalizer=()=>{}, catcher=()=>{}){
  /**
   * Функции выполняющей гет запрос при помощи промисов
   *
   * @param {string} url - урл с которого загружаются данные
   * @param {function} finalizer - функция которая выполняется после загрузки данных, и сохраняет их
   * @param {function} catcher - функция которая выполняется если загрузка произошла не с кодом ОК
   *
   * */
  finalizeRequest(
    makeParticalUpdateRequest(url, data),
    (data) => finalizer(data, url),
    (data, status) => catcher(data, url, status),
    (resp, data)=>{
      store.dispatch("feedback/appendToRequestHistory", {response: resp, data: data, url: url, sent: data})
    }
  );
}



export function sendPostRequest(url, data, finalizer=()=>{}, catcher=()=>{}){
  /**
   * Функции выполняющей гет запрос при помощи промисов
   *
   * @param {string} url - урл с которого загружаются данные
   * @param {Object} data - данные отправляемые
   * @param {function} finalizer - функция которая выполняется после загрузки данных, и сохраняет их
   * @param {function} catcher - функция которая выполняется если загрузка произошла не с кодом ОК
   *
   * */
  finalizeRequest(
    makePostRequest(url, data),
    (data) => finalizer(data, url),
    (data, status) => catcher(data, url, status),
    (resp, data)=>{
      store.dispatch("feedback/appendToRequestHistory", {response: resp, data: data, url: url, sent: data})
    }
  );
}

export function sendPutRequest(url, data, finalizer=()=>{}, catcher=()=>{}){
  /**
   * Функции выполняющей гет запрос при помощи промисов
   *
   * @param {string} url - урл с которого загружаются данные
   * @param {Object} data - данные отправляемые
   * @param {function} finalizer - функция которая выполняется после загрузки данных, и сохраняет их
   * @param {function} catcher - функция которая выполняется если загрузка произошла не с кодом ОК
   *
   * */
  finalizeRequest(
    makeUpdateRequest(url, data),
    (data) => finalizer(data, url),
    (data, status) => catcher(data, url, status),
    (resp, data)=>{
      store.dispatch("feedback/appendToRequestHistory", {response: resp, data: data, url: url, sent: data})
    }
  );
}

export function sendDeleteRequest(url, finalizer=()=>{}, catcher=()=>{}){
  /**
   * Функции выполняющей гет запрос при помощи промисов
   *
   * @param {string} url - урл с которого загружаются данные
   * @param {Object} data - данные отправляемые
   * @param {function} finalizer - функция которая выполняется после загрузки данных, и сохраняет их
   * @param {function} catcher - функция которая выполняется если загрузка произошла не с кодом ОК
   *
   * */
  finalizeRequest(
    makeDeleteRequest(url),
    (data) => finalizer(data, url),
    (data, status) => catcher(data, url, status),
    (resp, data)=>{
      store.dispatch("feedback/appendToRequestHistory", {response: resp, data: data, url: url, sent: {}})
    }
  );
}

export function loadData(url, saver=()=>{}, catcher=()=>{}){
  /**
  * Ренейм функции выполняющей гет запрос
  *
  * @param {string} url - урл с которого загружаются данные
  * @param {function} saver - функция которая выполняется после загрузки данных, и сохраняет их
  * @param {function} catcher - функция которая выполняется если загрузка произошла не с кодом ОК
  *
  * */
  sendGetRequest(url, saver, catcher);
}

export function mapPaginatedResult(from, to) {
  /**
   * Присваивает параметры пагинации к объекту из запроса
   *
   * @param {Object} from - откуда брать параметры пагинации
   * @param {Object} to - куда прокидывать параметры пагинации
   */
  to.next = from.next;
  to.prev = from.previous;
  to.count = from.count;
}

export function setUrlParameters(url, parameters) {
  /**
   * замена параметров урла
   *
   * @param {string} url - адресс в котором будут проводиться замены параметров
   * @param {Map} parameters - словарь заменяемых параметров, где ключ это что заменяем, а значение на что заменяем
   *
   * @return {string} итоговый урл
   */
  for(let key in parameters){
    url = url.replace("<" + key + ">", parameters[key]);
  }
  return url
}

export function addGetParameters(url, parameters){
  /**
   * Добавление к адресу гет параметры
   *
   * @param {string} url - адресс к которому добавляются гет параметры
   * @param {Map} parameters - словарь добавляемых параметров, где ключ название параметра а значаение это добавляемое значение
   * @type {Array}
   */
  // let urlObject = new URL(url);
  // let parameters = urlObject.searchParams;
  // new URLSearchParams(params).toString();
  // urlObject.searchParams

  let urlObject = new URL(url);
  for(let el of Object.keys(parameters)){
    if(parameters[el] === undefined || parameters[el] == null)
      urlObject.searchParams.delete(el)
    else
      urlObject.searchParams.set(el, parameters[el])
  }
  return urlObject.toString();
  // let gparameters = [];
  // if(url.includes('?')){
  //   let split = url.split('?');
  //   url = split[0]
  //   gparameters = [split[1],]
  // }
  // for(let key in parameters){
  //   gparameters.push(`${key}=${parameters[key]}`)
  // }
  // return `${url}?${gparameters.join('&')}`
}

export function checkFieldExist(object, field) {
  /**
   * Проверка существования поля в объекте
   *
   * @param {Object} object - объект в котором проводиться проверка
   * @param {String} field - поле которое проверяется в объекте
   *
   * @return {Boolean} - результат True если есть
   */
  if(object !== Object && object !== Array)
    return false;
  return field in object
}

export function checkFieldTrue(object, field) {
  /**
   * Проверить что поле существуе и оно не null, undefined, False
   *
   * @param {Object} object - объект в котором проводиться проверка
   * @param {String} field - поле которое проверяется в объекте
   *
   * @return {Boolean} - результат True если соответвует условиям
   */
  return checkFieldExist(object, field) && object[field]
}

export function getFieldOrEmpty(object, field) {
  /**
   * Вывод либо значение поля, либо пустую строку
   *
   * @param {Object} object - объект в котором проводиться проверка
   * @param {String} field - поле которое проверяется в объекте
   *
   * @return {Boolean} - результат True если соответвует условиям
   */
  if(checkFieldExist(object, field))
    return object[field];
  return ""
}

export function displayDate(date, minutes = false) {
  /**
   * Вывод нормального представления даты по русски
   * @param {string} date - строка даты и времени
   * @param {Boolean} minutes - выводить минут или не выводить, по умолчанию нет
   *
   * @return {String} вывод строки даты в нужном виде
   */
  let format = "DD.MM.YYYY";
  if (minutes)
    format += " HH:mm";
  return moment(date).format(format)
}

export function checkIsProductionServer() {
  return process.env.NODE_ENV === "production"
}
export function checkIsDevelopmentServer() {
  return process.env.NODE_ENV === "development"
}
