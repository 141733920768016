<template>
  <div v-if="is_self_route" >
    <DetailBase v-if="loaded" :student_url="student"></DetailBase>
  </div>
  <router-view v-else></router-view>
</template>

<script>
  import TitledPageMixin from "@/mixins/TitledPageMixin";
  import {loadData} from "@/helper";
  import cathedras from "../../../../urls/cathedras";
  import names from "../../routers/names";
  import DetailBase from "./DetailBase";
  // import router from "../../../../router";

  export default {
    name: "Base",
    components: {
      DetailBase
    },
    mixins: [
      TitledPageMixin],
    data: function () {
      return {
        cathedra_uid: this.$route.params.cathedra_uid.toUpperCase(),
        student_id: this.$route.params.student_id,
        student: null,
        loaded: false,
        attachment: false
      }
    },
    computed: {
      is_self_route: function () {
        return names.CathedraStudentsDetail === this.$route.name
      },
      taught: function () {
        return this.$route.params.taught === "taught"
      },
      detailUrl: function () {
        if(this.taught){
          return cathedras.CATHEDRA.STUDENT.TAUGHT.DETAIL
        }else{
          return cathedras.CATHEDRA.STUDENT.OWNED.DETAIL
        }
      }
    },
    methods: {

      updateLeftMenu: function () {
        // console.log('updateLeftMenu')
        let menu = this.getLeftMenu();
        // console.log(menu)
        this.$store.commit("leftmenu/replaceItems", menu);
      },
      getLeftMenu() {
        if(this.attachment) {
          let with_cathedra_uid = (name) => {
            return {name: name, params: {cathedra_uid: this.$route.params.cathedra_uid}}
          };
          let params = {
            cathedra_uid: this.$route.params.cathedra_uid,
            taught: this.$route.params.taught,
            student_id: this.$route.params.student_id
          };
          let query = {
            group: this.$route.query.group,
            teacher: this.$route.query.teacher
          };
          let leftmenu = [
            {title: "Обучающийся", icon: "person", included: false, router: {
                name: names.CathedraStudentsDetail,
                params: params,
                query: query
              }
            },
            {title: "ВКР", icon: "description", hide: true,
              router: {
                name: names.CathedraStudentsDetailThesis,
                params: params,
                query: query
              }},
            {title: "Список учебных работ", icon: "view_list",
              router: {
                name: names.CathedraStudentsDetailWorks,
                params: params,
                query: query
              }},
            {title: "Индивидуальный план", icon: "list_alt", router: {
                name: names.CathedraStudentsDetailCurriculum,
                params: params,
                query: query
              }},
            {title: "Журнал оценок", icon: "format_list_bulleted", router: {
                name: names.CathedraStudentsDetailJournal,
                params: params,
                query: query
              }},
            {title: "Портфолио", icon: "file_present", router: {
                name: names.CathedraStudentsPortfolio,
                params: params,
                query: query
              }},
            {title: "Документы об образовании", icon: "cases", router: {
                name: names.CathedraStudentsDocEdu,
                params: params,
                query: query
              }}
          ];

          if(this.$route.query.group) {
            return [
              {
                title: "Группа",
                icon: "arrow_back_ios",
                included: false,
                router: {
                  name: names.CathedraGroupsDetail,
                  params: {
                    group_id: this.$route.query.group,
                    cathedra_uid: this.$route.params.cathedra_uid,
                    taught: this.$route.params.taught,
                    reload_left_menu: true
                  }
                }
              },
              ...leftmenu
            ];
          }else if(this.$route.query.teacher){
            return [
              {
                title: "Обучающиеся преподавателя",
                icon: "arrow_back_ios",
                included: false,
                router: {
                  name: names.CathedraTeachersDetailStudents,
                  params: {
                    teacher_uid: this.$route.query.teacher,
                    cathedra_uid: this.$route.params.cathedra_uid,
                    reload_left_menu: true
                  }
                }
              },
              ...leftmenu
            ];
          }
          else {
            return [
              {
                title: "Список",
                icon: "arrow_back_ios",
                included: false,
                router: {
                  name: names.CathedraStudents,
                  params: {
                    ...params,
                    reload_left_menu: true
                  }
                }
              }, ...leftmenu
            ];
          }
        }else{
          if(this.$route.query.group) {
            return [
              {
                title: "Группа",
                icon: "arrow_back_ios",
                included: false,
                router: {
                  name: names.CathedraGroupsDetail,
                  params: {
                    group_id: this.$route.query.group,
                    cathedra_uid: this.$route.params.cathedra_uid,
                    taught: this.$route.params.taught,
                    reload_left_menu: true
                  },
                  query: {
                    teacher: this.$route.query.teacher
                  }
                }
              }
            ];
          }else if(this.$route.query.teacher){
            return [
              {
                title: "Обучающиеся преподавателя",
                icon: "arrow_back_ios",
                included: false,
                router: {
                  name: names.CathedraTeachersDetailStudents,
                  params: {
                    teacher_uid: this.$route.query.teacher,
                    reload_left_menu: true,
                    cathedra_uid: this.$route.params.cathedra_uid
                  }
                }
              }
            ];
          }else{
            return []
          }
        }
      },
      loadStudentBase(){
        this.loaded = false;
        // console.log(this.detailUrl);
        loadData(
          this.detailUrl(this.cathedra_uid, this.student_id),
          (data) => {
            this.student = data;
            this.attachment = data.url.attachment;
            this.loaded = true;
            if(this.attachment)
              this.updateLeftMenu();
            // finalizer(this.student);
          }
        )
      }

    },
    created() {
      // console.log('StudentDetailBase')
      if(this.attachment || this.$route.query.group || this.$route.query.teacher)
        this.updateLeftMenu();
      this.loadStudentBase();
    }
    // beforeRouteLeave (to, from, next) {
    //   this.$parent.$parent.updateLeftMenu()
    //   next();
    // },
    // beforeRouteUpdate(to, from, next){
    //   console.log('aaaaaaaaaaa')
    //   console.log(this.$route.query.group || this.$route.query.teacher)
    //   if(this.attachment || this.$route.query.group || this.$route.query.teacher)
    //     this.updateLeftMenu();
    //   // console.log('UPDATE')
    //   next();
    // },

  }
</script>

<style scoped>

</style>
