
const DEAN = {
  BASE: "Dean",
  FACULTIES: undefined,
  DETAIL: undefined,
  GROUP: {
    _BASE: undefined,
    LIST: undefined,
    DETAIL: undefined,
    JOURNAL: undefined,
    JOURNALSEMESTER: undefined,
    OPTIONALLYGROUP: undefined,
    STUDENTSEMESTERCATHEDRAS: undefined
  },
  PERMISSION: {
    _BASE: undefined,
    LIST: undefined
  },
  CURRICULUM: {
    _BASE: undefined,
    LIST: undefined
  },
  TRANSFER:{
    _BASE: undefined,
    LIST_TO: undefined,
    LIST_FROM: undefined,
    DETAIL_TO: undefined,
    DETAIL_FROM: undefined,
    APPROVE: undefined
  },
  DEPARTAMENTS: {
    _BASE: undefined,
    // LIST: undefined,
    DETAIL: undefined,
    LED_SUBJECTS: {
      _BASE: undefined,
      LIST: undefined
    },
    STUDENTS: {
      _BASE: undefined,
      LIST: undefined,
      LED: undefined
    },
    GROUP: {
      _BASE: undefined,
      LIST: undefined,
      DETAIL: undefined,
      LED: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined,
        JOURNAL: undefined,
        JOURNALSEMESTER: undefined
      }
    },
    TEACHER: {
      _BASE: undefined,
      LIST: undefined,
      DETAIL: undefined
    }
  },
  ADMISSIONS: {
    _BASE: undefined,
    DETAIL: undefined,
    LIST: undefined
  },
  ADMISSIONS_REQUEST: {
    _BASE: undefined,
    LIST: undefined,
    DETAIL: undefined
  },
  CERTIFICATE: {
    _BASE: undefined,
    LIST: undefined
  },
  STUDENT:{
    _BASE: undefined,
    LIST: undefined,
    PORTFOLIO: undefined,
    EDUDOC: undefined,
    LED: undefined,
    DETAIL: undefined,
    TRANSFER: undefined,
    CURCULUM: undefined,
    JOURNAL: undefined,
    ADMISSIONS: {
      _BASE: undefined,
      DETAIL: undefined,
      LIST: undefined
    },
    FINISHSUBJECT: undefined,
    ACADEMICWORKS:{
      _BASE: undefined,
      LIST: undefined,
      DETAIL: undefined
    }
  }
};

  DEAN.FACULTIES = `${DEAN.BASE}|Faculties`
  DEAN.DETAIL = `${DEAN.BASE}|Faculty`
  DEAN.TRANSFER._BASE = `${DEAN.BASE}|Transfer`
  DEAN.TRANSFER.LIST_FROM = `${DEAN.TRANSFER._BASE}|ListFrom`
  DEAN.TRANSFER.LIST_TO = `${DEAN.TRANSFER._BASE}|ListTo`
  DEAN.TRANSFER.DETAIL_TO = `${DEAN.TRANSFER._BASE}|DetailTo`
  DEAN.TRANSFER.DETAIL_FROM = `${DEAN.TRANSFER._BASE}|DetailFrom`
  DEAN.TRANSFER.APPROVE = `${DEAN.TRANSFER._BASE}|Approve`
  DEAN.STUDENT._BASE = `${DEAN.BASE}|Student`
  DEAN.STUDENT.LIST = `${DEAN.STUDENT._BASE}|List`
  DEAN.STUDENT.LED = `${DEAN.STUDENT._BASE}|Led`
  DEAN.STUDENT.DETAIL = `${DEAN.STUDENT._BASE}|Detail`
  DEAN.STUDENT.PORTFOLIO = `${DEAN.STUDENT._BASE}|Portfolio`
  DEAN.STUDENT.EDUDOC = `${DEAN.STUDENT._BASE}|EduDoc`
  DEAN.STUDENT.TRANSFER = `${DEAN.STUDENT._BASE}|Transfer`
  DEAN.STUDENT.CURCULUM = `${DEAN.STUDENT._BASE}|Curculum`
  DEAN.STUDENT.JOURNAL = `${DEAN.STUDENT._BASE}|Journal`
  DEAN.STUDENT.ADMISSIONS._BASE = `${DEAN.STUDENT._BASE}|Admission`
  DEAN.STUDENT.ADMISSIONS.DETAIL = `${DEAN.STUDENT.ADMISSIONS._BASE}|Detail`
  DEAN.STUDENT.ADMISSIONS.LIST = `${DEAN.STUDENT.ADMISSIONS._BASE}|List`
  DEAN.CERTIFICATE._BASE = `${DEAN.STUDENT._BASE}|Certificate`
  DEAN.CERTIFICATE.LIST = `${DEAN.CERTIFICATE._BASE}|List`
  DEAN.ADMISSIONS._BASE = `${DEAN.BASE}|Admission`
  DEAN.ADMISSIONS.DETAIL = `${DEAN.ADMISSIONS._BASE}|Detail`
  DEAN.ADMISSIONS.LIST = `${DEAN.ADMISSIONS._BASE}|List`
  DEAN.ADMISSIONS_REQUEST._BASE = `${DEAN.BASE}|AdmissionRequest`
  DEAN.ADMISSIONS_REQUEST.DETAIL = `${DEAN.ADMISSIONS_REQUEST._BASE}|Detail`
  DEAN.ADMISSIONS_REQUEST.LIST = `${DEAN.ADMISSIONS_REQUEST._BASE}|List`
  DEAN.STUDENT.FINISHSUBJECT = `${DEAN.STUDENT._BASE}|FinishSubjects`
  DEAN.STUDENT.ACADEMICWORKS._BASE = `${DEAN.STUDENT._BASE}|Academicworks`
  DEAN.STUDENT.ACADEMICWORKS.DETAIL = `${DEAN.STUDENT.ACADEMICWORKS._BASE}|Detail`
  DEAN.STUDENT.ACADEMICWORKS.LIST = `${DEAN.STUDENT.ACADEMICWORKS._BASE}|List`
  DEAN.GROUP._BASE = `${DEAN.BASE}|Group`
  DEAN.GROUP.DETAIL = `${DEAN.GROUP._BASE}|Group`
  DEAN.GROUP.LIST = `${DEAN.GROUP._BASE}|List`
  DEAN.GROUP.JOURNAL = `${DEAN.GROUP._BASE}|Journal`
  DEAN.GROUP.JOURNALSEMESTER  = `${DEAN.GROUP.JOURNAL}|Semester`
  DEAN.GROUP.OPTIONALLYGROUP  = `${DEAN.GROUP._BASE}}|OptionallyGroup`
  DEAN.GROUP.STUDENTSEMESTERCATHEDRAS  = `${DEAN.GROUP._BASE}}|StudentSemesterCathedras`
  DEAN.PERMISSION._BASE = `${DEAN.BASE}|Permission`
  DEAN.PERMISSION.LIST = `${DEAN.PERMISSION._BASE}|List`
  DEAN.CURRICULUM._BASE = `${DEAN.BASE}|Curriculum`
  DEAN.CURRICULUM.LIST = `${DEAN.CURRICULUM._BASE}|List`
  DEAN.CURRICULUM.DETAIL = `${DEAN.CURRICULUM._BASE}|Detail`
  DEAN.TRANSFER._BASE = `${DEAN.BASE}|Transfer`
  DEAN.DEPARTAMENTS.LIST = `${DEAN.BASE}|Departaments`
  // DEAN.DEPARTAMENTS.DETAIL = `${DEAN.BASE}|Departament`
  DEAN.DEPARTAMENTS._BASE = `${DEAN.BASE}|Departaments|`
  DEAN.DEPARTAMENTS.DETAIL = `${DEAN.DEPARTAMENTS._BASE}Detail`
  DEAN.DEPARTAMENTS.LED_SUBJECTS._BASE = `${DEAN.DEPARTAMENTS._BASE}LedSubject`
  DEAN.DEPARTAMENTS.LED_SUBJECTS.LIST = `${DEAN.DEPARTAMENTS.LED_SUBJECTS._BASE}List`
  DEAN.DEPARTAMENTS.STUDENTS._BASE = `${DEAN.DEPARTAMENTS._BASE}Student`
  DEAN.DEPARTAMENTS.STUDENTS.LIST = `${DEAN.DEPARTAMENTS.STUDENTS._BASE}|List`
  DEAN.DEPARTAMENTS.STUDENTS.LED = `${DEAN.DEPARTAMENTS.STUDENTS._BASE}|Led`
  DEAN.DEPARTAMENTS.TEACHER._BASE = `${DEAN.DEPARTAMENTS._BASE}Teacher`
  DEAN.DEPARTAMENTS.TEACHER.DETAIL = `${DEAN.DEPARTAMENTS.TEACHER._BASE}|Detail`
  DEAN.DEPARTAMENTS.TEACHER.LIST = `${DEAN.DEPARTAMENTS.TEACHER._BASE}|List`
  DEAN.DEPARTAMENTS.GROUP._BASE = `${DEAN.DEPARTAMENTS._BASE}Group`
  DEAN.DEPARTAMENTS.GROUP.LIST = `${DEAN.DEPARTAMENTS.GROUP._BASE}|List`
  DEAN.DEPARTAMENTS.GROUP.DETAIL = `${DEAN.DEPARTAMENTS.GROUP._BASE}|Detail`
  DEAN.DEPARTAMENTS.GROUP.LED._BASE = `${DEAN.DEPARTAMENTS.GROUP._BASE}|Led`
  DEAN.DEPARTAMENTS.GROUP.LED.DETAIL = `${DEAN.DEPARTAMENTS.GROUP.LED._BASE}|Detail`
  DEAN.DEPARTAMENTS.GROUP.LED.LIST = `${DEAN.DEPARTAMENTS.GROUP.LED._BASE}|LIST`
  DEAN.DEPARTAMENTS.GROUP.LED.JOURNAL = `${DEAN.DEPARTAMENTS.GROUP.LED._BASE}|Journal`
  DEAN.DEPARTAMENTS.GROUP.LED.JOURNALSEMESTER  = `${DEAN.DEPARTAMENTS.GROUP.LED.JOURNAL}|Semester`

export default {
  ...DEAN
}
