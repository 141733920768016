<template>
  <v-container>
    <v-card >
      <v-toolbar
        class="mb-2"
        color="style-color-main-gray"
        flat
      >

      </v-toolbar>
      <v-data-table
        :headers="headers"
        :footer-props="{'items-per-page-options':[10,25,50]}"
        :items="items"
      >
        <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
        <template v-slot:item.option="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="saveFile(item)"
                medium
              >
                <v-icon>
                  get_app
                </v-icon>
              </v-btn>
            </template>
            <span>Загрузить</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>

import urls from "@/urls/student";
import UserDataMixin from "@/mixins/UserDataMixin"
import {loadData, makeGetRequest } from "@/helper";
import names from "@/modules/dean/routers/names";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";


export default {
  name: "List",

  mixins: [
    TitledPageMixin,
    PaginatedDataMapperMixin,
    UserDataMixin],
  components: { },

  data () {
    return {
      status:"Все статусы",
      page_title: "Выданные справки из деканата",
      total: 0,
      names:names,
      items: [],
      loading: true,
      pagination: {},
      URL_LIST: urls.STUDENT.CERTIFICATE.LIST(),
      headers: [
        {text: "Справка", sortable: false, align: "left", value: "type"},
        {text: "Дата выдачи", sortable: false, align: "left", value: "date_issue"},
        {text: "Номер", sortable: false, align: "left", value: "number"},
        {text: "Действия", sortable: false, align: "right", value: "option"}
      ]
    }
  },
  created() {
    this.getItems();
  },
  methods: {
    saveFile(item){
      let url = urls.STUDENT.CERTIFICATE.SAVE( item.id)
      let status = true
      makeGetRequest(
        url
      ) .then(resp => {
        if (!resp.ok) {
           status = false
          alert("Не удалось загрузить файл")
        }
        return resp.blob();
      }) .then(blob => {
          if(status) {
            var a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = "certificate.pdf";
            a.click();
          }
      })
    },

    loadData(url = undefined) {
      this.getItems(url)
    },
    getItems (url=undefined) {
      this.setLoading();
      if(url === undefined) {
        url = this.URL_LIST
        this.setCurrentLoadUrl(url);
      }
      loadData(
        url,
        (data) => {
          this.items = data
          this.setNotLoading();
        }
      )
    }
  }
}


</script>

<style scoped>

</style>
