<template>
  <v-container>
    <StudentTemplate
      v-if="student.loaded"
      :student="student.data"
      :hides="{email: true, country: true}"
    >
      <template v-slot:education_group_title="{education_group}">
        <router-link :to="makeDetailLink(education_group)">
          {{ education_group.litter }}-{{ education_group.course }}{{ education_group.number }}
        </router-link>
      </template>
      <template v-slot:append_rows>
        <student-info-row
          :visible="student.loaded"
          label="Внутренние почты обучающегося:"
          :divider-no-show="!student.data.is_scientific"
        >
          <template v-slot:value>
            <span v-if="student.data.user.emails.length === 0">Не привязаны</span>
            <div v-else v-for="(str, index) in student.data.user.emails" :key="index">
              <a v-bind:href="['mailto:' + str.email]">{{str.email}}</a>
            </div>
          </template>
        </student-info-row>
      </template>
    </StudentTemplate>
    <v-toolbar
      class="mb-2"
      color="style-color-main-gray"
      flat
    >
        <v-toolbar-title>
          <span>Ведомые дисциплины</span>:
        </v-toolbar-title>
    </v-toolbar>

      <v-card-text class="text-left" v-for="(subject, index) in student.data.subjects" :key="`subject_${index}`">
        <v-row>
          <v-col cols="6">
            {{ subject.name }}
          </v-col>
          <v-col cols="6">
             Рейтинг: {{subject.rating}}
          </v-col>
        </v-row>
        <br v-if="index !== student.data.subjects.length"/>
      </v-card-text>
    <v-expansion-panels>
      <v-expansion-panel >
        <v-expansion-panel-header class="title" color="style-color-main-gray">
          Портфолио обучающегося
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <Portfolio></Portfolio>
          </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
import {loadData} from "@/helper";
import {generateUidToName} from "@/helper/uidToName";
import accounts from "@/urls/accounts";
import teacher from "@/urls/teacher";
import StudentTemplate from "@/modules/templates/students/StudentTemplate";
import StudentInfoRow from "@/modules/templates/students/StudentInfoRow";
import names from "@/modules/teacher/routers/names";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import Portfolio from "./Portfolio";

export default {
  name: "StudentDetail",
  mixins: [TitledPageMixin],
  components: {
    DebugJsonComponent,
    StudentTemplate,
    StudentInfoRow,
    Portfolio
  },
  props: {
  },
  data() {
    return {
      page_title: "Обучающийся",
      student: {
        data: {},
        loading: false,
        loaded: false
      }
    }
  },
  methods: {
    makeDetailLink(item) {
      return {name: names.GROUPS.DETAIL, params: {group_id: item.id, type: "led"}}
    },
    loadStudent: function () {
      loadData(
        teacher.URLS.STUDENT.DETAIL(this.$route.params.student_id),
        (data) => {
          this.student.data = data;
          this.$set(this.student.data, "fio", "");
          generateUidToName(this.student.data, "student", "fio");
          loadData(
            accounts.PROFILE.DETAIL(this.student.data.student),
            (data) => {
              this.$set(this.student.data, "user", data);
              this.student.loaded = true;
            }
          )
        }
      );
    }
  },
  created() {
    this.loadStudent();
  }
}
</script>

<style scoped>

</style>
