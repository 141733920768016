<template>
  <scroll-center-dialog v-model="opened_form"
                        width="500"
                        @close="closeDialog"
                        :title="(!id)?'Загрузить справку':'Редактировать справку'"
                        @apply="createItem"
  >
    <loading-autocomplete
      v-if="!id"
      class="pa-1 ma-1"
      placeholder="Начните вводить ФИО обучающегося"
      label="Обучающийся"
      v-model="value.student"
      :error="hasError('student')"
      :error-messages="getError('student')"
      search-parameter="fio"
      :url="student_selector"
    >
      <template v-slot:no-data>
        <div>
          Для поиска начните набирать ФИО обучающигося, </br> и либо остановитесь на 1 секунду, либо нажмите Enter
        </div>
      </template>
    </loading-autocomplete>
    <v-text-field
      :error="hasError('number')"
      :error-messages="getError('number')"
      label="Номер справки"
      v-model="value.number"
      class="pa-1 ma-1"
    ></v-text-field>
    <v-text-field
      :error="hasError('date_issue')"
      :error-messages="getError('date_issue')"
      label="Дата выдачи справки"
      v-model="value.date_issue"
      type="date"
      class="pa-1 ma-1"
    ></v-text-field>
    <v-select
      :error="hasError('type')"
      :error-messages="getError('type')"
      label="Тип справки"
      class="pa-1 ma-1"
      :items=selectors.type
      v-model="value.type">
    </v-select>
    <v-file-input
      v-if="file === ''"
      class="pa-1 ma-1"
      :error="hasError('file')"
      :error-messages="getError('file')"
      v-model="value.file"
      accept="application/pdf"
      label="Документ"
    ></v-file-input>
    <v-text-field
      v-else
      class="pa-1 ma-1"
      clearable
      readonly
      v-model="type"
      @click:clear="file = ''"
      @click:append="saveFile(id)"
      append-icon="download"
      prepend-icon="attach_file"
    ></v-text-field>
  </scroll-center-dialog>
</template>

<script>

import UserDataFormMixin from "../../../../mixins/UserDataFormMixin"
import selectors from "@/urls/selectors";
import LoadingAutocomplete from "@/modules/core/components/LoadingAutocomplete";
import {finalizeRequest, loadData, makeGetRequest, makePostRequestFile} from "@/helper";
import urls from "@/urls/dean";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";


export default {
  name: "Admission",
  mixins: [UserDataFormMixin],
  components: {
    ScrollCenterDialog,
    LoadingAutocomplete
  },
  props: {
    id:{} ,
    number:{
      type:String,
      default: ""
    } ,
    date_issue:{
      type:String,
      default: ""
    } ,
    type:{
      type:String,
      default: ""
    } ,
    file: {
      type: String,
      default: ""
    } ,
    opened_form: Boolean
  },
  data() {
    return {
      selectors:{
        type: [] 
      },
      value: {
        id: this.id || undefined,
        student: undefined  ,
        file: null,
        number: this.number || undefined,
        type: this.type || undefined,
        date_issue: this.date_issue || undefined
      }
    }
  },
  watch: {

  },
  computed: {
    student_selector(){
      return selectors.SELECTORS.DEAN.STUDENT(this.$route.params.idFaculty)
    }
  },
  methods: {
    loadType(){
      let url = selectors.URLS.CERTIFICATE_TYPE()
      let saverType = (data) => {
        this.selectors.type = data
      };
      let catherType = (data) => {
        this.errors = data
      };
      loadData(url, saverType, catherType);
    },
    closeDialog() {
      this.$emit("ended", "")
    },
    saveFile(id){
      console.log(id)
      let url = urls.DEAN.CERTIFICATE.SAVE(this.$route.params.idFaculty, id)
      let status = true
      makeGetRequest(
        url
      ) .then(resp => {
        if (!resp.ok) {
          status = false
          alert("Не удалось загрузить файл")
        }
        return resp.blob();
      }) .then(blob => {
        if(status) {
          var a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          a.download = "certificate.pdf";
          a.click();
        }
      })
    },
    createItem() {
      let item = this.value
      this.errors = {}
      let url = urls.DEAN.CERTIFICATE.CREATE(this.$route.params.idFaculty)
      let metod = "POST"
      if (item.id) {
        url = urls.DEAN.CERTIFICATE.UPDATE(this.$route.params.idFaculty,item.id)
        metod = "PATCH"
      }

      let formData = new FormData();
      if (!item.id) {
        formData.append("student", item.student)
      }
      formData.append("number", item.number)
      formData.append("type", item.type)
      formData.append("date_issue", item.date_issue)

      if (typeof item.file === "object" && item.file !== null){
        if (String(item.file.name).length < 130 )
          formData.append("file", item.file)
        else {
          alert("Слишком длинное имя файла для загрузки")
          item.file = null
          return
        }
      }
      finalizeRequest(makePostRequestFile(url, formData, metod),
        (json) => {
          this.$emit("ended", json);
        },
        (json) => {
          this.receiveErrors(json);
        })

    }

  },
  created() {
    this.loadType()
  }
}

</script>

<style scoped>

</style>
