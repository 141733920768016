<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <v-row>
          <v-col
            cols="12"
            md="6"
            xl="6"
            v-for="(item, index) in getLeftMenu()" :key="`menu_card_${index}`"
          >
            <v-card class="elevation-5 ma-3">
              <router-link :to="{name: getName(item)}">
                <div class="blue lighten-4 black--text item_in_list rounded">
                  <v-row no-gutters>
                    <v-col md="10" lg="11" sm="11" class="text-left pl-3">
                      {{ item.title }}
                    </v-col>
                    <v-col md="2" lg="1" sm="1">
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-col>
                  </v-row>
                </div>
              </router-link>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import urls from "@/urls/management";
  import TitledPageMixin from "@/mixins/TitledPageMixin";

  export default {
    name: "EducationManagementProfile",
    mixins: [TitledPageMixin],
    components: {},
    data: function () {
      return {
        page_title: "Учебное управление"
      }
    },
    computed: {
    },
    methods: {
      getLeftMenu() {
        let left = []
        let mass = this.$store.getters["leftmenu/getLeftMenu"]
        for (var i=1; i < mass.length; ++i) {
          if (!mass[i].subs) {
            left.push(mass[i])
          }
          else {
            if (mass[i].subs) {
              left = left.concat(this.getLeftSubs(mass[i].subs))
            }
          }
        }
        return left
      },
      getLeftSubs(second_menu) {
        let subs = []
        for (var i=0; i < second_menu.length; ++i) {
            subs.push(second_menu[i])
          }
        return subs
      },
      allowed(urlname, action) {
        return urls.EDUCATION.getUrlAllowed(urlname, action)
      },
      getName(el){
        if (typeof el.router === "object")
          return el.router.name
        else
          return el.router
      }
    },
    created() {
    }
  }
</script>

<style scoped>
.item_in_list{
  height: 100px;
  text-align: center;
  line-height: 100px;
  font-size: 24px;
  user-select: none;
  text-decoration: underline !important;
}
</style>
