<template>
  <v-container>
    <CurriculumTemplate v-if="curriculum.loaded" :curriculum="curriculum.data"></CurriculumTemplate>
    <CurriculumSubjectsTemplate
      v-if="subjects.loaded"
      :semesters="subjects.data.results"
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.size"
      :count="pagination.count"
      :footerprops=" {'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
      :searchable="false"
      :append_headers="[]"

    ></CurriculumSubjectsTemplate>
  </v-container>
</template>

<script>
  import urls from "@/urls/management";
  import {loadData} from "../../../../../helper";
  import DebugJsonComponent from "../../../../core/components/DebugJsonComponent";
  import CurriculumTemplate from "@/modules/templates/curriculum/CurriculumTemplate";
  import CurriculumSubjectsTemplate from "@/modules/templates/curriculum/CurriculumSubjectsTemplate";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";

  export default {
    name: "CurriculumDetail",
    mixins: [
      PaginatedDataMapperMixin
    ],
    components: {
      DebugJsonComponent,
      CurriculumTemplate,
      CurriculumSubjectsTemplate
    },
    computed: {
    },
    data: function () {
      return {
        title: "Учебный план",
        curriculum: {
          id: this.$route.params.curriculum_id,
          data: {},
          loading: false,
          loaded: false
        },
        subjects: {
          data: {},
          loading: false,
          loaded: false
        }
      }
    },
    methods: {
      loadCurriculum: function () {
        this.curriculum.loading = true;
        loadData(
          urls.EDUCATION.CURRICULUM.RETRIEVE(this.curriculum.id),
          (data) => {
            this.curriculum.data = data;
            this.curriculum.loaded = true;
            this.curriculum.loading = false;
            this.loadSubjects();
          });
      },
      loadSubjects(url=undefined){
        this.subjects.loading = true;
        if(url === undefined)
          url = urls.EDUCATION.CURRICULUM.SUBJECT.LIST(this.curriculum.id)
        loadData(
          url,
          (data) => {
            this.subjects.data = data;
            this.mapPagination(data);
            this.setCurrentLoadUrl(url)
            this.subjects.loaded = true;
            this.subjects.loading = false;
          }
        )
      },
      loadData(url=undefined){
        this.loadSubjects(url)
      }
    },
    created() {
      this.$store.commit("mainpage/changeTitle", this.title);
      this.loadCurriculum();
    }
  }
</script>

<style scoped>

</style>
