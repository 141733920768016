<template>
  <v-container  >
    <v-card >
      <v-toolbar :extended='$vuetify.breakpoint.smAndDown'
                 elevation="0"
                 color="style-color-main-gray"
                 :style="{'height':`${$vuetify.breakpoint.mdAndUp ? 64: 150}px`} "
      >
        <v-toolbar-title>Список всех дисциплин кафедры:</v-toolbar-title>
        <template v-if="$vuetify.breakpoint.mdAndUp" >
          <v-text-field class="pa-6"
                        dense
                        placeholder="Поиск по профилю или предмету"
                        v-model="search.value"
                        @keypress.enter="searchData"
                        @click:clear="clearSearchData"
                        clearable
                        messages="Для поиска нажмите Enter"
          ></v-text-field>
          <v-select class="py-0 pa-6"
                    style="max-width: 300px"
                    :items="selectors.year"
                    v-model="pagination.filters.year"
                    item-value="text"
                    placeholder="Год не выбран"
                    clearable
          ></v-select>
        </template>

        <template v-slot:extension v-if='$vuetify.breakpoint.smAndDown'>
          <v-col>
            <v-text-field
              class="pt-6"
              dense
              placeholder="Поиск по профилю или предмету"
              v-model="search.value"
              @keypress.enter="searchData"
              @click:clear="clearSearchData"
              clearable
              messages="Для поиска нажмите Enter"
            ></v-text-field>

            <v-select
              :items="selectors.year"
              v-model="pagination.filters.year"
              item-value="text"
              placeholder="Год не выбран"
              clearable
            ></v-select>
          </v-col>
        </template>

      </v-toolbar>
      <v-autocomplete
        label="Кафедра"
        class="pa-3 pt-3"
        :items="selectors.cathedra"
        v-model="pagination.filters.cathedra"
        placeholder="Не выбрано"
        clearable
      ></v-autocomplete>
      <v-card-text class="pa-1 pt-1 pb-3">
        <v-layout justify-center>
          <v-flex>
            <v-data-table
              :headers="headers"
              :items="items"
              no-data-text="Нет данных"
              :page.sync="pagination.page"
              :items-per-page.sync="pagination.size"
              :server-items-length="pagination.count"
              :footer-props="pagination.footer_page"
            >
              <template v-slot:item.work_program="props">
                <v-btn v-if="props.item.work_program !== null" icon>
                  <a :href="props.item.work_program" target="_blank">
                    <v-icon color="blue">get_app</v-icon>
                  </a>
                </v-btn>
              </template>
              <template v-slot:item.methodological_materials="props">
                <v-btn v-if="props.item.methodological_materials !== null" icon>
                  <a :href="props.item.methodological_materials" target="_blank">
                    <v-icon color="blue">get_app</v-icon>
                  </a>
                </v-btn>
              </template>
              <template v-slot:item.appraisal_tools="props">
                <v-btn v-if="props.item.appraisal_tools !== null" icon>
                  <a :href="props.item.appraisal_tools" target="_blank">
                    <v-icon color="blue">get_app</v-icon>
                  </a>
                </v-btn>
              </template>
              <template slot="no-data">
                <v-card-text>Данные не загружены</v-card-text>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import UserDataMixin from "@/mixins/UserDataMixin"
import {loadData, makeGetRequest} from "@/helper";
import names from "@/modules/educationalManagement/routers/names";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import management from "@/urls/management.js"
export default {
  name: "ListAdmissions",
  mixins: [UserDataMixin, PaginatedDataMapperMixin],
  data () {
    return {
      names:names,
      items: [],
      search: {
        parameter: "info"
      },
      selectors:{
        cathedra:[],
        year:[]
      },
      pagination : {
        filters: {
          year: null,
          cathedra: null
        }
      },
      URL_LIST: management.EDUCATION.SUBJECTS.LIST(),
      headers: [
        {text: "Предмет", sortable: false, align: "left",value:"subject", width: "15%"},
        {text: "Год", sortable: false, align: "left",value:"curriculum.year", width: "5%"},
        {text: "Направление", sortable: false, align: "left",value:"curriculum.direction.name", width: "30%"},
        {text: "Профиль", sortable: false, align: "left",value:"curriculum.profile", width: "30%"},
        {text: "Форма", sortable: false, align: "left",value:"curriculum.form", width: "5%"},
        {text: "Файл РПД", sortable: false, align: "left",value:"work_program", width: "5%"},
        {text: "Файл МУ", sortable: false, align: "left",value:"methodological_materials", width: "5%"},
        {text: "Файл ОС", sortable: false, align: "left",value:"appraisal_tools", width: "5%"}
      ]
    }
  },

  created() {
    this.$store.commit("mainpage/changeTitle", "Список документов");
    this.loadSelector();
    this.loadData();
  },
    watch: {
    },
  methods: {
    loadSelector() {
      let year = (new Date()).getFullYear()+1
      for(let i = year; i >= 2010; i--){
        this.selectors.year.push({text: i,value: i})
      }
      loadData(management.EDUCATION.SELECTORS.CATHEDRAS(),(json)=>{
        this.selectors.cathedra = json
        this.pagination.filters.cathedra = null
      })
    },
    loadData(url=undefined) {
      this.setLoading();
      if(!url) {
        url = this.addFiltersParameter(this.URL_LIST);
        this.setCurrentLoadUrl(url);
      }
      url = this.getCurrentLoadUrl()
      loadData(
        url,
        (data) => {
          this.mapResults(data, "items")
          this.setNotLoading();
        }
      )
    }
  }
}


</script>

<style scoped>

</style>
