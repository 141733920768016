<template>
  <scroll-center-dialog v-model="opened"
                        width="600"
                        @close="$emit('close', false)"
                        @apply="save"
                        :title="!data_work ? 'Загрузка документа' : 'Редактирование загруженного документа'"
                        :disabled="!work.semester"
                        :applyable="work.semester"
  >
    <v-autocomplete
      class="pa-1 ma-1 pt-4"
      outline
      :disabled="Boolean(this.data_work.id)"
      :error="hasError('direction')"
      :error-messages="getError('direction')"
      :items="selectors.student"
      :loading="loading"
      no-data-text="Нет данных"
      label="Обучающийся"
      v-model="work_student"
    ></v-autocomplete>
    <v-autocomplete
      v-if="work_student"
      class="pa-1 ma-1 pt-4"
      outline
      :disabled="Boolean(this.data_work.id)"
      :error="hasError('direction')"
      :error-messages="getError('direction')"
      :items="selectors.semester"
      no-data-text="Нет данных"
      label="Семестр"
      v-model="work.semester"
    ></v-autocomplete>
    <v-text-field
      :error="hasError('name')"
      :error-messages="getError('name')"
      label="Название"
      v-model="work.name"
      class="pa-1 ma-1"
    ></v-text-field>
    <v-text-field
      :error="hasError('subtitle')"
      :error-messages="getError('subtitle')"
      label="Описание"
      v-model="work.subtitle"
      class="pa-1 ma-1"
    ></v-text-field>
    <v-autocomplete
      label="Тип"
      :error="hasError('type')"
      :error-messages="getError('type')"
      :items="choices.type"
      v-model="work.type"
      class="pa-1 ma-1"
    >
    </v-autocomplete>
    <v-file-input
      v-if="typeof work.file !== 'string'"
      class="pa-1 ma-1"
      :error="hasError('file')"
      :error-messages="getError('file')"
      v-model="work.file"
      :messages="setInputFile(work.file)"
      accept="application/pdf"
      label="Файл (Формат .pdf, до 15МБ)"
    ></v-file-input>
    <v-text-field
      v-else
      class="pa-1 ma-1"
      v-model="work.file"
      clearable
      @click:clear="work.file = null"
      prepend-icon="attach_file"
      append-icon="get_app"
      readonly
      @click:append="loadFileWork"
      label="Файл (Формат .pdf, до 15МБ)"
    ></v-text-field>
  </scroll-center-dialog>
</template>

<script>
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent"
import {loadData} from "@/helper"
import {makePostRequestFile, makeUpdateRequest} from "@/helper"
import UserDataFormMixin from "@/mixins/UserDataFormMixin"
import teacher from "@/urls/teacher"
import choices from "@/urls/choices"
import selectors from "@/urls/selectors";
import {loadFile} from "@/helper/fileLoader"
import {makeGetRequest} from "../../../../helper";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

export default {
  name: "WorkForm",
  mixins: [UserDataFormMixin],
  components: {
    ScrollCenterDialog,
    DebugJsonComponent
  },
  data() {
    return {
      opened: true,
      URL_CHOICE_WORK_TYPE: choices.EDUCATION.CHOICES.WORK_TYPE(),
      URL_SELECT_STUDENT: selectors.SELECTORS.TEACHER.STUDENT(),
      error:null,
      choices: {
        type: [
        ]
      },
      selectors: {
        student: [],
        semester: []
      },
      work_student: null,
      work: {
        semester: null,
        name: "",
        type: "",
        subtitle: "",
        file: null,
        id: null,
        data: null
      },
      loading: false
    }
  },
  watch: {
    work_student(){
      this.selectors.semester = [],
      this.loadSelector("semester", selectors.SELECTORS.TEACHER.STUDENT_SEMESTER(this.work_student))

    }
  },
  props:{
    data_work: {
      required: false,
      default: ""
    }
  },
  methods: {
    close(){
      this.$emit("close");
    },
    loadChoices(url, choices){
      loadData(
        url,
        (data) => {
          this.choices[choices] = data;
        }
      )
    },
    setInputFile(str){
      if (typeof str === "string"){
        return [str]
      }
      return []
    },
    loadSelector(data, url) {
      let sys = true
      this.loading = true
      makeGetRequest(url).then(resp => {
        if (!resp.ok) {
          sys = false
          this.loading = false
        }
        return resp.json()
      }).then(json => {
        this.selectors[data] = json
        this.loading = false
      })
    },
    save(){
      if (!this.data_work){
        this.create()
      }
      else{
        this.change()
      }
    },
    create(){
      let url = teacher.URLS.INDIVIDUAL_WORK.ADD_WORK()
      let formData = new FormData();
      formData.append("name", this.work.name)
      formData.append("file", this.work.file)
      formData.append("type", this.work.type)
      formData.append("subtitle", this.work.subtitle)
      formData.append("semester", this.work.semester)
      formData.append("student", this.work_student)
      let status = true;
      makePostRequestFile(url, formData,"POST")
        .then(resp => {
          if (!resp.ok) {
            status = false;
          }
          return resp.json();
        })
        .then(json => {
          if (status) {
            this.close();
          } else {
            this.receiveErrors(json);
          }
        })
    },
    change(){
      let url = teacher.URLS.INDIVIDUAL_WORK.UPDATE_WORK(this.work.id)
      let formData = new FormData();
      formData.append("name", this.work.name)
      if (typeof this.work.file !== "string"){
        formData.append("file", this.work.file)
      }
      formData.append("type", this.work.type)
      formData.append("subtitle", this.work.subtitle)
      formData.append("semester", this.work.semester)

      let status = true;
      makePostRequestFile(url, formData, "PATCH")
        .then(resp => {
          if (!resp.ok) {
            status = false;
          }
          return resp.json();
        })
        .then(json => {
          if (status) {
            this.close();
          } else {
            this.receiveErrors(json)
          }
        })
    },
    LoadWorkFileName(){
      let url = teacher.URLS.INDIVIDUAL_WORK.WORK_DETAIL(this.data_work.id)
      loadData(url, (data) => {
        this.work.file = data.document.file.file_name
        this.work.data = data
      })
    },
    loadFileWork(){
      loadFile(this.work.data.document.file)
    }
  },
  created() {
    this.loadChoices(this.URL_CHOICE_WORK_TYPE,"type")
    this.loadSelector("student", this.URL_SELECT_STUDENT)
    if (this.data_work) {
      this.work_student = this.data_work.student
      this.work.semester = this.data_work.semester.id
      this.work.subtitle = this.data_work.subtitle
      this.work.type = this.data_work.type
      this.work.name = this.data_work.name
      this.work.id = this.data_work.id
      this.LoadWorkFileName()
    }
  }

}
</script>

<style scoped>

</style>
