<template>
  <scroll-center-dialog v-model="opened_form"
                        @close="closeDialog"
                        @apply="apply"
                        width="500"
                        :title="!!id ? 'Изменить запись' : 'Выдать права'"
  >
    <loading-autocomplete
      placeholder="Начните вводить ФИО работника"
      label="Работник"
      v-model="value.worker_uid"
      :error="hasError('worker_uid')"
      :error-messages="getError('worker_uid')"
      search-parameter="fio"
      :url="worker_selector"
      :post-loader="postLoader"
    >
      <template v-slot:no-data>
        <div>
          Для поиска начните набирать ФИО работника, </br> и либо остановитесь на 1 секунду, либо нажмите Enter
        </div>
      </template>
    </loading-autocomplete>

    <v-checkbox
      label="Выдать полный доступ"
      v-model="value.head"
      :error="hasError('head')"
      :error-messages="getError('head')"
    ></v-checkbox>

    <v-alert
      :value="hasError('detail')"
      type="error"
    >
      {{ getError('detail') }}
    </v-alert>
  </scroll-center-dialog>
</template>

<script>
import urlsM from "@/urls/management";
import UserDataFormMixin from "@/mixins/UserDataFormMixin"
import {makeGetRequest,makePostRequest} from "@/helper";
import selectors from "@/urls/selectors";
import {generateListUidToName} from "@/helper/uidToName";
import LoadingAutocomplete from "@/modules/core/components/LoadingAutocomplete";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

export default {
  name: "Role",
  mixins: [UserDataFormMixin],
  props: [ "id", "worker_uid", "head", "opened_form"],
  components: {
    ScrollCenterDialog,
    LoadingAutocomplete
  },
  data() {
    return {
      URL_CREATE:  urlsM.EDUCATION.PERMISSION.СREATE(),
      URL_UPDATE:  "",
      loaders: {
        responsible: {
          status: true,
          handler: () => this.load(this, urlsM.WORKER.USER())
        }
      },
      value: {
        id: this.id || "",
        worker_uid:this.worker_uid || "",
        head:this.head || false
      },
      selectors:{
        responsible: []
      }
    }
  },
  computed: {
    worker_selector(){
      return selectors.URLS.WORKERS.ALL()
    }
  },
  methods: {
    postLoader(data, list, end) {
      data.results.forEach(el => {
        this.$set(el, "fio", "");
      })
      let vals = list(data.results);
      generateListUidToName(
        vals,
        "value",
        "fio",
        ()=>{
          vals.forEach(el => {
            this.$set(el, "text", `${el.fio}, ${el.text}`)
          })
          end();
        }
      )
    },
    load: function(app, url) {
      if(url === null) {
        app.loaders.responsible.status = false;
        return;
      }
      makeGetRequest(url)
        .then(resp => resp.json())
        .then(json => {
          let users = [];
          let results = [];
          let next_url = null;
          if(json["next"] !== null)
            next_url = url.split("?")[0] + "?" + json["next"].split("?")[1];
          for(let i in json["results"]){
            users.push(json["results"][i].value);
            results.push(Object.assign({}, json["results"][i]));
          }
          makePostRequest(
            urlsM.WORKER.USER(),
            {"uids": users}
          )
            .then(resp => resp.json())
            .then(json => {
              for(let i in json){
                let replaced = results.find((item) => item.value === json[i].value);
                replaced.text =  json[i].text + " (" + replaced.text + ")";
                app.selectors.responsible.push(replaced)
              }
              results = null;
              app.load(app, next_url);
            });
        });
    },
    closeDialog() {
      this.$emit("ended", "")
    }
  },
  created() {
  }
}

</script>

<style scoped>

</style>
