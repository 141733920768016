<template>
  <scroll-center-dialog v-model="opened"
                        width="600"
                        @close="$emit('close', false)"
                        @apply="save"
                        :applyable="status_status"
                        title="Изменить статус"
  >
    <v-autocomplete
      class="pa-1 ma-1 pt-4"
      outline
      :items="selectors.status"
      no-data-text="Нет данных"
      label="Новый статус"
      v-model="status_status"
    ></v-autocomplete>
    <v-autocomplete
      v-if="status_status"
      class="pa-1 ma-1 pt-4"
      outline
      :items="selectors.reason"
      no-data-text="Нет данных"
      label="Причина"
      v-model="status.reason"
      :error="hasError('reason')"
      :error-messages="getError('reason')">
    </v-autocomplete>
    <v-text-field
      type="date"
      v-model="status.date"
      :error="hasError('date')"
      :error-messages="getError('date')">
    </v-text-field>
  </scroll-center-dialog>
</template>

<script>
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent"
import UserDataFormMixin from "@/mixins/UserDataFormMixin"
import selectors from "@/urls/selectors"
import {makeGetRequest,sendPostRequest} from "@/helper";
import urls from "@/urls/dean"
import moment from "moment";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

export default {
  name: "AddStatus",
  mixins: [UserDataFormMixin],
  components: {
    ScrollCenterDialog,
    DebugJsonComponent
  },
  data() {
    return {
      URL_SELECT_STATUS: selectors.SELECTORS.DEAN.STUDENT_STATUS(this.student_status.status),
      URL_ADD_STATUS: urls.DEAN.STUDENTS.STATUSES.ADD_STATUS(this.$route.params.idFaculty, this.$route.params.idStudent),
      opened: true,
      selectors: {
        status: [],
        reason: []
      },
      status: {
        reason: null,
        date: null
      },
      status_status: null
    }
  },
  watch: {
    status_status(){
      this.selectors.reason = [],
        this.loadSelector(
          "reason",
          selectors.SELECTORS.DEAN.STUDENT_STATUS_REASON(this.status_status)
    )
    }
  },
  props:{
    student_status: {
      required: false,
      default: ""
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    loadSelector(data, url) {
      let sys = true
      makeGetRequest(url).then(resp => {
        if (!resp.ok) {
          sys = false
        }
        return resp.json()
      }).then(json => {
        this.selectors[data] = json
      })
    },
    displayDate(date, minutes = false) {
      let format = "DD.MM.YYYY";
      if (minutes)
        format += " HH:mm";
      return moment(date).format(format)
    },
    save(){
      sendPostRequest(
        this.URL_ADD_STATUS,
        {"status": this.status_status, "reason": this.status.reason, "date": this.status.date}, () => {
          this.$emit("refreshItem")
          this.close()
        }, (json) => {
          this.receiveErrors(json)
        }
      )
    }
  },
  created() {
    this.loadSelector("status", this.URL_SELECT_STATUS)
    // this.status.date = this.student_status.date
  }
}
</script>

<style scoped>

</style>
