<template>
<v-container>
  <v-card>
    <Group></Group>
    <Student></Student>
    <Subjects></Subjects>
  </v-card>
</v-container>
</template>

<script>
  import Group  from "./Group"
  import Student  from "./Student"
  import Subjects  from "./Subjects"
  import UserDataMixin from "../../../../../../mixins/UserDataMixin"
  import {makeGetRequest} from "../../../../../../helper";
  import urls from "../../../../urls"

    export default {
        name: "TeacherDetail",
        components: {Group,Student,Subjects}
    }
</script>

<style scoped>

</style>
