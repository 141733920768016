import List from "../pages/subjects/Base";
import Detail from "../pages/subjects/Detail";
import names from "@/modules/curriculumEditor/routers/names";

const education = String(process.env.VUE_APP_EDUCATION_PREFIX).replace("/", "");

export default ([
  {
    path: "subjects",
    name: names.CURRICULUM.SUBJECTS.LIST,//'CurriculumEditorSubjectsList',
    // name: 'CurriculumEditorSubjectsList',
    component: List,
    meta: {
      requiredAuth: true,
      breadcrumb: "Список дисциплин",
      instances: [education]
    },
    children: [
      {
        path: ":subject_id([0-9]+)",
        name: names.CURRICULUM.SUBJECTS.DETAIL,//'CurriculumEditorSubjectsDetail',
        // name: 'CurriculumEditorSubjectsDetail',
        component: Detail,
        meta: {
          requiredAuth: true,
          breadcrumb: "Предмет",
          instances: [education]
        },
        children: []
      }
    ]
  }
])


