import {
  // sendPostRequest,
  // sendDeleteRequest,
  sendGetRequest
  // sendPutRequest
} from "@/helper";
import urls from "@/urls/admin";

const state = {
  items: [],
  count: 0,
  next: null,
  prev: null,
  detail: null,
  id: null,
  loading: false,
  currentUrl: false,
  error: null
};

const getters = {
  getFaceItems: state => state.items,
  getFaceCount: state => state.count,
  getFaceCurrentUrl: state => state.currentUrl,
  getFaceDetail: state => state.detail,
  isFaceLoading: state => state.loading,
  getFaceError: state => state.error

};

const actions = {
  loadFaceList: ({commit, getters}, {url, urlAppend, finalizer=()=>{}, catcher=()=>{}}) => {
    if (!url) {
      url = urls.ADMIN.FACE.LIST()
      if (urlAppend) url = urlAppend(url)
    }
    if (!getters.isFaceLoading) {
      commit("SET_FACE_LOADING");
      sendGetRequest(
        url,
        (data, url) => {
          commit("SET_FACE", data["results"]);
          commit("SET_FACE_COUNT", data["count"]);
          commit("SET_FACE_CURRENT_URL", url);
          commit("SET_FACE_LOADING_END");
          finalizer(data, url);
        },
        (data, url) => {
          commit("SET_FACE_LOADING_END");
          commit("SAVE_FACE_ERROR", data);
          catcher(data, url);
        }
      )
    }
  },
  loadCarsDetail: ({commit}, {url, id, finalizer=()=>{}, catcher=()=>{}}) => {
    if (!url) url = urls.ADMIN.FACE.DETAIL(id)
    sendGetRequest(
      url, (data, url) => {
        commit("SET_FACE_DETAIL", data);
        finalizer(data, url);
      }, (data, url) => {
        commit("SAVE_FACE_ERROR", data);
        catcher(data, url);
      }
    )
  }
  // createCars: ({dispatch, commit, getters}, {data, finalizer=()=>{}, catcher=()=>{}}) => {
  //   let url = urls.ADMIN.FACE.CREATE()
  //   executeRequest('Cars', sendPostRequest, {url, data, finalizer, catcher}, {dispatch, commit, getters});
  // },
  // changeCars: ({dispatch, commit, getters}, {data, finalizer,catcher}) => {
  //   let url = urls.ADMIN.FACE.UPDATE(data.id)
  //   executeRequest('Cars', sendPutRequest, {url, data, finalizer, catcher}, {dispatch, commit, getters});
  // },
  // deleteCars: ({dispatch, commit, getters}, {id, finalizer, catcher}) => {
  //   let url = urls.ADMIN.FACE.DELETE(id)
  //   executeRequest('Cars', sendDeleteRequest, {url, finalizer, catcher}, {dispatch, commit, getters});
  // },

};

const mutations = {
  SET_FACE: (state, cars) => {
    state.items = cars
  },
  SET_FACE_DETAIL: (state, value) => {
    state.detail = value
  },
  SET_FACE_COUNT: (state, value) => {
    state.count = value
  },
  SET_FACE_CURRENT_URL: (state, value) => {
    state.currentUrl = value
  },
  SET_FACE_LOADING: (state) => {
    state.loading = true
  },
  SET_FACE_LOADING_END: (state) => {
    state.loading = false
  },
  SAVE_FACE_ERROR: (state, error) => {
    state.error = error
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
