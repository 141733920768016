<template>
  <v-container>
    <v-toolbar
      class="mb-2"
      dark
      flat
    >
      <v-toolbar-title>
        Обучающийся:
        <template v-if="student.fio !== undefined">{{student.fio}}</template>
        <template v-else>{{student.user.lastname}} {{student.user.firstname}} {{student.user.midname}}</template>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title class="text-caption" v-if="student.user">
        <template v-if="student.user.last_login !== null">(Последний раз был {{student.user.last_login}})</template>
        <template v-else>(Ни разу не заходил)</template>
      </v-toolbar-title>
      <v-toolbar-items>
        <!--            <v-btn v-if="attachment" @click="opened_form = true">редактировать</v-btn>-->
      </v-toolbar-items>
    </v-toolbar>
    <v-row>
      <v-col cols="12" sm="4" class="d-flex flex-column justify-center">
        <v-img :src="(student.user.avatar !== null) ? student.user.avatar: NotAvatar "
               class="ma-5 d-flex"
               aspect-ratio="1"
               contain>
        </v-img>
      </v-col>
      <v-col class="text-left d-flex flex-column px-5" height="100%" cols="12" sm="8">
        <v-row no-gutters v-if="student.user">
          <v-col cols="12" sm="4" class="font-weight-bold">Дата рождения:</v-col>
          <v-col cols="12" sm="8" class="text-justify">{{student.user.birth}}</v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row no-gutters v-if="student.user">
          <v-col cols="12" sm="4" class="font-weight-bold">Гражданство:</v-col>
          <v-col cols="12" sm="8" class="text-justify">{{student.user.country}}</v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row no-gutters v-if="student.user">
          <v-col cols="12" sm="4" class="font-weight-bold">Внешняя электронная почта:</v-col>
          <v-col cols="12" sm="8" class="text-justify">{{student.user.email}}</v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row no-gutters >
          <v-col cols="12" sm="4" class="font-weight-bold">Форма обучения:</v-col>
          <v-col cols="12" sm="8" class="text-justify">{{student.education_group.form}}</v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row no-gutters v-if="student.entry_year">
          <v-col cols="12" sm="4">Год поступления:</v-col>
          <v-col cols="12" sm="8">{{student.entry_year}}</v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row no-gutters>
          <v-col cols="12" sm="4" class="font-weight-bold">Форма обучения:</v-col>
          <v-col cols="12" sm="8" class="text-justify">{{student.education_group.form}}</v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row no-gutters>
          <v-col cols="12" sm="4" class="font-weight-bold">Уровень образования:</v-col>
          <v-col cols="12" sm="8" class="text-justify">{{student.education_group.level}}</v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row no-gutters v-if="student.financing">
          <v-col cols="12" sm="4" class="font-weight-bold">Финансирование:</v-col>
          <v-col cols="12" sm="8">{{student.financing}}</v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row no-gutters>
          <v-col cols="12" sm="4" class="font-weight-bold">Направление подготовки:</v-col>
          <v-col cols="12" sm="8" class="text-justify">{{student.direction.code}} {{student.direction.name}}</v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row no-gutters>
          <v-col cols="12" sm="4" class="font-weight-bold">Профиль подготовки:</v-col>
          <v-col cols="12" sm="8" class="text-justify">{{student.profile.name}}</v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row no-gutters>
          <v-col cols="12" sm="4" class="font-weight-bold">Кафедра:</v-col>
          <v-col cols="12" sm="8">{{student.education_group.cathedra}}</v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row no-gutters>
          <v-col cols="12" sm="4" class="font-weight-bold">Учебная группа:</v-col>
          <v-col cols="12" sm="8">
            <router-link :to="groupLink">
              {{student.education_group.litter}}-{{student.education_group.course}}{{student.education_group.number}}
            </router-link>
          </v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row no-gutters v-if="student.scientific_director !== undefined">
          <v-col cols="12" sm="4" class="font-weight-bold">Научный руководитель:</v-col>
          <v-col cols="12" sm="8">
            <span v-if="student.scientific_director === null">Не назначен</span>
            <span class="d-flex justify-space-between">
              <span class="mr-auto">
                {{student.scientific_director_fio}}
              </span>
              <v-btn icon small class="ml-auto" @click="change_science_director = true">
                <v-icon small>
                  edit
                </v-icon>
              </v-btn>
            </span>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
    <v-dialog v-model="change_science_director"  min-width="300px" max-width="800px" ref="science_director_modal">
      <ScienceDirectorChouse
        :teacher_uid="student.scientific_director"
        :student_id="student.id"
        :student_fio="student.fio"
        :group_id="student.education_group.id"
        @close="change_science_director = false"
        @saved="changeScienceDirector"
      ></ScienceDirectorChouse>
    </v-dialog>
  </v-container>
</template>

<script>
  import NotAvatar from "@/assets/NotAvatar.png";
  import names from "../../routers/names";
  import ScienceDirectorChouse from "../scientific_directors/ScienceDirectorChouse";

  export default {
    name: "DetailAttachment",
    components: {
      ScienceDirectorChouse
    },
    computed: {
      NotAvatar: () => NotAvatar,
      groupLink: function () {
        return {
          name: names.CathedraGroupsDetail,
          params: {
            cathedra_uid: this.$route.params.cathedra_uid,
            taught: this.$route.params.taught,
            group_id: this.student.education_group.id
          },
          query: {
            student: this.student.id
          }
        }
      }
    },
    props: {
      student: {
        required: true,
        type: Object
      }
    },
    data() {
      return {
        change_science_director: false
      }
    },
    methods: {
      changeScienceDirector(event){
        this.change_science_director = false;
        this.$emit("change_science_director", event)
      }
    }
  }
</script>

<style scoped>

</style>
