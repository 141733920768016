<template>
  <scroll-center-dialog v-model="opened"
                        width="600"
                        @close="$emit('close', false)"
                        @apply="save"
                        title="Изменения дисциплины учебного плана"
  >
    <v-alert outlined type="info" class="py-1 mx-1">
      Загружать можно только файлы в формате pdf размером не более 15 Мб
    </v-alert>
    <div v-if="subject.work_program !== null">
      <v-file-input
        v-if="typeof subject.work_program.name === 'string'"
        v-model="subject.work_program"
        accept="application/pdf"
        label="Рабочая программа дисциплины"
        :error="hasError('work_program')"
        :error-messages="getError('work_program')"
      ></v-file-input>
      <v-text-field
        v-else-if="typeof subject.work_program.file_name === 'string'"
        v-model="subject.work_program.file_name"
        label="Рабочая программа дисциплины"
        readonly
        clearable
        append-icon="get_app"
        prepend-icon="attach_file"
        @click:append="loadFileWork(subject.work_program)"
        @click:clear="subject.work_program = null"
      ></v-text-field>
    </div>
    <v-file-input
      v-else
      v-model="subject.work_program"
      accept="application/pdf"
      label="Рабочая программа дисциплины"
      :error="hasError('work_program')"
      :error-messages="getError('work_program')"
    ></v-file-input>
    <div v-if="subject.methodological_materials !== null ">
      <v-file-input
        v-if="typeof subject.methodological_materials.name === 'string'"
        v-model="subject.methodological_materials"
        accept="application/pdf"
        label="Методические материалы"
        :error="hasError('methodological_materials')"
        :error-messages="getError('methodological_materials')"
      ></v-file-input>
      <v-text-field
        v-else-if="typeof subject.methodological_materials.file_name === 'string'"
        v-model="subject.methodological_materials.file_name"
        label="Методические материалы"
        readonly
        clearable
        append-icon="get_app"
        prepend-icon="attach_file"
        @click:append="loadFileWork(subject.methodological_materials)"
        @click:clear="subject.methodological_materials = null"
      ></v-text-field>
    </div>
    <v-file-input
      v-else
      v-model="subject.methodological_materials"
      accept="application/pdf"
      label="Методические материалы"
      :error="hasError('methodological_materials')"
      :error-messages="getError('methodological_materials')"
    ></v-file-input>
    <div v-if="subject.appraisal_tools !== null">
      <v-file-input
        v-if="typeof subject.appraisal_tools.name === 'string'"
        v-model="subject.appraisal_tools"
        accept="application/pdf"
        label="Оценочные средства"
        :error="hasError('appraisal_tools')"
        :error-messages="getError('appraisal_tools')"
      ></v-file-input>
      <v-text-field
        v-else-if="typeof subject.appraisal_tools.file_name === 'string'"
        v-model="subject.appraisal_tools.file_name"
        label="Оценочные средства"
        readonly
        clearable
        append-icon="get_app"
        prepend-icon="attach_file"
        @click:append="loadFileWork(subject.appraisal_tools)"
        @click:clear="subject.appraisal_tools = null"
      ></v-text-field>
    </div>
    <v-file-input
      v-else
      v-model="subject.appraisal_tools"
      accept="application/pdf"
      label="Оценочные средства"
      :error="hasError('appraisal_tools')"
      :error-messages="getError('appraisal_tools')"
    ></v-file-input>

  </scroll-center-dialog>
</template>

<script>
import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
import {loadData, sendPostRequest, sendPutRequest} from "../../../../helper";
import urls from "@/modules/teacher/urls";
import choices from "../../../core/urls/choices";
import {makePostRequestFile} from "@/helper";
import {loadFile} from "@/helper/fileLoader";
import FormErrorsMixin from "@/mixins/FormErrorsMixin"
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

export default {
  name: "SubjectsForm",
  components: {
    ScrollCenterDialog,
    DebugJsonComponent
  },
  mixins: [
    FormErrorsMixin
  ],
  props: {
    opened: Boolean,
    work_program: Object,
    appraisal_tools: Object,
    methodological_materials: Object
  },
  data() {
    return {
      subject: {
        work_program: this.work_program,
        appraisal_tools: this.appraisal_tools,
        methodological_materials: this.methodological_materials
      }
    }
  },
  methods: {
    loadFileWork(url){
      loadFile(url)
    },
    save() {
        this.update();
    },
    update() {
      let url = urls.getUrl("administrative|curriculum|subject-detail", "partial_update")
        .replace("<cathedra_uid>", this.$route.params.cathedra_uid.toLowerCase())
        .replace("<id>", this.$route.params.idDoc)

      let formData = new FormData();

      if (typeof this.subject.work_program === "object" && this.subject.work_program !== null) {
        if(!this.subject.work_program.file_name)
          formData.append("work_program", this.subject.work_program)
      }else if(this.subject.work_program === null)
        formData.append("work_program", "")
      if (typeof this.subject.appraisal_tools === "object" && this.subject.appraisal_tools !== null) {
        if(!this.subject.appraisal_tools.file_name)
          formData.append("appraisal_tools", this.subject.appraisal_tools)
      }else if(this.subject.appraisal_tools === null)
        formData.append("appraisal_tools", "")
      if (typeof this.subject.methodological_materials === "object" && this.subject.methodological_materials !== null) {
        if(!this.subject.methodological_materials.file_name)
          formData.append("methodological_materials", this.subject.methodological_materials)
      }else if(this.subject.methodological_materials === null)
        formData.append("methodological_materials", "")


      let status = true;
      makePostRequestFile(url, formData, "PATCH")
        .then(resp => {
          if (!resp.ok) {
            status = false;
          }
          return resp.json();
        })
        .then(json => {
          if (status) {
            this.close();
          } else {
            if(json.hasOwnProperty("detail")){
              this.errors.non.push(json["detail"])
            }
            this.mapErrors(json)
          }
        })
    },
    create() {
      // let url = urls.getUrl('administrative|curriculum|subject-detail', 'partial_update')
      //   .replace('<cathedra_uid>', this.$route.params.cathedra_uid.toLowerCase())
      //   .replace('<id>', this.$route.params.idDoc)
      //
      // let formData = new FormData();
      //
      // if (typeof this.subject.work_program === 'object' && this.subject.work_program !== null) {
      //   formData.append('work_program', this.subject.work_program)
      // }
      // if (typeof this.subject.appraisal_tools === 'object' && this.subject.appraisal_tools !== null) {
      //   formData.append('appraisal_tools', this.subject.appraisal_tools)
      // }
      // if (typeof this.subject.methodological_materials === 'object' && this.subject.methodological_materials !== null) {
      //   formData.append('methodological_materials', this.subject.methodological_materials)
      // }
      //
      //
      // let status = true;
      // makePostRequestFile(url, formData, "POST")
      //   .then(resp => {
      //     if (!resp.ok) {
      //       status = false;
      //     }
      //     return resp.json();
      //   })
      //   .then(json => {
      //     if (status) {
      //       this.close();
      //     } else {
      //       alert(json)
      //     }
      //   })
    },
    close() {
      this.$emit("close");
    }
  },
  created() {

  }
}
</script>

<style scoped>

</style>
