<template>
  <v-card class="mx-1" height="100%" :elevation="elevation">
    <v-toolbar elevation="0">
      <v-toolbar-title class="font-weight-bold subtitle-1">
        <slot name="title">
          Общая информация
        </slot>
      </v-toolbar-title>
      <slot name="append_title"></slot>
    </v-toolbar>
    <v-divider></v-divider>
    <v-list dense>
      <slot name="prepend_row"></slot>
      <v-list-item>
        <v-list-item-content class="text-left">
          <v-row>
            <v-col cols="6" class="text-left py-1 px-3">
              Наименование:
            </v-col>
            <v-col cols="6" class="text-right py-1 px-3">
              {{name}}
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="form && (!hides || !hides.form)">
        <v-list-item-content>
          <v-row>
            <v-col cols="6" class="text-left py-1 px-3">
              Форма обучения:
            </v-col>
            <v-col cols="6" class="text-right py-1 px-3">
              {{ form }}
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="level && (!hides || !hides.level)">
        <v-list-item-content>
          <v-row>
            <v-col cols="6" class="text-left py-1 px-3">
              Уровень образования:
            </v-col>
            <v-col cols="6" class="text-right py-1 px-3">
              {{ level }}
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
      <template v-for="(append, index) in appends">
        <v-list-item v-if="data[append.field] && (!hides || !hides[append.field])" :key="`append_row_${index}`">
          <v-list-item-content>
            <v-row>
              <v-col cols="6" class="text-left py-1 px-3">
                {{ append.title }}:
              </v-col>
              <v-col cols="6" class="text-right py-1 px-3">
                {{ data[append.field] }}
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </template>

      <slot name="append_row"></slot>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "ArchiveGroupTemplate",
  props: {
    elevation: {
      type: Number,
      default: 2
    },
    level: {
      type: String
    },
    form: {
      type: String
    },
    name: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: false,
      default: undefined
    },
    hides: {
      required: false,
      type: Object,
      default: () => {
        return {
          course: false,
          form: false,
          level: false
        }
      }
    },
    appends: {
      required: false,
      type: Array
    }
  }
}
</script>

<style scoped>

</style>
