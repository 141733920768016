<template>
  <scroll-center-dialog v-model="dialog"
                        :applyable="false"
                        :dennyable="false"
                        width="500"
                        @close="closeInfo"
                        @apply="closeInfo"
                        :title="'Внимание! Внимание! Внимание!'"
  >
    <v-card-text class="text-left">
      Напоминаем, что ПРИЕМ ЗАЯВЛЕНИЙ на оказание материальной поддержки В ЭТОМ ГОДУ <span class="bold">осуществляется до 5 декабря включительно</span> .<br>
      УСПЕЙТЕ ПОДАТЬ В ЭТОМ ГОДУ НА ВОЗМЕЩЕНИЕ:<br>
      <v-icon color="green">done</v-icon>БИЛЕТЫ ЗА ПРОЕЗД ДОМОЙ<br>
      <v-icon color="green">done</v-icon>ЗА ПРОЖИВАНИЕ В ОБЩЕЖИТИИ<br>
      <v-icon color="green">done</v-icon>ЗАЯВЛЕНИЯ ПО ВСТУПЛЕНИЮ В БРАК, ПОТЕРИ БЛИЗКИХ РОДСТВЕННИКОВ И Т.Д.,<br>
      ТАК КАК В СЛЕДУЮЩЕМ ГОДУ ВЫПЛАТЫ ЗА 2023 ГОД НЕ ОСУЩЕСТВЛЯЮТСЯ!<br>
      <v-icon color="red">hide_source</v-icon> ЗАЯВКИ, ПОДАННЫЕ ПОСЛЕ 5 ДЕКАБРЯ В ЛИЧНОМ КАБИНЕТЕ, НЕ ОБРАБАТЫВАЮТСЯ И НЕ РАССМАТРИВАЮТСЯ!<br>
      <v-icon color="red">hide_source</v-icon> НА СЛЕДУЮЩИЙ ГОД ОЧЕРЕДЬ НЕ ПЕРЕНОСИТСЯ!!!<br>
      <v-icon color="red">hide_source</v-icon> В ЯНВАРЕ ВЫПЛАТЫ НЕ ПРОИЗВОДЯТСЯ!!!<br>
      Также напоминаем о категории "Компенсация питания", по которой вы можете получить выплату, если ваш доход ниже 16242 рублей (учитываются только стипендии и социальные выплаты, которые начисляются в РХТУ).<br>
      Предлагаем ознакомиться со статьей, где собраны основные моменты по
      <a target="_blank" href="https://vk.com/@omp_rhtu-polozheniya-ob-okazanii-materialnoi-podderz">Положению.</a> <br>
    </v-card-text>
  </scroll-center-dialog>
</template>

<script>

import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";


export default {
  name: "AlertStudents",
  components: {ScrollCenterDialog},
  data: function () {
    return {
      dialog:true
    }},
  methods: {
    closeInfo: function () {
      let domain = null
      if(process.env.VUE_APP_COOKIE_DOMAIN !== undefined){
        domain = process.env.VUE_APP_COOKIE_DOMAIN;
      }
      window.$cookies.set(
        "acceptMuctr",
        {
          acceptMuctr: true
        },
        "1d",
        null,
        domain,
        null
      );
      this.dialog = false
    }
  },
  created() {
    if (new Date(2023, 11, 5,18).getTime() < new Date().getTime())
      this.dialog = false
    let acceptMuctr = window.$cookies.get("acceptMuctr");
    if (acceptMuctr !== undefined && acceptMuctr !== null) {
      this.dialog = false
    }
  }
}


</script>
<style scoped>

</style>
