<template>
  <v-container class="px-5" >
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-toolbar
            elevation="0"
            color="style-color-main-gray"
          >
            <v-text-field
              dense
              placeholder="Поиск"
              v-model="search.value"
              @change="searchData"
              messages="Для поиска нажмите Enter или кнопку поиск"
            >
            </v-text-field>
            <v-btn icon><v-icon>search</v-icon></v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  @click=" reloadData()"
                  class="mr-2"
                  medium
                >
                  update
                </v-icon>
              </template>
              <span>Обновить данные</span>
            </v-tooltip>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="students"
            :items-per-page.sync="pagination.size"
            no-data-text="Обучающиеся не загружены"
            no-results-text="Обучающиеся не были найдены"
            :page.sync="pagination.page"
            :loading="pagination.loading"
            :server-items-length="pagination.count"
            :footer-props="{'items-per-page-options':[10,25,50], 'footer-props.items-per-page-text': 'Число элементов'}"
          >
            <template v-slot:loading>
              <template v-for="i in 10">
                <v-skeleton-loader type="table-row" :key="`skeleton${i}`"></v-skeleton-loader>
              </template>
            </template>
            <template v-slot:item.fio="student">
              <v-skeleton-loader v-if="student.item.fio === ''" type="text"></v-skeleton-loader>
              <router-link v-else :to="makeLink(student.item)">
                {{student.item.fio}}
              </router-link>

            </template>
            <template v-slot:item.group="props">
              <router-link :to="makeGroupLink(props.item.group)">
                {{props.item.group.name}}
              </router-link>
            </template>
            <template v-slot:item.headman="student">
              <v-icon color="success" v-if="student.item.headman">
                check_circle_outline
              </v-icon>
              <v-icon v-else>
                highlight_off
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
<!--    <DebugJsonComponent :json="pagination"></DebugJsonComponent>-->
<!--    <DebugJsonComponent :json="students"></DebugJsonComponent>-->
  </v-container>
</template>

<script>
  import {loadData} from "@/helper";
  import names from "../../routers/names";
  import TitledPageMixin from "../../../../mixins/TitledPageMixin";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import OneFieldBaseSeachMixin from "@/mixins/OneFieldBaseSearchMixin";
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";

  export default {
    name: "Students",
    components: {
      DebugJsonComponent
    },
    props: {
      detail_url: {
        required: true,
        type: Function
      }
    },
    computed: {},
    mixins: [PaginatedDataMapperMixin, TitledPageMixin, OneFieldBaseSeachMixin],
    data: function(){
      return {
        page_title: "Список обучающихся",
        headers: [
          {text:"ФИО", value: "fio", sortable: false, width: "40%"},
          {text: "Курс", value: "course", sortable: false, width: "15%", align: "center"},
          {text: "Группа", value: "group", sortable: false, width: "15%", align: "center"},
          {text: "Форма", value: "form", sortable: false, width: "15%", align: "center"},
          {text: "Староста", value: "headman", sortable: false, width: "15%", align: "center"}
        ],
        cathedra_uid: this.$route.params.cathedra_uid,
        students: [],
        search: {
          parameter: "info"
        }
        // loading: false,
      }
    },
    watch: {
      "detail_url": function (){
        this.students = [];
        this.cleanPaginationData();
        this.loadData();
      }
    },
    methods: {
      makeLink: function(item) {
        return {name: names.CathedraStudentsDetail, params: {
            cathedra_uid: this.cathedra_uid,
            student_id: item.id,
            taught: this.$route.params.taught
          }}
      },
      makeGroupLink: function(group) {
        return {name: names.CathedraGroupsDetail, params: {
            cathedra_uid: this.cathedra_uid,
            group_id: group.id,
            taught: this.$route.params.taught
          }}
      },
      loadData: function (url=undefined) {
        this.pagination.loading = true;
        // this.students = [];
        if(url === undefined)
          url = this.detail_url(this.cathedra_uid);
        this.pagination.current = url;
        loadData(
          url,
          (data) => {
            this.mapResults(data, "students");
            this.loadNames(
              this.students,
              "student",
              "fio",
              () => {this.pagination.loading = false}
            );
          }
        )
      }
    },
    created() {
      this.setPageTitleWithObject("Cathedra", {uid: this.$route.params.cathedra_uid}, " - ")
      this.loadData();
    }

  }
</script>

<style scoped>

</style>
