<template>
  <v-container style="height: 100%" v-if="is_self_router">
    <WorkForm v-if="change"
          @close="form_close()"
          :data_work = "data_work">
    </WorkForm>
    <v-card >
      <v-toolbar color="style-color-main-gray" elevation="0">
        <v-text-field
          dense
          placeholder="Поиск"
          v-model="search.value"
          @keypress.enter="searchData"
          messages="Для поиска по названию работы нажмите Enter или кнопку поиск"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="AddItem()"
              class="mr-2"
              medium
            >
              add
            </v-icon>
          </template>
          <span>Добавить работу</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="loadData()"
              class="mr-2"
              medium
            >
              update
            </v-icon>
          </template>
          <span>Обновить данные</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text class="pb-3 px-0 pt-0">
        <v-row justify-center no-gutters>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="works"
              :loading="pagination.loading"
              :page.sync="pagination.page"
              :items-per-page.sync="pagination.size"
              :server-items-length="pagination.count"
              :footer-props="{'items-per-page-options':[10,25,50]}"
              group-by="semester.subject"
              no-data-text="Нет данных"
            >
              <template v-slot:group.header="item">
                <td :colspan="headers.length" class="text-left">
                  <v-btn @click="item.toggle" icon>
                    <v-icon>{{ item.isOpen ? 'remove' : 'add' }}</v-icon>
                  </v-btn>
                  {{item.group}}
                </td>
              </template>
              <template v-slot:item.accept="{  item }">
                <span v-if="item.accept === null">Работа еще не проверялась</span>
                <span v-else-if="item.accept ">Работа  проверена и принята</span>
                <span v-else >Работа проверена и не принята</span>
              </template>

              <template v-slot:item.name="props">
                <router-link target= '_blank' v-if="props.item.name !== ''" :to="makeDetailLink(props.item)">
                  {{props.item.name}}
                </router-link>
                <v-skeleton-loader v-else type="text"></v-skeleton-loader>
              </template>
              <template v-slot:item.semester="props">
                {{props.item.semester.subject}} ({{props.item.semester.semester}} семестр {{props.item.semester.course}} курс)
              </template>
              <template v-slot:item.action="props">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click=changeItem(props.item)
                        class="mr-2"
                        medium
                      >
                        edit
                      </v-icon>
                    </template>
                    <span>Редактировать</span>
                  </v-tooltip>
                  <v-tooltip bottom >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="openConfirmDelete(props.item)"
                        medium
                      >
                        delete
                      </v-icon>
                    </template>
                    <span>Удалить</span>
                  </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-dialog persistent v-model="opened_confirm" width="500">
        <v-card v-if="deletable">
          <v-card-title>Подтвердите удаление</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            Вы уверены, что хотите удалить?<br>
          </v-card-text>
          <v-card-actions>
            <v-flex>
              <v-btn
                @click="deleteItem(deletable.id)"
                color="warning"
                text
              >
                Удалить
              </v-btn>
              <v-btn
                @click="closeConfirmDelete"
                text
              >Отмена
              </v-btn>
            </v-flex>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>


<script>

import TitledPageMixin from "@/mixins/TitledPageMixin";
import UserDataMixin from "../../../../mixins/UserDataMixin"
import {loadData} from "@/helper";
import {generateListUidToName} from "@/helper/uidToName";
import names from "@/modules/teacher/routers/names";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import teacher from "@/urls/teacher";
import WorkForm from "@/modules/teacher/pages/studentWorks/WorkForm"
import {makeDeleteRequest} from "../../../../helper";

export default {
  name: "SupervisedGroupList",
  mixins: [
    TitledPageMixin,
    PaginatedDataMapperMixin,
    UserDataMixin
  ],
  components: {
    WorkForm,
    DebugJsonComponent
  },
  data () {
    return {
      change: false,
      page_title: "Список работ",
      URL_WORK_LIST: teacher.URLS.INDIVIDUAL_WORK.WORK_LIST(),
      search: {
        parameter: "document"
      },
      headers: [
        {text: "Обучающийся", value: "fio", sortable: false, align: "start", width: "20%"},
        {text: "Группа", value: "group", sortable: false, align: "center"},
        {text: "Название работы", value: "name", sortable: false, align: "center"},
        {text: "Тип работы", value: "type", sortable: false, align: "center"},
        {text: "Проверено", value: "accept", sortable: false, align: "center"},
        {text: "Предмет", value: "semester", sortable: false, align: "center"},
        {text: "Действия", value: "action", sortable: false, align: "center"}
      ],
      works: [],
      data_work: ""
    }
  },
  beforeRouteUpdate(to, from, next) {
    if(to.name === names.WORKS.LIST)
      this.loadData();
    next()
  },
  computed: {
    is_self_router(){
      return this.$route.name === names.WORKS.LIST;
    }
  },
  created() {
    if(this.is_self_router)
      this.loadData();
  },
  methods: {
    makeDetailLink(item){
      return {name: names.WORKS.DETAIL, params: { idWork: item.id}}
    },
    deleteItem(id){
      let url  = teacher.URLS.INDIVIDUAL_WORK.WORK_DELETE(id, this.$route.params.idStudent)
      makeDeleteRequest(url).then(resp=>{
        this.opened_confirm = false
        this.getItems(this.getCurrentLoadUrl())
      })
    },
    loadData(url = undefined) {
      this.getItems(url)
    },
    getItems (url=undefined) {
      this.setLoading();
      if(url === undefined) {
        url = this.URL_WORK_LIST
        this.setCurrentLoadUrl(url);
      }
      loadData(
        url,
        (data) => {
          this.works = data.results;
          this.mapPagination(data) ;
          for (let el of this.works) this.$set(el, "fio","")
          generateListUidToName(this.works,"student","fio", () => {this.setNotLoading();})
        }
      )
    },
    AddItem(){
      this.data_work = ""
      this.change = true
    },
    changeItem(data){
      this.data_work = data
      this.change = true
    },
    form_close() {
      this.change = false
      this.getItems(this.getCurrentLoadUrl())
      this.data_work = ""
    }
  }
}


</script>
<style scoped>

</style>
