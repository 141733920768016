import names from "./names";
import Base from "../pages/teachers/Base";
import Groups from "../pages/teachers/Groups";
import Students from "../pages/teachers/Students";
import Subjects from "../pages/teachers/Subjects";
import ScientificDiplom from "../pages/teachers/ScientificDiplom";
import PortfolioTeacher from "../pages/teachers/portfolio/Portfolio";
import ListWorks from "../pages/teachers/studentWorks/ListWorks"
import DetailWork from "../pages/teachers/studentWorks/DetailWork"

const organization = String(process.env.VUE_APP_ORGANIZATION_PREFIX).replace("/", "");

export default ([
  {
    path: ":teacher_uid",
    name: names.CathedraTeachersDetail,//'Administrative|Cathedra|Students',
    component: Base,
    meta: {
      requiredAuth: true,
      breadcrumb: "Преподаватель",
      instances: [organization]
    },
    children: [
      {
        path: "diploms",
        name: names.CathedraTeachersDetailDiploms,//'Administrative|Cathedra|Students',
        component: ScientificDiplom,
        meta: {
          requiredAuth: true,
          breadcrumb: "Диплом"
        },
        children: []
      },
      {
        path: "groups",
        name: names.CathedraTeachersDetailGroups,//'Administrative|Cathedra|Students',
        component: Groups,
        meta: {
          requiredAuth: true,
          breadcrumb: "Группы"
        },
        children: []
      },
      {
        path: "students",
        name: names.CathedraTeachersDetailStudents,//'Administrative|Cathedra|Students',
        component: Students,
        meta: {
          requiredAuth: true,
          breadcrumb: "Обучающиеся"
        },
        children: []
      },
      {
        path: "subjects",
        name: names.CathedraTeachersDetailSubjects,//'Administrative|Cathedra|Students',
        component: Subjects,
        meta: {
          requiredAuth: true,
          breadcrumb: "Дисциплины"
        },
        children: []
      },
      {
        path: "works",
        name: names.CathedraTeachersDetailWorks,
        component: ListWorks,
        meta: {
          breadcrumb: "Работы обучающихся",
          requiredAuth: true
        },
        children:[
          {
            path: ":idWork([0-9]+)",
            name: names.CathedraTeachersDetailWorksDetail,
            component: DetailWork,
            meta: {
              breadcrumb: "Работа",
              requiredAuth: true
            }
          }
        ]
      },
      {
        path: "portfolio",
        name: names.CathedraTeachersPortfolio,//'Administrative|Cathedra|Students',
        component: PortfolioTeacher,
        meta: {
          requiredAuth: true,
          breadcrumb: "Портфолио"
        },
        children: []
      }

    ]
  }
  // ...curriculum,
])


