
    <template>
      <v-container v-if="$route.name === names.StudentEducationListEducationWorks">
<!--            <DebugJsonComponent :json="data_work"></DebugJsonComponent>-->
        <Work v-if="change"
              @close="form_close()"
              :data_work = "data_work">
        </Work>
        <v-card >
          <v-toolbar
            class="mb-2"
            color="style-color-main-gray"
            flat
          >
            <v-card-title class="title px-0"  style="word-break: break-word !important;" >
              Список загруженных работ
            </v-card-title>
            <v-spacer></v-spacer>
            <v-btn text icon @click=AddItem>
              <v-icon
                medium
              >
                add
              </v-icon>
            </v-btn>

          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="items"
            class="elevation-1 "
            no-data-text="Нет данных"
            :options.sync="pagination"
            :page.sync="pagination.page"
            :items-per-page.sync="pagination.size"
            :server-items-length="pagination.count"
            :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
          >
            <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
              <template v-slot:item.accept="props">
                                  <span v-if="props.item.accept === null">Работа еще не проверялась</span>
                                  <span v-else-if="props.item.accept === true">Работа проверена и принята</span>
                                  <span v-else  >Работа проверена и не принята</span>
              </template>
            <template v-slot:item.name="props">
              <span v-if="props.item.name === 'null'">-</span>
              <span v-else  >{{props.item.name}}</span>
            </template>
              <template v-slot:item.actions="props">
                <v-tooltip bottom  v-if="props.item.accept === null">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      @click=changeItem(props.item)
                      medium
                    >
                      <v-icon>
                        edit
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Редактировать</span>
                </v-tooltip>
<!--                <v-tooltip bottom  v-if="props.item.accept === null">-->
<!--                  <template v-slot:activator="{ on }">-->
<!--                    <v-btn-->
<!--                      icon-->
<!--                      v-on="on"-->
<!--                      @click="openConfirmDelete(props.item)"-->
<!--                      medium-->
<!--                    >-->
<!--                      <v-icon>-->
<!--                        delete-->
<!--                      </v-icon>-->
<!--                    </v-btn>-->
<!--                  </template>-->
<!--                  <span>Удалить</span>-->
<!--                </v-tooltip>-->
              </template>
            <template v-slot:item.file_name="props">
              <span>{{props.item.file_name}}</span>
            </template>
            <template v-slot:pageText="item">
              {{item.pageStart}} - {{item.pageStop}} из {{item.itemsLength}}
            </template>
            <template v-slot:no-data>
              <v-card-text>Данные не загружены</v-card-text>
            </template>
          </v-data-table>

<!--          <v-dialog persistent v-model="opened_confirm" width="500">-->
<!--            <v-card v-if="deletable">-->
<!--              <v-card-title>Подтвердите удаление</v-card-title>-->
<!--              <v-divider></v-divider>-->
<!--              <v-card-text class="mt-4">-->
<!--                Вы уверены, что хотите удалить?<br>-->
<!--              </v-card-text>-->
<!--              <v-card-actions>-->
<!--                <v-flex>-->
<!--                  <v-btn-->
<!--                    @click="deleteItem(deletable.id)"-->
<!--                    color="warning"-->
<!--                    text-->
<!--                  >-->
<!--                    Удалить-->
<!--                  </v-btn>-->
<!--                  <v-btn-->
<!--                    @click="closeConfirmDelete"-->
<!--                    text-->
<!--                  >Отмена-->
<!--                  </v-btn>-->
<!--                </v-flex>-->
<!--              </v-card-actions>-->
<!--            </v-card>-->
<!--          </v-dialog>-->
        </v-card>
      </v-container>
      <router-view :key="$route.fullPath" v-else></router-view>
    </template>

    <script>
      import UserDataMixin from "../../../../mixins/UserDataMixin"
      import Work from "@/modules/student/forms/Work";
      import student from "@/urls/student";
      import {loadData, makeDeleteRequest} from "../../../../helper";
      import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin"
      import DebugJsonComponent from "../../../core/components/DebugJsonComponent"
      import names from "@/modules/student/routers/names"

      export default {
        name: "ListEducationWorks",
        components: {
          DebugJsonComponent,
          Work
        },
        mixins: [
          PaginatedDataMapperMixin,
          UserDataMixin
        ],

        data() {
          return {
            change: false,
            total: 0,
            URL_WORK_LIST: student.STUDENT.INDIVIDUAL_WORK.WORK_LIST(this.$route.params.idStudent),
            pagination: {},
            items: [],
            data_work: "",
            headers: [
              {text: "Предмет", sortable: false, align: "left",value:"semester.subject",width: "20%"},
              {text: "Семестр", sortable: false, align: "left",value:"semester.semester"},
              {text: "Курс", sortable: false, align: "left",value:"semester.course"},
              {text: "Тип файла", sortable: false, align: "left",value:"type"},
              {text: "Название работы", sortable: false, align: "left",value:"name"},
              {text: "Проверено", sortable: false, align: "left",value:"accept"},
              {text: "Файл", sortable: false, align: "left",value:"file_name", width: "20%"},
              {text: "Действия", sortable: false, align: "center",value:"actions"}
            ]
          }
        },
        computed: {
          names: () => names
        },
        beforeRouteUpdate(to, from, next) {
          if(to.name === names.StudentEducationListEducationWorks)
            this.getItems();
          next()
        },
        created() {
          this.$store.commit("mainpage/changeTitle", "Список учебных работ")
          this.getItems()
        },
        methods: {
          form_close() {
            this.change = false
            this.getItems()
            this.data_work = ""
          },
          getDetail(item) {
            this.$router.push({name: names.StudentEducationDetailEducationWorks, params: {idWorks: item.id}})
          },
          deleteItem(id){
            let url  = student.STUDENT.INDIVIDUAL_WORK.WORK_DELETE(id, this.$route.params.idStudent)
            makeDeleteRequest(url).then(resp=>{
              this.opened_confirm = false
              this.getItems()
            })
          },
          AddItem(){
            this.data_work = ""
            this.change = true
          },
          changeItem(data){
            this.data_work = data
            this.change = true
          },
          loadData(url = undefined) {
            this.getItems(url)
          },
          getItems(url = undefined) {
            this.setLoading()
            if (url === undefined) {
              this.setCurrentLoadUrl(
                this.URL_WORK_LIST
              )
              url = this.getCurrentLoadUrl()
            }
            loadData(
              url,
              (data) => {
                this.mapResults(data, "items")
                this.setNotLoading()
              }
            )
          }
        }
      }

    </script>

    <style scoped>
    </style>
