<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-card>
          <v-toolbar
            elevation="0"
            color="style-color-main-gray"
          >
            <v-text-field
              dense
              placeholder="Поиск"
              v-model="search.value"
              @keypress.enter="searchData"
              messages="Для поиска нажмите Enter или кнопку поиск"
            ></v-text-field>
  <!--          <v-btn icon><v-icon>search</v-icon></v-btn>-->
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="students"
            :page.sync="pagination.page"
            :items-per-page.sync="pagination.size"
            :server-items-length="pagination.count"
            :footer-props="pagination.footer_page"
          >
            <template v-slot:item.fio="student">
              <span :class="{'font-weight-bold': student.item.headman}">
                <v-skeleton-loader type="text" v-if="student.item.fio === ''"></v-skeleton-loader>
                <router-link :to="makeStudentLink(student.item)" v-else>
                  {{student.item.fio}}
                </router-link>
              </span>
            </template>
            <template v-slot:item.group="student">
              <span :class="{'font-weight-bold': student.item.headman}">
                <router-link :to="makeGroupLink(student.item)">
                  {{student.item.education_group.name}}
                </router-link>
              </span>
            </template>
            <template v-slot:item.headman="student">
              <v-icon color="success" v-if="student.item.headman">
                check
              </v-icon>
              <v-icon v-else>
                remove
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
<!--    <DebugJsonComponent :json="students"></DebugJsonComponent>-->
  </v-container>
</template>

<script>
  import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
  import TitledPageMixin from "../../../../mixins/TitledPageMixin";
  import cathedras from "../../../../urls/cathedras";
  import {loadData} from "../../../../helper";
  import {generateListUidToName} from "../../../../helper/uidToName";
  import names from "../../routers/names";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";

  export default {
    name: "Students",
    components: {
      DebugJsonComponent
    },
    mixins: [TitledPageMixin, PaginatedDataMapperMixin],
    props: {
      teacher_fio: {
        required: false,
        type: String
      }
    },
    data: function(){
      return {
        // page_title: `Список обучающихся ${this.teacher_fio}`,
        students: undefined,
        headers: [
          {text: "ФИО", value: "fio", width: "35%", filterable: true, sortable: false},
          {text: "Кафедра", value: "cathedra", width: "35%", filterable: true, sortable: false},
          {text: "Группа", value: "group", width: "15%", align: "center", filterable: true, sortable: false},
          {text: "Староста", value: "headman", width: "15%", align: "center", filterable: false, sortable: false}
        ],
        search: {
          parameter: "fio"
        }
      };
    },
    computed: {
      page_title() {
        return `${this.teacher_fio}: Список обучающихся`
      }
    },
    methods: {
      makeStudentLink: function(student) {
        return {
          name: names.CathedraStudentsDetail,
          params: {
            cathedra_uid: this.$route.params.cathedra_uid,
            student_id: student.id,
            taught: student.attachment?undefined:"taught"
          },
          query: {
            teacher: this.$route.params.teacher_uid
          }
        }
      },
      makeGroupLink: function(student) {
        // console.log(id)
        return {
          name: names.CathedraGroupsDetail,
          params: {
            cathedra_uid: this.$route.params.cathedra_uid,
            group_id: student.education_group.id,
            taught: student.attachment?undefined:"taught"
          },
          query: {
            teacher: this.$route.params.teacher_uid
          }
        }
      },
      loadData(url=undefined) {
        this.setLoading();
        if(url === undefined) {
          this.setCurrentLoadUrl(
            cathedras.CATHEDRA.TEACHERS.STUDENTS(
              this.$route.params.cathedra_uid,
              this.$route.params.teacher_uid
            )
          )
          url = this.getCurrentLoadUrl()
        }
        loadData(
          url,
          (data) => {
            // this.students = data.results;
            this.mapResults(data, "students")
            // let fios = [];
            this.students.forEach(el => {
              this.$set(el, "fio", "");
            });
            generateListUidToName(this.students, "student", "fio");
            this.setNotLoading();
          }
        )
      }
    },
    created() {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
