import { render, staticRenderFns } from "./EducationTemplate.vue?vue&type=template&id=2682023e&scoped=true"
import script from "./EducationTemplate.vue?vue&type=script&lang=js"
export * from "./EducationTemplate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2682023e",
  null
  
)

export default component.exports