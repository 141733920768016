<template>
  <v-container v-if="names.INDIVIDUAL_STUDENTS.LIST === this.$route.name">
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-toolbar
            elevation="0"
            color="style-color-main-gray"
          >
            <v-text-field
              dense
              placeholder="Поиск"
              v-model="search.value"
              @keypress.enter="searchData"
              @click:clear="clearSearchData"
              clearable
              messages="Для поиска нажмите Enter или кнопку поиск"
              append-icon="search"
              @click:append="searchData"
            >
            </v-text-field>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page.sync="pagination.size"
            no-data-text="Обучающийся с индивидуальными учебными планами не загружены"
            no-results-text="Обучающийся с индивидуальными учебными планами не были найдены"
            :page.sync="pagination.page"
            :loading="pagination.loading"
            :server-items-length="pagination.count"
            :footer-props="{'items-per-page-options':[10,20,50], 'show-current-page': true, 'show-first-last-page': true}"
          >
            <template v-slot:item.journal="props">
              <v-btn outlined :to="journalLink(props.item.id)">
                <v-icon>format_list_bulleted</v-icon>Журнал оценок
              </v-btn>
            </template>
            <template v-slot:item.semesters="props">
              <v-row class="py-6">
                <template v-for="(semester, index) in props.item.semesters" >
                  <span :key="`semesters_${index}`" class="mx-1"  >
                    {{semester.name}}<template v-if="index + 1 !== props.item.semesters.length">;</template>
                  </span>
                </template>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <router-view v-else></router-view>
</template>

<script>

import names from "@/modules/teacher/routers/names";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import urls from "@/urls/teacher"
import {loadData} from "@/helper";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";

export default {
  name: "ListStudents",
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  data: function() {
    return {
      names: names,
      page_title: "Список обучающихся с индивидуальным учебным планом",
      items: [],
      search: {
        parameter: "fio"
      },
      headers: [
        {text: "ФИО", sortable: false, align: "left", value: "student", width: "20%"},
        {text: "Дисциплины", sortable: false, align: "left", value: "semesters", width: "60%"},
        {text: "", sortable: false, align: "left", value: "journal", width: "20%"}
      ]
    }
  },
  computed: {},
  methods:{
    journalLink(student_id) {
      return {
        name: names.INDIVIDUAL_STUDENTS.JOURNAL,
        params: {
          student_id: student_id
        },
        query: this.$route.query
      }
    },
    loadData(url=undefined){
      this.setLoading()
      if (url === undefined) {
        url = urls.URLS.INDIVIDUAL_STUDENT.LIST()
        this.setCurrentLoadUrl(url)
      }
      loadData(
        url,
        (data) => {
          this.mapResults(data, "items")
          this.setNotLoading()
        }
      )
    }
  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
