<template>
  <v-container >
    <v-card  >
      <v-toolbar class="title" flat dense>
<!--        <v-toolbar-title>-->
<!--          Выдача прав учебного управления работнику-->
<!--        </v-toolbar-title>-->
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn @click="opened_form = true" color="secondary" text>
            <v-icon>add</v-icon>
            Добавить
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                text
                v-on="on"
                @click="getItems()"
                class="mr-2"
                medium
              >
                <v-icon>
                  update
                </v-icon>
              </v-btn>
            </template>
            <span>Обновить данные</span>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pb-3 px-0 pt-0">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          no-data-text="Нет данных"
          :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
        >
          <template v-slot:item="props">
            <tr class="text-left">
              <td class="text-left">{{props.item.fio}}</td>
              <td class="text-left"><v-icon v-if="props.item.head">done</v-icon> <v-icon v-else>clear</v-icon></td>
              <td class="text-center" v-bind="selected">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      @click="openConfirmDelete(props.item)"
                      medium
                    >
                      <v-icon>
                        delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Удалить</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template slot="no-data">
            <v-card-text>Данные не загружены</v-card-text>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

<!--    <v-dialog @input="closedForm" v-model="opened_form" width="500" persistent>-->
      <Form @addItem="addItem"
             @ended="closedForm"
             @refreshItem="refreshItem"
             v-bind="selected"
             v-bind:opened_form="opened_form"
             v-if="opened_form"
      >
      </Form>
<!--    </v-dialog>-->

    <v-dialog persistent v-model="opened_confirm" width="500">
      <v-card v-if="deletable">
        <v-card-title>Подтвердите удаление</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          Вы уверены, что хотите удалить?<br>
        </v-card-text>
        <v-card-actions>
          <v-flex>
            <v-btn
              @click="deleteItem(deletable.id)"
              color="warning"
              text
            >
              Удалить
            </v-btn>
            <v-btn
              @click="closeConfirmDelete"
              text
            >Отмена
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>


<script>
  import Form  from "@/modules/curriculumEditor/pages/permission/Form"
  import UserDataMixin from "@/mixins/UserDataMixin"
  import {
    getInstNameByRouterName,
    makeGetRequest
  } from "@/helper";
  import urls from "../../urls"
  import {generateListUidToName} from "@/helper/uidToName";
  import names from "@/modules/dean/routers/names";

  export default {
    name: "ListLedGroups",
    components: {Form},
    mixins: [UserDataMixin],
    props: {
    },
    data () {
      return {
        URL_LIST:  urls.NAMES.EDITOR.WORKER.LIST(),
        URL_DELETE: urls.NAMES.EDITOR.WORKER.DESTROY(),
        total: 0,
        names:names,
        items: [],
        loading: true,
        pagination: {},
        headers: [
          {text: "Работник", sortable: false },
          {text: "Глава учебного управления", sortable: false},
          {text: "Действия", sortable: false, align: "center"}
        ]
      }
    },
    watch: {
      pagination: {
        handler () {
          this.getItems()
        },
        deep: true
      }
    },


    created() {
      this.getItems();
      this.$store.commit("mainpage/changeTitle", "Выдача прав на редактирование учебных планов");
    },
    methods: {


      addItem(){
        this.closedForm()
        this.getItems();
      },

      getItems () {
        this.loading = true
        let url = new URL(this.URL_LIST)
        let params = {};

        url.search = new URLSearchParams(params).toString();

        let sys = true
        makeGetRequest(url).then(resp => {
          if (!resp.ok) {
            sys = false
          }
          return resp.json()
        }) .then(json => {
          this.items = json
          this.loading = false
          for (let i in this.items) {
            this.$set(this.items[i], "fio","-")
          }
          generateListUidToName(this.items,"worker","fio")
        })
      }
    }
  }


</script>
<style scoped>

</style>
