<template>
  <v-container v-if="is_self_router">
    <PrintAdmissions :admissions="print" style="display: none"></PrintAdmissions>
    <v-card >
<!--      <DebugJsonComponent :json="success"></DebugJsonComponent>-->
      <v-toolbar
        class="mb-2"
        color="style-color-main-gray"
        flat
      >
        <v-text-field class=""
                      dense
                      placeholder="Поиск"
                      v-model="search.value"
                      @keypress.enter="searchData"
                      @click:clear="clearSearchData"
                      clearable
                      messages="Для поиска нажмите Enter"
        ></v-text-field>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon class="pa-3"
              v-on="on"
              @click="opened_load= true"
              medium
            >
              get_app
            </v-icon>
          </template>
          <span>Загрузить допуски</span>
        </v-tooltip>
        <template v-slot:extension>
          <v-row>
            <v-col cols="3">
              <v-select class="py-0 " :items="selectors.success" v-model="pagination.filters.success"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select class="py-0 " :items="selectors.session" v-model="pagination.filters.session"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select
                clearable
                label="Вид допуска"
                class="py-0 " :items="selectors.first_tolerance" v-model="pagination.filters.first_tolerance"
              ></v-select>
            </v-col>
            <v-col>
              <v-autocomplete class="py-0 " :items="selectors.subject" v-model="pagination.filters.subject"></v-autocomplete>
            </v-col>
          </v-row>
        </template>

      </v-toolbar>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :loading="pagination.loading"
        :page.sync="pagination.page"
        :items-per-page.sync="pagination.size"
        :server-items-length="pagination.count"
        :footer-props="{'items-per-page-options':[10,25,50]}"
        :items="items"
        show-select
      >
        <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
        <template v-slot:header.data-table-select>
          <v-icon>mail</v-icon>
        </template>
        <template v-slot:foot>
          <PrintAdmissions :id="'printMe2'" :admissions="admissionPrint" style="display: none"></PrintAdmissions>
          <v-btn
            color="primary"
            dark
            :loading="loadingPrint"
            class="ma-2"
            :disabled = 'selected.length === 0'
            @click="PrintAdmissions"
          >
            Напечатать
          </v-btn>

        </template>
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Отправить письма: {{ selected.length }}/10</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="ma-2"
              @click="show_teachers = true"
              v-if="!show_teachers"
            >
              Закрепление преподавателей
            </v-btn>
            <v-btn
              color="success"
              dark
              class="ma-2"
              @click="SaveChanges"
              v-if="show_teachers"
            >
              Сохранить изменения
            </v-btn>
            <v-btn
              color="error"
              dark
              class="ma-2"
              @click="UndoChanges"
              v-if="show_teachers"
            >
              Отменить изменения
            </v-btn>
            <v-btn
              v-if="(selected.length < 11) && (selected.length > 0)"
              color="primary"
              dark
              class="ma-2"
              v-model="sendMesg.dialog"
              @click="sendMesg.dialog = true"
            >
              Совершить рассылку
            </v-btn>
            <scroll-center-dialog
              v-model="sendMesg.dialog"
              width="500px"
              @close="sendMesg.dialog=false"
              @apply="sendEmail"
              :loading="sendMesg.loading"
              title="Совершить рассылку"
              apply-color="green darken-1"
              apply-text="Отправить"
              :denny-text="!sendMesg.alert ? 'Отмена' : 'Закрыть'"
              :applyable="!sendMesg.alert"
            >
              <p class="pt-4">Уведомление о допуске по {предмет} от {кафедра}</p>
              <p>Уважаемый обучающийся {фио}!</p>
              <p>Сообщаем Вам о выданном допуске по дисциплине "{дисциплина}" ({кафедра}) для дополнительной сессии с
                {даты сессии} по {даты сессии}.</p>
              <p> Кафедра подготовила для вас информационное сообщение:</p>
              <v-textarea v-model="sendMesg.text"
                          outlined
                          class="py-3 px-3"
                          :rules="[rules.textErr]"
                          :error="hasErrorMsg('Это поле не может быть пустым.')"
                          :error-messages="getErrorMsg('Это поле не может быть пустым.')"

                          label="Текст рассылки">
              </v-textarea>
              <p> Это письмо создано автоматически.
              <p/>
              <p>Пожалуйста, не отвечайте на него. </p>
              <v-text-field v-model="sendMesg.user_email"
                            v-if="sendMesg.dev"
                            class="py-3 px-3"
                            label="отладочная почта рассылки(тестовый сервер)">
              </v-text-field>
              <v-alert v-if="sendMesg.alert" type="success">
                Успешно отправленно
              </v-alert>
            </scroll-center-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.data-table-select="props">
          <v-simple-checkbox v-if="props.item.success == null" :value="props.isSelected" @input="props.select($event)"></v-simple-checkbox>
        </template>
        <template v-slot:item.subject="props">
          <span style="text-decoration: underline; cursor: pointer" @click="getDetail(props.item)">{{props.item.subject}} </span>
        </template>
        <template v-slot:item.semester="props">
          <span>{{getNumberSemestr(props.item.semester,props.item.course)}}  </span>
        </template>
        <template v-slot:item.group="props">
          <span>{{props.item.group.name}}</span>
        </template>
        <template v-slot:item.control_type="props">
          <span>{{props.item.control_type}}</span>
        </template>
        <template v-slot:item.created="props">
          <span>{{displayDate(props.item.created)}}</span>
        </template>
        <template v-slot:item.action="props">
            <v-tooltip bottom >
              <template v-slot:activator="{ on, attrs }">
                <v-btn  v-bind="attrs" v-on="on"  icon @click="PrintAdmission(props.item)"><v-icon>print</v-icon></v-btn>
              </template>
              <span>Напечатать допуск</span>
            </v-tooltip>
            <v-tooltip bottom v-if="props.item.reopen">
              <template v-slot:activator="{ on, attrs }">
                <v-btn  v-bind="attrs" v-on="on"  icon @click="ReopenAdmission(props.item)"><v-icon>published_with_changes</v-icon></v-btn>
              </template>
              <span>Переоткрыть допуск</span>
            </v-tooltip>
        </template>
        <template v-slot:item.admission_type="props">
          <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{getAbbreviation(props.item.academic_difference, props.item.is_exam)}}</span>
            </template>
            <span>{{getAdmissionType(props.item.academic_difference, props.item.is_exam)}}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="props.item.tolerance_count > 1">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on"> <v-icon color="red"> done</v-icon></span>
            </template>
            Это {{props.item.tolerance_count}} допуск данного вида по предмету.
          </v-tooltip>

        </template>
        <template v-slot:item.success="props">
          <v-icon v-if="props.item.success" color="green">done</v-icon>
          <v-icon v-else-if="props.item.success !== null" color="red">clear</v-icon>
          <span v-else>
            <span v-if="props.item.closed && !props.item.success">Архив</span>
            <span v-else>Не рассмотрен</span>
          </span>
        </template>
        <template  v-slot:item.teacher="props" >
          <v-autocomplete
            v-if="show_teachers"
            v-model="props.item.teacher"
            :items="selectors.teacher"
            label="Преподаватель"
            :disabled="props.item.success !== null"
            :hide-details="!hasError(props.item.teacher, props.item.id)"
            :error="hasError(props.item.teacher, props.item.id)"
            :error-messages="getError(props.item.teacher, props.item.id)"
          >
          </v-autocomplete>
          <span v-else>{{props.item.fio_teacher}}</span>
        </template>
        <template slot="no-data">
          <v-card-text>Данные не загружены</v-card-text>
        </template>
      </v-data-table>
    </v-card>
    <LoadXlsx @ended="opened_load = false"
              v-if="opened_load"
              :opened_form="opened_load"
    >
    </LoadXlsx>
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>
<script>

  import names from "@/modules/cathedra/routers/names";
  import selectors from "@/urls/selectors";
  import cathedra from "@/urls/cathedras"
  import UserDataMixin from "@/mixins/UserDataMixin"
  import {
    addGetParameters,
    loadData,
    sendPostRequest,
    checkIsProductionServer,
    makeGetRequest,
    sendGetRequest
  } from "@/helper";
  import TitledPageMixin from "@/mixins/TitledPageMixin";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import {generateListUidToName, generateUidToName, uidToPositionHelds} from "@/helper/uidToName";
  import moment from "moment"
  import LoadXlsx from "@/modules/cathedra/pages/admission/LoadXlsx";
  import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";
  import PrintAdmissions from "@/modules/templates/admission/PrintAdmission";
  import urls from "@/urls/teacher";
  export default {
    name: "ListAdmissions",
    mixins: [ TitledPageMixin, PaginatedDataMapperMixin, UserDataMixin],
    components: {ScrollCenterDialog, PrintAdmissions,LoadXlsx},
    data () {
      return {
        search: {
          parameter: "search"
        },
        errors: [],
        opened_load:false,
        success: "null",
        session: null,
        subject:null,
        selected: [],
        admissionPrint: [],
        print:null,
        selectors:{
          subject:[],
          session:[],
          first_tolerance:[
            {text:"Первичный допуск",value: "true"},
            {text:"Повторный допуск",value: "false"}
          ],
          success:[
            {text:"Все допуски",value: ""},
            {text:"Успешно закрытые допуски",value: "true"},
            {text:"Не успешно закрытые допуски",value: "false"},
            {text:"Допуски не взятые в работу",value: "null"},
            {text:"Все закрытые",value: "all_closed "}
          ],
          teacher: []
        },
        show_teachers: false,
        page_title: "Список допусков",
        total: 0,
        names:names,
        loadingPrint:false,
        items: [],
        admission: [],
        loading: true,
        sendMesg:{
          dev:true,
          alert:false,
          text: "",
          user_email: "krasnov.d.o@muctr.ru",
          dialog: false,
          loading: false
        },
        rules: {
          textErr: v => !!(v || "") || "Это поле не может быть пустым."
        },
        pagination: {
          filters: {
            success: "null",
            subject: null,
            session: null,
            first_tolerance: null
          }
        },
        URL_LIST: cathedra.CATHEDRA.ADMISSION.LIST(this.$route.params.cathedra_uid.toLowerCase()),
        headers: [
          {text: "Номер", sortable: false, align: "left", value: "number"},
          {text: "Предмет", sortable: false, align: "left", value: "subject"},
          {text: "Семестр", sortable: false, align: "left", value: "semester"},
          {text: "Контроль", sortable: false, align: "left", value: "control_type"},
          {text: "Обучающийся", sortable: false, align: "left", value: "fio_student"},
          {text: "Группа", sortable: false, align: "left", value: "group"},
          {text: "Тип допуска", sortable: false, align: "left", value: "admission_type"},
          {text: "Рейтинг в семестре", sortable: false, align: "left", value: "in_semester"},
          {text: "Рейтинг", sortable: false, align: "left", value: "total"},
          {text: "Дата выдачи", sortable: false, align: "left", value: "created"},
          {text: "Статус", sortable: false, align: "left", value: "success"},
          {text: "Преподаватель", sortable: false, align: "left", value: "teacher"},
          {text: "Действия", value: "action", sortable: false, align: "center"}
        ]
      }
    },
    created() {
      this.loadSelectorSubject()
      this.sendMesg.dev=!checkIsProductionServer();
      this.setPageTitleWithObject("Cathedra", {uid: this.$route.params.cathedra_uid.toLowerCase()}, " - ")
      this.loadTeachers();
    },
    watch: {
      "sendMesg.dialog": function (nVal, oVal) {
        this.sendMesg.alert = false
      },
      "sendMesg.text": function (nVal, oVal) {
        this.errors = []
      }
    },
    computed: {
      is_self_router(){
        this.loadSelectorSubject()
        return this.$route.name === names.CathedraAdmission;
      }
    },
    methods: {
      PrintAdmissions(){
        this.loadingPrint=true
        this.admissionPrint = []
        let prom = []
        this.selected.forEach((element) =>
          prom.push(this.loadDataDetail(element.id))
        );
        Promise.all(prom).then((values) => {
            const options = {
              name: "_blank",
              autoClose: false,
              specs: [
                "fullscreen=yes",
                "titlebar=yes",
                "scrollbars=yes"
              ],
              styles: [
                "*"
              ]
            }
          setTimeout(()=>{
            this.loadingPrint=false
            this.$htmlToPaper("printMe",options);
          }, 2000);
        })
      },
      loadDataDetail(idAdmission){
        let url = new URL( cathedra.CATHEDRA.ADMISSION.DETAIL(this.$route.params.cathedra_uid.toLowerCase(), idAdmission))
        let params = {};
        url.search = new URLSearchParams(params).toString();

        let sys = true;
        return makeGetRequest(url).then(resp => {
          return resp.json()
        }).then(json => {
          var detail = json
          this.$set(detail, "fio_teacher", "Не указан")
          generateUidToName(detail, "teacher", "fio_teacher")
          this.$set(detail, "fio_student", "Не указан")
          generateUidToName(detail, "student", "fio_student")
          this.$set(detail, "fio_issued_by", "Не указан")
          if(detail.issued_by)
            uidToPositionHelds(detail, "issued_by", "fio_issued_by")
          this.admissionPrint.push(detail)
        })
      },
      PrintAdmission(item){
        let url = new URL( cathedra.CATHEDRA.ADMISSION.DETAIL(this.$route.params.cathedra_uid.toLowerCase(),item.id))
        let params = {};
        url.search = new URLSearchParams(params).toString();
        makeGetRequest(url).then(resp => {
          return resp.json()
        }).then(json => {
          let print = json
           print.fio_student = item.fio_student
           print.fio_teacher = item.fio_teacher
          this.print = [print]
          const options = {
            name: "_blank",
            autoClose: false,
            specs: [
              "fullscreen=yes",
              "titlebar=yes",
              "scrollbars=yes"
            ],
            styles: [
              "*"
            ]
          }
          this.$nextTick(() => {
            this.$htmlToPaper("printMe",options);
          });
        })
      },
      ReopenAdmission(item){
        let url = cathedra.CATHEDRA.ADMISSION.REOPEN(this.$route.params.cathedra_uid.toLowerCase(),item.id)
        sendGetRequest(url,
          ()=>{
            this.$router.push({name: names.CathedraAdmissionDetail, params: {idAdmission: item.id}})
          },
          (error)=>{
            alert(error.error)
          })
      },
      hasError: function (teacher, tolerance) {
        if(this.errors.length > 0){
          let idx = this.errors.findIndex(el => el.teacher === teacher, el => el.tolerance === tolerance)
          if(idx !== -1) {
            return true
          }
        }
        return false
      },
      getError: function (teacher, tolerance) {
        if(this.errors.length > 0){
          let idx = this.errors.findIndex(el => el.teacher === teacher, el => el.tolerance === tolerance)
          if(idx !== -1)
            return (
              this.errors[idx].error[0]
            )
        }
        return []
      },
      hasErrorMsg: function (textMsg) {
        if (this.errors.length > 0) {
          let idx = this.errors.findIndex(el => el === textMsg)
          if (idx !== -1) {
            return true
          }
        }
        return false
      },
      getErrorMsg: function (textMsg) {
        if (this.errors.length > 0) {
          let idx = this.errors.findIndex(el => el === textMsg)
          if (idx !== -1)
            return (
              this.errors[idx]
            )
        }
        return []
      },
      sendEmail(){
        let url = cathedra.CATHEDRA.ADMISSION.SENDEMAIL(this.$route.params.cathedra_uid.toLowerCase())
        this.sendMesg.loading=true
        let data= {
          text:this.sendMesg.text
        }
        if(this.sendMesg.dev){
          data.user_email = this.sendMesg.user_email
        }
        let tolerances = [];
        for (let obj in this.selected){
          tolerances.push({id : this.selected[obj].id})
        }
        data.tolerances = tolerances
        sendPostRequest(url,data,
          ()=>{
            this.sendMesg.loading=false
            this.sendMesg.alert=true
        },
          (error)=>{
            this.sendMesg.loading=false
            this.errors = error.text;
        }
        )
      },
      displayDate(date, minutes = false) {
        let format = "DD.MM.YYYY";
        if (minutes)
          format += " HH:mm";
        return moment(date).format(format)
      },
      getNumberSemestr(semester,course){
        let num =0
        if(semester === "осенний"){
          num = course * 2 - 1
        }else {
          num = course * 2
        }
        return num + " (" +semester+ ")"
      },
      getAbbreviation(academic_difference, is_exam){
        if (academic_difference)
          return "А/Р"
        else if (is_exam)
          return "Э"
        else
          return "Д"
      },
      getAdmissionType(academic_difference, is_exam){
        if (academic_difference)
          return "Академическая разница"
        else if (is_exam)
          return "Экзамен/Зачет с оценкой"
        else
          return "Добор/Зачет"
      },
      loadSelectorSubject(){
        loadData(
          selectors.URLS.SEMESTERS(),
          (data) => {
            this.selectors.subject=data
            this.selectors.subject.unshift({text:"Все предметы",value:null})
            this.loadSelectorSession()
          }
        )
      },
      loadSelectorSession(){
        loadData(
          selectors.URLS.ACADEMIC_YEAR_ALL_SESSION(),
          (data) => {
            this.selectors.session=data
            this.selectors.session.unshift({text:"Все сессии",value:null})
            this.loadData()
          }
        )
      },
      loadTeachers(){
        loadData(
          selectors.SELECTORS.WORKERS.CATHEDRA_PPS(this.$route.params.cathedra_uid),
          (data) => {
            this.selectors.teacher = data
            this.selectors.teacher.forEach(el => {
              this.$set(el, "fio", "");
              this.$set(el, "user", el.text.split(",")[0].trim());
              el.text = el.text.split(",")[1].trim();
            });
            generateListUidToName(this.selectors.teacher, "user", "fio", (data) => {
              data.forEach(el => {
                el.text = `${el.fio}, ${el.text}`
                el.value = `${el.user}`
              });
            });
          }
        )
      },
      UndoChanges(){
        this.show_teachers = false
        this.loadData();
      },
      SaveChanges(){
        let url = cathedra.CATHEDRA.ADMISSION.APPOINT_TEACHER(this.$route.params.cathedra_uid.toLowerCase())
        let dat = []
        for (let obj in this.items){
          if((this.items[obj].teacher) && (this.items[obj].success === null)){
            dat.push({tolerance: this.items[obj].id, teacher: this.items[obj].teacher})
          }
        }
        if(dat.length!=0){
          let value = []
          value.push({data: dat})
          sendPostRequest(url,value[0],
            ()=>{
              this.show_teachers = false
              this.loadData()
            },
            (error)=>{
              this.errors = error.errors;
              // this.loadData()
              // alert('Выбранный преподаватель не принадлежит кафедре')
            }
          )
        }
      },
      loadData(url = undefined) {
        this.selected=[]
        this.getItems(url)
      },
      getItems (url=undefined) {
        this.setLoading();
        if(url === undefined) {
          url = this.addFiltersParameter(this.URL_LIST)
          this.setCurrentLoadUrl(url)
          this.cleanPaginationData()
        }
        loadData(
          url,
          (data) => {
            this.mapResults(data, "items")
            this.items.forEach(el => {
              this.$set(el, "fio_student", "");
              this.$set(el, "fio_teacher", "");
            });
            generateListUidToName(this.items, "student", "fio_student");
            generateListUidToName(this.items, "teacher", "fio_teacher")
            this.setNotLoading();
          }
        )
      },
      getDetail(item){
        this.$router.push({name: names.CathedraAdmissionDetail, params: {idAdmission: item.id}})
      },

      addItem(){
        this.closedForm()
        this.getItems();
      }

    }
  }

</script>

<style scoped>

</style>
