<template>
  <AdmissionTemplate :admission="admission" :issued_by="false" :btnPrint="true"  ref="admission">
    <template v-slot:append_body>
      <ApplyDialog
        v-if="printDialog"
        :opened="printDialog"
        :get-text="() => `Напечатать допуск?`"
        :apply-text="`Да`"
        :close-text="`Нет`"
        @close="printAdmission"
      >
      </ApplyDialog>
      <v-card-actions class=" d-flex justify-end"  v-if="!admission.closed || admission.reopen">
        <v-btn v-if="admission.reopen"
               @click="reopenAdmission"
               text class="primary">
          Переоткрыть допуск
        </v-btn>
        <v-btn v-if="admission.success == null" text class="primary" @click="opened_form = true">Закрытие допуска
          обучающегося
        </v-btn>
      </v-card-actions>
      <scroll-center-dialog @input="closedForm"
                            v-model="opened_form"
                            width="500"
                            @close="opened_form = false"
                            title="Закрытие допуска"
                            :dennyable="false"
                            :applyable="false"
      >
        <v-alert class="pa-2" type="info" outlined>
          Обратите внимание!
          В строке "Балл" необходимо указать набранные баллы за допуск.
        </v-alert>
        <v-card-text class="px-0 pa-2 text-left subtitle-1 font-weight-bold" >
          Баллов в семестре: {{admission.in_semester}}
        </v-card-text>
        <v-text-field v-model="in_admission"
                      type="number"
                      label="Балл"
                      :error="hasError('total')"
                      :error-messages="getError('total')"
        ></v-text-field>
        <v-card-text class="px-0 pa-2 text-left subtitle-1 font-weight-bold" >
          Итоговый балл: {{all}}
        </v-card-text>
        <v-text-field v-model="value.officially_closed"
                      type="Date"
                      label="Дата сдачи"
                      :error="hasError('officially_closed')"
                      :error-messages="getError('officially_closed')">
        </v-text-field>
        <v-text-field v-model="value.comment"
                      label="Комментарий"
                      :error="hasError('comment')"
                      :error-messages="getError('comment')">
        </v-text-field>
        <v-alert v-if="hasError('error')">{{ getError('error') }}</v-alert>
        <v-alert class="pa-2" type="warning" outlined>
          <b><span>{{admission.type}} </span> будет закрыт</b>
        </v-alert>
        <v-alert class="pa-2 " type="info" outlined>
          В случае ошибки Вы можете переоткрыть и исправить допуск в течение суток с момента закрытия
        </v-alert>
        <template v-slot:append-actions>
          <v-card-text class="px-0">
            <v-row>
              <v-btn @click="closeAdmission()" :loading="loading" block class="primary">
                Закрыть
              </v-btn>
            </v-row>
          </v-card-text>
        </template>
      </scroll-center-dialog>
    </template>
  </AdmissionTemplate>
</template>

<script>
import ApplyDialog from "@/modules/core/components/ApplyDialog";
import AdmissionTemplate from "@/modules/templates/admission/AdmissionTemplate.vue"
import {clearUserAuth, makeDeleteRequest, makeGetRequest, makePostRequest, sendGetRequest} from "@/helper";
import urls from "@/modules/teacher/urls";
import {generateUidToName, uidToPositionHelds} from "@/helper/uidToName";
import UserDataMixin from "@/mixins/UserDataMixin";
import UserDataFormMixin from "@/mixins/UserDataFormMixin";
import names from "@/modules/cathedra/routers/names";
import cathedra from "@/urls/cathedras";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

export default {
  name: "DetailAdmission",
  components: {
    ScrollCenterDialog,
    AdmissionTemplate,
    ApplyDialog
  },
  mixins: [UserDataMixin,UserDataFormMixin],
  data: function () {
    return {
        admission: undefined,
        in_admission: 0,
        loading:false,
        printDialog:false,
        value:{
          comment: "",
          total: 0,
          officially_closed: new Date().toISOString().substring(0,10)
        }
      }
  },
  computed:{
    all(){
      let total = 0
      try {
        total = Number(this.in_admission) + Number(this.admission.in_semester)
      } catch {
        total = 0
      }
      return total
    }
  },
  methods: {
    reopenAdmission(){
      let url = cathedra.CATHEDRA.ADMISSION.REOPEN(this.$route.params.cathedra_uid.toLowerCase(),this.admission.id)
      sendGetRequest(url,
        ()=>{
          this.loadData()
        },
        (error)=>{
          alert(error.error)
        })
    },
    printAdmission(apply) {
      if(apply){
        this.loadData(()=>{
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs.admission.PrintAdmission()
              this.printDialog=false
              this.$router.push({name: names.CathedraAdmission})
            }, "1000");
          });
        })
      }else {
        this.$router.push({name: names.CathedraAdmission})
      }

    },
    loadData(finalizer=()=>{} ){
      let url = cathedra.CATHEDRA.ADMISSION.DETAIL(this.$route.params.cathedra_uid.toLowerCase(), this.$route.params.idAdmission)
      makeGetRequest(url).then(resp => {
        if (!resp.ok) {
          this.$router.push({name: names.CathedraAdmission})
        }
        return resp.json()
      }).then(json => {
        this.admission = json
        this.loading=false
        this.$set(this.admission, "fio_teacher", "")
        generateUidToName(this.admission, "teacher", "fio_teacher")

        this.$set(this.admission, "fio_student", "")
        generateUidToName(this.admission, "student", "fio_student")
      }).then(json =>{
        finalizer(json)
      })
    },

    closeAdmission() {
      this.loading=true
      let url = cathedra.CATHEDRA.ADMISSION.CLOSE(this.$route.params.cathedra_uid.toLowerCase(), this.$route.params.idAdmission)
      let status = true
      this.value.total =this.all
        makePostRequest(url,this.value)
        .then(resp => {
            this.loading=false
            if (!resp.ok) {
              status = false
            }
          return resp.json();
        })
        .then(json => {
          if (status) {
            this.printDialog=true
          } else {
            this.receiveErrors(json);
          }
        });
    }

  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
