<template>

    <v-container v-if="journal.loaded">
      <v-card>
<!--        <DebugJsonComponent :json="student"></DebugJsonComponent>-->
        <v-toolbar :extended='$vuetify.breakpoint.xsOnly'
                   class="mb-2"
                   color="style-color-main-gray"
                   flat
                   :style="{'height':`${$vuetify.breakpoint.smAndUp ? 64: 150}px`} "
        >
          <v-toolbar-title>
            Полный журнал
          </v-toolbar-title>

          <template v-if="$vuetify.breakpoint.smAndUp">
            <v-select :items="journal.data" item-text="name" return-object v-model="journal.selected" class="ml-3"
                      hide-details dense style="min-width: 280px;" filled>
            </v-select>
            <v-spacer></v-spacer>
          </template>

          <template v-slot:extension v-if='$vuetify.breakpoint.xsOnly'>
            <v-col>
              <v-select :items="journal.data" item-text="name" return-object v-model="journal.selected" class="pt-6 pb-2"
                        hide-details dense style="min-width: 280px;" filled>
              </v-select>
              <v-text-field class="pt-2" v-model="search" clearable placeholder="Поиск" hide-details
                            style="min-width: 100px;" prepend-inner-icon="search" dense hint="" filled></v-text-field>
            </v-col>
          </template>
        </v-toolbar>
        <v-row>
          <v-col>
            <v-alert outlined type="info">
              Уважаемые пользователи, обращаем ваше внимание, что вы можете перемещаться между полями, используя стрелочки на клавиатуре, а навигация через кнопку "Tab" работает по столбцам, а не строчкам.
            </v-alert>
            <v-data-table
              :items="student"
              :headers="filteredHederJournal"
              dense
              :items-per-page="student.length"
              hide-default-footer
              no-data-text="Нет данных"
            >
              <template v-for="(head, i) of filteredHederJournal"  v-slot:[`item.${head.value}`]="{ item, index }">
                <span v-if="head.value == 'fio'">
                     {{ item.fio}}
                </span>
                <span v-else>
                  <v-text-field
                    type="number"
                    step="1"
                    min="0"
                    max="100"
                    hide-details
                    v-if="item.can_change"
                    dense
                    :error="haveError(item,head.value)"
                    class="body-2 my-auto"
                    v-model="item[head.value]"
                    :ref="getTableRefName(index, i - 1)"
                    :tabindex="getTableTabIndex(index, i - 1)"
                    @keydown.down.prevent="downTable(index, i - 1, student.length)"
                    @keydown.up.prevent="upTable(index, i - 1, student.length)"
                    @keydown.right.prevent="rightTable(index, i - 1, filteredHederJournal.length - 1)"
                    @keydown.left.prevent="leftTable(index, i - 1, filteredHederJournal.length - 1)"
                    @focus="$event.target.select()"
                  >
                     <template v-slot:append v-if="haveError(item,head.value)">
                        <v-tooltip bottom>
                          <template v-slot:activator="{on}">
                            <v-icon class="mt-2" v-on="on" small color="error">warning</v-icon>
                          </template>
                          <span >
                        {{getError(item,head.value)}}
                          </span>
                        </v-tooltip>
                      </template>
                  </v-text-field>
                  <span v-else>{{item[head.value]}}</span>
                </span>
              </template>
            </v-data-table>
            <v-col cols="auto mr-5" v-if="can_save">
              <v-btn color="success" @click="saveJournal">Сохранить</v-btn>
            </v-col>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

  <v-container v-else>
    <v-row>
      <v-col>
        <v-skeleton-loader
          height="50"
          type="list-item"
        >
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader
          height="200"
          type="card"
        >
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {loadData, sendPostRequest} from "@/helper";
import JournalTableTemplate from "@/modules/templates/journal/JournalTableTemplate";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import dean from "@/urls/dean"
import {generateListUidToName} from "@/helper/uidToName";
import helper from "@/urls/helper";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import JournalTableArrowNavigateMixin from "@/mixins/JournalTableArrowNavigateMixin";

  export default {
    name: "FinalJournal",
    components: {
      DebugJsonComponent,
      JournalTableTemplate
    },
    mixins: [TitledPageMixin, JournalTableArrowNavigateMixin],
    watch: {
      "journal.selected": function (value) {
        this.journal.loaded = false;
        if (value.subjects.basename !== undefined)
          this.loadSemester(value);
      }
    },
    computed: {
      rows_length() {
        return this.journal.selected.length
      },
      filteredHederJournal() {
        let header = []
        if(this.journal.subjects)
          if (this.journal.subjects.length > 0) {
          header.push({
            text: "ФИО",
            value: "fio",
            width: "15%",
            control_type: "",
            semester_subject: "",
            sortable: false,
            filterable: false
          })
          this.journal.subjects.forEach(sub => {
            header.push({
              text: sub.subject,
              control_type: sub.control_type,
              semester_subject: String(sub.semester),
              sortable: false,
              width: "10%",
              value: String(sub.semester),
              filterable: false
            })
          })
        }
        return header
      }
    },
    data: function () {
      return {
        page_title: "Итоговый журнал успеваемости",
        group_id: this.$route.params.idGroup,
        idFaculty: this.$route.params.idFaculty,
        student: [],
        errors: [],
        can_save: false,
        journal: {
          data: [],
          subjects: [],
          students: [],
          selected: [],
          loading: false,
          loaded: false
        },
        search: "",
        nameGroup: ""
      }
    },
    methods: {
      // findInErrors(item,val){
      //   return this.errors.findIndex((fel) => {return fel.student === item.id})
      // },
      haveError(item,val){
        let ans = false
        if (this.errors.length!==0){
            ans = (this.errors.errors.findIndex((fel) => {
              return fel.student == item.id && fel.semester == val
            }) >= 0)
        }
          return ans
      },
      getError(item,val){
        if (this.errors.length!==0){
          let ans = this.errors.errors.findIndex((fel) => {
            return fel.student == item.id && fel.semester == val
          })
          if(ans >= 0){
            let msg =this.errors.errors[ans].message
            if(msg){
              if(msg.rating)
                return String(msg.rating)
              if(msg.student_education_id)
                return String(msg.student_education_id)
              if(msg.semester_id)
                return String(msg.semester_id)
            }
          }
        }

      },

      loadSemester(selected, finalizer = () => {
      }) {
        loadData(
          dean.getByRecievedUrlData(selected.subjects, selected.subjects.get_params),
          res => {
            this.journal.subjects = res;
             this.loadStudentFios(selected)
          }
        );
      },
      loadStudentFios(selected) {
        loadData(
          dean.getByRecievedUrlData(selected.students, selected.students.get_params),
          res => {
            this.journal.students = res;
            let st = []
            this.journal.students.forEach(student => {
              let userData = {
                "fio": student.fio,
                "id": student.id,
                "can_change" : student.can_change
              }
              student.semesters.forEach(subj => {
              this.$set(subj, "old_rating", subj.rating);
              userData[subj.semester] = subj.rating
              })
              st.push(
                userData
              )
            })
            this.student = st
            this.student.sort((a,b) => a.fio.toUpperCase() > b.fio.toUpperCase() ? 1: -1)
            let index_can_change = this.student.findIndex(el => el.can_change === true)
            if(index_can_change!== -1)
              this.can_save = true
            this.journal.loaded = true;
          }
        );
      },
      loadJournal() {
        loadData(
          dean.DEAN.JOURNAL.LOADSEM(this.idFaculty, this.group_id),
          (data) => {
            for (let course of data) {
              for (let semester of course.semesters) {
                this.journal.data.push({
                  name: `${course.number} курс ${semester.name} семестр`,
                  subjects: semester.subjects,
                  students: semester.students
                })
              }
            }
            if (this.journal.data.length > 0)
              this.journal.selected = this.journal.data[0]
          }
        )
      },
      saveJournal(){
        let sendRating = [];
        this.errors = [];
        this.student.forEach(data => {
          //Нашли изначального обучающигося в массиве
          let findSt =  this.journal.students.find(currentValue => currentValue.id == data.id)
          Object.entries(data).forEach(([k,v]) => {
            //Игнорируем неиспользуемые ключи
            if (k !== "fio" && k !== "id" && k !== "can_change"){
              let Student= {}
              //собираем объект
              Student.student_education_id = data.id
              Student.rating = Number(v)
              Student.semester_id = Number(k)
              //Ищем изначальное значение
              let semester =  findSt.semesters.find(currentValue => Number(currentValue.semester) == Number(k))
              //Проверяем сменнился ли балл
              if (Number(semester.old_rating) !== Number(v)){
                sendRating.push(Student)
              }
            }
          })
        })
        if(sendRating.length > 0)
          sendPostRequest(
            dean.DEAN.JOURNAL.SAVESEM(this.idFaculty, this.group_id, this.journal.selected.subjects.get_params),
            {"students":sendRating} ,
            (res) => {
              this.journal.loaded = false;
              this.loadSemester(this.journal.selected);
            },
            (error) => {
              this.errors = error
            }
          );

      }
    },
    created() {

      this.loadJournal();
      let url = dean.STRING.PRESENTATION()
      sendPostRequest(
        url,
        [{
          "type": "Group",
          "id": Number(this.group_id)
        }],
        (final) => {
          this.nameGroup = final[0].str
          this.page_title= "Журнал группы "+this.nameGroup
        }
      );
    }
  }
</script>

<style scoped>

</style>

