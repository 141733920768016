<template>
  <v-container style="height: 100%">
    <v-card >
      <v-toolbar
        elevation="0"
        color="style-color-main-gray"
      >
          <v-spacer></v-spacer>
            <v-btn @click="opened_form = true" color="secondary" text>
              <v-icon>add</v-icon>
              Добавить
            </v-btn>
      </v-toolbar>

      <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              class="elevation-1"
              no-data-text="Нет данных"
              :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
            >
              <template v-slot:item.fio="{ item }">
                {{item.fio}}
              </template>
              <template v-slot:item.position_held="{ item }">
                {{item.position_held}}
              </template>
              <template v-slot:item.option="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      @click="openConfirmDelete(item)"
                      medium
                    >
                      <v-icon>
                        delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Удалить</span>
                </v-tooltip>
              </template>

              <template slot="no-data">
                <v-card-text>Данные не загружены</v-card-text>
              </template>
            </v-data-table>
    </v-card>

    <PermissionWorker @addItem="addItem"
                      @ended="closedForm"
                      @refreshItem="refreshItem"
                      v-bind="selected"
                      v-if="opened_form"
                      :opened_form="opened_form"
    >
    </PermissionWorker>

    <v-dialog persistent v-model="opened_confirm" width="500">
      <v-card v-if="deletable">
        <v-card-title>Подтвердите удаление</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          Вы уверены, что хотите удалить?<br>
        </v-card-text>
        <v-card-actions>
          <v-flex>
            <v-btn
              @click="deleteItem(deletable.id)"
              color="warning"
              text
            >
              Удалить
            </v-btn>
            <v-btn
              @click="closeConfirmDelete"
              text
            >Отмена
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--</v-flex>-->
    <!--</v-layout>-->
  </v-container>
</template>


<script>
  import PermissionWorker  from "@/modules/dean/pages/forms/PermissionWorker.vue"
  import UserDataMixin from "@/mixins/UserDataMixin"
  import {
    makeDeleteRequest,
    makeGetRequest,
    makeProfileUrl
  } from "@/helper";
  import urls from "@/urls/dean"
  import {generateListUidToName} from "@/helper/uidToName";
  import names from "@/modules/dean/routers/names";

  export default {
    name: "ListLedGroups",
     components: {PermissionWorker},
    mixins: [UserDataMixin],
    props: {
    },
    data () {
      return {
        URL_LIST: urls.DEAN.PERMISSION.LIST(this.$route.params.idFaculty),
        total: 0,
        names:names,
        items: [],
        loading: true,
        pagination: {},
        headers: [
          {text: "Работник", sortable: false, value: "fio" },
          {text: "Должность", sortable: false, value: "position_held"},
          {text: "Действия", sortable: false, value: "option", align: "center"}
        ]

      }
    },
    watch: {
      pagination: {
        handler () {
          this.getItems()
        },
        deep: true
      }
    },
    created() {
      this.getItems();
      this.$store.commit("mainpage/changeTitle", "Список прав");
    },
    methods: {
      deleteItem: function (id) {
        let url = urls.DEAN.PERMISSION.DELETE(this.$route.params.idFaculty, id)
        makeDeleteRequest(url).then(resp => {
          if (resp.ok) {
            this.closeConfirmDelete()
            this.getItems();
          }
        })
      },

      addItem(){
        this.closedForm()
        this.getItems();
      },

      getItems () {
        this.loading = true
        let url = new URL(this.URL_LIST)
        let params = {};

        url.search = new URLSearchParams(params).toString();

        let sys = true
        makeGetRequest(url).then(resp => {
          if (!resp.ok) {
            sys = false
          }
          return resp.json()
        }) .then(json => {
          this.items = json
          this.loading = false
          for (let i in this.items) {
            this.$set(this.items[i], "fio","-")
          }
          generateListUidToName(this.items,"user","fio")
        })
      }
    }
  }


</script>
<style scoped>

</style>
