import {loadData, sendPostRequest} from "@/helper";
import admin_urls from "@/urls/admin";
import urls from "@/modules/curriculumEditor/urls";

export default {
  props: {
    item: {
      required: true
    },
    parent: {
      required: true
    },
    loading: undefined
  },
  data() {
    return {
      modals: {
        detail: {
          open: false
        }
      }
    }
  },
  computed: {

    isParentLinked: function () {
      return this.parent.one !== null && this.parent.eios !== null && this.parent.one !== undefined && this.parent.eios !== undefined
    },
    isLinked: function () {
      return this.item.one !== null && this.item.one !== undefined && this.item.eios !== null && this.item.eios !== undefined
    },
    isOnlyEIOS: function () {
      return (this.item.one === null || this.item.one === undefined) && this.item.eios !== null && this.item.eios !== undefined
    },
    isOnlyOne: function () {
      return (this.item.eios === null || this.item.eios === undefined) && this.item.one !== null && this.item.one !== undefined
    },
    isAdmin: function () {
      return admin_urls.ADMIN.COURSE.IS_LIST_ALLOWED()
    }
  },
  watch: {

  },
  methods: {
    isParentHasOne() {
      return this.parent.one !== null
    },
    isParentHasEIOS() {
      return this.parent.eios !== null
    },
    getParentOneGUID(){
      if(this.isParentHasOne())
        return this.parent.one.guid
      return undefined
    },
    getParentEIOSId(){
      if(this.isParentHasEIOS())
        return this.parent.eios.id
      return undefined
    },
    getEIOSId(){
      return this.item.eios !== null ? this.item.eios.id: null
    },
    callIfParentOne(caller, ...args) {
      return this.isParentHasOne() ? caller(...args): null
    },
    openDialog(dialog) {
      dialog.open = true;
    },
    setLoadingValue(value){
      this.$emit("update:loading", value);
    },
    setItemValue(value){
      this.$emit("update:item", value)
    },
    displayError(error){
      console.log(error);
    },
    refresh() {
      this.setLoadingValue(true)
      let saver = (data) => {
        this.setItemValue(data)
        this.setLoadingValue(false)
      };
      let catcher = (error) => {
        this.setLoadingValue(false)
        // this.$emit('update:loading', false);
        this.displayError(error)
      };
      // let url = urls.NAMES.EDITOR.SEMESTER.DIFFERENCE(this.item.eios.id, this.$route.params.curriculum_id);
      loadData(this.getDifferenceUrl(), saver, catcher);
    },
    refreshByParent() {
      this.$emit("refresh")
    },
    getDifferenceUrl(){
      throw SyntaxError()
    }
  }
}
