import Breadcrumbs from "../pages/Breadcrumbs";
import Detail from "../pages/curriculum/Detail";
import PermissionWorkerList from "../pages/permission/PermissionWorkerList"
import names from "@/modules/curriculumEditor/routers/names";
import semesters from "./semesters";
import subjects from "./subjects";
import SummaryList from "../pages/summary/SummaryList";
import Difference from "../pages/difference/Difference";
import  {ACCOUNTS_INSTANCE,ORGANIZATION_INSTANCE} from "@/helper/consts";
const education = String(process.env.VUE_APP_EDUCATION_PREFIX).replace("/", "");


export default ([
  {
    path: "/editor/curriculum",
    name: names.CURRICULUM.LIST,
    // name: 'CurriculumEditorCurriculumList',
    component: Breadcrumbs,
    meta: {
      requiredAuth: true,
      breadcrumb: "Список учебных планов",
      instances: [education]
    },
    children: [
      {
        path: "permission",
        name: names.PERMISSION,//'CurriculumEditorPermission',
        // name: 'CurriculumEditorPermission',
        component: PermissionWorkerList,
        meta: {
          requiredAuth: true,
          breadcrumb: "Права доступа",
          instances: [education,ACCOUNTS_INSTANCE,ORGANIZATION_INSTANCE]
        }
      },
      {
        path: ":curriculum_id([0-9]+)",
        name: names.CURRICULUM.DETAIL,//'CurriculumEditorCurriculumDetail',
        // name: 'CurriculumEditorCurriculumDetail',
        component: Detail,
        meta: {
          requiredAuth: true,
          breadcrumb: "Учебный план",
          instances: [education]
        },
        children: [
          {
            path: "summary",
            name: names.CURRICULUM.SUMMARY,
            component: SummaryList,
            meta: {
              requiredAuth: true,
              breadcrumb: "Сводная таблица учебного плана",
              instances: [education]
            }

          },
          {
            path: "difference",
            name: names.CURRICULUM.DIFFERENCE,
            component: Difference,
            meta: {
              requiredAuth: true,
              breadcrumb: "Отличия от 1С",
              instances: [education]
            }

          },
          ...semesters,
          ...subjects
        ]
      }
    ]
  }
])


