<template>
  <scroll-center-dialog v-model="opened_form"
                        width="600"
                        title="Изменить номера зачетных книжек"
                        @close="closeDialog"
                        @apply="saveBookNumber"

  >
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">
            Обучающийся
          </th>
          <th class="text-left">
            Номер зачетной книжки
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="item in students"
          :key="item.id"
        >
          <td class="text-left">{{ item.fio }}</td>
          <td class="text-left">
            <v-text-field v-model="item.record_book_number"></v-text-field>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "../../../../mixins/FormErrorsMixin";
import dean from "@/urls/dean";
import {sendPatchRequest, sendPostRequest} from "@/helper";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";


export default {
  name: "GroupChangeHeadman",
  components: {ScrollCenterDialog},
  mixins: [
    FormErrorsMixin
  ],
  props: ["id", "all_students", "opened_form"],
  data() {
    return {
      loading: false,
      students:[]
    }
  },
  computed: {
    getActiveStudent: function () {
      return this.all_students.filter(e => e.active === true);
    }
  },
  methods: {
    saveBookNumber() {
      this.loading = true
      let students=[]
      for (let prop in this.students) {
        students.push(
          {
          "education_id": this.students[prop].id,
          "record_book_number": this.students[prop].record_book_number
          }
        )
      }
      sendPostRequest(
        dean.DEAN.GROUP.RECORD_BOOK(this.$route.params.idFaculty, this.id),
        {"students":students},()=>{
          this.loading = false
          this.$emit("refreshItem")
        },()=>{
          this.loading = false
        }
        )
    },
    closeDialog() {
      this.$emit("ended")
    }
  },
  created() {
    this.students = this.all_students.filter(e => e.active === true);
  }
}


</script>

<style scoped>

</style>
