<template>
      <v-container>
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="!work.loaded"
        ></v-progress-circular>
        <EducationWorks v-else :EducationWorks="work.data"></EducationWorks>
      </v-container>
</template>

<script>
  import urls from "@/urls/management";
  import {loadData, checkFieldTrue, displayDate} from "@/helper";
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
  import {generateUidToName} from "@/helper/uidToName";
  import EducationWorks from "@/modules/templates/work/EducationWorks"
  export default {
    name: "WorkDetail",
    components: {
      DebugJsonComponent,
      EducationWorks
    },
    computed: {
      loadingPage: function(){
        return checkFieldTrue(this.work, "loading");
      }
    },
    data: function () {
      return {
        title: "Список принятых учебных работ обучающегося",
        loadErrorMsg: "",
        actions: {
          nextCourseApplier: false,
          prevCourseApplier: false
        },
        work: {
          id: this.$route.params.work_id,
          student_id: this.$route.params.student_id,
          data: {},
          loading: false,
          loaded: false
        }
      }
    },
    methods: {
      loadStudent: function () {
        this.work.loading = true;
        let saver = (data) => {
          this.work.data = data;
          this.work.loading = false;
          this.work.loaded = true;
          this.$set(this.work.data, "fio_student", "-")
          this.$set(this.work.data, "loader", this.work.data.document.file.loader)
          this.$set(this.work.data, "fio", "-")
          this.$set(this.work.data, "loader_teacher", this.work.data.teacher)
          generateUidToName(this.work.data, "loader", "fio_student")
          generateUidToName(this.work.data, "loader_teacher", "fio")
        };
        let catcher = (error) => {
          // console.log(error);
        };
        let url = urls.EDUCATION.STUDENT.WORK.RETRIEVE(this.work.student_id, this.work.id);
        loadData(url, saver, catcher);
      },
      makeMediaUrl(url){
        return urls.EDUCATION.makeMediaHostUrl(url);
      },
      displayDate(date, minutes=false){
        return displayDate(date, minutes);
      }
    },
    created() {
      this.$store.commit("mainpage/changeTitle", this.title);
      this.loadStudent();
    }
  }
</script>

<style scoped>

</style>
