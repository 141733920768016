<template>
  <v-container style="height: 100%">
    <v-card flat elevation="3">
      <v-toolbar :extended='$vuetify.breakpoint.xsOnly'
                  class="title"
                  color="style-color-main-gray"
                  flat
                 :style="{'height':`${$vuetify.breakpoint.smAndUp ? 64: 150}px`} "
      >
        <v-toolbar-title>
          Список дисциплин:
        </v-toolbar-title>
        <template v-if="$vuetify.breakpoint.smAndUp" >
          <v-spacer></v-spacer>
          <v-toolbar-title>
            <v-text-field
              dense
              placeholder="Поиск"
              v-model="search.value"
              @change="searchData"
              messages="Для поиска нажмите Enter или кнопку поиск"
            >
            </v-text-field>
          </v-toolbar-title>
          <v-btn icon><v-icon>search</v-icon></v-btn>
          <v-toolbar-items>
            <v-btn @click="opened_form = true" color="secondary" text>
              <v-icon>add</v-icon>
              Добавить
            </v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  v-on="on"
                  @click="getItems()"
                  class="mr-2"
                  medium
                >
                  <v-icon>
                    update
                  </v-icon>
                </v-btn>
              </template>
              <span>Обновить данные</span>
            </v-tooltip>
          </v-toolbar-items>
        </template>
        <template v-slot:extension v-if='$vuetify.breakpoint.xsOnly'>
          <v-col>
            <v-row class="pt-6 pb-4">
              <v-toolbar-title >
                <v-text-field
                  dense
                  placeholder="Поиск"
                  v-model="search.value"
                  @change="searchData"
                  messages="Для поиска нажмите Enter"
                >
                </v-text-field>
              </v-toolbar-title>
              <v-btn icon><v-icon>search</v-icon></v-btn>
            </v-row>
            <v-toolbar-items>
              <v-btn @click="opened_form = true" color="secondary" text>
                <v-icon>add</v-icon>
                Добавить
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    v-on="on"
                    @click="getItems()"
                    medium
                  >
                    <v-icon>
                      update
                    </v-icon>
                  </v-btn>
                </template>
                <span>Обновить данные</span>
              </v-tooltip>
            </v-toolbar-items>
          </v-col>
        </template>
      </v-toolbar>
      <v-card-text class="pb-3 px-0">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="pagination.loading"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.size"
          :server-items-length="pagination.count"
          no-data-text="Нет данных"
          class="elevation-1"
          :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
        >
          <template v-slot:item.actions="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  @click="updateItem(props.item)"
                  medium>
                  <v-icon>
                    edit
                  </v-icon>
                </v-btn>
              </template>
              <span>Редактировать</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  @click="openConfirmDelete(props.item)"
                  medium>
                  <v-icon>
                    delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Удалить</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            <v-card-text>Данные не загружены</v-card-text>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <Subjects @addItem="addItem"
              @ended="closedForm"
              @refreshItem="refreshItem"
              @input="closedForm"
              :opened_form="opened_form"
              v-bind="selected"
              v-if="opened_form"
    >
    </Subjects>
    <v-dialog persistent v-model="opened_confirm" width="500">
      <v-card v-if="deletable">
        <v-card-title>Подтвердите удаление</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          Вы уверены, что хотите удалить?<br>
        </v-card-text>
        <v-card-actions>
          <v-flex>
            <v-btn @click="deleteItem(deletable.id)" color="warning" text>
              Удалить
            </v-btn>
            <v-btn @click="closeConfirmDelete" text>
              Отмена
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
  import Subjects  from "../../forms/Subjects"
  import UserDataMixin from "../../../../mixins/UserDataMixin"
  import {loadData, makeDeleteRequest} from "../../../../helper";
  import urls from "@/urls/management"
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin"

  export default {
    name: "ListSubjects",
    components: {Subjects},
    mixins: [UserDataMixin, PaginatedDataMapperMixin],
    props: {
      isDetail: Boolean
    },
    data () {
      return {
        URL_LIST: urls.EDUCATION.REFERENCE.SUBJECTS.LIST(),
        items: [],
        search: {
          parameter: "name"
        },
        pagination: {},
        headers: [
          {text: "Название", value: "name", sortable: false, align: "start", width: "60%"},
          {text: "Уровень", value: "level", sortable: false, align: "start", width: "20%"},
          {text: "Действия", value: "actions", sortable: false, align: "right" }
        ]
      }
    },
    watch: {},
    created() {
      this.getItems()
      this.$store.commit("mainpage/changeTitle", "Список дисциплин");
    },
    methods: {
      addItem(){
        this.closedForm()
        this.getItems()
      },
      deleteItem(id) {
        let url = urls.EDUCATION.REFERENCE.SUBJECTS.DELETE(id)
        makeDeleteRequest(url).then(resp => {
          if (resp.ok) {
            this.closeConfirmDelete()
            this.getItems()
          }
        })
      },
      loadData(url = undefined) {
        this.getItems(url)
      },
      getItems (url=undefined) {
        this.setLoading();
        if(url === undefined) {
          url = this.URL_LIST,
            this.setCurrentLoadUrl(url)
        }
        loadData(
          url,
          (data) => {
            this.mapResults(data,"items")
            this.setNotLoading()
          }
        )
      }
    }
  }


</script>
<style scoped>

</style>
