<template>
  <v-container>
    <v-card flat>
      <v-toolbar
        elevation="0"
        class="white--text"
        color="style-color-main-gray"
      >
        <v-text-field
          dense
          hide-details
          label="Поиск"
          v-model="search"
        ></v-text-field>
        <v-btn icon><v-icon>search</v-icon></v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click=" loadData()"
              class="mr-2"
              medium
            >
              update
            </v-icon>
          </template>
          <span>Обновить данные</span>
        </v-tooltip>

      </v-toolbar>
      <v-card-text class="body-1">
        <v-data-table
          :headers="subjects.headers"
          :items="subjects.data"
          :loading="subjects.loading"
          :expanded.sync="subjects.expanded"
          show-expand
          :search="search"
          :items-per-page="subjects.data.length"
          sort-by="name"
          group-by="control_type"
          group-desc
          hide-default-footer
        >
          <template v-slot:item.cathedrals="props">
            <td class="text-left">
              <div v-for="(str,index) in props.item.cathedrals" class="align-start">
                {{str.name}}<span v-if="index!==props.item.cathedrals.length-1">;</span> <br>
              </div>
            </td>
          </template>
          <template v-slot:group.header="{group, headers, isOpen, toggle}">
            <td :colspan="headers.length" class="text-start">
              <v-row justify="space-between">
                <v-col class="ma-0 py-0 pl-10 d-flex" cols="auto">
                  <span class="my-auto subtitle-1">Форма контроля: {{ group }}</span>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="ma-0 py-0 pr-10" cols="auto">
                  <v-btn @click="toggle" icon>
                    <v-icon v-if="isOpen">keyboard_arrow_up</v-icon>
                    <v-icon v-else>keyboard_arrow_down</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </td>
          </template>
          <template v-slot:expanded-item="props">
            <v-expand-transition>
              <td :colspan="props.headers.length" class="mx-0 pa-1">
                <v-card dense tile elevation="1" class="py-0" v-if="props.item.lesson_kinds !== undefined">
                  <v-subheader>Список форм занятий</v-subheader>
                  <v-divider></v-divider>
                  <v-data-table
                    :headers="[
                      {text: 'Форма', value: 'kind'},
                      {text: 'Часов в класе', value: 'classroom_hours'},
                      {text: 'Часов самоподготовки', value: 'independent_hours'},
                      {text: 'ЗЕТ', value: 'zets'}
                    ]"
                    hide-default-header
                    hide-default-footer
                    :items="props.item.lesson_kinds"
                    no-data-text="Формы занятий не определены"
                  ></v-data-table>
                </v-card>
                <v-card dense tile elevation="1" class="py-0">
                  <v-subheader>Список преподавателей</v-subheader>
                  <v-divider></v-divider>
                  <v-data-table
                    :headers="[{text: '', value: 'fio'}, {text: '', value: 'type'}]"
                    hide-default-header
                    hide-default-footer
                    :items="props.item.teachers"
                    no-data-text="Нет ни одного назначенного преподавателя"
                  ></v-data-table>
                </v-card>
                <v-btn
                  icon
                  @click="subjects.expanded.splice(subjects.expanded.indexOf(props.item), 1)"
                  class="my-2"
                ><v-icon>keyboard_arrow_up</v-icon></v-btn>
              </td>
            </v-expand-transition>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import urls from "@/urls/management";
  import {loadData, checkFieldTrue} from "../../../../helper";
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
  import {generateListUidToName} from "@/helper/uidToName";

  export default {
    name: "SubjectList",
    components: {
      DebugJsonComponent
    },
    computed: {
      loadingPage: function(){
        return checkFieldTrue(this.group, "loading");
      }
    },
    data: function () {
      return {
        title: "Список текущих дисциплин группы",
        loadErrorMsg: "",
        search: "",
        subjects: {
          data: [],
          loaded: false,
          loading: false,
          count: null,
          next: null,
          previous: null,
          expanded: [],
          headers: [
            {text: "Предмет", value: "subject", sortable: false, width: "35%"},
            {text: "Кафедры", value: "cathedrals", sortable: false, width: "35%"},
            {text: "Аудиторных часов", value: "classroom_hours", sortable: false, width: "10%"},
            {text: "Часов самоподготовки", value: "independent_hours", sortable: false, width: "10%"},
            {text: "Зеты", value: "zets", sortable: false, width: "5%"},
            {text: "", value: "data-table-expand", width: "5%"}
          ]
        }
      }
    },
    methods: {
      loadData: function () {
        this.subjects.loading = true;
        loadData(
          urls.EDUCATION.GROUP.SUBJECTS(this.$route.params.group_id),
          (data) => {
            this.subjects.data = data;
            this.subjects.loaded = true;
            this.subjects.loading = false;

            let teachers = [];
            this.subjects.data.forEach(el => {
              el.teachers.forEach(el => {
                if(!teachers.find(fel => {return fel.uid === el.teacher}))
                  teachers.push({uid: el.teacher, fio: ""});
              });
            });
            generateListUidToName(teachers, "uid", "fio", (data) => {
              this.subjects.data.forEach(el => {
                el.teachers.forEach(el => {
                  let teacher = data.find(fel => {return fel.uid === el.teacher});
                  this.$set(el, "fio", teacher.fio);
                });
              });
            });
          }
        );
      }
    },
    created() {
      this.loadData();
      this.$store.commit("mainpage/changeTitle", this.title);
    }
  }
</script>

<style scoped>

</style>
