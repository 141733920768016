<template>
  <div v-if="$route.name === names.STUDENT.DETAIL">
  <v-container v-if="student.loaded" fluid>
    <AddStatusForm v-if="status_add_change"
                @refreshItem="refreshItem"
                @close="closeDialog()"
                :student_status = "statuses.data[0]">
    </AddStatusForm>
    <StatusHistoryForm v-if="status_history"
                       @close="closeDialog()"
                       @refreshItem="refreshItem">
    </StatusHistoryForm>
    <StudentTemplate v-if="student.loaded" :student="student.data" :is_dean="true">
      <template v-slot:education_group_title>
        <router-link  :to="{ name: names.GROUP.DETAIL,params: {idGroup: student.data.education_group.id
        ,idFaculty: student.data.curriculum.params.faculty_uid }}">
          {{student.data.education_group.litter}}-{{student.data.education_group.course}}{{student.data.education_group.number}}
        </router-link>
      </template>
      <template v-slot:title>
        <v-toolbar-title>
          {{student.data.user.lastname}} {{student.data.user.firstname}} {{student.data.user.midname}}

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                @click="opened_form = true"
                class="mr-2"
                medium
              >
                edit
              </v-icon>
            </template>
            <span>Редактировать</span>
          </v-tooltip>
        </v-toolbar-title>
      </template>
      <template v-slot:extra_actions>
        <v-btn @click="dialog.openFromGroup = true" text color="primary">Перевести в другую группу</v-btn>
        <v-btn @click="AddChangeStatus()" text color="primary">Изменить статус</v-btn>
<!--        <v-btn @click="dialog.openFormExpel = true" text color="deep-orange"><span v-if="student.data.active">Отчислить</span> <span v-else>Восстановить</span></v-btn>-->
      </template>
      <template v-slot:append_body>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="status_history = true"
              class="mr-2"
              medium
            >
              info
            </v-icon>
          </template>
          <span>История изменения статусов</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="dialog.openFormWithdraw = true"
              class="mr-2"
              medium
            >
              restore
            </v-icon>
          </template>
          <span>Отозвать последний статус</span>
        </v-tooltip>
      </template>
    </StudentTemplate>
    <FormProfileChange @refreshItem="refreshItem"
                       @ended="closedForm"
                       v-bind="selected"
                       v-bind:info="$route.params.id"
                       :opened_form="opened_form"
                       v-if="opened_form"
                       :student="student.data"
    >
    </FormProfileChange>
    <StudentContactsTemplate :phones="student.data.user.phones" :addresses="student.data.user.addresses"
                             :emails="student.data.user.emails"></StudentContactsTemplate>
    <v-dialog @input="closeDialog" v-model="dialog.openFormExpel" width="500" persistent>
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>
            <span  v-if="student.data.active">Отчисление</span><span v-else>Восстановление</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-toolbar-items>
              <v-btn @click="closeDialog" class="pa-0 ma-0" dark text icon>
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar-items>
        </v-toolbar>
        <v-alert type="error" v-if="dialog.error.length > 0">
          <span v-for="er in dialog.error"><div v-if="er.error!==undefined">{{ er.error }}</div></span>
        </v-alert>
        <v-card-text class="text-sm-left title">Вы уверены в действии?</v-card-text>
        <v-card-actions>
          <v-btn @click="expelStudent" v-if="student.data.active">
            <v-icon color="green">done</v-icon>
            Отчислить
          </v-btn>
          <v-btn @click="expelStudent(false)" v-else>
            <v-icon color="green">done</v-icon>
            Восстановить
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog">
            <v-icon color="red">clear</v-icon>
            Отменить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <scroll-center-dialog v-model="dialog.openFromGroup"
                          width="500"
                          @close="closeDialog"
                          :dennyable="false"
                          :applyable="false"
                          title="Перевод в другую группу"
    >
      <v-alert type="error" v-if="dialog.error.length > 0">
        <span v-for="er in dialog.error"><div v-if="er.error!==undefined">{{ er.error }}</div></span>
      </v-alert>
      <v-card-text class="text-sm-left title">Вы уверены, что необходимо перевести обучающегося?</v-card-text>
      <template v-slot:actions>
        <v-btn @click="startTransfer()">
          <v-icon color="green">done</v-icon>
          Начать перевод
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog">
          <v-icon color="red">clear</v-icon>
          Отменить
        </v-btn>
      </template>
    </scroll-center-dialog>
    <v-dialog @input="closeDialog" v-model="dialog.openFormWithdraw" width="500">
      <v-card>
        <v-card-title>Подтвердите отзыв статуса</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          Вы уверены, что хотите отозвать последний статус обучающегося?<br>
        </v-card-text>
        <v-alert type="error" v-if="hasError('detail') && check">
          {{ getError('detail')}}
        </v-alert>
        <v-card-actions>
          <v-flex>
            <v-btn
              @click="withdrawStatus()"
              color="warning"
              text
            >
              Отозвать
            </v-btn>
            <v-btn
              @click="closeDialog"
              text
            >Отмена
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
<!--      <DebugJsonComponent :json="student.data"></DebugJsonComponent>-->
    </v-card>

  </v-container>
  </div>
  <router-view v-else></router-view>
</template>

<script>
import urlsM from "@/urls/management";
import {checkFieldTrue, loadData, sendPostRequest} from "@/helper";
import {getInstStudentMenuDean} from "@/modules/dean/helper";
import StudentTemplate from "@/modules/templates/students/StudentTemplate";
import StudentContactsTemplate from "@/modules/templates/students/StudentContactsTemplate";
import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
import NotAvatar from "@/assets/NotAvatar.png";
import names from "@/modules/dean/routers/names";
import dean_names from "@/modules/dean/routers/names";
import dean from "@/urls/dean"
import StudentInfoRow from "../../../templates/students/StudentInfoRow";
import FormProfileChange from "@/modules/dean/pages/forms/ProfileChange"
import UserDataMixin from "@/mixins/UserDataMixin"
import FormErrorsMixin from "@/mixins/FormErrorsMixin"
import moment from "moment";
import AddStatusForm from "@/modules/dean/pages/student/status/AddStatus"
import StatusHistoryForm from "@/modules/dean/pages/student/status/StatusHistory"
import UserDataFormMixin from "@/mixins/UserDataFormMixin"
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";
export default {
  name: "StudentDetail",
  components: {
    ScrollCenterDialog,
    DebugJsonComponent,
    StudentTemplate,
    StudentContactsTemplate,
    StudentInfoRow,
    FormProfileChange,
    AddStatusForm,
    StatusHistoryForm
  },
  mixins:[
    UserDataMixin,
    FormErrorsMixin,
    UserDataFormMixin
  ],
  computed: {
    loadingPage2: function () {
      return checkFieldTrue(this.student, "loading");
    }
  },
  data: function () {
    return {
      names:names,
      status_add_change: false,
      status_history: false,
      NotAvatar: NotAvatar,
      opened_form: false,
      title: "Профиль обучающегося",
      URL_DETAIL: dean.DEAN.STUDENTS.DETAIL(this.$route.params.idFaculty, this.$route.params.idStudent),
      URL_EXPEL: dean.DEAN.STUDENTS.EXPEL(this.$route.params.idFaculty, this.$route.params.idStudent),
      URL_RECOVERY: dean.DEAN.STUDENTS.RECOVERY(this.$route.params.idFaculty, this.$route.params.idStudent),
      URL_UPDATE_INFO: dean.DEAN.STUDENTS.UPDATE_INFO(this.$route.params.idFaculty, this.$route.params.idStudent),
      URL_STATUSES_LIST: dean.DEAN.STUDENTS.STATUSES.LIST(this.$route.params.idFaculty, this.$route.params.idStudent),
      URL_STATUSES_WITHDRAW: dean.DEAN.STUDENTS.STATUSES.WITHDRAW_STATUS(this.$route.params.idFaculty, this.$route.params.idStudent),
      check: false,
      dialog:{
        openFormExpel: false,
        openFromGroup: false,
        openFormWithdraw: false,
        loadErrorMsg: "",
        error: [],
        education_group: null
      },
      student: {
        id: this.$route.params.idStudent,
        data: {},
        loading: false,
        loaded: false
      },
      statuses: {
        data: {}
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === names.STUDENT.DETAIL) {
      this.loadLeftMenu()
    }
    next();
  },
  methods: {
    refreshItem(){
      this.closedForm()
      this.student.loaded = false
      this.loadStudent()
      this.loadStatuses()
      this.closeDialog()
    },
    startTransfer(){
      this.$router.push({name: names.STUDENT.TRANSFER, params: {idDetail: this.$route.params.idStudent}})
    },
    closeDialog() {
      this.dialog.openFormExpel = false
      this.dialog.openFromGroup = false
      this.dialog.openFormWithdraw = false
      this.status_add_change = false
      this.status_history = false
      this.dialog.error = []
      this.check = false
    },
    closedForm(){
      this.opened_form = false
    },
    loadLeftMenu() {
      let menu = getInstStudentMenuDean(
        this.$route.params.idStudent,
        this.$route.params.idFaculty,
        this.$route.query.cathedra !== undefined ?
          {
            name: dean_names.DEPARTAMENTS.STUDENTS.LIST,
            params: {idFaculty: this.$route.params.idFaculty, uidDepartament: this.$route.query.cathedra}
          } : undefined
      )
      this.$store.commit("leftmenu/replaceItems", menu);
    },
    expelStudent(not=true) {
      let url = this.URL_EXPEL
      if(!not){
         url = this.URL_RECOVERY
      }
      let saverExpelStudent = (data) => {
        this.dialog.openFormExpel = false
        this.student.loaded = false
        this.loadStudent()
      };
      let catherExpelStudent = (data) => {
        this.dialog.error.push(data)
      };
      loadData(url, saverExpelStudent, catherExpelStudent);
    },

    loadStudent: function () {
      let url = this.URL_DETAIL
      loadData(
        url,
        (data) => {
          this.student.data = data;
          loadData(
            urlsM.ACCOUNTS.PROFILE(this.student.data.student),
            (data) => {
              this.student.data.user = data;
              this.student.loaded = true;
            });
        }
      );
    },
    loadStatuses: function () {
      let url = this.URL_STATUSES_LIST
      loadData(
        url,
        (data) => {
          this.statuses.data = data
        }
      )
    },
    displayDate(date, minutes = false) {
      let format = "DD.MM.YYYY";
      if (minutes)
        format += " HH:mm";
      return moment(date).format(format)
    },
    withdrawStatus(){
      loadData(this.URL_STATUSES_WITHDRAW,()=>{
        this.refreshItem()
      },(json)=>{
        this.receiveErrors(json)
        this.check = true
      })
    },
    AddChangeStatus(){
      this.loadStatuses()
      this.status_add_change = true
    }
  },
  created() {
    this.$store.commit("mainpage/changeTitle", this.title);
    this.loadStudent()
    this.loadStatuses()
    this.loadLeftMenu()
  }

}
</script>

<style scoped>

</style>

