<template>
  <div class="page-content" style="margin: 0px; width: 100%">
    <PageHeader></PageHeader>
    <StudentProfileDetail v-if="$route.name === names.StudentProfile">
    </StudentProfileDetail>
    <router-view v-else></router-view>
    <AlertStudents></AlertStudents>
  </div>
</template>
<script>

  import {mapState} from "vuex"
  import StudentProfileDetail from "./StudentProfileDetail/StudentProfileDetail"
  import {makeGetRequest, makeProfileUrl} from "../../../helper";
  import urls from "../urls"
  import names from "@/modules/student/routers/names"
  import {getInstMenu, getInstNameByRouterName} from "@/helper/instances";
  import PageHeader from "@/modules/menus/PageHeader";
  import store from "@/store";
  import teacher_names from "@/modules/teacher/routers/names";
  import dean_names from "@/modules/dean/routers/names";
  import cathedra_names from "@/modules/cathedra/routers/names";
  import educational_names from "@/modules/educationalManagement/routers/names";
  import AlertStudents from "@/modules/core/components/AlertStudents.vue";

  export default {
    name: "Profile",
    components: {StudentProfileDetail,AlertStudents
    ,PageHeader},
    data: function () {
      return {

        timeout: 600000,
        itemssnac:[

        ]
      }
    },
    computed: mapState({
      mainpage: state => state.mainpage,
      names: () => names
    }),
    methods: {
      allowed(urlname, action) {
        return urls.getUrlAllowed(urlname, action)
      },
      goPath(route){
        if (this.$route.name !== route)
          this.$router.push({name: route})
      },
      loadLeftMenu(){
          this.$store.commit("leftmenu/enableLeftMenu");
      }

    },
      beforeRouteUpdate(to, from, next){
        if (to.name === names.StudentProfile){
            this.loadLeftMenu()
            this.$store.commit("leftmenu/selectInstance", getInstNameByRouterName(names.StudentProfile));
        }
          next()
      },
    created() {
        this.loadLeftMenu()
        this.$store.commit("leftmenu/replaceInstances", getInstMenu());
        this.$store.commit("mainpage/changeTitle", "Профиль пользователя");
        this.$store.commit("leftmenu/selectInstance", getInstNameByRouterName(names.StudentProfile));
    }

  }
</script>

<style scoped>


</style>
