import helper from "./helper";
import user from "../store/modules/user";
const host_account = process.env.VUE_APP_API_HOST + process.env.VUE_APP_ACCOUNTS_PREFIX
let getAccountsUrlWithParameters = helper.getAccountsUrlWithParameters;

export default {
  getAccountsUrlWithParameters,
  getByRecievedUrlData: (url, gets=null) => getAccountsUrlWithParameters(
    url.basename,
    url.action,
    url.params,
    gets
  ),
  PROFILE: {
    DETAIL:
      (user_uid, gets=null) => getAccountsUrlWithParameters(
        "users|user|profile",
        "get",
        {user_uid: user_uid},
        gets
      ),
    COUNTRY: (gets = null) => getAccountsUrlWithParameters(
      "selectors|contacts|country",
      "get",
      {},
      gets
    )
  },
  STUDENT_SEX: host_account + "choices/user/sex/"

}
