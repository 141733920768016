<template>
<!--  <v-card>-->
      <v-expansion-panel>
        <v-progress-linear indeterminate :active="loading" absolute></v-progress-linear>
        <v-expansion-panel-header class="py-0">
          <v-card-subtitle class="d-flex pt-2 pb-0 mb-0">
            <div @click.stop style="min-width: 400px">
              <v-text-field class="pt-0 mt-0" hide-details :label="label"  v-model="filter" @focus="$emit('focusInput')" @input="$emit('update:outerFilter', $event)" clearable @click:clear="$emit('update:outerFilter', '')">
                <template v-slot:append-outer v-if="showForAll">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-switch :value="forAll" class="pt-0 mt-0" hide-details @change="emitForAll"></v-switch>
                      </div>
                    </template>
                    <span>По всем спискам</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </div>
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="$emit('refresh')"><v-icon>refresh</v-icon></v-btn>
          </v-card-subtitle>
        </v-expansion-panel-header>

        <v-expansion-panel-content class="py-1" eager>
          <v-list>
            <template v-for="(subject, i) in subject_list">
              <div :key="`${i}_subject_row`" v-show="checkIsFiltered(subject_list[i])">
                <v-divider
                  :key="`${i}_subject_first_divider`"
                  v-show="i === 0"
                  style="border-bottom-width: 3px"
                ></v-divider>
                <subject
                  class="pt-2"
                  :key="`${i}_subject_diff`"
                  :item.sync="subject_list[i]"
                  :parent="curriculum"
                  :ref="`subject`"
                  @refresh="refreshByParent"
                  @refreshIdentifiers="refreshIdentifiers"
                ></subject>
                <semesters :key="`${i}_subject_semesters_diff`" :subject="subject" @refresh="refreshSemesters(i)"></semesters>
                <v-divider
                  :key="`${i}_subject_divider`"
                  v-show="i < subject_list.length - 1"
                 style="border-bottom-width: 3px"
                ></v-divider>
              </div>
            </template>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
<!--  </v-card>-->
</template>

<script>

import Subject from "@/modules/curriculumEditor/pages/difference/Subject.vue";
import Semesters from "@/modules/curriculumEditor/pages/difference/Semesters.vue";

export default {
  name: "SubjectListTemplate",
  components: {Subject, Semesters},
  props: {
    subjects: {
      required: true
    },
    curriculum: {
      required: true
    },
    loading: {
      default: false
    },
    label: {
      required: true
    },
    forAll: {
      required: false,
      type: Boolean,
      default: false
    },
    showForAll: {
      required: false,
      type: Boolean,
      default: false
    },
    outerFilter: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      subject_list: this.subjects,
      filter: ""
    }
  },
  computed: {
  },
  watch: {
    subjects(nV, oV){
      this.subject_list = nV
    },
    outerFilter(nV, oV) {
      if(this.forAll){
        this.filter = nV
      }
    }
  },
  methods: {
    checkIsFiltered(el){
      if (this.filter === "" || this.filter === undefined) return true
      let returned = false
      if(el.eios !== undefined && el.eios !== null) returned = el.eios.subject.toLowerCase().includes(this.filter.toLowerCase())
      if(!returned && el.one) returned = el.one.subject.name.toLowerCase().includes(this.filter.toLowerCase())
      return returned
    },
    refreshSemesters(index){
      this.$refs["subject"][index].refresh()
    },
    refreshByParent(subjects){
      return this.$emit("refresh", subjects)
    },
    refreshIdentifiers(identifiers) {
      this.$emit("refreshIdentifiers", identifiers)
    },
    emitForAll(event){
      this.$emit("update:forAll", event);
      if (event) {
        this.$emit("update:outerFilter", this.filter);
      } else {
        this.$emit("update:outerFilter", "");
      }

    }
  }
}
</script>

<style scoped>

</style>
