import { render, staticRenderFns } from "./LoadXlsx.vue?vue&type=template&id=7f3261c8&scoped=true"
import script from "./LoadXlsx.vue?vue&type=script&lang=js"
export * from "./LoadXlsx.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f3261c8",
  null
  
)

export default component.exports