<template>
  <v-container style="height: 100%">
    <v-card flat>
      <v-toolbar
        elevation="0"
        color="style-color-main-gray"
      >
        <v-text-field
          dense
          placeholder="Поиск"
          v-model="search"
          @change="getItems()"
          messages="Для поиска нажмите Enter или кнопку поиск"
        >
        </v-text-field>
        <v-btn icon><v-icon>search</v-icon></v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="getItems()"
              class="mr-2"
              medium
            >
              update
            </v-icon>
          </template>
          <span>Обновить данные</span>
        </v-tooltip>
      </v-toolbar>
      <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              :expanded.sync="expanded"
              show-expand
              class="elevation-1"
              no-data-text="Нет данных"
              :options.sync="pagination"
              :server-items-length="total"
              :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
            >
              <template v-slot:item.fio="props">
                <v-card-text class="px-0" v-if="props.item.fio !== '-'">{{ props.item.fio}}</v-card-text>
                                    <v-skeleton-loader v-else type="list-item" class="mx-auto"></v-skeleton-loader>
              </template>
              <template v-slot:item.education_group.course="props">
              <router-link  :to="{ name:names.DEPARTAMENTS.GROUP.LED.DETAIL, params: {idGroup: props.item.education_group.id }}">
                {{props.item.education_group.litter}}-{{props.item.education_group.course}}{{props.item.education_group.number}}
              </router-link>
              </template>

              <template v-slot:expanded-item="props">
                <td :colspan="props.headers.length" class="py-2">
                  <v-card-text class="text-left" v-for="it in props.item.semesters_data">{{it.subject}}</v-card-text>
                </td>
              </template>
              <template slot="no-data">
                <v-card-text>Данные не загружены</v-card-text>
              </template>
            </v-data-table>
    </v-card>

    <!--</v-flex>-->
    <!--</v-layout>-->
  </v-container>
</template>


<script>
  // import Courses  from "../../forms/Courses"
  import UserDataMixin from "../../../../../mixins/UserDataMixin"
  import { makeGetRequest, loadData} from "@/helper";
  import {getInstNameByRouterName} from "@/helper/instances";
  import urls from "@/urls/dean"
  import {generateListUidToName} from "../../../../../helper/uidToName";
  import names from "@/modules/dean/routers/names";
  import cathedras from "@/urls/cathedras";

  export default {
    name: "ListLedStudents",
    // components: {Courses},
    mixins: [UserDataMixin],
    props: {
    },
    data () {
      return {
        URL_LIST: urls.DEAN.CATHEDRA.LED.STUDENT.LIST(this.$route.params.uidDepartament),
        names:names,
        total: 0,
        expanded: [],
        search:"",
        items: [],
        loading: true,
        pagination: {},
        headers: [
          {text: "Обучающийся",value:"fio", sortable: false, align: "left", width: "40"},
          {text: "Группа",value:"education_group.course", sortable: false, align: "left", width: "5%"},
          {text: "Уровень",value:"education_group.level", sortable: false, align: "left",  width: "10%"},
          {text: "Номер зачетной книжки", value: "record_book_number", sortable: false, align: "start", width: "10%"},
          {text: "Кафедра",value:"cathedra", sortable: false, align: "left",  width: "40"},
          {text: "Год поступления",value:"entry_year", sortable: false, align: "left", width: "5%"},
          { text: "", value: "data-table-expand", width: "5%"}
        ]
      }
    },
    watch: {
      expanded: function (newVal) {
        newVal.forEach(el => {
          if (el.semesters_data === undefined) {
            this.loadSubjects(el);
          }
        });
      },
      pagination: {
        handler () {
          this.getItems()
        },
        deep: true
      }
    },

    created() {
      // this.$store.commit('leftmenu/activeRouterChange', this.$route.name);
      // this.$store.commit('leftmenu/selectInstance', getInstNameByRouterName(names.DETAIL));
      this.$store.commit("mainpage/changeTitle", "Список ведомых обучающихся кафедры");
    },
    methods: {
      loadSubjects: function (group) {
        loadData(
          cathedras.getByRecievedUrlData(group.semesters),
          data => {
            this.$set(group, "semesters_data", data);
          }
        );
      },

      addItem(){
        this.closedForm()
        this.getItems();
      },

      getItems () {
        this.loading = true
        let url = new URL(this.URL_LIST)
        let params = {};
        if(this.search.length > 0)
          params.fio = this.search;

        if(this.pagination.page !== undefined) {
          params.page = this.pagination.page;
          params.size = this.pagination.itemsPerPage;
        }
        else{
          params.page = "1";
          params.size = "10";
        }

        url.search = new URLSearchParams(params).toString();

        let sys = true
        makeGetRequest(url).then(resp => {
          if (!resp.ok) {
            sys = false
          }
          return resp.json()
        }) .then(json => {
          this.items = json.results
          this.expanded= []
          this.total = json.count
          this.loading = false
          for (let i in this.items) {
            this.$set(this.items[i], "fio","-")
          }
          generateListUidToName(this.items,"student","fio")
        })
      }
    }
  }


</script>
<style scoped>

</style>
