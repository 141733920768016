<template>
    <div v-if="visible" >
      <v-row no-gutters align="center" class="py-2">
        <v-col cols="12" sm="4" class="pl-md-3 font-weight-bold">
          <slot name="label">
            {{label}}
          </slot>
        </v-col>
        <v-col cols="12" sm="8" class="pr-md-3 text-left">
          <slot name="value">
            {{value}}
          </slot>
          <slot name="icon">
          </slot>
        </v-col>
      </v-row>
      <v-divider class="my-1" style="width: 100%" v-if="dividerNoShow"></v-divider>
    </div>
</template>

<script>
export default {
  name: "StudentInfoRow",
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    dividerNoShow: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      required: false
    },
    value: {
      required: false
    }
  }
}
</script>

<style scoped>

</style>
