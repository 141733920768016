<template>
  <v-container v-if="load">
    <v-card class="mx-1">
<!--      <DebugJsonComponent :json="loading"></DebugJsonComponent>-->
      <v-toolbar
        class="mb-2"
        color="style-color-main-gray"
        flat
      >
        <v-row>
          <v-col cols="3">
            <v-autocomplete
              class="mt-6"
              v-if="selectors.semesters"
              label="Семестр"
              :items="selectors.semesters"
              v-model="semester"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-toolbar>
      <template v-if="semester">
        <v-expansion-panels v-if="this.selectors.optionally_group && this.selectors.optionally_group!=0"
                            v-model="panel"
        >
          <v-expansion-panel
            v-for="item in this.selectors.optionally_group"
            :key="item.id"
          >
            <v-expansion-panel-header>
              <template v-slot:default="{open}">
                <v-row>
                  <v-col cols="10">
                    <v-toolbar-title>
                      {{item.text}}
                    </v-toolbar-title>
                  </v-col>
                  <v-col cols="2">
                    <v-btn @click.native.stop :disabled="!open" :loading="load_semester" color="primary" dark class="mb-2" @click="UpdateOptSemester()">
                      Сохранить
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:actions>
                <v-btn icon>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                v-if="load"
                v-model="selected"
                :headers="headers"
                :items="students"
                no-data-text="Нет данных"
                :items-per-page="students.length"
                hide-default-footer
                show-select
              >
                <template v-slot:top>
                  <v-toolbar
                    class="mb-2"
                    flat
                  >
                    <v-row >
                      <v-col cols="3">
                        <div class="text-left">Назначить предмет по выбору: {{selected.length}}/{{students.length}}</div>
                      </v-col>
                      <v-col cols="9">
                        <v-autocomplete
                          v-if="selectors.optionally_semesters"
                          label="Семестр"
                          :items="selectors.optionally_semesters"
                          v-model="optionally_semester"
                          :error="hasError('semester')"
                          :error-messages="getError('semester')"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                  <v-progress-linear v-if="!loading" indeterminate></v-progress-linear>
                </template>
                <template v-slot:item.data-table-select="props">
                  <v-simple-checkbox :value="props.isSelected" @input="props.select($event)"></v-simple-checkbox>
                </template>
                <template v-slot:item.group="props">
                  <span>{{props.item.fio}}</span>
                </template>
                <template slot="no-data">
                  <v-card-text>Данные не загружены</v-card-text>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-else-if="loading" class="px-2 pt-2 pb-1">
          <v-alert outlined type="info" border="left">
            <div class="text-justify">
              У данной учебной группы нет предметов по выбору в текущем семестре.
            </div>
          </v-alert>
        </div>
      </template>
    </v-card>
  </v-container>
</template>

<script>
import names from "@/modules/dean/routers/names"
import LeftMenuChangerMixin from "@/mixins/LeftMenuChangerMixin"
import TitledPageMixin from "@/mixins/TitledPageMixin"
import DebugJsonComponent from "../../../core/components/DebugJsonComponent"
import dean from "@/urls/dean"
import selectors from "@/urls/selectors"
import {loadData, sendPostRequest} from "@/helper";
import {generateListUidToName} from "@/helper/uidToName"
import UserDataFormMixin from "@/mixins/UserDataFormMixin"

export default {
  name: "Base",
  mixins: [TitledPageMixin, LeftMenuChangerMixin, UserDataFormMixin],
  components: {DebugJsonComponent},
  data () {
    return {
      page_title: "Предметы по выбору группы",
      names: names,
      nameGroup: "",
      students: [],
      subjects: {},
      loading: false,
      errors: [],
      load: false,
      load_semester: false,
      selected: [],
      active: true,
      optionally_group: null,
      semester: null,
      optionally_semester : null,
      selectors: {
        semesters: [],
        optionally_group: [],
        optionally_semesters: []
      },
      panel: [],
      old_panel: 0,
      headers: [
        {text: "ФИО", value: "fio", sortable: false, align: "start"},
        {text: "Выбранный предмет", value: "semester", sortable: false, align: "start"}
      ]

    }
  },
  watch: {
    semester() {
      this.selected = []
      this.loading = false
      this.selectors.optionally_group = null
      this.optionally_group = null
      loadData(
        selectors.SELECTORS.DEAN.OPTIONALLY_GROUP(this.$route.params.idGroup, {year_semester: this.semester}),
        (data) => {
          this.selectors.optionally_group = data
          if (this.selectors.optionally_group != 0){
            this.loadOptGroupSemester(this.selectors.optionally_group[0].value)
            this.panel = 0
          }
          else{
            this.loading = true
          }
        }
      )
    },
    panel() {
      if(this.panel!= undefined && this.panel != this.old_panel) {
        this.loadOptGroupSemester(this.selectors.optionally_group[this.panel].value)
        this.selected = []
        this.old_panel = this.panel
      }
    }
  },
  methods: {
    getLeftMenu(){
      return [
        {
          title: "Группа",
          icon: "arrow_back_ios",
          included: false,
          router: {
            name: names.GROUP.DETAIL,
            params: {
              group_id: this.$route.params.idGroup,
              idFaculty: this.$route.params.idFaculty,
              taught: this.$route.params.taught,
              reload_left_menu: true
            },
            query: this.$route.query
          }
        }
      ]
    },
    loadOptGroupSemester(group){
      this.selectors.optionally_semesters = null
      this.optionally_semester = null
      this.optionally_group = group
      loadData(
        selectors.SELECTORS.DEAN.OPTIONALLY_GROUP_SEMESTER(this.$route.params.idGroup,{year_semester: this.semester, optionally_group: this.optionally_group}),
        (data) => {
          this.selectors.optionally_semesters = data
          this.optionally_semester = this.selectors.optionally_semesters[0].value
        }
      )
      if (this.semester!=null && this.optionally_group!=null){
        this.loadSemesterStudent()
      }
    },
    loadSemesterStudent(){
      let url = dean.DEAN.GROUP.OPTIONALLY_SEMESTER_STUDENT(this.$route.params.idFaculty, this.$route.params.idGroup,
        {
          year_semester: this.semester,
          optionally_group: this.optionally_group
        })
      loadData(
        url,
        (data) => {
          this.subjects = data
          this.students.forEach(el => {
            this.$set(el, "semester", "")
            for (let i in this.subjects) {
              if (el.user == this.subjects[i].student){
                el.semester = this.subjects[i].semester
                break
              }
            }
          })
          this.loading = true
        })
    },
    loadStudents(){
      let url = dean.DEAN.GROUP.STUDENTS.LIST(this.$route.params.idFaculty, this.$route.params.idGroup)
      loadData(
        url,
        (data) => {
          this.students = data.filter(el => el.active)
          this.loadStudentFios(this.students)
        })
    },
    loadStudentFios(students, finalizer=()=>{}){
      students.forEach(el => {
        this.$set(el, "fio", "")
      });
      generateListUidToName(students, "user", "fio", () => {
        students.sort((a,b) => a.fio.toUpperCase() > b.fio.toUpperCase() ? 1: -1)
        finalizer();
        this.load = true
      });
    },
    loadSelector(){
      loadData(
        selectors.SELECTORS.DEAN.YEAR_SEMESTER(this.$route.params.idGroup),
        (data) => {
          this.selectors.semesters= data
          this.loadStudents()
        }
      )
    },
    UpdateOptSemester(){
      if(this.selected.length != 0 ) {
        this.errors = []
        this.load_semester = true
        let url = dean.DEAN.GROUP.UPDATE_OPTIONALLY_SEMESTER(this.$route.params.idFaculty, this.$route.params.idGroup,{year_semester: this.semester})
        let data= {
          semester:this.optionally_semester
        }
        let students = [];
        for (let obj in this.selected){
          students.push({id : this.selected[obj].id})
        }
        this.selected = []
        data.students = students
        sendPostRequest(url,data,
          ()=>{
            this.loadSemesterStudent()
            this.load_semester = false
          },
          (error)=>{
            this.receiveErrors(error);
            this.load_semester = false
          }
        )
      }
    }
  },
  created() {
    this.setPageTitleWithObject("Group", {id: this.$route.params.idGroup}, " ", null, (value) => {
      this.nameGroup = value
    })
    this.loadSelector()
  }
}
</script>

<style scoped>
.v-expansion-panel-header{
  pointer-events: none;
}
.v-btn{
  pointer-events: All;
}
</style>
