<template>
  <v-card flat>
    <v-toolbar dense flat>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <tooltip-btn tooltip="Раскрыть все" icon="unfold_more" @click="panels = [0,1,2]" v-if="panels.length !== 3" top></tooltip-btn>
        <tooltip-btn tooltip="Скрыть все" icon="unfold_less" @click="panels = []" v-if="panels.length !== 0" top></tooltip-btn>
<!--        <v-btn @click="panels = [0,1,2]" v-if="panels.length !== 3" icon>-->
<!--          <v-icon>unfold_more</v-icon>-->
<!--        </v-btn>-->
<!--        <v-btn @click="panels = []" v-if="panels.length !== 0" icon>-->
<!--          <v-icon>unfold_less</v-icon>-->
<!--        </v-btn>-->
      </v-toolbar-items>

    </v-toolbar>
<!--    <v-expand-transition >-->
    <v-expansion-panels class="rounded-0" v-model="panels" multiple accordion>
      <subject-list-template
        :curriculum="curriculum"
        :subjects="notLinkedEIOS"
        v-show="['all', 'eios'].indexOf(filters.differenceType.value) !== -1"
        @refresh="$emit('refresh')"
        @focusInput="focusInput(0)"
        :loading="inLoading"
        label="Существуют только в ЭИОС"
        @refreshIdentifiers="refreshIdentifiers"
        show-for-all
        :for-all.sync="forAll"
        :outer-filter.sync="search"
      >
      </subject-list-template>
<!--    </v-expand-transition>-->
<!--    <v-expand-transition key="transition">-->
      <subject-list-template
        :curriculum="curriculum"
        :subjects="notLinkedOne"
        v-show="['all', 'one'].indexOf(filters.differenceType.value) !== -1"
        @refresh="$emit('refresh')"
        @focusInput="focusInput(1)"
        :loading="inLoading"
        label="Существуют только в 1C"
        @refreshIdentifiers="refreshIdentifiers"
        show-for-all
        :for-all.sync="forAll"
        :outer-filter="search"
      >
      </subject-list-template>
<!--    </v-expand-transition>-->
<!--    <v-expand-transition key="transition">-->
      <subject-list-template
        :curriculum="curriculum"
        :subjects="linked"
        v-show="['all', 'linked'].indexOf(filters.differenceType.value) !== -1"
        @refresh="$emit('refresh')"
        @focusInput="focusInput(2)"
        :loading="inLoading"
        label="Связанные"
        @refreshIdentifiers="refreshIdentifiers"
        show-for-all
        :for-all.sync="forAll"
        :outer-filter="search"
      >
      </subject-list-template>
    </v-expansion-panels>
<!--    </v-expand-transition>-->
  </v-card>

</template>

<script>
import SubjectListTemplate from "@/modules/curriculumEditor/pages/difference/SubjectListTemplate.vue";
import TooltipBtn from "@/modules/administrative/components/TooltipBtn.vue";
import urls from "@/modules/curriculumEditor/urls";
import {sendGetRequest} from "@/helper";

export default {
  name: "Subjects",
  components: {SubjectListTemplate, TooltipBtn},
  props: {
    subjects: {
      required: true
    },
    curriculum: {
      required: true
    },
    loading: {
      default: false
    }
  },
  data(){
    return {
      panels: [],
      in_loading: false,
      search: "",
      forAll: false,
      filters: {
        differenceType: {
          value: "all",
          items: [
            {text: "Все", value: "all"},
            {text: "Только связанные", value: "linked"},
            {text: "Только 1С", value: "one"},
            {text: "Только ЭИОС", value: "eios"}
          ]
        }
      }
    }
  },
  computed: {
    inLoading(){
      return this.loading || this.in_loading
    },
    sortedSubjects(){
      let sorted = [...this.subjects].sort((a, b) => {
        let name1 = a.one ? a.one.subject.name : a.eios.subject;
        let name2 = b.one ? b.one.subject.name : b.eios.subject;
        return name1 < name2 ? -1: 1;
      })
      sorted.map((el) =>
        el.semesters.map(el => {
          el.group = el.eios !== null && el.one !== null ? "Связанные" : el.eios !== null ? "Только ЭИОС" : "Только 1С"
          return el
        }))
      return sorted
    },
    linked(){
      return this.sortedSubjects.filter(el => el.difference.subject)
    },
    notLinkedOne(){
      return this.sortedSubjects.filter(el => !el.difference.subject && el.one !== null)
    },
    notLinkedEIOS(){
      return this.sortedSubjects.filter(el => !el.difference.subject && el.eios !== null)
    }
  },
  methods: {

    focusInput(idx){
      if (this.panels.indexOf(idx) === -1)
        this.panels.push(idx)
    },
    refreshIdentifiers(identifiers){
      this.in_loading = true
      sendGetRequest(
        urls.NAMES.EDITOR.SUBJECT.DIFFERENCE_LIST(identifiers, this.$route.params.curriculum_id),
        (data) => {
          let subjects = [...this.subjects];
          if (data.length === 1){
            subjects.splice(subjects.findIndex((el) => el.one && el.one.guid === data[0].one.guid), 1)
            subjects.splice(subjects.findIndex((el) => el.eios && el.eios.id === data[0].eios.id), 1)
            subjects.unshift(...data)
          } else {
            subjects.splice(subjects.findIndex(
              (el) =>
                (el.one && data[0].one && el.one.guid === data[0].one.guid) ||
                (el.eios && data[0].eios && el.eios.id === data[0].eios.id)
            ), 1)
            subjects.unshift(...data)
          }
          this.$emit("update:subjects", subjects)
          this.in_loading = false
        },
        () => {
          this.in_loading = false
        }
      )
    }
  }
}
</script>

<style scoped>

</style>
