<template>
  <div class="page-content" style="margin: 0px; width: 100%">
    <PageHeader></PageHeader>
    <Administrative v-if="$route.name === 'AdministrativeBreadcrumbs'"></Administrative>
    <router-view v-else></router-view>
  </div>
</template>

<script>
  import {mapState} from "vuex"
  import Administrative  from "./Administrative"
  import urls from "../urls"
  import names from "@/modules/administrative/routers/names"
  import {getInstMenu, getInstNameByRouterName} from "@/helper/instances";
  import PageHeader from "@/modules/menus/PageHeader.vue"

  export default {
    name: "AdministrativeBreadcrumbs",
    components: {Administrative, PageHeader},
    data: function () {
        return {}
    },
    computed: mapState({
        mainpage: state => state.mainpage
    }),
    methods: {
      allowed(urlname, action) {
        return urls.getUrlAllowed(urlname, action)
      }
    },
    created() {
      this.$store.commit("leftmenu/enableLeftMenu");
      this.$store.commit("mainpage/changeTitle", "Административный работник");
      this.$store.commit("leftmenu/selectInstance", getInstNameByRouterName("AdministrativeBreadcrumbs"));
      this.$store.commit("leftmenu/replaceInstances", getInstMenu());
      let menu = [
        {title: "Административный работник", icon: "settings_applications", router: "AdministrativeBreadcrumbs"},
        {title: "Список курсов", icon: "vertical_split", router: "AdministrativeListCourses"},
        {
          title: "Справочники 1С", icon: "vertical_split", subs: [
            {title: "Направления", icon: "vertical_split", router: names.REFERENCE.DIRECTIONS},
            {title: "Профили", icon: "vertical_split", router: names.REFERENCE.PROFILES},
            {title: "Факультеты", icon: "vertical_split", router: names.REFERENCE.FACULTIES},
            {title: "Кафедры", icon: "vertical_split", router: names.REFERENCE.CATHEDRAS},
            {title: "Предметы", icon: "vertical_split", router: names.REFERENCE.SUBJECTS}
          ]
        },
        {
          title: "Данные 1С", icon: "vertical_split", subs: [
            {title: "Учебные планы", icon: "vertical_split", router: names.CURRICULUM.LIST},
            {title: "Группы", icon: "vertical_split", router: names.GROUP.LIST},
            {title: "Лица", icon: "vertical_split", router: names.FACE.LIST},
            {title: "Образования", icon: "vertical_split", router: names.EDUCATION.LIST}
          ]
        }
      ]
      this.$store.commit("leftmenu/replaceItems", menu);
    }
  }
</script>

<style scoped>


</style>
}
