<template>
  <scroll-center-dialog
    v-model="inValue"
    @close="inValue = false"
    @apply="execute({education_id: getEIOSValue(item).id})"
    :loading="loading"
    :disabled="!apply && applyable"
    applyText="Выполнить"
  >
    <template v-slot:title>
      <slot name="title">Поиск и сопоставление дочерних сущностей</slot>
    </template>
    <v-alert type="info" outlined>
      Нажав на кнопку «Выполнить», вы запустите процесс {{actionName}}.
    </v-alert>
    <v-card-title class="text-left" v-if="level !== 'kind'">
    <span style="word-break: break-word">Процесс затронет объекты, зависимые от "{{title}}"({{typeName}}) с такими типами как: </span>
    </v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item v-if="SUBS.CURRICULUM.indexOf(level) !== -1">
          <v-list-item-icon><v-icon>radio_button_checked</v-icon></v-list-item-icon>
          Предметы учебного плана
        </v-list-item>
        <v-list-item v-if="SUBS.SUBJECT.indexOf(level) !== -1">
          <v-list-item-icon><v-icon>radio_button_checked</v-icon></v-list-item-icon>
          Семестры предмета учебного плана
        </v-list-item>
        <v-list-item v-if="SUBS.SEMESTER.indexOf(level) !== -1">
          <v-list-item-icon><v-icon>radio_button_checked</v-icon></v-list-item-icon>
          Занятия в семестре учебного плана
        </v-list-item>
      </v-list>
      <v-alert type="warning" outlined>
        <slot name="apply">
            Это действие не может быть отменено!
        </slot>
        <v-switch v-if="applyable" v-model="apply" label="Я уверен и понимаю"></v-switch>
      </v-alert>
    </v-card-text>
    <slot></slot>
  </scroll-center-dialog>
</template>

<script>
import {sendPostRequest} from "@/helper";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ActionExecutroMixin from "@/modules/administrative/mixins/ActionExecutroMixin";

const LEVEL = {
  CURRICULUM: "curriculum",
  SUBJECT: "subject",
  SEMESTER: "semester",
  KIND: "kind"
}

export default {
  name: "ActionForTree",
  components: {ScrollCenterDialog},
  mixins: [ActionExecutroMixin, FormErrorsMixin],
  props: {
    level: {
      required: true
    },
    actionName: {
      required: true
    },
    item: {
      required: true
    },
    applyable: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      apply: false,
      LEVEL: LEVEL,
      SUBS: {
        CURRICULUM: [LEVEL.CURRICULUM],
        SUBJECT: [LEVEL.CURRICULUM, LEVEL.SUBJECT],
        SEMESTER: [LEVEL.CURRICULUM, LEVEL.SUBJECT, LEVEL.SEMESTER],
        KIND: [LEVEL.CURRICULUM, LEVEL.SUBJECT, LEVEL.SEMESTER, LEVEL.KIND]
      }
    }
  },
  computed: {
    title(){
      if (this.level === LEVEL.CURRICULUM) {
        return this.getEIOSValue(this.item).name
      } else if (this.level === LEVEL.SUBJECT) {
        return this.getEIOSValue(this.item).subject
      } else if (this.level === LEVEL.SEMESTER) {
        return `${(this.getEIOSValue(this.item).course - 1) * 2 + (this.getEIOSValue(this.item).semester === "осенний" ? 1: 2)} семестр (${this.getEIOSValue(this.item).course}:${this.getEIOSValue(this.item).semester})`
      } else {
        return this.getEIOSValue(this.item).name
      }
    },
    typeName(){
      if (this.level === LEVEL.CURRICULUM) {
        return "Учебный план"
      } else if (this.level === LEVEL.SUBJECT) {
        return "Предмет учебного плана"
      } else if (this.level === LEVEL.SEMESTER) {
        return "Семестр учебного плана"
      } else {
        return "Вид занятий учебного плана"
      }
    }
  },
  methods: {
    getActionArgs(kwargs){
      if (this.applyable)
        return {
          apply: this.apply
        }
      else
        return {}
    },
    getEIOSValue(item){
      if(item !== undefined)
        return Object.hasOwn(item, "eios") ? item.eios : item
      return {}
    },
    getEducationId(){
      return this.getEIOSValue(this.item).id
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>
