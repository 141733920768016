<template>
  <v-container style="height: 100%">
    <v-card>
      <v-toolbar
        elevation="0"
        color="style-color-main-gray"
      >
        <v-text-field
          dense
          placeholder="Поиск"
          v-model="search.value"
          @keypress.enter="searchData"
          messages="Для поиска нажмите Enter или кнопку поиск"
        >
        </v-text-field>
        <v-btn icon><v-icon>search</v-icon></v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="loadData() "
              class="mr-2"
              medium
            >
              update
            </v-icon>
          </template>
          <span>Обновить данные</span>
        </v-tooltip>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="isLoading"
        :page.sync="pagination.page"
        :items-per-page.sync="pagination.size"
        :server-items-length="count"
        :options.sync="options"
        :footer-props="{'items-per-page-options':[10,50,100], 'items-per-page-text': 'Число элементов'}"
        class="elevation-1 rounded-b-lg"
        :no-data-text="`${plural_model_name} не загружены`"
        :no-results-text="`${plural_model_name} не были найдены`"
      >
        <template v-slot:item.face="{ item }">
          {{ item.face.lastname }} {{ item.face.firstname }} {{ item.face.midname }}
        </template>
      </v-data-table>

    </v-card>
  </v-container>
</template>

<script>

  import {loadData} from "@/helper";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
  import names from "@/modules/administrative/routers/names.js";
  import UserDataMixin from "@/mixins/UserDataMixin";
  import TitledPageMixin from "@/mixins/TitledPageMixin";
  import urls from "@/urls/admin";
  import {mapActions, mapGetters} from "vuex";

  export default {
    name: "CurriculumList",
    mixins:[
      PaginatedDataMapperMixin,
      TitledPageMixin
    ],

    props: {},
    data(){
      return {
        page_title: "Образования студентов",
        plural_model_name: "Образования",
        search: {
          parameter: "search"
        },
        pagination: {
          filters: {}
        },
        selectors: {
          model: []
        },
        headers: [
          {text: "Обучающийся", sortable: false, align: "left",weight:"30%",value: "face"},
          {text: "Группа", sortable: false, align: "left",weight:"10%",value: "group.name"},
          {text: "Учебный план", sortable: false, align: "left",weight:"10%",value: "curriculum.number"},
          {text: "Направление", sortable: false, align: "left",weight:"20%",value: "direction.name"},
          {text: "Факультет", sortable: false, align: "left",weight:"20%",value: "faculty.name"}
        ]
      }
    },
    computed: {
      ...mapGetters({
        items: "admin/educations/getEducationItems",
        count: "admin/educations/getEducationCount",
        isLoading: "admin/educations/isEducationLoading",
        getCurrentUrl: "admin/educations/getEducationCurrentUrl"
      })
    },
    methods: {
      ...mapActions({
        loadFaceList: "admin/educations/loadEducationList"
      }),
      updateData(){
        if (this.search.value) {
          this.search.value = ""
        }
        this.pagination.filters = {
          "model": null
        }
        this.car_filter_model = null
        this.loadData()
      },
      loadData(url = undefined) {
        this.loadFaceList({
          url: url,
          urlAppend: (url) => {
            return this.addPageParameter(this.addSizeParameter(url));
          },
          finalizer: (data, url) => {
            this.setCurrentLoadUrl(url);
          }
        })
      }
    },
    created() {
      // if (admin.ADMIN.ALLOWED()){
      //   if (this.getCurrentUrl) {
      //     this.loadFromSavedUrl(this.getCurrentUrl)
      //   } else {
      this.loadData()
      //   }
      //   this.loadSelectors()
      // }
      // else{
      //   this.$router.push({name: names_core.MAIN})
      // }
    }
  }
</script>

<style scoped>

</style>
