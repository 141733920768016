<template>
  <v-container style="height: 100%" v-if="is_self_router">
    <v-card >
      <v-toolbar color="style-color-main-gray" elevation="0">
        <v-text-field
          dense
          placeholder="Поиск"
          v-model="search.value"
          @keypress.enter="searchData"
          messages="Для поиска нажмите Enter или кнопку поиск"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="loadData()"
              class="mr-2"
              medium
            >
              update
            </v-icon>
          </template>
          <span>Обновить данные</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text class="pb-3 px-0 pt-0">
        <v-row justify-center no-gutters>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="groups"
              :loading="pagination.loading"
              :page.sync="pagination.page"
              :items-per-page.sync="pagination.size"
              :server-items-length="pagination.count"
              :footer-props="{'items-per-page-options':[10,25,50]}"
              no-data-text="Нет данных"
            >
              <template v-slot:item.name="props">
                <router-link :to="makeDetailLink(props.item)">
                  {{props.item.litter}}-{{ props.item.course }}{{ props.item.number }}
                </router-link>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>


<script>

import TitledPageMixin from "@/mixins/TitledPageMixin";
import urls from "@/urls/teacher"
import {loadData} from "@/helper";
import names from "@/modules/teacher/routers/names";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";

export default {
  name: "SupervisedGroupList",
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  components: {DebugJsonComponent},
  data () {
    return {
      page_title: "Список курируемых групп",
      search: {
        parameter: "name"
      },
      headers: [
        {text: "Группа", value: "name", sortable: false, align: "left"},
        {text: "Курс", value: "course", sortable: false, align: "left"},
        {text: "Уровень", value: "level", sortable: false, align: "left"},
        {text: "Форма", value: "form", sortable: false, align: "left"},
        {text: "Кафедра", value: "cathedra", sortable: false, align: "left", width: "40%"}
        // {text: 'Куратор', value: 'supervisor',sortable: false, align: 'left', width: '20%'},
        // {text: 'Дисциплины', value: 'data-table-expand', sortable: false, align: 'center' },
      ],
      groups: []
    }
  },
  computed: {
    is_self_router(){
      return this.$route.name === names.GROUPS.LIST;
    }
  },
  created() {
    if(this.is_self_router)
      this.loadData();
  },
  methods: {
    getUrl(){
      return
    },
    makeDetailLink(item){
      return {name: names.GROUPS.DETAIL, params: {group_id: item.id}}
    },
    loadData (url=undefined) {
      this.setLoading();
      if(url === undefined) {
        url = urls.URLS.GROUP.SUPERVISED.LIST();
        this.setCurrentLoadUrl(url);
      }
      loadData(
        url,
        (data) => {
          this.groups = data.results;
          this.mapPagination(data) ;
          this.setNotLoading();
        }
      )
    }
  }
}


</script>
<style scoped>

</style>
