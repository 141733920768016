<template>
  <v-card class="mx-auto">
    <v-card-title style="position: sticky; top: 0; z-index: 1" class="text-subtitle-1 elevation-1 white">
      <v-btn icon absolute right class="mx-0" style="right: 5px; top: 5px;" @click="$emit('close', selected)">
        <v-icon>close</v-icon>
      </v-btn>
      <v-row class="text-left">
        <span class="mr-8 mr-md-3">Назначение научного руководителя обучающемуся:</span> <span class="font-weight-bold">{{student_fio}}</span>
      </v-row>
      <v-row>
        <v-col cols="12" sm="8">
          <v-text-field
            v-model="search"
            dense
            hide-details
            label="Поиск"
            append-icon="search"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-btn color="success" width="100%" @click="saveScienceDirector">
            Сохранить
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row class="fill-height">
        <v-col>
          <v-list class="overflow-y-auto fill-height">
            <v-list-item-group v-model="selected" color="info">
              <v-list-item
                v-for="(teacher, index) in filtered" :key="`teacher_selector_${index}`"
                :value="teacher.value"
                >
                <v-list-item-content>
                  <v-list-item-title >
                    {{ teacher.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
    <v-snackbar
      v-model="notification"
      timeout="3000"
      :color="saved === undefined ? 'info' : saved ? 'success' : 'warning'"
      top
      right
      app
      style="border-top-left-radius: 0; border-top-right-radius: 0;"
    >
      {{msg}}
    </v-snackbar>
<!--    <DebugJsonComponent :json="teachers" status="errors"></DebugJsonComponent>-->
  </v-card>
</template>

<script>
  import cathedras from "../../../../urls/cathedras";
  import selectors from "../../../../urls/selectors";
  import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
  import {generateListUidToName} from "@/helper/uidToName";
  import {loadData, sendPostRequest} from "@/helper";

  export default {
    name: "ScienceDirectorChouse",
    components: {
      DebugJsonComponent
    },
    props: {
      group_id: {
        required: true,
        type: Number
      },
      student_fio: {
        required: true,
        type: String
      },
      student_id: {
        required: true,
        type: Number
      },
      teacher_uid: {
        required: true,
        type: String
      }
    },
    data() {
      return {
        teachers: [],
        selected: this.teacher_uid,
        search: "",
        saved: undefined,
        notification: false,
        msg: ""
      }
    },
    computed: {
      filtered() {
        if(this.search)
          return this.teachers.filter(el => el.text.toLowerCase().match(this.search.toLowerCase()));
        else
          return this.teachers
      }
    },
    methods: {
      loadTeachers: function () {
        this.loading = true;
        loadData(
          selectors.SELECTORS.WORKERS.CATHEDRA(this.$route.params.cathedra_uid),
          (data) => {
            this.teachers = data;
            this.teachers.forEach(el => {
              this.$set(el, "fio", "");
              this.$set(el, "value", el.text.split(",")[0].trim());
              el.text = el.text.split(",")[1].trim();
            });
            generateListUidToName(this.teachers, "value", "fio", (data) => {
              data.forEach(el => {
                el.text = `${el.fio}, ${el.text}`
              });
              this.loading = false;
            });
          }
        )
      },
      saveScienceDirector: function (){
        this.loading = true;
        let data = {
          "students": [{
            student: this.student_id,
            scientific_director: this.selected
          }]
        };
        if(this.selected !== this.teacher_uid){
          sendPostRequest(
            cathedras.CATHEDRA.GROUPS.SCIENCE_DIRECTORS.SAVE(this.$route.params.cathedra_uid, this.group_id),
            data,
            (res) => {
              this.saved = true;
              this.notification = true;
              this.loading = false;
              this.$emit("saved", this.selected);
              this.msg = "Успешно сохранено";
            },
            (res) => {
              this.saved = false;
              this.notification = true;
              this.loading = false;
              this.msg = res;
            }
        )
        }else{
          this.saved = undefined;
          this.notification = true;
          this.loading = false;
          this.msg = "Данные не были изменены";
        }
      }
    },
    created() {
      this.loadTeachers();
      // console.log(this.teacher_uid)
    }
  }
</script>

<style scoped>

</style>
