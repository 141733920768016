const EDUCATIONAL = {
  BASE: "Administrative|Educational",
  GROUP: {
    _BASE: undefined,
    DETAIL: undefined,
    STUDENTS: undefined,
    SUBJECTS: undefined,
    CURRENT_JOURNAL: undefined
  },
  PERMISSION: {
    _BASE: undefined,
    LIST: undefined
  },
  SUBJECTS_DOC: {
    LIST: undefined
  },
  STUDENTS: {
    _BASE: undefined,
    LIST: undefined,
    DETAIL: undefined,
    PORTFOLIO: undefined,
    EDUDOC: undefined,
    WORK: {
      _BASE: undefined,
      LIST: undefined,
      DETAIL: undefined
    },
    CURRICULUM: {
      _BASE: undefined,
      DETAIL: undefined,
      SUBJECT: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined
      }
    },
    FULL_JOURNAL: undefined
  },
  ACADEMIC_YEAR: {
    LIST: undefined,
    SEMESTER: {
      DETAIL: undefined,
      SESSION: undefined
    }
  },
  SUBJECTS: {
    _BASE: undefined,
    LIST: undefined
  },
  CURRICULUM: {
    _BASE: undefined,
    LIST: undefined,
    DETAIL: undefined
  },
  ADMISSION: {
    _BASE: undefined,
    LIST: undefined,
    DETAIL: undefined
  },
  ADMISSION_REQUEST: {
    _BASE: undefined,
    LIST: undefined,
    DETAIL: undefined
  },
  REFERENCE: {
    BASE: undefined,
    COMPETENCES: undefined,
    COURSES: undefined,
    RATING_SYSTEMS: undefined,
    STATE_STANDARDS: undefined,
    TRAINING_DIRECTIONS: undefined,
    TRAINING_PROFILES: undefined
  }
};


EDUCATIONAL.PERMISSION._BASE = `${EDUCATIONAL.BASE}|Permission`;
EDUCATIONAL.PERMISSION.LIST = `${EDUCATIONAL.PERMISSION._BASE}|List`;
EDUCATIONAL.SUBJECTS._BASE = `${EDUCATIONAL.BASE}|Subjects`;
EDUCATIONAL.SUBJECTS.LIST = `${EDUCATIONAL.SUBJECTS._BASE}|List`;
EDUCATIONAL.SUBJECTS_DOC.LIST = `${EDUCATIONAL.BASE}|SubjectsDoc`;
EDUCATIONAL.GROUP._BASE = `${EDUCATIONAL.BASE}|Group`;
EDUCATIONAL.GROUP.LIST = `${EDUCATIONAL.GROUP._BASE}|List`;
EDUCATIONAL.GROUP.DETAIL = `${EDUCATIONAL.GROUP._BASE}|Detail`;
EDUCATIONAL.GROUP.STUDENTS = `${EDUCATIONAL.GROUP._BASE}|Students`;
EDUCATIONAL.GROUP.SUBJECTS = `${EDUCATIONAL.GROUP._BASE}|Subjects`;
EDUCATIONAL.GROUP.CURRENT_JOURNAL = `${EDUCATIONAL.GROUP._BASE}|CurrentJournal`;

EDUCATIONAL.STUDENTS._BASE = `${EDUCATIONAL.BASE}|Student`;
EDUCATIONAL.STUDENTS.LIST = `${EDUCATIONAL.STUDENTS._BASE}|List`;
EDUCATIONAL.STUDENTS.DETAIL = `${EDUCATIONAL.STUDENTS._BASE}|Detail`;
EDUCATIONAL.STUDENTS.WORK._BASE = `${EDUCATIONAL.STUDENTS._BASE}|Work`;
EDUCATIONAL.STUDENTS.WORK.LIST = `${EDUCATIONAL.STUDENTS.WORK._BASE}|List`;
EDUCATIONAL.STUDENTS.WORK.DETAIL = `${EDUCATIONAL.STUDENTS.WORK._BASE}|Detail`;
EDUCATIONAL.STUDENTS.CURRICULUM._BASE = `${EDUCATIONAL.STUDENTS._BASE}|Curriculum`;
EDUCATIONAL.STUDENTS.CURRICULUM.DETAIL = `${EDUCATIONAL.STUDENTS.CURRICULUM._BASE}|Detail`;
EDUCATIONAL.STUDENTS.CURRICULUM.SUBJECT._BASE = `${EDUCATIONAL.STUDENTS.CURRICULUM._BASE}|Subject`;
EDUCATIONAL.STUDENTS.CURRICULUM.SUBJECT.LIST = `${EDUCATIONAL.STUDENTS.CURRICULUM.SUBJECT._BASE}|List`;
EDUCATIONAL.STUDENTS.CURRICULUM.SUBJECT.DETAIL = `${EDUCATIONAL.STUDENTS.CURRICULUM.SUBJECT._BASE}|Detail`;
EDUCATIONAL.STUDENTS.FULL_JOURNAL = `${EDUCATIONAL.STUDENTS._BASE}|FullJournal`;
EDUCATIONAL.STUDENTS.PORTFOLIO= `${EDUCATIONAL.STUDENTS._BASE}|Portfolio`;
EDUCATIONAL.STUDENTS.EDUDOC= `${EDUCATIONAL.STUDENTS._BASE}|EduDoc`;

EDUCATIONAL.ACADEMIC_YEAR.LIST = `${EDUCATIONAL.BASE}|AcademicYear`;
EDUCATIONAL.ACADEMIC_YEAR.SEMESTER.DETAIL = `${EDUCATIONAL.ACADEMIC_YEAR.LIST}|Semester`;
EDUCATIONAL.ACADEMIC_YEAR.SEMESTER.SESSION = `${EDUCATIONAL.ACADEMIC_YEAR.SEMESTER.DETAIL}|Session`;

EDUCATIONAL.CURRICULUM._BASE = `${EDUCATIONAL.BASE}|Curriculum`
EDUCATIONAL.CURRICULUM.LIST = `${EDUCATIONAL.CURRICULUM._BASE}|List`;
EDUCATIONAL.CURRICULUM.DETAIL = `${EDUCATIONAL.CURRICULUM._BASE}|Detail`;

EDUCATIONAL.ADMISSION._BASE = `${EDUCATIONAL.BASE}|Admission`
EDUCATIONAL.ADMISSION.LIST = `${EDUCATIONAL.ADMISSION._BASE}|List`;
EDUCATIONAL.ADMISSION.DETAIL = `${EDUCATIONAL.ADMISSION._BASE}|Detail`;

EDUCATIONAL.ADMISSION_REQUEST._BASE = `${EDUCATIONAL.BASE}|AdmissionRequest`
EDUCATIONAL.ADMISSION_REQUEST.LIST = `${EDUCATIONAL.ADMISSION_REQUEST._BASE}|List`;
EDUCATIONAL.ADMISSION_REQUEST.DETAIL = `${EDUCATIONAL.ADMISSION_REQUEST._BASE}|Detail`;

EDUCATIONAL.REFERENCE.BASE = `${EDUCATIONAL.BASE}|References`
EDUCATIONAL.REFERENCE.COMPETENCES = `${EDUCATIONAL.REFERENCE.BASE}|Competences`;
EDUCATIONAL.REFERENCE.COURSES = `${EDUCATIONAL.REFERENCE.BASE}|Courses`;
EDUCATIONAL.REFERENCE.RATING_SYSTEMS = `${EDUCATIONAL.REFERENCE.BASE}|RatingSystems`;
EDUCATIONAL.REFERENCE.STATE_STANDARDS = `${EDUCATIONAL.REFERENCE.BASE}|StateStandards`;
EDUCATIONAL.REFERENCE.TRAINING_DIRECTIONS = `${EDUCATIONAL.REFERENCE.BASE}|TrainingDirections`;
EDUCATIONAL.REFERENCE.TRAINING_PROFILES = `${EDUCATIONAL.REFERENCE.BASE}|TrainingProfiles`;


export default {
  EDUCATIONAL
}
