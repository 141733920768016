<template>
  <v-card>
    <v-toolbar class="subprimary" elevation="2">
      <v-toolbar-title class="subtitle-1">
        <span v-if="edit">Изменение</span><span v-else>Добавление</span> промежуточной контрольной точки
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn text @click="$emit('update:dialog', false)">
          <v-icon>
            close
          </v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text class="pt-3">
      <v-form v-model="valid" ref="form">
        <v-text-field
          label="Название контрольной точки"
          v-model="edited.name"
          :error="hasError('name')"
          :error-messages="getError('name')"
          :rules="[rules.required]"
          @blur="deleteError('name')"
          :loading="loading"
        ></v-text-field>
        <v-menu v-model="edited.menu" max-width="390" min-width="290" offset-x :close-on-click="false">
          <template v-slot:activator="{on, attrs}">
            <v-text-field
              v-model="formated_date"
              label="Конечная дата контрольной точки"
              v-on="on"
              v-bind="attrs"
              hint="ДД.ММ.ГГГГ"
              persistent-hint
              append-icon="calendar_today"
              @input="clearErrorWhenInput(formated_date, postDateInput, 'date')"
              :error="hasError('date')"
              :error-messages="getError('date')"
              :rules="[rules.required]"
              :loading="loading"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="edited.date"
            full-width locale="ru"
            first-day-of-week="1"
            no-title
            @change="formated_date = formatDate(edited.date)"
            @input="deleteError('date')"
            :max="parent_end_date"
          ></v-date-picker>
        </v-menu>
        <v-alert type="error" v-if="hasError('origin')" class="text-start mt-5" text>
          <p v-for="(error, idx) in getError('origin')" :key="`error_${idx}`">{{error}}</p>
        </v-alert>
        <v-btn :disabled="!valid" :loading="loading" @click="saveMiddlePoint" :color="hasError('origin') ? 'error': 'success'">
          <span v-if="edit">Измененить</span><span v-else>Добавить</span>
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {sendPostRequest, sendPutRequest} from "@/helper";
import urls from "@/urls/teacher";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";


export default {
  name: "MiddlePointDetailForm",
  mixins: [FormErrorsMixin],
  components: {
    DebugJsonComponent
  },
  props: {
    group_subject_id: {
      required: true,
      type: Number
    },
    edit: {
      required: true
    },
    id: {
      required: true,
      type: Number
    },
    name: {
      default: "",
      type: String
    },
    date: {
      default: "",
      type: String
    },
    parent_end_date: {
      default: "",
      type: String
    },
    dialog: {
      default: false
    }
  },
  data: function () {
    return {
      menu: false,
      loading: false,
      formated_date: this.formatDate(this.date),
      valid: true,
      edited: {
        name: this.name,
        date: this.date
      },
      rules: {
        required: (val) => !!val || "Не может быть пустым"
      }
    }
  },
  watch: {
    dialog: function (val) {
      if(val && !this.edit){
        this.$refs["form"].reset();
      }
    }
  },
  methods: {
    getToday() {
      return (new Date()).toISOString()
    },
    clearErrorWhenInput(input, checker, field) {
      this.deleteError(field);
      checker(input)
    },
    postDateInput(input){
      this.edited.date = this.parseDate(input);
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split("-")
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split(".")
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    },
    saveMiddlePoint(){
      if(this.$refs["form"].validate()) {
        this.loading = true;
        if (!this.edit)
          this.sendRequestMiddlePoint(
            sendPostRequest,
            urls.URLS.SUBJECT.MIDDLE_POINTS.CREATE(this.group_subject_id)
          );
        else
          this.sendRequestMiddlePoint(
            sendPostRequest,
            urls.URLS.SUBJECT.MIDDLE_POINTS.UPDATE(this.group_subject_id)
          );
      }
    },
    sendRequestMiddlePoint(sender, url){
      sender(
        url,
        {
          origin: this.id,
          name: this.edited.name,
          date: this.edited.date
        },
        (data) => {
          this.loading = false;
          if(data instanceof Array) {
            this.$emit("save", data)
            this.$emit("update:dialog", false)
          } else
            this.mapErrors(data)
        },
        (data) => {
          this.mapErrors(data)
          this.loading = false;
        }
      )
    }
  }
}
</script>

<style scoped>

</style>
