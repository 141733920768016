<template>
  <v-list shaped single-line>
    <template v-for="(item, i) in items">
      <span :key="i" v-if="!item.base && !item.hide">
        <CurrentLeftMenuAction
          :item="item"
          v-if="item.router"
        />
        <template v-if="item.subs !== undefined && !item.router && !item.base">
          <v-divider class="my-1"></v-divider>
          <v-list-group
            :icon="item.icon || ''"
            :value="inSubs(item)"
            prepend-icon=""
            no-action
          >
            <template v-slot:activator>
              <v-list-item-title class="text-left subtitle-2 px-2">{{item.title}}</v-list-item-title>
            </template>
            <template v-for="(subitem, j) in item.subs">
              <CurrentLeftMenuAction
                :key="j"
                :item="subitem"
              />
            </template>
          </v-list-group>
          <v-divider class="my-1 "></v-divider>
        </template>
        <v-divider class="pt-2 " v-if="item.part"></v-divider>
      </span>
    </template>
  </v-list>
</template>

<script>
import CurrentLeftMenuAction from "@/modules/menus/left_menu/CurrentLeftMenuAction";

export default {
  name: "CurrentLeftMenu",
  components: {
    CurrentLeftMenuAction
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    inSubs: function (item) {
      if(item.subs !== undefined) {
        let idx = item.subs.findIndex((el) => {
          return el.router === this.activeRouter;
        });
        return idx !== -1;
      }else {
        return false;
      }
    }
  }
}
</script>

<style scoped>

</style>
