<template>
  <v-container>
    <v-card>
      <v-toolbar class="title" color="style-color-main-gray" elevation="0">
        <v-toolbar-title> </v-toolbar-title>
        <v-text-field
          label="Поиск по дисциплинам"
          class="pl-md-3 my-auto" v-model="search" hide-details></v-text-field>
        <v-spacer></v-spacer>
        <v-switch
          v-model="toggle_archive"
          inset
          hide-details
          label="Показать архивные"
          class="pr-5"
        ></v-switch>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="loadData(pagination.current)"
              class="mr-2"
              medium
            >
              update
            </v-icon>
          </template>
          <span>Обновить данные</span>
        </v-tooltip>

      </v-toolbar>
      <v-card-text class="pb-3 pt-0 px-0">
        <v-row no-gutters>
          <v-col>
            <v-data-table
              :headers="subjects.headers"
              :items="searched_items"
              :loading="subjects.loading"
              :expanded.sync="subjects.expanded"
              class="elevation-0"
              no-data-text="Нет данных"
              show-expand
              :page.sync="pagination.page"
              :items-per-page.sync="pagination.size"
              :server-items-length="pagination.count"
              :footer-props="{'items-per-page-options':[10,25,50], 'footer-props.items-per-page-text': 'Число элементов'}"
            >
              <template v-slot:expanded-item="props">
                <td :colspan="props.headers.length" class="pa-1">
                  <v-card>
                    <v-data-table
                      v-if="props.item.groups !== undefined"
                      :headers="subjects.group_headers"
                      :items="props.item.groups"
                      :items-per-page="props.item.groups.length"
                      :server-items-length="props.item.groups.length"
                      hide-default-footer
                      no-data-text="Нет данных"
                    >
                      <template v-slot:item.name="props">
                        <router-link :to="makeLedGroupLink(props.item)">
                          {{props.item.litter}}-{{props.item.course}}{{props.item.number}}({{props.item.form}})
                        </router-link>
                      </template>
                      <template v-slot:item.curator_fio="props">
                        <span v-if="props.item.curator">
                          {{props.item.curator_fio}}
                        </span>
                        <span v-else>
                          Не назначен
                        </span>
                      </template>
                      <template v-slot:item.controls="props">
                        <div class="justify-center d-flex">
                          <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" max-width="700px" v-model="dialog">
                            <template v-slot:activator="{on, attrs}">
                              <v-btn icon v-on="on" v-bind="attrs">
                                <v-icon>
                                  edit
                                </v-icon>
                              </v-btn>
                            </template>
                            <MiddlePointListForm
                              :gets_param="{old:toggle_archive}"
                              :group_subject_id="props.item.group_subject.id" :dialog.sync="dialog"></MiddlePointListForm>
                          </v-dialog>
                        </div>
                      </template>
                      <template v-slot:item.files="props">
                        <div class="justify-center d-flex">
                          <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" max-width="700px" v-model="file_list_dialog">
                            <template v-slot:activator="{on, attrs}">
                              <v-btn icon v-on="on" v-bind="attrs">
                                <v-icon>
                                  get_app
                                </v-icon>
                              </v-btn>
                            </template>
                            <FileListForm :subject="props" :open.sync="file_list_dialog"></FileListForm>
                          </v-dialog>
                        </div>
                      </template>
                      <template v-slot:item.mottled="props">
                        <v-icon v-if="!props.item.group_subject.mottled">clear</v-icon>
                        <template v-else>
                          <v-icon
                            class="ma-0"
                            medium
                            :style="Rotate(props.item.group_subject.half)"
                          >
                            hourglass_bottom
                          </v-icon>
                          {{props.item.group_subject.half}} половина
                        </template>
                      </template>
                    </v-data-table>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import urls from "@/urls/teacher"
import TitledPageMixin from "@/mixins/TitledPageMixin";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import {loadData} from "@/helper";
import {generateListUidToName} from "@/helper/uidToName";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import names from "@/modules/teacher/routers/names";
import MiddlePointListForm from "@/modules/teacher/pages/subjects/MiddlePointListForm";
import FileListForm from "@/modules/teacher/pages/subjects/FileListForm.vue"

export default {
  name: "ListSubjects",
  mixins: [
    TitledPageMixin,
    PaginatedDataMapperMixin
  ],
  components: {
    MiddlePointListForm,
    DebugJsonComponent,
    FileListForm
  },

  data: function () {
    return {
      page_title: "Список преподаваемых дисциплин",
      dialog: false,
      search: "",
      toggle_archive: false,
      pagination: {
        filters: {
          old: false
        }
      },
      file_list_dialog: false,
      subjects: {
        items: [],
        expanded: [],
        loading: false,
        loaded: false,
        headers: [
          {text: "Предмет", value: "name", sortable: false, width: "70%", align: "start"},
          {text: "Уровень образования", value: "level", sortable: false, align: "center"},
          {text: "", value: "data-table-expand", width: "5%"}
        ],
        group_headers: [
          {text: "Группа", value: "name", sortable: false, align: "start"},
          {text: "Кафедра", value: "cathedra", sortable: false, width: "30%", align: "start"},
          // {text: 'Предмет', value: 'form', sortable: false, align: 'start',},
          {text: "Куратор", value: "curator_fio", sortable: false, width: "20%", align: "start"},
          {text: "Аудиторные часы", value: "group_subject.classroom_hours", sortable: false, width: "5%", align: "center"},
          {text: "Самостоятельные часы", value: "group_subject.independent_hours", sortable: false, width: "5%", align: "center"},
          {text: "Экзаменационные часы", value: "group_subject.exam_hours", sortable: false, width: "5%", align: "center"},
          {text: "Общее число часов", value: "group_subject.hours", sortable: false, width: "5%", align: "center"},
          {text: "ЗЕТы", value: "group_subject.zets", sortable: false, width: "5%", align: "center"},
          {text: "Контрольные точки", value: "controls", sortable: false, width: "5%", align: "center"},
          {text: "Нормативная документация", value: "files", sortable: false, align: "center"},
          {text: "Полусеместр", value: "mottled", sortable: false, align: "center"}
        ]
      }
    }
  },
  computed: {
    searched_items(){
      return this.subjects.items.filter(el => el.name.toLowerCase().includes(this.search.toLowerCase()))
    }
  },
  watch: {
      toggle_archive: function () {
        if(this.toggle_archive) {
          this.pagination.filters.old = true;
        }else{
          this.pagination.filters.old = false;
        }
        this.cleanPaginationPageData();
        this.setCurrentLoadUrl(
          this.addFiltersParameter(
            this.getCurrentLoadUrl()
          )
        )
        this.loadData(
          this.addFiltersParameter(
            this.getCurrentLoadUrl()
          ))
      },

    "subjects.expanded": function () {
      this.subjects.expanded.forEach(el => {
        if (el.groups === undefined)
          this.loadGroupData(el);
      })
    }
  },
  methods: {
    Rotate(half) {
      let sign = ""
      if (half === "вторая")
        sign = "-"
      return "transform: rotate(" + sign + "90deg)"
    },
    makeLedGroupLink(item){
      return {
        name: names.GROUPS.DETAIL,
        params: {
          group_id: item.id,
          type: "led"
        }
      }
    },
    loadGroupData(item){
      loadData(
        urls.URLS.SUBJECT.GROUPS(item.id,{old:this.toggle_archive}),
        (data) => {
          item.groups = data;
          item.groups.forEach(el => {
            this.$set(el, "curator_fio", "")
          })
          generateListUidToName(item.groups, "curator", "curator_fio")
        }
      )
    },
    loadData(url=undefined) {
      this.subjects.loading = true;
      if (url === undefined)
        url = urls.URLS.SUBJECT.LIST()
      loadData(
        url,
        (data) => {
          this.subjects.items = data.results;
          this.subjects.loaded = true;
          this.subjects.loading = false;
          this.mapPagination(data);
          this.setCurrentLoadUrl(url);
          this.subjects.items.forEach(el => {
            this.$set(el, "groups", undefined)
          })
        }
      )
    }
  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>



