<template>
  <v-container  >
<!--    <DebugJsonComponent :json="subject"></DebugJsonComponent>-->
    <v-card  v-if="curriculum.loaded" >
      <v-toolbar
        elevation="0"
        color="style-color-main-gray"
      >
        <v-toolbar-title>
          {{ curriculum.data.name }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items v-if="!curriculum.data.is_system">
          <v-btn text @click="goToEdit">Редактировать</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <CurriculumDetailTemplate :loaded="curriculum.loaded" :curriculum="curriculum.data"></CurriculumDetailTemplate>
      <v-data-iterator
        :items="subject"
        ::options.sync="pagination"
        :page.sync="pagination.page"
        :items-per-page.sync="pagination.size"
        :server-items-length="pagination.count"
        :footer-props="{'items-per-page-options':[10,25,50], 'items-per-page-text': 'Число элементов'}"
      >
        <template v-slot:header>
          <v-toolbar
            class="mb-2"
            color="style-color-main-gray"
            flat
          >
            <v-toolbar-title>Дисциплины в учебном плане </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              dense
              placeholder="Поиск"
              v-model="search.value"
              @keypress.enter="searchData"
              messages="Для поиска нажмите Enter"
            >
            </v-text-field>
          </v-toolbar>
        </template>

        <template v-slot:default="props">
          <v-expansion-panels
            class="px-1"
            v-model="panel"
            multiple
            focusable
          >
            <v-expansion-panel
              v-for="item in props.items"
              :key="item.id"
            >

              <v-expansion-panel-header>
                <div>
                  {{item.subject}}
                  <v-row v-if="item.sub" class="py-3">
                    <v-col v-if="item.sub.length > 0">
                    <span class="text-left"  v-for="(it,index)  in item.sub" >
                       {{it.course}} курс,
                      {{it.semester}} семестр
                      <span v-if="index!==item.sub.length-1">;</span>
                    </span>
                    </v-col>
                  </v-row>
                </div>

              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="px-4 py-3">
                  <v-col cols="12" lg="4">
                    <v-card-text class="text-left">
                      Файл рабочей программы:
                      <span  v-if="!item.work_program">Файл не загружен</span>
                      <span v-else>
                        {{item.work_program.file_name}}
                        <v-btn @click="loadFileWork(item.work_program)" icon><v-icon >get_app</v-icon></v-btn>
                      </span>

                    </v-card-text>
                  </v-col>
                  <v-col cols="12" lg="4" >
                    <v-card-text class="text-left">
                      Файл методических материалов:
                      <span  v-if="!item.methodological_materials">Файл не загружен</span>
                      <span v-else>
                        {{item.methodological_materials.file_name}}
                        <v-btn @click="loadFileWork(item.methodological_materials)" icon><v-icon >get_app</v-icon></v-btn>
                      </span>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" lg="4" >
                    <v-card-text class="text-left">
                      Файл оценочных средств:
                      <span  v-if="!item.appraisal_tools">Файл не загружен</span>
                      <span v-else>
                        {{item.appraisal_tools.file_name}}
                        <v-btn @click="loadFileWork(item.appraisal_tools)" icon><v-icon >get_app</v-icon></v-btn>
                      </span>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-card v-if="item.sub" flat>
                  <v-card flat v-if="item.sub.length > 0">
                    <div  v-for="it  in item.sub" >
                      <v-row  class="px-4 py-3">
                        <v-col cols="12" sm="6" md="4">
                          <v-card-text class="text-left" v-for="(str,index) in it.cathedrals">
                            {{str.name}}<span v-if="index!== it.cathedrals.length-1">;</span> <br>
                          </v-card-text>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-card-text class="px-7">
                            <v-row class="py-1" >Тип: {{it.control_type}}</v-row>
                            <v-row class="py-3">Зачетные единицы: {{it.zets}}</v-row>
                          </v-card-text>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-card-text class="px-7">
                            <v-row class="py-1">Аудиторные часы:  {{it.classroom_hours}}</v-row>
                            <v-row class="py-3">Самостоятельне часы:  {{it.independent_hours}}</v-row>
                          </v-card-text>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                    </div>
                  </v-card>
                  <v-card-text v-else flat>
                    Семестры данного дисциплины не были добавлены
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </v-data-iterator>
    </v-card>
  </v-container>

</template>

<script>
  import DebugJsonComponent from "../../../core/components/DebugJsonComponent";
  import CurriculumDetailTemplate from "@/modules/curriculumEditor/pages/templates/CurriculumDetailTemplate";
  import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin"
  import {loadData, setUrlParameters, addGetParameters, makeMediaUrl} from "../../../../helper";
  import names from "@/modules/curriculumEditor/routers/names";
  import dean from "@/urls/dean";
  // import {getUrlWithParameters} from "@/helper/urls";
  // import names from "@/modules/curriculumEditor/routers/";
  import {loadFile} from "@/helper/fileLoader";

  export default {
    name: "CurriculumDetail",
    mixins:[
      PaginatedDataMapperMixin
    ],
    components: {
      DebugJsonComponent,
      CurriculumDetailTemplate
    },
    props: {},
    watch: {
      panel: {
        handler () {
          for(let obj in this.panel){
              if(!this.subject[this.panel[obj]].sub){
                this.$set(this.subject[this.panel[obj]], "sub",undefined)
                this.loadSemester(this.subject[this.panel[obj]])
              }
          }
        },
        deep: true
      }
    },
    data(){
      return {
        title: "Учебный план",
        panel:[],
        total: 0,
        URL_DETAIL: dean.DEAN.CURRICULUM.CUR_DETAIL(this.$route.params.idFaculty, this.$route.params.idCurriculum),
        pagination: {},
        curriculum: {
          id: this.$route.params.idCurriculum,
          data: {},
          subject:[],
          loading: false,
          loaded: false
        },
        search: {
          parameter: "subject"
        },
        subject: []
      }
    },

    methods: {
      goToEdit(){
        // console.log({
        //   name: names.CURRICULUM.DETAIL,//'CurriculumEditorCurriculumDetail',
        //   params: {
        //     curriculum_id: this.$route.params.idCurriculum,
        //   }
        // })
        this.$router.push({
          name: names.CURRICULUM.DETAIL,//'CurriculumEditorCurriculumDetail',
          params: {
            curriculum_id: this.$route.params.idCurriculum
          },
          query: {
            faculty: this.$route.params.idFaculty
          }
        })
      },
      loadFileWork(url){
        loadFile(url)
      },
      loadData(url = undefined){
        this.loadSubject(url)
      },
      loadSemester(item){
        let saver = (data) => {
          item.sub = data
        };
        let catcher = (error) => {console.log(error)}
        loadData(
          dean.getEduUrlWithParameters(
            item.semesters.basename,
            item.semesters.action,
            item.semesters.params,
            item.semesters.get_params
          ),
          saver,
          catcher);
      },
      loadSubject(url = undefined){
        this.setLoading()
        if (url===undefined){
          this.setCurrentLoadUrl(
            dean.getEduUrlWithParameters(
              this.curriculum.data.subjects.basename,
              this.curriculum.data.subjects.action,
              this.curriculum.data.subjects.params,
              this.curriculum.data.subjects.get_params
            )
          )
          url = this.getCurrentLoadUrl()
        }
        loadData(
          url,
          (data) =>{
            this.mapResults(data, "subject")
            this.setNotLoading()

          }
        )
      },
      loadCurriculum: function () {
        let saver = (data) => {
          this.curriculum.data = data;
          this.curriculum.loaded = true;
          this.loadSubject()
        };
        let catcher = (error) => {console.log(error)}
        let url = this.URL_DETAIL
        loadData(url, saver, catcher);
      }

    },

    created() {
      this.$store.commit("mainpage/changeTitle", this.title);
      this.loadCurriculum();

    }
  }
</script>

<style scoped>

</style>
