<template>
  <scroll-center-dialog v-model="opened_form"
                        @close="closeDialog"
                        @apply="apply"
                        :loading="loading"
                        :title="!!id ? 'Изменить компетенции подготовки' : 'Добавить компетенции подготовки'"
                        width="500"
  >
    <v-text-field
      :error="hasError('name')"
      :error-messages="getError('name')"
      v-model="value.name"
      label="Наименование"
      required
    />
    <v-text-field
      :error="hasError('code')"
      :error-messages="getError('code')"
      v-model="value.code"
      label="Код"
      required
    />
  </scroll-center-dialog>
</template>

<script>
import urls from "@/urls/management";
import UserDataFormMixin from "@/mixins/UserDataFormMixin"
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

export default {
  components: {ScrollCenterDialog},
  mixins: [UserDataFormMixin],
  name: "CompetenceForm",
  props: {
    open: Boolean,
    id: Number,
    name: String,
    code: String,
    opened_form: Boolean
  },
  data: function (){
    return {
      URL_CREATE: urls.EDUCATION.REFERENCE.COMPETENCE.CREATE(),
      URL_UPDATE: urls.EDUCATION.REFERENCE.COMPETENCE.UPDATE(this.id),
      loading : false,
      value: {
        id: this.id || undefined,
        name: this.name || "",
        code: this.code || ""
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit("ended", "")
    }
  },
  created() {

  }
}
</script>

<style scoped>

</style>
