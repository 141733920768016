import { render, staticRenderFns } from "./CurrentLeftMenuAction.vue?vue&type=template&id=08e391ba&scoped=true"
import script from "./CurrentLeftMenuAction.vue?vue&type=script&lang=js"
export * from "./CurrentLeftMenuAction.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08e391ba",
  null
  
)

export default component.exports