<template>

  <v-container v-if="$route.name === names.DEPARTAMENTS.DETAIL">

    <v-layout justify-center>
      <v-flex>
        <v-card ref="form" v-if='item !== undefined'>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>
              <span v-if="item === undefined">Загрузка</span>
              <span v-else> <div>{{ item.name }}</div></span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-row  v-if='item.detail === undefined' >
            <v-col cols="12" md="9" offset-md="1">
              <v-timeline
                :dense="$vuetify.breakpoint.smAndDown"
              >
                <v-timeline-item
                  icon="groups"
                >
                  <v-card>
                    <v-row class="mx-2">
                      <v-col md="10" cols="auto" class="text-left"><router-link :to="{name: names.DEPARTAMENTS.GROUP.LIST}">Учебные группы, обучающиеся на кафедре:</router-link>
                      </v-col>
                      <v-col md="2" cols="auto" class="text-right grow"> {{item.study_group_count }}</v-col>
                    </v-row>
                  </v-card>
                </v-timeline-item>
                <v-timeline-item
                  icon="group"
                >
                  <v-card>
                    <v-row class="mx-2">
                      <v-col  md="10" cols="auto" class="text-left"><router-link :to="{name: names.DEPARTAMENTS.GROUP.LED.LIST}">Группы, которым преподаёт кафедра в текущем семестре:</router-link>
                      </v-col>
                      <v-col md="2"  cols="auto" class="text-right grow">{{item.current_group_count}}</v-col>
                    </v-row>
                  </v-card>
                </v-timeline-item>
                <v-timeline-item
                  icon="menu_book"
                >
                  <v-card>
                    <v-row class="mx-2">
                      <v-col md="10" cols="auto" class="text-left"><router-link :to="{name: names.DEPARTAMENTS.LED_SUBJECTS.LIST}">Дисциплины, преподаваемые кафедрой в текущем семестре:</router-link></v-col>
                      <v-col md="2"  cols="auto" class="text-right grow">{{ item.current_subjects_count}}</v-col>
                    </v-row>
                  </v-card>
                </v-timeline-item>
<!--                <v-timeline-item-->
<!--                  icon="access_time"-->
<!--                >-->
<!--                  <v-card>-->
<!--                    <v-row class="mx-2">-->
<!--                      <v-col md="10" cols="auto" class="text-left">общее количество часов всех дисциплин, преподаваемых на кафедре в текущем семестре:</v-col>-->
<!--                      <v-col  md="2"  cols="auto" class="text-right grow">{{item.current_hours_count}}</v-col>-->
<!--                    </v-row>-->
<!--                  </v-card>-->
<!--                </v-timeline-item>-->
                <v-timeline-item
                  icon="person"
                >
                  <v-card>
                    <v-row class="mx-2">
                      <v-col md="10" cols="auto" class="text-left"><router-link :to="{name: names.DEPARTAMENTS.TEACHER.LIST}">Преподаватели кафедры:</router-link></v-col>
                      <v-col md="2" cols="auto" class="text-right grow">{{item.current_teacher_count}}</v-col>
                    </v-row>
                  </v-card>
                </v-timeline-item>
<!--                <v-timeline-item-->
<!--                  icon="school"-->
<!--                >-->
<!--                  <v-card>-->
<!--                    <v-row class="mx-2">-->
<!--                      <v-col cols="auto" class="text-left">Общее количество преподавателей:</v-col>-->
<!--                      <v-col cols="auto" class="text-right grow">{{ cathedra.current_teacher_count }}</v-col>-->
<!--                    </v-row>-->
<!--                  </v-card>-->
<!--                </v-timeline-item>-->
              </v-timeline>
            </v-col>
          </v-row>

          <v-card-text v-else>
            <PageNotFound></PageNotFound>
          </v-card-text>
        </v-card>
        <v-layout align-center column fill-heigh justify-center v-else style="min-height: 70vh;">
          <div class="action">
            <semipolar-spinner
              :animation-duration="2000"
              :size="115"
              color="#1935ff"
            />
          </div>
        </v-layout>
      </v-flex>
    </v-layout>

  </v-container>
  <router-view :key="$route.fullPath" v-else></router-view>
</template>

<script>


import { makeGetRequest} from "@/helper";
import {getInstNameByRouterName} from "@/helper/instances";
import urls from "@/urls/dean"
import PageNotFound from "../../../../modules/core/pages/errors/Error404.vue"
import {SemipolarSpinner} from "epic-spinners";
import names from "@/modules/dean/routers/names";
import {getInsDepartmentMenu} from "@/modules/dean/helper";

export default {

  name: "DepartamentBreadcrumbs",
  components: {SemipolarSpinner, PageNotFound},
  data() {
    return {
      names: names,
      item: undefined,
      URL_DETAIL: urls.DEAN.CATHEDRA.DETAIL(this.$route.params.idFaculty, this.$route.params.uidDepartament)
    }
  },
  methods: {
    getItems() {

      let url = new URL(this.URL_DETAIL)
      let params = {};
      url.search = new URLSearchParams(params).toString();
      let sys = true
      makeGetRequest(url).then(resp => {
        if (!resp.ok) {
          sys = false
        }
        return resp.json()
      }).then(json => {
        this.item = json
        this.$store.commit("leftmenu/replaceItems", this.loadLeftMenu());
      })
    },
    loadLeftMenu: function () {
      return getInsDepartmentMenu(
        this.$route.params.idFaculty,
        this.$route.params.uidDepartament,
        this.item.name
      )
    }

  },


  created() {
    // this.$store.commit('leftmenu/activeRouterChange', this.$route.name);
    // this.$store.commit('leftmenu/selectInstance', getInstNameByRouterName(names.DETAIL));
    if (this.$route.name === names.DEPARTAMENTS.DETAIL)
      this.getItems()
  }
}
</script>

<style scoped>

</style>
