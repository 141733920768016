<template>
    <Reference v-if="is_self_route"/>
    <router-view v-else>
    </router-view>
</template>

<script>
  import Reference from "./Reference"
  import {mapState} from "vuex"
  import urls from "@/urls/management";
  import names from "@/modules/educationalManagement/routers/names";

  export default {
    name: "ReferenceBreadcrumbs",
    components: {
      Reference
    },
    data: function () {
      return {}
    },
    computed: mapState({
      mainpage: state => state.mainpage,
      is_self_route: function() {
        return this.$route.name === names.EDUCATIONAL.REFERENCE.BASE
      }
    }),
    methods: {
      allowed(urlname, action) {
        return urls.EDUCATION.getUrlAllowed(urlname, action)
      }
    },
    beforeRouteUpdate(to, from, next){
      this.$store.commit("mainpage/changeTitle", "Справочники");
      next();
    },
    created() {
      this.$store.commit("mainpage/changeTitle", "Справочники");
    }

  }
</script>

<style scoped>


</style>
}
