import ListCourses from "@/modules/administrative/pages/ListCourses.vue"

import AdministrativeBreadcrumbs from "@/modules/administrative/pages/AdministrativeBreadcrumbs.vue"

import university_data from "./university_data";
import university_refernce from "./university_refernce";

const accounts = String(process.env.VUE_APP_ACCOUNTS_PREFIX).replace("/", "");

const education = String(process.env.VUE_APP_EDUCATION_PREFIX).replace("/", "");
export default ([

  {
    path: "/administrative",
    name: "AdministrativeBreadcrumbs",
    component: AdministrativeBreadcrumbs,
    meta: {
      breadcrumb: "Административный работник",
      requiredAuth: true,
      instances: [
        accounts,
        education
      ]
    },
    children:[
      {
        path: "courses",
        name: "AdministrativeListCourses",
        component: ListCourses,
        meta: {
          breadcrumb: "Список курсов",
          requiredAuth: true,
          instances: [
            accounts,
            education
          ]
        }
      },
      ...university_data,
      ...university_refernce
    ]
  }

])
