<template>
  <v-card flat>
    <v-toolbar
      elevation="0"
      color="style-color-main-gray"
      :extension-height="extensionHeight"
    >
      <v-text-field
        dense
        placeholder="Поиск"
        v-model="pagination.search"
        @keydown.enter="$emit('searchEnter', pagination.search)"
        messages="Для поиска нажмите Enter или кнопку поиск"
      >
      </v-text-field>
      <template v-slot:extension>
        <slot name="header_actions"></slot>
      </template>
    </v-toolbar>
    <v-card-text class="pb-3 pt-0 px-0">
      <v-row no-gutters>
        <v-col>
          <v-data-table
            class="elevation-1"
            :headers="headers"
            :items="students"
            :loading="loading"
            :server-items-length="count"
            :page="page"
            @update:page="$emit('update:page', $event)"
            :items-per-page="size"
            @update:items-per-page="$emit('update:size', $event)"
            no-data-text="Нет данных"
            :footer-props="{'items-per-page-options':[10,25,50], 'footer-props.items-per-page-text': 'Число элементов'}"
          >
            <template v-for="(_, slot) of header_slots" v-slot:[slot]="scope">
              <slot :name="slot" v-bind="scope"/>
            </template>
            <template v-slot:item.fio="props">
              <span v-if="disable">
                {{ props.item.fio }}
              </span>
              <router-link v-else-if="props.item.fio !== ''" :to="maker_detail_link(props.item)">
                {{ props.item.fio }}
              </router-link>
              <v-skeleton-loader v-else type="text"></v-skeleton-loader>
            </template>
            <template v-slot:item.is_exam="props">
              <td class="justify-center">
                <v-icon v-if="props.item.is_exam">done</v-icon>
                <v-icon v-else>clear</v-icon>
              </td>
            </template>
            <template v-slot:item.admission_type="props">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs"
                        v-on="on">{{ getAbbreviation(props.item.academic_difference, props.item.is_exam) }}</span>
                </template>
                <span>{{ getAdmissionType(props.item.academic_difference, props.item.is_exam) }}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="props.item.tolerance_count > 1">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on"> <v-icon color="red"> done</v-icon></span>
                </template>
                Это {{ props.item.tolerance_count }} допуск данного вида по предмету.
              </v-tooltip>
            </template>
            <template v-slot:item.success="props">
              <v-icon v-if="props.item.success" color="green">done</v-icon>
              <v-icon v-else-if="props.item.success !== null" color="red">clear</v-icon>
              <span v-else>
            <span v-if="props.item.closed && !props.item.success">Архив</span>
            <span v-else>Не рассмотрен</span>
            </span>
              <v-tooltip bottom v-if="props.item.reopen">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="green">autorenew</v-icon>
                </template>
                <span>Переоткрыть допуск</span>
              </v-tooltip>
            </template>
            <template v-slot:item.active="props">
              <v-icon v-if="props.item.active">check_box</v-icon>
              <v-icon v-else>check_box_outline_blank</v-icon>
            </template>
            <template v-slot:item.education_group="props">
              <span v-if="disable">
                 {{ props.item.education_group.litter }}-{{
                  props.item.education_group.course
                }}{{ props.item.education_group.number }}
              </span>
              <router-link :to="maker_group_link(props.item)" v-else>
                {{ props.item.education_group.litter }}-{{
                  props.item.education_group.course
                }}{{ props.item.education_group.number }}
              </router-link>
            </template>
            <template v-slot:item.action="props">
              <v-btn icon @click="$emit('print',props.item)">
                <v-icon>print</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>


<script>

export default {
  name: "StudentListTemplate",
  props: {
    headers: {
      required: true,
      type: Array
    },
    students: {
      required: true,
      type: Array
    },
    maker_detail_link: {
      required: true,
      type: Function
    },
    maker_group_link: {
      type: Function,
      default: () => {
        return ""
      }
    },
    loading: {
      required: true,
      type: Boolean,
      default: false
    },
    disable: {
      required: true,
      type: Boolean,
      default: false
    },
    page: {
      required: true,
      type: Number
    },
    size: {
      required: true,
      type: Number
    },
    count: {
      required: true,
      type: Number
    },
    search: {
      required: true,
      type: String
    },
    extensionHeight: {
      required: false,
      type: Number
    }
  },
  data() {
    return {
      pagination: {
        search: this.search
      }
    }
  },
  computed: {
    header_slots() {
      let slots = {};
      for (let slot of Object.keys(this.$scopedSlots)) {
        if (this.headers.findIndex((el) => `header.${el.value}` === slot) !== -1)
          slots[slot] = this.$scopedSlots[slot];
      }
      return slots
    }
  },

  methods: {

    getAbbreviation(academic_difference, is_exam) {
      if (academic_difference)
        return "А/Р"
      else if (is_exam)
        return "Э"
      else
        return "Д"
    },
    getAdmissionType(academic_difference, is_exam) {
      if (academic_difference)
        return "Академическая разница"
      else if (is_exam)
        return "Экзамен/Зачет с оценкой"
      else
        return "Добор/Зачет"
    }
  }
}


</script>
<style scoped>

</style>
