<template>
      <Detail v-if="is_self_route"></Detail>
      <router-view v-else></router-view>
</template>

<script>
  import names from "@/modules/educationalManagement/routers/names";
  import Detail from "./Detail";
  import LeftMenuChangerMixin from "@/mixins/LeftMenuChangerMixin";

  export default {
    name: "EducationManagementBaseGroup",
    mixins: [LeftMenuChangerMixin],
    components: {
      Detail
    },
    data: function () {
      return {}
    },
    computed: {
      is_self_route: function() {
        return this.$route.name === names.EDUCATIONAL.GROUP.DETAIL
      }
    },
    methods: {
      getLeftMenu(){
        return [
          {title: "Назад", icon: "arrow_back_ios", router: {name: names.EDUCATIONAL.GROUP.LIST, params: this.$route.params}},
          {title: "Группа", icon: "group", router: {name: names.EDUCATIONAL.GROUP.DETAIL, params: this.$route.params}},
          {title: "Список дисциплин", icon: "list_alt", router: {name: names.EDUCATIONAL.GROUP.SUBJECTS, params: this.$route.params}},
          {title: "Список обучающихся", icon: "groups", router: {name: names.EDUCATIONAL.GROUP.STUDENTS, params: this.$route.params}},
          {title: "Текущий журнал", icon: "format_list_bulleted", router: {name: names.EDUCATIONAL.GROUP.CURRENT_JOURNAL, params: this.$route.params}}
        ];
      }
    },
    created() {}
  }
</script>

<style scoped>

</style>
