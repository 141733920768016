<template>
  <v-container fluid>
    <StudentTemplate
      v-if="loaded"
      :student="student"
      :show_extra_actions="true"
    >
      <template v-slot:student_user_email="{ email }">
        <a :href="setEmail(student.user.email)">{{student.user.email}}</a>
      </template>
      <template v-slot:education_group_title="{ education_group }">
        <router-link :to="makeGroupLink(education_group.id)">
          {{education_group.litter}}-{{education_group.course}}{{education_group.number}}
        </router-link>
      </template>
      <template v-slot:scientific_director="{scientific_director}">
        <span v-if="student.scientific_director === null">Не назначен</span>
        <span v-else class="d-flex justify-space-between">
          <span class="mr-auto">
            <router-link :to="{name:names.CathedraTeachersDetail, params: { cathedra_uid: cathedra_uid,teacher_uid:student.scientific_director}}">
              {{student.scientific_director_fio}}
            </router-link>
          </span>
        </span>
      </template>
      <template v-slot:extra_actions v-if="$route.params.taught !== 'taught'">
        <v-btn text @click="change_science_director = true">Назначить научного руководителя</v-btn>
      </template>
      <template v-slot:append_body>
<!--        <v-dialog v-model="change_science_director"  min-width="300px" max-width="800px" ref="science_director_modal">-->
<!--          <ScienceDirectorChouse-->
<!--            :teacher_uid="student.scientific_director"-->
<!--            :student_id="student.id"-->
<!--            :student_fio="student.fio"-->
<!--            :group_id="student.education_group.id"-->
<!--            @close="change_science_director = false"-->
<!--            @saved="savedScienceDirector($event)"-->
<!--          ></ScienceDirectorChouse>-->
          <ScienceDirectorStudentChange
            :teacher_uid="student.scientific_director"
            :student_id="student.id"
            :student_fio="student.fio"
            :group_id="student.education_group.id"
            :teacher_fio="student.scientific_director_fio"
            :opened_form="change_science_director"
            ref="science_director_modal"
            @close="change_science_director = false"
            @saved="savedScienceDirector($event)"
          >
            <template v-slot:snackbar="{notification, color, timeout, msg}">
              <v-snackbar
                v-model="notification"
                :timeout="timeout"
                :color="color"
                top
                right
                app
                style="border-top-left-radius: 0; border-top-right-radius: 0;"
              >
                {{msg}}
              </v-snackbar>
            </template>
          </ScienceDirectorStudentChange>

<!--        </v-dialog>-->
      </template>
    </StudentTemplate>
    <v-container v-else>
      <v-row>
        <v-col>
          <v-skeleton-loader
            height="50"
            type="list-item"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-skeleton-loader
            height="200"
            type="card"
          >
          </v-skeleton-loader>
        </v-col>
        <v-col>
          <v-skeleton-loader
            height="200"
            type="card"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
<!--    <DebugJsonComponent :json="student"></DebugJsonComponent>-->
<!--    <DebugJsonComponent :json="$route"></DebugJsonComponent>-->
<!--    {{$route}}-->
  </v-container>
</template>

<script>

  import DetailTemplate from "./DetailTemplate";
  import StudentTemplate from "@/modules/templates/students/StudentTemplate";
  import TitledPageMixin from "@/mixins/TitledPageMixin";
  import ScienceDirectorChouse from "../scientific_directors/ScienceDirectorChouse";
  import ScienceDirectorStudentChange from "@/modules/cathedra/pages/scientific_directors/ScienceDirectorStudentChange";
  import {loadData} from "@/helper";
  import {generateUidToName} from "@/helper/uidToName";
  import cathedras from "../../../../urls/cathedras";
  import accounts from "../../../../urls/accounts";
  import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
  import names from "@/modules/cathedra/routers/names";

  export default {
    name: "Detail",
    components: {
      DebugJsonComponent,
      DetailTemplate,
      StudentTemplate,
      // ScienceDirectorChouse,
      ScienceDirectorStudentChange
    },
    props: {
      student_url: {
        required: true,
        type: Object
      }
    },
    mixins: [
      // LeftMenuChangerMixin,
      TitledPageMixin],
    data: function () {
      return {
        names:names,
        title:"Обучающийся",
        cathedra_uid: this.$route.params.cathedra_uid.toUpperCase(),
        student_id: this.$route.params.student_id,
        student: null,
        loaded: false,
        attachment: false,
        change_science_director: false
      }
    },
    computed: {},
    methods: {
      setEmail(email){
        return "mailto:"+email
      },
      makeGroupLink: function (education_group_id) {
        return {
          name: names.CathedraGroupsDetail,
          params: {
            cathedra_uid: this.$route.params.cathedra_uid,
            taught: this.$route.params.taught,
            group_id: education_group_id
          },
          query: {
            student: this.student.id
          }
        }
      },
      loadStudent() {
        loadData(
          cathedras.getByRecievedUrlData(this.student_url.url),
          (data) => {
            this.student = data;
            this.$set(this.student, "fio", "");
            this.$set(this.student, "scientific_director_fio", "");
            generateUidToName(this.student, "student", "fio");
            generateUidToName(this.student, "scientific_director", "scientific_director_fio");
            loadData(
              accounts.PROFILE.DETAIL(this.student.student),
              (data) => {
                this.$set(this.student, "user", data);
                this.loaded = true;
              }
            )
          }
        )
      },
      savedScienceDirector(scientific_director) {
        this.student.scientific_director = scientific_director;
        this.change_science_director = false;
        generateUidToName(this.student, "scientific_director", "scientific_director_fio");
      }
    },
    created() {
      this.loadStudent();
      this.$store.commit("mainpage/changeTitle", this.title);
      // console.log(this.$route)
    }
  }
</script>

<style scoped>

</style>
