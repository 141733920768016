

import helper from "./helper";
import {getUrlAllowed} from "@/helper/urls";
import urls from "@/modules/dean/urls";
import names from "@/modules/dean/routers/names";
import education from "@/modules/student/pages/Education";
// import callable from "@/helper/callable";

let getEduUrlWithParameters = helper.getEduUrlWithParameters;
let makeEduMediaUrl = helper.makeEduMediaUrl;


export default {
  getEduUrlWithParameters,
  makeEduMediaUrl,
  getByRecievedUrlData: (url, gets=null) => {
    return getEduUrlWithParameters(
    url.basename,
    url.action,
    url.params,
    gets
  )} ,
  STRING:{
    PRESENTATION:
      (gets = null) => getEduUrlWithParameters(
        "worker|relations|string|presentation",
        "post",
        {},
        gets
      )
  },
  DEAN: {
    LIST:
      (gets = null) => getEduUrlWithParameters(
        "dean|relations|faculty-list",
        "list",
        {},
        gets
      ),
    JOURNAL: {
      LOAD: (faculty_uid, group_id, gets = null) =>
        getEduUrlWithParameters(
          "dean|group|group|faculty|all-current-journal",
          "current_journal",
          {
            faculty_uid: faculty_uid.toLowerCase(),
            id: group_id
          },
          gets
        ),
        SAVESEM: (faculty_uid, group_id, gets = null) =>
            getEduUrlWithParameters(
                "dean|group|group|faculty-update-semester-rating",
                "update_semester_rating",
                {
                    faculty_uid: faculty_uid.toLowerCase(),
                    id: group_id
                },
                gets
            ),
        LOADSEM: (faculty_uid, group_id, gets = null) =>
          getEduUrlWithParameters(
            "dean|group|group|faculty-semester-journal",
            "semester_journal",
            {
              faculty_uid: faculty_uid.toLowerCase(),
              id: group_id
            },
            gets
          ),
          STATEMENT: (faculty_uid, group_id, gets = null) =>
            getEduUrlWithParameters(
              "dean|group|group|faculty|all-get-statement-xlsx",
              "get_statement_xlsx",
              {
                faculty_uid: faculty_uid,
                id: group_id
              },
              gets
            ),
      SEMESTER:{
        LOAD: (faculty_uid, group_id, gets = null) =>
          getEduUrlWithParameters(
            "dean|group|detail_group-journal-students",
            "current_journal",
            {
              faculty_uid: faculty_uid.toLowerCase(),
              id: group_id
            },
            gets
          ),
        SAVE: (faculty_uid, group_id, gets = null) =>
          getEduUrlWithParameters(
            "dean|group|detail_group-update-semester-rating",
            "update_semester_rating",
            {
              faculty_uid: faculty_uid.toLowerCase(),
              id: group_id
            },
            gets
          ),
        CANCEL_RELOAD: (faculty_uid, education_id, gets = null) =>
          getEduUrlWithParameters(
            "dean|students|individual|curriculum|semester-cancel-reload",
            "cancel_reload",
            {
              faculty_uid: faculty_uid.toLowerCase(),
              education_id: education_id
            },
            gets
          ),
        SEMESTER_RELOAD: (faculty_uid, education_id, id, gets = null) =>
          getEduUrlWithParameters(
            "dean|students|individual|curriculum|semester-reload",
            "reload",
            {
              faculty_uid: faculty_uid.toLowerCase(),
              education_id: education_id,
              id: id
            },
            gets
          )
      }
    },
    EDUDOC:{
      LIST:
        ( faculty_uid,education_id,gets = null) => getEduUrlWithParameters(
          "dean|students|education|documents-list",
          "list",
          {faculty_uid:faculty_uid,
            education_id:education_id},
          gets
        ),
      CREATE:
        ( faculty_uid,education_id,gets = null) => getEduUrlWithParameters(
          "dean|students|education|documents-list",
          "list",
          {faculty_uid:faculty_uid,
            education_id:education_id},
          gets
        ),
      UPDATE:
        ( faculty_uid,education_id,id,gets = null) => getEduUrlWithParameters(
          "dean|students|education|documents-detail",
          "update",
          {id: id,
            faculty_uid:faculty_uid,
            education_id:education_id},
          gets
        ),
      DELETE:
        (faculty_uid,education_id,id, gets = null) => getEduUrlWithParameters(
          "dean|students|education|documents-detail",
          "destroy",
          {id: id,
            faculty_uid:faculty_uid,
            education_id:education_id},
          gets
        )
    },
    PORTFOLIO:{
      LIST:
        ( faculty_uid,education_id,gets = null) => getEduUrlWithParameters(
          "dean|relations|student|achievement|documents-list",
          "list",
          {faculty_uid:faculty_uid,
            education_id:education_id},
          gets
        ),
      CREATE:
        ( faculty_uid,education_id,gets = null) => getEduUrlWithParameters(
          "dean|relations|student|achievement|documents-list",
          "list",
          {faculty_uid:faculty_uid,
            education_id:education_id},
          gets
        ),
      UPDATE:
        ( faculty_uid,education_id,id,gets = null) => getEduUrlWithParameters(
          "dean|relations|student|achievement|documents-detail",
          "update",
          {id: id,
            faculty_uid:faculty_uid,
            education_id:education_id},
          gets
        ),
      DELETE:
        (faculty_uid,education_id,id, gets = null) => getEduUrlWithParameters(
          "dean|relations|student|achievement|documents-detail",
          "destroy",
          {id: id,
            faculty_uid:faculty_uid,
            education_id:education_id},
          gets
        )
    },
    TRANSFER: {
      INCOMING: {
        LIST: (faculty_uid, gets=null) => getEduUrlWithParameters(
          "dean|students|education|transfer|to-list",
          "list",
          {faculty_uid: faculty_uid},
          gets
        ),
        // urls.getUrl('dean|students|education|transfer|to-journal', 'journal').replace('<id>', this.$route.params.idDetail).replace('<faculty_uid>', this.$route.params.idFaculty)+'?from='+this.typejournal,
        JOURNAL:
          (faculty_uid, id, from) => getEduUrlWithParameters(
            "dean|students|education|transfer|to-journal",
            "journal",
            {
              faculty_uid: faculty_uid,
              id: id
            },
            {
              "from": from
            }
          ),
        DETAIL:
          (faculty_uid, id, gets=null) => getEduUrlWithParameters(
            "dean|students|education|transfer|to-detail",
            "retrieve",
            {
              faculty_uid: faculty_uid,
              id: id
            },
            gets
          ),

        REJECT:
          (faculty_uid, id, gets=null) => getEduUrlWithParameters(
            "dean|students|education|transfer|to-reject-transfer",
            "reject_transfer",
            {
              faculty_uid: faculty_uid,
              id: id
            },
            gets
          ),

        APPROVE:
          (faculty_uid, id, gets=null) => getEduUrlWithParameters(
            "dean|students|education|transfer|to-approve-transfer",
            "approve_transfer",
            {
              faculty_uid: faculty_uid,
              id: id
            },
            gets
          ),

        DIFFERENCE:
          (faculty_uid, id, gets=null) => getEduUrlWithParameters(
            "dean|students|education|transfer|to-find-difference",
            "find_difference",
            {
              faculty_uid: faculty_uid,
              id: id
            },
            gets
          )

      },
      OUTGOING: {
        LIST:
          (faculty_uid, gets=null) => getEduUrlWithParameters(
            "dean|students|education|transfer|from-list",
            "list",
            {faculty_uid: faculty_uid},
            gets
          ),
        CREATE: (faculty_uid, gets=null) => getEduUrlWithParameters(
          "dean|students|education|transfer|from-create-transfer",
          "create_transfer",
          {faculty_uid: faculty_uid},
          gets
        ),
        UPDATE:
          (faculty_uid, gets=null) => getEduUrlWithParameters(
            "dean|students|education|transfer|from-create-transfer",
            "create_transfer",
            {faculty_uid: faculty_uid},
            gets
          ),

        DETAIL:
          (faculty_uid, id, gets=null) => getEduUrlWithParameters(
            "dean|students|education|transfer|from-detail",
            "retrieve",
            {
              faculty_uid: faculty_uid,
              id: id
            },
            gets
          ),

        CANCEL:
          (faculty_uid, id, gets=null) => getEduUrlWithParameters(
            "dean|students|education|transfer|from-cancel-transfer",
            "cancel_transfer",
            {
              faculty_uid: faculty_uid,
              id: id
            },
            gets
          ),

        JOURNAL:
          (faculty_uid, id, from) => getEduUrlWithParameters(
            "dean|students|education|transfer|from-journal",
            "journal",
            {
              faculty_uid: faculty_uid,
              id: id
            },
            {
              "from": from
            }
          ),

        CHANGE_GROUP:
          (faculty_uid, id, gets=null) => getEduUrlWithParameters(
            "dean|students|education|from_faculty-change-group",
            "change_group",
            {
              faculty_uid: faculty_uid,
              id: id
            },
            gets
          ),

        DIFFERENCE:
          (faculty_uid, id, gets=null) => getEduUrlWithParameters(
            "dean|students|education|from_faculty-find-difference",
            "find_difference",
            {
              faculty_uid: faculty_uid,
              id: id
            },
            gets
          )
      }
    },
    GROUP: {
      LIST: (faculty_uid, gets=null) => getEduUrlWithParameters(
        "dean|group|group|faculty-list",
        "list",
        {
          faculty_uid: faculty_uid
        },
        gets
      ),
      DETAIL: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|group|group|faculty-detail",
        "retrieve",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      ),
      CREATE: (faculty_uid, gets=null) => getEduUrlWithParameters(
        "dean|group|group|faculty-list",
        "create",
        {
          faculty_uid: faculty_uid
        },
        gets
      ),
      UPDATE: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|group|group|faculty-detail",
        "retrieve",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      ),
      DELETE: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|group|group|faculty-detail",
        "retrieve",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      ),
      CHANGE_CATHEDRA: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|group|group|faculty-change-cathedra",
        "change_cathedra",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      ),
      CURRENT_JOURNAL: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|group|group|faculty-current-journal",
        "current_journal",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      ),
      SELECT_HEADMAN: (faculty_uid, id, gets = null) => getEduUrlWithParameters(
        "dean|group|group|faculty-select-headman",
        "select_headman",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      ),
      RECORD_BOOK: (faculty_uid, id, gets = null) => getEduUrlWithParameters(
        "dean|group|group|faculty-update-record-book-numbers",
        "update_record_book_numbers",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      ),
      STUDENTS: {
        LIST: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
          "dean|group|group|faculty-get-all-student",
          "get_all_student",
          {
            faculty_uid: faculty_uid,
            id: id
          },
          gets
        )
      },
      UPDATE_OPTIONALLY_SEMESTER: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|group|group|faculty-update-optionally-semester",
        "update_optionally_semester",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      ),
      OPTIONALLY_SEMESTER_STUDENT: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|group|group|faculty-optionally-semester-student",
        "optionally_semester_student",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      ),
      STUDENT_SEMESTER_CATHEDRALS: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|group|group|faculty-student-semester-cathedrals",
        "student_semester_cathedrals",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      ),
      UPDATE_STUDENT_CATHEDRALS: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|group|group|faculty-student-update-cathedrals",
        "student_update_cathedrals",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      )
    },
    CATHEDRA: {
      LIST: (faculty_uid, gets=null) => getEduUrlWithParameters(
        "dean|relations|cathedra-list",
        "list",
        {
          faculty_uid: faculty_uid
        },
        gets
      ),
      DETAIL: (faculty_uid, uid, gets=null) => getEduUrlWithParameters(
        "dean|relations|cathedra-detail",
        "retrieve",
        {
          faculty_uid: faculty_uid,
          uid: uid
        },
        gets
      ),
      GROUP: {
        DETAIL: (cathedra_uid, id, gets=null) => getEduUrlWithParameters(
          "dean|group|group|cathedra-detail",
          "retrieve",
          {
            cathedra_uid: cathedra_uid,
            id: id
          },
          gets
        ),
        LIST: (faculty_uid,  gets=null) => getEduUrlWithParameters(
          "dean|group|group|faculty-list",
          "list",
          {
            faculty_uid: faculty_uid
          },
          gets
        )
      },
      STUDENT:{
        LIST: (faculty_uid,  gets=null) => getEduUrlWithParameters(
          "dean|students|education|from_faculty-list",
          "list",
          {
            faculty_uid: faculty_uid
          },
          gets
        )
      },
      TEACHER:{
        LIST: (cathedra_uid,  gets=null) => getEduUrlWithParameters(
          "dean|relations|teacher-list",
          "list",
          {
            cathedra_uid: cathedra_uid
          },
          gets
        ),
        GROUP: {
          LIST: (cathedra_uid, uid,  gets=null) => getEduUrlWithParameters(
            "dean|relations|teacher-get-teacher-groups",
            "get_teacher_groups",
            {
              cathedra_uid: cathedra_uid,
              uid: uid
            },
            gets
          )
        },
        STUDENT:{
          LIST: (cathedra_uid, teacher_uid, gets=null) => getEduUrlWithParameters(
            "dean|students|teacher|students-list",
            "list",
            {
              cathedra_uid: cathedra_uid,
              teacher_uid: teacher_uid
            },
            gets
          )
        },
        SUBJECT:{
          LIST: (cathedra_uid, uid,  gets=null) => getEduUrlWithParameters(
            "dean|relations|teacher-get-teacher-subjects",
            "get_teacher_subjects",
            {
              cathedra_uid: cathedra_uid,
              uid: uid
            },
            gets
          )
        }
      },
      LED:{
        GROUP: {
          LIST: (cathedra_uid,  gets=null) => getEduUrlWithParameters(
            "dean|group|group|cathedra-list",
            "list",
            {
              cathedra_uid: cathedra_uid
            },
            gets
          )
        },
        STUDENT:{
          LIST: (cathedra_uid,  gets=null) => getEduUrlWithParameters(
            "dean|students|education|from_cathedra-list",
            "list",
            {
              cathedra_uid: cathedra_uid
            },
            gets
          )
        },
        SUBJECT:{
          LIST: (cathedra_uid,  gets=null) => getEduUrlWithParameters(
            "dean|core|subjects|from_cathedra-list",
            "list",
            {
              cathedra_uid: cathedra_uid
            },
            gets
          )
        }
      }
    },
    CURRICULUM: {
      DETAIL: (cathedra_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|group|group|cathedra-detail",
        "retrieve",
        {
          cathedra_uid: cathedra_uid,
          id: id
        },
        gets
      ),
      LIST: (faculty_uid, gets=null) => getEduUrlWithParameters(
        "dean|curriculum|curriculum|from|faculty-list",
        "list",
        {
          faculty_uid: faculty_uid
        },
        gets
      ),
      SEMESTER_LIST:(faculty_uid,group_id, gets=null) => getEduUrlWithParameters(
        "dean|curriculum|group|subject|semesters-list",
        "list",
        {
          faculty_uid: faculty_uid,
          group_id: group_id
        },
        gets
      ),
      UPDATE_CURRICULUM:(faculty_uid,id, gets=null) => getEduUrlWithParameters(
        "dean|group|group|faculty-update-curriculum",
        "update_curriculum",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      ),
      CUR_DETAIL: (faculty_uid, curriculum_id, gets=null) => getEduUrlWithParameters(
        "dean|curriculum|curriculum|from|faculty-detail",
        "retrieve",
        {
          faculty_uid: faculty_uid,
          id: curriculum_id
        },
        gets
      ),
      EDITOR: {
        EXTEND: (faculty_uid, gets=null) => getEduUrlWithParameters(
          "dean|curriculum|curriculum|from|faculty-change-extend",
          "change_extend",
          {
            faculty_uid: faculty_uid
            // id: id
          },
          gets
        ),
        // FACULTY: (id,  gets=null) => getEduUrlWithParameters(
        //   'dean|curriculum|curriculum|from|faculty-change-faculty',
        //   'change_faculty',
        //   {
        //     id: id
        //   },
        //   gets,
        // ),
        DRAFT: (faculty_uid, gets=null) => getEduUrlWithParameters(
          "dean|curriculum|curriculum|from|faculty-change-draft",
          "change_draft",
          {
            faculty_uid: faculty_uid
            // id: id
          },
          gets
        ),
        DUPLICATE: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
          "dean|curriculum|curriculum|from|faculty-duplication",
          "duplication",
          {
            faculty_uid: faculty_uid,
            id: id
          },
          gets
        )
      }
    },
    STUDENTS: {
      STATUSES:{
        LIST: (faculty_uid, education_id, gets=null) => getEduUrlWithParameters(
          "dean|students|statuses-list",
          "list",
          {
            faculty_uid: faculty_uid,
            education_id: education_id
          },
          gets
        ),
        ADD_STATUS: (faculty_uid, education_id, gets = null) => getEduUrlWithParameters(
          "dean|students|statuses-add",
          "add",
          {
            faculty_uid: faculty_uid,
            education_id: education_id
          },
          gets
        ),
        WITHDRAW_STATUS: (faculty_uid, education_id, gets = null) => getEduUrlWithParameters(
          "dean|students|last|status-withdraw",
          "withdraw",
          {
            faculty_uid: faculty_uid,
            education_id: education_id
          },
          gets
        ),
        UPDATE: (faculty_uid, education_id, id, gets = null) => getEduUrlWithParameters(
          "dean|students|statuses-detail",
          "partial_update",
          {
            faculty_uid: faculty_uid,
            education_id: education_id,
            id: id
          },
          gets
        )
      },
      LIST: (faculty_uid, gets=null) => getEduUrlWithParameters(
        "dean|students|education|from_faculty-list",
        "list",
        {
          faculty_uid: faculty_uid
        },
        gets
      ),
      DETAIL: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|students|education|from_faculty-detail",
        "retrieve",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      ),
      EXPEL: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|students|education|from_faculty-expel",
        "expel",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      ),
      RECOVERY: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|students|education|from_faculty-recovery",
        "recovery",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      ),
      UPDATE_INFO: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|students|education|from_faculty-update-info",
        "update_info",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      ),
      FULL_JOURNAL: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|students|education|from_faculty-full-journal",
        "full_journal",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      ),
      CURRICULUM: {
        DETAIL: (education_id, faculty_uid, gets=null) => getEduUrlWithParameters(
          "dean|students|individual|curriculum-detail",
          "retrieve",
          {
            education_id: education_id,
            faculty_uid: faculty_uid
          },
          gets
        ),
        UPDATE: (education_id, faculty_uid, gets=null) => getEduUrlWithParameters(
            "dean|students|individual|curriculum-detail",
            "update",
            {
                education_id: education_id,
                faculty_uid: faculty_uid
            },
            gets
        ),
        SUBJECT:{
          CREATE: (faculty_uid, education_id, gets=null) => getEduUrlWithParameters(
            "dean|students|subject-list",
            "create",
            {
              faculty_uid: faculty_uid,
              education_id: education_id
            },
            gets
          ),
          UPDATE: (education_id, id, gets=null) => getEduUrlWithParameters(
            "dean|students|subject-detail",
            "partial_update",
            {
              education_id: education_id,
              id: id
            },
            gets
          ),
          DELETE: (education_id, id, gets = null) => getEduUrlWithParameters(
            "dean|students|subject-detail",
            "destroy",
            {
              education_id: education_id,
              id: id
            },
            gets
          )
        },
        SEMESTER:{
          LIST: (faculty_uid, education_id, gets=null) => getEduUrlWithParameters(
            "dean|students|individual|curriculum|semester-list",
            "list",
            {
              faculty_uid: faculty_uid,
              education_id: education_id
            },
            gets
          ),
          DETAIL: (faculty_uid, education_id, id,  gets=null) => getEduUrlWithParameters(
            "dean|students|individual|curriculum|semester-detail",
            "retrieve",
            {
              faculty_uid: faculty_uid,
              education_id: education_id,
              id: id
            },
            gets
          ),
          CREATE: (faculty_uid, education_id, gets=null) => getEduUrlWithParameters(
            "dean|students|semester-list",
            "create",
            {
              faculty_uid: faculty_uid,
              education_id: education_id
            },
            gets
          ),
          UPDATE: (education_id, id, gets=null) => getEduUrlWithParameters(
            "dean|students|semester-detail",
            "partial_update",
            {
              education_id: education_id,
              id: id
            },
            gets
          ),
          DELETE: (education_id, id, gets = null) => getEduUrlWithParameters(
            "dean|students|semester-detail",
            "destroy",
            {
              education_id: education_id,
              id: id
            },
            gets
          ),
          INDIVIDUAL: {
            UPDATE: (faculty_uid, education_id, id, gets=null) => getEduUrlWithParameters(
              "dean|students|semester-detail",
              "update",
              {
                faculty_uid: faculty_uid,
                education_id: education_id,
                id: id
              },
              gets
            ),
            CLEAR_INDIVIDUAL: (faculty_uid, education_id, id, gets = null) => getEduUrlWithParameters(
              "dean|students|semester-clear-individual",
              "clear_individual",
              {
                faculty_uid: faculty_uid,
                education_id: education_id,
                id: id
              },
              gets
            ),
            ADD_OPTIONALLY_SEMESTER: (faculty_uid, education_id, gets = null) => getEduUrlWithParameters(
              "dean|students|semester-add-optionally-semester",
              "add_optionally_semester",
              {
                faculty_uid: faculty_uid,
                education_id: education_id
              },
              gets
            )
          }
        }
      },
      ADMISSION: {
        LIST: (faculty_uid, education_id, gets=null) => getEduUrlWithParameters(
          "dean|individual|tolerance-list",
          "list",
          {
            faculty_uid: faculty_uid,
            education_id: education_id
          },
          gets
        ),
        DETAIL: (faculty_uid, education_id, id, gets=null) => getEduUrlWithParameters(
          "dean|individual|tolerance-detail",
          "retrieve",
          {
            faculty_uid: faculty_uid,
            education_id: education_id,
            id: id
          },
          gets
        )
      },
      SEMESTER: {
        LIST: (faculty_uid, education_id, gets=null) => getEduUrlWithParameters(
          "dean|students|semester-list",
          "list",
          {
            faculty_uid: faculty_uid,
            education_id: education_id
          },
          gets
        ),
        ADD_TOLERANCE:(faculty_uid, education_id, id, gets=null) => getEduUrlWithParameters(
          "dean|students|semester-add-tolerance",
          "add_tolerance",
          {
            faculty_uid: faculty_uid,
            education_id: education_id,
            id: id
          },
          gets
        ),
        CHECK_TOLERANCE:(faculty_uid, education_id, id, gets=null) => getEduUrlWithParameters(
          "dean|students|semester-check-tolerance",
          "check_tolerance",
          {
            faculty_uid: faculty_uid,
            education_id: education_id,
            id: id
          },
          gets
        ),
        GET_TOLERANCE:(faculty_uid, education_id, id, gets=null) => getEduUrlWithParameters(
          "dean|students|semester-get-tolerance",
          "get_tolerance",
          {
            faculty_uid: faculty_uid,
            education_id: education_id,
            id: id
          },
          gets
        )
      },
      INDIVIDUAL_WORK: {
        LIST: (faculty_uid, education_id, gets=null) => getEduUrlWithParameters(
          "dean|individual|work-list",
          "list",
          {
            faculty_uid: faculty_uid,
            education_id: education_id
          },
          gets
        ),
        ADD_WORK: (faculty_uid, education_id, gets=null) => getEduUrlWithParameters(
          "dean|individual|work-list",
          "create",
          {
            faculty_uid: faculty_uid,
            education_id: education_id
          },
          gets
        ),
        DETAIL: (faculty_uid, education_id, id, gets=null) => getEduUrlWithParameters(
          "dean|individual|work-detail",
          "retrieve",
          {
            faculty_uid: faculty_uid,
            education_id: education_id,
            id: id
          },
          gets
        ),
        UPDATE_WORK: (faculty_uid, education_id, id, gets=null) => getEduUrlWithParameters(
          "dean|individual|work-detail",
          "update",
          {
            faculty_uid: faculty_uid,
            education_id: education_id,
            id: id
          },
          gets
        ),
        DELETE_WORK: (faculty_uid, education_id, id, gets=null) => getEduUrlWithParameters(
          "dean|individual|work-detail",
          "destroy",
          {
            faculty_uid: faculty_uid,
            education_id: education_id,
            id: id
          },
          gets
        )
      }

    },
    ADMISSION:{
      FILE: (faculty_uid, gets=null) => getEduUrlWithParameters(
        "dean|individual|faculty|tolerance-download-xlsx",
        "download_xlsx",
        {
          faculty_uid: faculty_uid
        },
        gets
      ),
      LIST: (faculty_uid, gets=null) => getEduUrlWithParameters(
        "dean|individual|faculty|tolerance-list",
        "list",
        {
          faculty_uid: faculty_uid
        },
        gets
      ),
      DETAIL: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|individual|faculty|tolerance-detail",
        "retrieve",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      ),
      DELETE: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|individual|faculty|tolerance-detail",
        "destroy",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      ),
      CREATE: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|individual|faculty|tolerance-create-tolerance",
        "create_tolerance",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      ),
      UPDATE: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|individual|faculty|tolerance-detail",
        "update",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      )
    },
    CERTIFICATE:{
      LIST: (faculty_uid, gets=null) => getEduUrlWithParameters(
        "dean|students|certificate-list",
        "list",
        {
          faculty_uid: faculty_uid
        },
        gets
      ),
      CREATE: (faculty_uid, gets=null) => getEduUrlWithParameters(
        "dean|students|certificate-list",
        "create",
        {
          faculty_uid: faculty_uid
        },
        gets
      ),
      UPDATE: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|students|certificate-detail",
        "destroy",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      ),
      DELETE: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|students|certificate-detail",
        "destroy",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      ),
      SAVE: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|students|certificate-detail",
        "retrieve",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      )
    },
    ADMISSIONS_REQUEST:{
      LIST: (faculty_uid, gets=null) => getEduUrlWithParameters(
        "dean|individual|faculty|tolerance|request-list",
        "list",
        {
          faculty_uid: faculty_uid
        },
        gets
      ),
      DETAIL: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|individual|faculty|tolerance|request-detail",
        "retrieve",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      ),
      DELETE: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|individual|faculty|tolerance|request-reject",
        "reject",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      ),
      ACCEPT: (faculty_uid, id, gets=null) => getEduUrlWithParameters(
        "dean|individual|faculty|tolerance|request-accept",
        "accept",
        {
          faculty_uid: faculty_uid,
          id: id
        },
        gets
      )
    },
    PERMISSION:{
      LIST: (faculty_uid, gets=null) => getEduUrlWithParameters(
        "dean|relations|workers-list",
        "list",
        {
          faculty_uid: faculty_uid
        },
        gets
      ),
      CREATE: (faculty_uid, gets=null) => getEduUrlWithParameters(
        "dean|relations|workers-list",
        "create",
        {
          faculty_uid: faculty_uid
        },
        gets
      ),
      DELETE:
        (faculty_uid,id, gets = null) => getEduUrlWithParameters(
          "dean|relations|workers-detail",
          "destroy",
          {
            id: id,
            faculty_uid:faculty_uid
          },
          gets
        )
    }
  }

}
