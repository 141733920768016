<template>
  <v-card flat class="pa-2 ma-2" v-if="$route.name === names.StudentEducationGroup">
    <v-toolbar
      class="mb-2"
      color="style-color-main-gray"
      flat
    >
      <v-toolbar-title v-if="detail.group">
        Группа: {{ detail.group.litter }}-{{ detail.group.course }}{{ detail.group.number }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-row wrap  v-if="detail.group">
      <v-col cols="12" md="6">
        <GroupTemplate
          :level="detail.group.curriculum.direction.level"
          :form="detail.group.curriculum.form"
          v-bind="detail.group">
        </GroupTemplate>
      </v-col>
      <v-col cols="12" md="6">
        <GroupCurriculumTemplate
          :direction="detail.group.curriculum.direction"
          :profile="detail.group.curriculum.profile"
          :year="detail.group.curriculum.year"
          :loaded="true"
          :cathedra="detail.group.cathedra"
          :is_system="detail.group.curriculum.is_system"
          :is_student="true"
        >
        </GroupCurriculumTemplate>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <GroupSubjectsTemplate
          v-if="detail.subjects.loaded"
          :loaded="detail.subjects.loaded"
          :semesters="detail.subjects.data"
          :expandable="false"
          :load_half="true"
        >
          <template v-slot:title>
            Дисциплины в семестре
          </template>
        </GroupSubjectsTemplate>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <GroupStudentsTemplate
          v-if="detail.students.loaded"
          :loaded="detail.students.loaded"
          :students="detail.students.data"
          :expandable="false"
        >
        </GroupStudentsTemplate>
      </v-col>
    </v-row>
<!--    <DebugJsonComponent v-bind:json="detail"></DebugJsonComponent>-->
  </v-card>

  <router-view v-else></router-view>
</template>

<script>
import urls from "@/urls/student"
import {loadData, setUrlParameters} from "@/helper";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent";
import {generateListUidToName} from "@/helper/uidToName";
import GroupTemplate from "@/modules/templates/group/GroupTemplate";
import GroupCurriculumTemplate from "@/modules/templates/group/GroupCurriculumTemplate";
import GroupSubjectsTemplate from "@/modules/templates/group/GroupSubjectsTemplate";
import GroupStudentsTemplate from "@/modules/templates/group/GroupStudentsTemplate";
import names from "@/modules/student/routers/names"

export default {
  name: "Group",
  components: {
    DebugJsonComponent,
    GroupTemplate,
    GroupCurriculumTemplate,
    GroupSubjectsTemplate,
    GroupStudentsTemplate},
  data: function () {
    return {

      detail: {
        students: {
          data: [],
          loading: false,
          loaded: false
        },
        group: null,
        subjects: {
          data: [],
          loading: false,
          loaded: false
        }
      }

    }
  },
  computed: {
    names: () => names
  },
  methods: {
    loadStudent() {
      let url = urls.STUDENT.GROUP.ALL_STUDENT(this.$route.params.idStudent, this.detail.group.id)
      loadData(
        url,
        (data) => {
          this.detail.students.data = data;
          this.detail.students.loaded = true;
          this.detail.students.loading = false;
          for (let i in this.detail.students.data) {
            this.$set(this.detail.students.data[i], "fio", "-")
          }
          generateListUidToName(this.detail.students.data, "user", "fio")
        });
    },
    loadSubjects() {
      let url = urls.STUDENT.GROUP.CURRENT_SUBJECT(this.$route.params.idStudent, this.detail.group.id)
      loadData(
        url,
        (data) => {
          this.detail.subjects.data = data;
          this.detail.subjects.loaded = true;
          this.detail.subjects.loading = false;
        });
    },
    loadGroup() {
      let url = urls.STUDENT.GROUP.DETAIL(this.$route.params.idStudent)
      loadData(
        url,
        (data) => {
          this.detail.group = data;
          this.loadStudent()
          this.loadSubjects()
        });
    }
  },
  created() {
    this.loadGroup()
    this.$store.commit("mainpage/changeTitle", "Учебная группа");
  }
}
</script>

<style scoped>

</style>
