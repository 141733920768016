<template>
  <scroll-center-dialog v-model="opened_form"
                        title="Добавить"
                        opened_form
                        @close="closeDialog"
                        @apply="apply"
                        width="500"
  >
    <v-autocomplete
      :error="hasError('semester')"
      :error-messages="getErrorArray('semester')"
      :items="selectors.semester"
      label="Допуск на предмет"
      v-model="value.semester"
      no-data-text="Нет данных"
    ></v-autocomplete>
    <v-alert v-if="hasError('detail')" type="error">{{getErrorArray('detail')}}</v-alert>
  </scroll-center-dialog>
</template>

<script>
import urls from "@/urls/student"
import selector from "@/urls/selectors"
import UserDataFormMixin from "@/mixins/UserDataFormMixin"
import {makeGetRequest} from "@/helper";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";

export default {
  name: "AdmissionRequestForm",
  components: {ScrollCenterDialog},
  mixins: [UserDataFormMixin],

  props: {
    opened_form: Boolean
  },

  data() {
    return {
      URL_CREATE: urls.STUDENT.ADMISSION_REQUEST.CREATE(this.$route.params.idStudent),
      selectors:{
        semester: []
      },
      value: {
        semester: this.semester || ""
      }
    }
  },
  methods: {
    loadSelector(data, url) {
      let sys = true
      makeGetRequest(url).then(resp => {
        if (!resp.ok) {
          sys = false
        }
        return resp.json()
      }).then(json => {
        this.selectors[data] = json
      })
    },
    closeDialog() {
      this.$emit("ended", "")
    }
  },
  created() {
    let url = selector.SELECTORS.STUDENT.ADMISSION( this.$route.params.idStudent)
    this.loadSelector("semester",url)
  }
}

</script>

<style scoped>

</style>
